import educationCheck from "/svgs/educationCheckGrey.svg"; 
import creditCheckB from "/svgs/creditCheckGrey.svg";
import employmentCheck from "/svgs/employmentCheckGrey.svg";

import { Tabs } from "../components/Tabs";
import { useState } from "react";

const BackgroundVerification: React.FC = () => {
  const tabs = [
    {
      title: "Essential Verification",
      value: "Essential Verification",
      content: (
        <div className="w-full h-auto min-h-[30rem] relative rounded-2xl p-4 md:p-10 bg-gradient-to-br from-accent to-accentSec ">
          <div className="flex items-center gap-x-4 md:gap-x-6 mb-4">
            <img src={educationCheck} alt="Education Check Badge" className="h-10 md:h-12" />
            <p className="text-2xl md:text-3xl lg:text-5xl">Essential Verification</p>
          </div>
          <EssentialVerification />
        </div>
      ),
    },
    {
      title: "Advanced Verification",
      value: "Advanced Verification",
      content: (
        <div className="w-full h-auto min-h-[30rem] relative rounded-2xl p-4 md:p-10 bg-gradient-to-br from-accent to-accentSec">
          <div className="flex items-center gap-x-4 md:gap-x-6 mb-4">
            <img src={employmentCheck} alt="Employment Check" className="h-10 md:h-12" />
            <p className="text-2xl md:text-3xl lg:text-5xl">Advanced Verification</p>
          </div>
          <AdvanceVerification />
        </div>
      ),
    },
    {
      title: "Elite Verification",
      value: "Elite Verification",
      content: (
        <div className="w-full h-auto min-h-[30rem] relative rounded-2xl p-4 md:p-10 bg-gradient-to-br from-accent to-accentSec">
          <div className="flex items-center gap-x-4 md:gap-x-6 mb-4">
            <img src={creditCheckB} alt="Credit Check Badge" className="h-10 md:h-12" />
            <p className="text-2xl md:text-3xl lg:text-5xl">Elite Verification</p>
          </div>
          <EliteVerification />
        </div>
      ),
    },
  ];

  return (
    <div className="h-auto min-h-[65rem] perspective-1000 relative flex flex-col mx-auto px-4 md:px-6 w-full items-start justify-start mb-[1050px] md:mb-48 lg:mb-30"> {/* Different bottom margins for different screen sizes */}
      <Tabs tabs={tabs} />
    </div>

  );
};

const EssentialVerification: React.FC = () => {
  const [addOnSelected, setAddOnSelected] = useState(false);
  const addOns = { socialMedia: 1000 };

  const toggleAddOn = () => {
    setAddOnSelected(!addOnSelected);
  };

  const calculateTotal = () => {
    let total = 2500;
    if (addOnSelected) total += addOns.socialMedia;
    return total;
  };

  return (
    <div className="flex flex-col md:flex-row justify-between items-start bg-white mx-4 md:mx-0 my-6 rounded-2xl p-4 md:p-8 shadow-xl gap-6">
      <div className="w-full md:w-1/2 border-r-2 md:pr-8">
        <h2 className="font-bold text-xl md:text-2xl mb-4 text-gray-800">Features</h2>
        <div className="flex flex-col gap-4 p-4 bg-white shadow-md rounded-lg text-gray-700 border border-gray-200">
          {['Identity Verification', 'Address Verification', 'Employment Verification', 'Education Verification', 'Credit Score Verification', 'Court Record Checks'].map((feature, index) => (
            <div key={index} className="flex flex-col">
              <label className="flex items-center gap-3 hover:bg-gray-100 p-2 rounded transition-colors duration-200 ease-in-out">
                <input type="checkbox" disabled checked className="accent-primary cursor-not-allowed" />
                <span className="font-semibold text-left">{feature}</span>
              </label>
              <ul className="list-disc pl-6 mt-2">
                {index === 0 && (<><li>Aadhar Card</li><li>Pan Card</li></>)}
                {index === 1 && (<><li>Physical Address Verification</li><li>Customized questionnaire for family and person inquiry</li></>)}
                {index === 2 && (<><li>Verification with Company HR</li></>)}
                {index === 3 && (<><li>Latest Education</li></>)}
                {index === 4 && (<><li>Credit assessment - Individual</li></>)}
                {index === 5 && (<><li>Criminal Records Verification</li><li>Civil Records Verification</li><li>Family Court/Developed Addresses</li></>)}
              </ul>
            </div>
          ))}
        </div>
      </div>

      <div className="w-full md:w-1/2 md:pl-8">
        <h2 className="font-bold text-xl md:text-2xl mb-4 text-gray-800">Add-On Verification</h2>
        <div className="flex flex-col gap-2 text-gray-700">
          <label className="flex items-center gap-2">
            <input type="checkbox" onChange={toggleAddOn} checked={addOnSelected} className="accent-primary" />
            Social Media Verification (+INR {addOns.socialMedia})
          </label>
          <div className="ml-6 text-sm text-gray-500">
            <span>Instagram</span>, <span>Facebook</span>, <span>LinkedIn</span>, <span>Twitter</span>
          </div>
        </div>

        <h2 className="font-bold text-xl md:text-2xl mt-4 text-gray-800">Total Price</h2>
        <div className="text-2xl md:text-3xl font-bold text-primary mt-2 mb-4">
          INR {calculateTotal()}
        </div>

        <button
          className="w-full bg-gradient-to-r from-indigo-500 to-blue-600 text-white font-semibold py-2 md:py-3 rounded-lg shadow-lg hover:from-indigo-600 hover:to-blue-700 transition-all duration-300"
          disabled={!addOnSelected}
        >
          Proceed to Payment
        </button>
      </div>
    </div>
  );
};

// Similar adjustments can be made to AdvanceVerification and EliteVerification components



const AdvanceVerification: React.FC = () => {
  const [employmentSelected, setEmploymentSelected] = useState(false);
  const [courtRecordSelected, setCourtRecordSelected] = useState(false);
  const addOns = {
    employmentVerification: 1000,
    courtRecordChecks: 1500,
  };

  const toggleEmploymentVerification = () => {
    setEmploymentSelected(!employmentSelected);
  };

  const toggleCourtRecordChecks = () => {
    setCourtRecordSelected(!courtRecordSelected);
  };

  const calculateTotal = () => {
    let total = 3500;
    if (employmentSelected) total += addOns.employmentVerification;
    if (courtRecordSelected) total += addOns.courtRecordChecks;
    return total;
  };

  return (
    <div className="flex flex-col md:flex-row justify-between place-content-center gap-y-10 bg-white mx-4 md:mx-0 my-6 rounded-2xl p-4 md:p-8 shadow-xl">
      <div className="w-full md:w-1/2 border-r-2 md:pr-8">
        <h2 className="font-bold text-xl md:text-2xl mb-4 text-gray-800">Features</h2>
        <div className="flex flex-col gap-4 p-4 bg-white shadow-md rounded-lg text-gray-700 border border-gray-200">
          {/* Features */}
          {['Identity Verification', 'Address Verification', 'Employment Verification', 'Education Verification', 'Credit Score Verification', 'Court Record Checks', 'Social Media Verification'].map((feature, index) => (
            <div key={index} className="flex flex-col gap-2 p-2 hover:bg-gray-100 rounded transition-colors duration-200 ease-in-out">
              <label className="flex items-center gap-2">
                <input type="checkbox" disabled checked className="accent-primary cursor-not-allowed" />
                <span className="font-semibold">{feature}</span>
              </label>
              <ul className="list-disc pl-8">
                {index === 0 && (<><li>Aadhar Card</li><li>Pan Card</li></>)}
                {index === 1 && (<><li>Physical Address Verification</li><li>Family Inquiry - Customized questionnaire</li></>)}
                {index === 2 && (<><li>With Company HR / Self-employment verification</li></>)}
                {index === 3 && (<><li>Latest Education Verification</li></>)}
                {index === 4 && (<><li>Individual Credit Assessment</li></>)}
                {index === 5 && (<><li>Criminal Records Verification</li><li>Civil Records Verification</li><li>Family Court/Developed Addresses</li></>)}
                {index === 6 && (<><li>Verification of latest social media activity</li></>)}
              </ul>
            </div>
          ))}
        </div>
      </div>

      <div className="w-full md:w-1/2 pl-0 md:pl-8">
        <h2 className="font-bold text-xl md:text-2xl mb-4 text-gray-800">Add-On Verification</h2>
        <div className="flex flex-col gap-2 text-gray-700">
          <label className="flex items-center gap-2">
            <input type="checkbox" onChange={toggleEmploymentVerification} checked={employmentSelected} className="accent-primary" />
            Employment Verification (+INR {addOns.employmentVerification})
          </label>
          <label className="flex items-center gap-2">
            <input type="checkbox" onChange={toggleCourtRecordChecks} checked={courtRecordSelected} className="accent-primary" />
            Court Record Checks (+INR {addOns.courtRecordChecks})
          </label>
        </div>

        <h2 className="font-bold text-xl md:text-2xl mt-4 text-gray-800">Total Price</h2>
        <div className="text-2xl md:text-3xl font-bold text-primary mt-2 mb-4">
          INR {calculateTotal()}
        </div>

        <button
          className="w-full bg-gradient-to-r from-indigo-500 to-blue-600 text-white font-semibold py-2 md:py-3 rounded-lg shadow-lg hover:from-indigo-600 hover:to-blue-700 transition-all duration-300"
          disabled={!employmentSelected && !courtRecordSelected}
        >
          Proceed to Payment
        </button>
      </div>
    </div>
  );
};

const EliteVerification: React.FC = () => {
  const [mediaProfileSelected, setMediaProfileSelected] = useState(false);
  const addOns = { mediaProfile: 2500 };

  const toggleMediaProfile = () => {
    setMediaProfileSelected(!mediaProfileSelected);
  };

  const calculateTotal = () => {
    let total = 5000;
    if (mediaProfileSelected) total += addOns.mediaProfile;
    return total;
  };

  return (
    <div className="flex flex-col md:flex-row justify-between place-content-center gap-y-10 bg-white mx-4 md:mx-0 my-6 rounded-2xl p-4 md:p-8 shadow-xl">
      <div className="w-full md:w-1/2 border-r-2 md:pr-8">
        <h2 className="font-bold text-xl md:text-2xl mb-4 text-gray-800">Features</h2>
        <div className="flex flex-col gap-4 p-4 bg-white shadow-md rounded-lg text-gray-700 border border-gray-200">
          {/* Features */}
          {['Identity Verification', 'Address Verification', 'Employment Verification', 'Education Verification', 'Credit Score Verification', 'Court Record Checks', 'Social Media Verification', 'Credit Card Verification'].map((feature, index) => (
            <div key={index} className="flex flex-col gap-2 p-2 hover:bg-gray-100 rounded transition-colors duration-200 ease-in-out">
              <label className="flex items-center gap-2">
                <input type="checkbox" disabled checked className="accent-primary cursor-not-allowed" />
                <span className="font-semibold">{feature}</span>
              </label>
              <ul className="list-disc pl-8">
                {index === 0 && (<><li>Aadhar Card</li><li>Pan Card</li></>)}
                {index === 1 && (<><li>Physical Address Verification</li><li>Family Inquiry - Customized questionnaire</li></>)}
                {index === 2 && (<><li>With Company HR / Self-employment verification</li></>)}
                {index === 3 && (<><li>Latest Education Verification</li></>)}
                {index === 4 && (<><li>Individual Credit Assessment</li></>)}
                {index === 5 && (<><li>Criminal Records Verification</li><li>Civil Records Verification</li><li>Family Court/Developed Addresses</li></>)}
                {index === 6 && (<><li>Verification of latest social media activity</li></>)}
                {index === 7 && (<><li>Verification of credit card history</li></>)}
              </ul>
            </div>
          ))}
        </div>
      </div>

      <div className="w-full md:w-1/2 pl-0 md:pl-8">
        <h2 className="font-bold text-xl md:text-2xl mb-4 text-gray-800">Add-On Verification</h2>
        <div className="flex flex-col gap-2 text-gray-700">
          <label className="flex items-center gap-2">
            <input type="checkbox" onChange={toggleMediaProfile} checked={mediaProfileSelected} className="accent-primary" />
            Media Profile Verification (+INR {addOns.mediaProfile})
          </label>
          <div className="ml-6 text-sm text-gray-500">
            <span>Instagram</span>, <span>Facebook</span>, <span>LinkedIn</span>, <span>Twitter</span>
          </div>
        </div>

        <h2 className="font-bold text-xl md:text-2xl mt-4 text-gray-800">Total Price</h2>
        <div className="text-2xl md:text-3xl font-bold text-primary mt-2 mb-4">
          INR {calculateTotal()}
        </div>

        <button
          className="w-full bg-gradient-to-r from-indigo-500 to-blue-600 text-white font-semibold py-2 md:py-3 rounded-lg shadow-lg hover:from-indigo-600 hover:to-blue-700 transition-all duration-300"
          disabled={!mediaProfileSelected}
        >
          Proceed to Payment
        </button>
      </div>
    </div>
  );
};

export default BackgroundVerification;
