import { useEffect, useState } from "react";
import { useAuthUser } from "../../pages/AuthUserContext";
import { SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import axios from "axios";
import { toast } from 'react-toastify';
const apiUrl = import.meta.env.VITE_API_URL;

const mainPhotoSchema = z.object({
  mainPhoto: z.instanceof(FileList),
});
type MainPhotoFields = z.infer<typeof mainPhotoSchema>;

const albumPhotosSchema = z.object({
  photo1: z.instanceof(FileList).optional(),
  photo2: z.instanceof(FileList).optional(),
  photo3: z.instanceof(FileList).optional(),
  photo4: z.instanceof(FileList).optional(),
  photo5: z.instanceof(FileList).optional(),
});
type AlbumPhotosFields = z.infer<typeof albumPhotosSchema>;

interface Photo {
  data: string; // Image URL
  approved: boolean; // Approval status
}

export const PhotoUpload = () => {
  const { user: TMI } = useAuthUser();  // Retrieve user ID or identifier
  const [settingPart, setSettingsPart] = useState(0);  // 0 for Profile, 1 for Album Photos
  const [previewImages, setPreviewImages] = useState<Photo[]>([]);  // Store album photo previews
  const [previewImage, setPreviewImage] = useState<string | null>(null);  // Store profile photo preview

  // Initialize forms
  const albumPhotosForm = useForm<AlbumPhotosFields>({ resolver: zodResolver(albumPhotosSchema) });
  const mainPhotoForm = useForm<MainPhotoFields>({ resolver: zodResolver(mainPhotoSchema) });

  // Fetch profile and album photos when the component loads
  useEffect(() => {
    if (TMI) fetchPhotos();  // Fetch only if user ID exists
  }, [TMI]);

  const fetchPhotos = async () => {
    try {
      // Fetch profile photo
      const profileResponse = await axios.get(`${apiUrl}/api/profile/${TMI}`);
      const { user } = profileResponse.data;
  
      // Set profile photo preview and log URL
      if (user && user.photo) {
        const profilePhotoUrl = `${apiUrl}${user.photo}`;
        setPreviewImage(profilePhotoUrl);
        console.log('Profile photo URL:', profilePhotoUrl); // Log profile photo URL
      } else {
        console.warn('No profile photo found.');
      }
  
      // Fetch album photos
      const albumResponse = await axios.get(`${apiUrl}/api/profile/${TMI}/photos`);
      const { albumPhotos } = albumResponse.data;
  
      console.log('Fetched album photos from API:', albumPhotos); // Log fetched album photos
  
      // Set album photos previews and log each photo URL
      if (albumPhotos && albumPhotos.length > 0) {
        const galleryPhotos = albumPhotos.map((photo: any) => {
          // Check if 'data' field exists and use it to construct the image URL
          if (!photo.data) {
            console.warn('No photo data found for photo:', photo); // Warn about missing data
          }
  
          const photoUrl = `${apiUrl}${photo.data}`;
          console.log(`Gallery photo URL for ${photo._id}: ${photoUrl}`); // Log gallery photo URL
  
          return {
            data: photoUrl, // Full URL of the photo
            approved: photo.approved, // Approval status
          };
        });
  
        setPreviewImages(galleryPhotos);
      } else {
        console.warn('No album photos found.');
      }
    } catch (error) {
      console.error('Error fetching user photos:', error);
    }
  };
  
  


// Handle profile photo change and preview
const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
        const objectUrl = URL.createObjectURL(file);
        setPreviewImage(objectUrl);
        console.log('Updated profile photo preview URL:', objectUrl); // Log new profile preview URL
    }
};

// Handle individual album photo changes and previews
const handleImagesChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const file = e.target.files?.[0];
    if (file) {
        const objectUrl = URL.createObjectURL(file);
        const newPreviewImages: Photo[] = [...previewImages];
        newPreviewImages[index] = {
            data: objectUrl, // URL preview of the new file
            approved: false,
        };
        setPreviewImages(newPreviewImages);
        console.log(`Updated gallery photo preview URL at index ${index}:`, objectUrl); // Log new gallery preview URL
    }
};


  // Submit profile photo
  const onMainPhotoSubmit: SubmitHandler<MainPhotoFields> = async (data) => {
    try {
      const formData = new FormData();
      formData.append('file', data.mainPhoto[0]);  // Add profile photo to FormData

      const response = await axios.post(`${apiUrl}/api/photos/uploadProfilePhoto/${TMI}`, formData, {
        headers: { "Content-Type": "multipart/form-data" }
      });

      if (response.status === 200) toast.success("Profile photo uploaded successfully!");
    } catch (error) {
      console.error('Error uploading profile photo:', error);
      toast.error("Error uploading profile photo");
    }
  };

  // Submit album photos
  const onAlbumPhotosSubmit: SubmitHandler<AlbumPhotosFields> = async (data) => {
    try {
      const formData = new FormData();

      // Collecting new files and appending them to FormData
      for (let index = 0; index < 5; index++) { // Adjust this based on the number of photos
        const fileList = data[`photo${index + 1}` as keyof AlbumPhotosFields] as FileList;

        if (fileList && fileList.length > 0) {
          const file = fileList[0]; // Just take the first file
          formData.append("photos", file, file.name); // Append the new file
        } else if (previewImages[index]) { // Accessing existing photo
          // If no new file was uploaded, include existing photo binary data
          const existingPhoto = previewImages[index];
          const response = await fetch(existingPhoto.data);
          const blob = await response.blob(); // Convert to Blob
          formData.append("photos", blob); // Add existing binary data as Blob
        }
      }

      const response = await axios.post(`${apiUrl}/api/photos/uploadAlbumPhotos/${TMI}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (response.status === 200) {
        fetchPhotos();
        toast.success("Album photos uploaded successfully!");
      }
    } catch (error) {
      console.error("Error uploading album photos", error);
      toast.error("Error uploading album photos");
    }
  };

  return (
    <div className="flex flex-col laptop:flex-row gap-x-6 laptop:p-8 transition-all duration-500">
      {/* Navigation: Profile and Album Photos */}
      <div className="w-full laptop:w-[30%] flex flex-wrap laptop:flex-nowrap place-content-center laptop:place-content-start laptop:flex-col my-6 laptop:my-0">
        {["Upload Profile Photo", "Upload Album Photos"].map((label, index) => (
          <button
            className={`relative group/btn border-2 px-3 transition-colors duration-300 ${settingPart === index ? "bg-accent/30" : "bg-white hover:bg-accent/20"} 
              laptop:px-0 h-10 font-medium shadow-md hover:shadow-xl rounded-lg transition-transform transform hover:scale-105 
              ${index > 0 ? "laptop:border-t-0 border-l-0 laptop:border-l-2" : ""}`}
            type="button"
            key={index}
            onClick={() => setSettingsPart(index)}
          >
            {label}
            <BottomGradient/>
          </button>
        ))}
      </div>
  
      {/* Profile Photo Upload */}
      <div className={`${settingPart === 0 ? "flex" : "hidden"} w-full laptop:w-[70%] flex-col border-2 p-4 rounded-lg shadow-lg transition-all duration-500 ease-in-out`}>
        <form onSubmit={mainPhotoForm.handleSubmit(onMainPhotoSubmit)} className="flex flex-col gap-6 place-items-center">
          <div>
            <p className="home-heading text-2xl font-bold mb-2">Upload Profile Photo</p>
            <p className="home-subheading text-gray-600">Set your profile photo</p>
          </div>
          <div className="w-[8.5rem] h-48 border-2 border-gray-300 rounded-lg flex-col place-items-center cursor-pointer relative hover:border-accent/40 transition-all duration-300">
            {previewImage ? (
              <img src={previewImage} alt="Preview" className="object-cover w-full h-full rounded-lg transition-transform transform hover:scale-110 duration-300 ease-in-out" />
            ) : (
              <span className="text-center w-full">Upload Photo</span>
            )}
            <input
              {...mainPhotoForm.register("mainPhoto")}
              type="file"
              accept="image/*"
              className="absolute inset-0 opacity-0 cursor-pointer"
              onChange={handleImageChange}
            />
          </div>
          <h4 className="text-blue-600">Click here to Upload your photo</h4>
          <div className="flex place-content-center">
            <button type="submit" className="btn p-2 bg-gradient-to-r from-blue-500 to-purple-500 text-white rounded-lg hover:shadow-lg hover:scale-105 transition-transform duration-300">
              Upload
            </button>
          </div>
        </form>
      </div>
  
      {/* Album Photos Upload */}
      <form
        className={`${settingPart === 1 ? "flex" : "hidden"} w-full flex-col laptop:w-[70%] flex-wrap gap-6 border-2 p-4 rounded-lg shadow-lg transition-all duration-500`}
        onSubmit={albumPhotosForm.handleSubmit(onAlbumPhotosSubmit)}
      >
        <div>
          <p className="home-heading text-2xl font-bold mb-2">Album Photos</p>
          <p className="home-subheading text-gray-600">Showcase your photos</p>
        </div>
        <div className="grid mobileLg:grid-cols-2 tablet:grid-cols-3 laptop:grid-cols-4 gap-8 place-items-center place-content-center">
          {["photo1", "photo2", "photo3", "photo4", "photo5"].map((field, index) => (
            <label
              key={field}
              className="flex place-items-center border-2 w-full h-48 border-gray-300 rounded-lg cursor-pointer relative hover:border-blue-400 transition-all duration-300"
            >
              {previewImages[index] ? (
                <>
                  {/* Added more logging here */}
                  <img 
                    src={previewImages[index].data} 
                    alt={`Preview ${field}`} 
                    className="object-cover w-full h-full rounded-lg transition-transform transform hover:scale-110 duration-300 ease-in-out" 
                  />
                  
                </>
              ) : (
                <span className="text-center w-full p-3">CLICK HERE to upload/re-upload {field}</span>
              )}
              <input
                {...albumPhotosForm.register(field as keyof AlbumPhotosFields)}
                type="file"
                accept="image/*"
                className="absolute inset-0 opacity-0 cursor-pointer"
                onChange={(e) => handleImagesChange(e, index)}
              />
            </label>
          ))}
        </div>
        <h4 className="text-blue-600">Click on respective photo for reupload and update</h4>
        <div className="flex place-content-center">
          <button type="submit" className="btn w-36 p-2 bg-gradient-to-r from-teal-400 to-green-500 text-white rounded-lg hover:shadow-lg hover:scale-105 transition-transform duration-300">
            Upload
          </button>
        </div>
      </form>
    </div>
  );
  
};

const BottomGradient = () => {
  return (
    <>
      <span className="group-hover/btn:opacity-100 block transition duration-500 opacity-0 absolute h-px w-full -bottom-px inset-x-0 bg-gradient-to-r from-transparent via-cyan-500 to-transparent" />
      <span className="group-hover/btn:opacity-100 blur-sm block transition duration-500 opacity-0 absolute h-px w-1/2 mx-auto -bottom-px inset-x-10 bg-gradient-to-r from-transparent via-indigo-500 to-transparent" />
    </>
  );
};
