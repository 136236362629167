import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const apiUrl = import.meta.env.VITE_API_URL;

interface Coupon {
  _id: string;
  code: string;
  validFrom: string;
  validUntil: string;
  applicablePlans: { months: number; plans: { name: string }[] }[];
  discount: number;
}

const formatDate = (dateString: string) => {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  };
  return new Intl.DateTimeFormat('en-US', options).format(new Date(dateString));
};

const CouponList: React.FC = () => {
  const [coupons, setCoupons] = useState<Coupon[]>([]);
  const [selectedCoupons, setSelectedCoupons] = useState<{ [key: string]: boolean }>({});
  const [tmiValues, setTmiValues] = useState<{ [key: string]: string }>({});
  const [errorMessages, setErrorMessages] = useState<{ [key: string]: string | null }>({});
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    fetchCoupons();
  }, []);

  const fetchCoupons = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/coupons`);
      const sortedCoupons = response.data.sort((a: Coupon, b: Coupon) => {
        // Get the minimum months from the applicable plans for each coupon
        const minMonthsA = Math.min(...a.applicablePlans.map(plan => plan.months));
        const minMonthsB = Math.min(...b.applicablePlans.map(plan => plan.months));
        return minMonthsA - minMonthsB; // Sort by the minimum months
      });
      setCoupons(sortedCoupons);
    } catch (error) {
      console.error('Error fetching coupons:', error);
    }
  };
  

  const handleCheckboxChange = (couponCode: string, checked: boolean) => {
    setSelectedCoupons((prev) => ({
      ...prev,
      [couponCode]: checked,
    }));
    if (checked) {
      setErrorMessages((prev) => ({
        ...prev,
        [couponCode]: null,
      }));
    }
  };

  const handleTMIChange = (couponCode: string, value: string) => {
    setTmiValues((prev) => ({
      ...prev,
      [couponCode]: value,
    }));
    if (value.trim()) {
      setErrorMessages((prev) => ({
        ...prev,
        [couponCode]: null,
      }));
    }
  };

  const validateFields = (couponCode: string): boolean => {
    const isCheckboxChecked = selectedCoupons[couponCode];
    const tmiValue = tmiValues[couponCode] || '';
  
    if (!tmiValue || !isCheckboxChecked) {
      setErrorMessages((prev) => ({
        ...prev,
        [couponCode]: 'Please enter TMI and select the coupon before sending.',
      }));
      return false;
    }
    return true;
  };

  const handleSendCoupon = async (couponCode: string) => {
    if (!validateFields(couponCode)) return;

    const selectedCoupon = coupons.find((coupon) => coupon.code === couponCode);

    if (selectedCoupon && selectedCoupons[couponCode]) {
      const { discount, validFrom, validUntil, applicablePlans } = selectedCoupon;
      const selectedPlan = applicablePlans.length > 0 ? applicablePlans[0].plans[0].name : '';
      const selectedMonths = applicablePlans.length > 0 ? applicablePlans[0].months : 0;

      if (discount <= 0) {
        setErrorMessages((prev) => ({
          ...prev,
          [couponCode]: 'Invalid discount. Please check the coupon details.',
        }));
        return;
      }

      setLoading(true);

      try {
        const response = await axios.post(`${apiUrl}/api/send-coupon`, {
          couponCode,
          tmi: tmiValues[couponCode],
          selectedPlan,
          selectedMonths,
          discount,
          validFrom,
          validUntil,
        });

        toast.success(response.data.message || 'Coupon sent successfully!');
        fetchCoupons();
      } catch (error) {
        const err = error as any;

        if (err.response) {
          toast.error(err.response.data.message || 'Error sending coupon');
        } else if (err.request) {
          toast.error('No response received from server');
        } else {
          toast.error('Error setting up request: ' + err.message);
        }
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} closeOnClick draggable pauseOnHover />
      <h2 className="text-2xl font-bold mb-4">Available Coupons</h2>
      {loading && <div className="loader"></div>}
      
      <div className="overflow-auto">
        <table className="min-w-full bg-white border rounded-lg shadow-sm">
          <thead>
            <tr className="bg-gray-200 text-gray-600 text-left text-sm uppercase tracking-wider">
              <th className="px-6 py-3">Code</th>
              <th className="px-6 py-3">Discount</th>
              <th className="px-6 py-3">Valid From</th>
              <th className="px-6 py-3">Valid Until</th>
              <th className="px-6 py-3">Applicable Plans</th>
              <th className="px-6 py-3">Select</th>
              <th className="px-6 py-3">TMI</th>
              <th className="px-6 py-3">Send</th>
            </tr>
          </thead>
          <tbody>
            {coupons.map((coupon) => (
              <tr key={coupon._id} className="border-t text-gray-700">
                <td className="px-6 py-4 font-semibold text-green-600">{coupon.code}</td>
                <td className="px-6 py-4">INR {coupon.discount}</td>
                <td className="px-6 py-4">{formatDate(coupon.validFrom)}</td>
                <td className="px-6 py-4">{formatDate(coupon.validUntil)}</td>
                <td className="px-6 py-4">
                  {coupon.applicablePlans.map((plan, index) => (
                    <div key={index}>
                      {plan.months} Months: {plan.plans.map((p) => p.name).join(', ')}
                    </div>
                  ))}
                </td>
                <td className="px-6 py-4">
                  <input
                    type="checkbox"
                    checked={selectedCoupons[coupon.code] || false}
                    onChange={(e) => handleCheckboxChange(coupon.code, e.target.checked)}
                  />
                </td>
                <td className="px-6 py-4">
                  <input
                    type="text"
                    className="w-full p-2 border rounded"
                    placeholder="Enter TMI"
                    value={tmiValues[coupon.code] || ''}
                    onChange={(e) => handleTMIChange(coupon.code, e.target.value)}
                  />
                  {errorMessages[coupon.code] && (
                    <div className="text-red-500 mt-1">{errorMessages[coupon.code]}</div>
                  )}
                </td>
                <td className="px-6 py-4">
                  <button
                    className="bg-blue-500 text-white py-1 px-4 rounded hover:bg-blue-600 transition"
                    onClick={() => handleSendCoupon(coupon.code)}
                    disabled={loading}
                  >
                    Send
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CouponList;


