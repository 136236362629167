export const dropdownData = {
    gender:["Male","Female"],
    education : {
        Doctorates: [
          "Ph.D.",
          "DM",
          "Postdoctoral fellow",
          "Fellow of National Board (FNB)",
        ],
        "Service - IAS / IPS / IRS / IES / IFS": [
          "IAS",
          "IPS",
          "IRS",
          "IES",
          "IFS",
          "Other Degree in Service",
        ],
        "Any Financial Qualification - ICWAI / CA / CS/ CFA": [
          "CA",
          "ICWA",
          "CS",
          "CFA (Chartered Financial Analyst)",
          "Other Degree in Finance",
        ],
        "Any Masters in Arts / Science / Commerce": [
          "M.Phil.",
          "MCom",
          "M.A.",
          "M.Ed.",
          "MLIS",
          "MSW",
          "Others",
          "MFA",
          "M.Des",
        ],
        "Any Masters in Engineering / Computers": [
          "M.S.(Engg.)",
          "M.Arch.",
          "MCA",
          "PGDCA",
          "ME",
          "M.Tech.",
          "IT / Computer Science",
          "Other Masters Degree in Engineering / Computers",
        ],
        "Any Masters in Legal": ["M.L.", "LL.M.", "Other Master Degree in  Legal"],
        "Any Masters in Management": [
          "MHM  (Hotel Management)",
          "MBA",
          "PGDM",
          "MHRM (Human Resource Management)",
          "MFM (Financial Management)",
          "Other Master Degree in Management",
          "MHA / MHM (Hospital Administration)",
        ],
        "Any Masters in Medicine - General / Dental / Surgeon": [
          "MD / MS (Medical)",
          "MDS",
          "MVSc ",
          "MCh",
          "DNB",
        ],
        "Any Bachelors in Arts / Science / Commerce": [
          "B.Phil.",
          "B.A.",
          "B.Ed.",
          "Aviation Degree",
          "BFA",
          "BLIS",
          "B.S.W",
          "B.M.M.",
          "BFT",
          "Other Bachelor Degree in Arts / Science / Commerce",
          "B.Des",
        ],
        "Any Bachelors in Engineering / Computers": [
          "BCA",
          "Aeronautical Engineering",
          "B.Arch",
          "B.Plan",
          "BE",
          "B.Tech.",
          "Other Bachelor Degree in Engineering / Computers",
          "IT/ Computer Science",
          "B.S.(Engineering)",
        ],
        "Any Bachelors in Legal": [
          "BGL",
          "B.L.",
          "LL.B.",
          "Other Bachelor Degree in Legal",
        ],
        "Any Bachelors in Management": [
          "BHM (Hotel Management)",
          "BBA",
          "BFM (Financial Management)",
          "Other Bachelor Degree in Management",
          "BHA / BHM (Hospital Administration)",
        ],
        "Any Bachelors in Medicine in General / Dental / Surgeon": [
          "MBBS",
          "BDS",
          "BVSc",
          "BHMS",
          "B.A.M.S.",
          "BSMS",
          "BUMS",
        ],
        "Any Diploma": [
          "Trade School",
          "Diploma",
          "Polytechnic",
          "Others in Diploma",
        ],
        "Higher Secondary / Secondary": ["Higher Secondary School / High School"],
        "Any Bachelors in Medicine Others": [
          "BPT",
          "BPharm",
          "Other Bachelor Degree in Medicine",
          "Nursing",
        ],
        "Any Masters in Medicine Others": [
          "MPT",
          "M.Pharm",
          "Other Master Degree in Medicine",
        ],
    },
    age: [
      18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36,
      37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55,
      56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74,
      75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93,
      94, 95, 96, 97, 98, 99
    ],
    occupations: {
        ADMINISTRATION: [
          "Manager",
          "Supervisor",
          "Officer",
          "Administrative Professional",
          "Executive",
          "Clerk",
          "Human Resources Professional",
          "Secretary / Front Office",
        ],
        AGRICULTURE: ["Agriculture & Farming Professional", "Horticulturist"],
        AIRLINE: ["Pilot", "Air Hostess / Flight Attendant", "Airline Professional"],
        "ARCHITECTURE & DESIGN": ["Architect", "Interior Designer"],
        "BANKING & FINANCE": [
          "Chartered Accountant",
          "Company Secretary",
          "Accounts / Finance Professional",
          "Banking Service Professional",
          "Auditor",
          "Financial Accountant",
          "Financial Analyst / Planning",
          "Investment Professional",
        ],
        "BEAUTY & FASHION": [
          "Fashion Designer",
          "Beautician",
          "Hair Stylist",
          "Jewellery designer",
          "Designer (others)",
          "Makeup Artist",
        ],
        "BPO & CUSTOMER SERVICE": [
          "BPO / KPO / ITes Professional",
          "Customer Service Professional",
        ],
        "CIVIL SERVICES": ["Civil Services (IAS / IES / IFS / IPS / IRS)"],
        "CORPORATE PROFESSIONALS": [
          "Analyst",
          "Consultant",
          "Corporate Communication",
          "Corporate Planning",
          "Marketing Professional",
          "Operations Management",
          "Sales Professional",
          "Senior Manager / Manager",
          "Subject Matter Expert",
          "Business Development Professional",
          "Content Writer",
        ],
        DEFENCE: [
          "Army",
          "Navy",
          "Defence Services (Others)",
          "Air Force",
          "Paramilitary",
        ],
        "EDUCATION & TRAINING": [
          "Professor / Lecturer",
          "Teaching / Academician",
          "Education Professional",
          "Training Professional",
          "Research Assistant",
          "Research Scholar",
        ],
        ENGINEERING: [
          "Civil Engineer",
          "Electronics / Telecom Engineer",
          "Mechanical / Production Engineer",
          "Quality Assurance Engineer - Non IT",
          "Engineer - Non IT",
          "Designer",
          "Product Manager - Non IT",
          "Project Manager - Non IT",
        ],
        HOSPITALITY: [
          "Hotel / Hospitality Professional",
          "Restaurant / Catering Professional",
          "Chef / Cook",
        ],
        "IT & SOFTWARE": [
          "Software Professional",
          "Hardware Professional",
          "Product Manager",
          "Project Manager",
          "Program Manager",
          "Animator",
          "Cyber / Network Security",
          "UI / UX Designer",
          "Web / Graphic Designer",
          "Software Consultant",
          "Data Analyst",
          "Data Scientist",
          "Network Engineer",
          "Quality Assurance Engineer",
        ],
        LEGAL: ["Lawyer & Legal Professional", "Legal Assistant"],
        "POLICE / LAW ENFORCEMENT": ["Law Enforcement Officer", "Police"],
        "MEDICAL & HEALTHCARE OTHER": [
          "Healthcare Professional",
          "Paramedical Professional",
          "Nurse",
          "Pharmacist",
          "Physiotherapist",
          "Psychologist",
          "Therapist",
          "Medical Transcriptionist",
          "Dietician / Nutritionist",
          "Lab Technician",
          "Medical Representative",
        ],
        "MEDIA & ENTERTAINMENT": [
          "Journalist",
          "Media Professional",
          "Entertainment Professional",
          "Event Management Professional",
          "Advertising / PR Professional",
          "Designer",
          "Actor / Model",
          "Artist",
        ],
        "MERCHANT NAVY": ["Mariner / Merchant Navy", "Sailor"],
        SCIENTIST: ["Scientist / Researcher"],
        "SENIOR MANAGEMENT": [
          "CXO / President, Director, Chairman",
          "VP / AVP / GM / DGM / AGM",
        ],
        DOCTOR: ["Doctor", "Dentist", "Surgeon", "Veterinary Doctor"],
        OTHERS: [
          "Technician",
          "Arts & Craftsman",
          "Student",
          "Librarian",
          "Business Owner / Entrepreneur",
          "Retired",
          "Transportation / Logistics Professional",
          "Agent / Broker / Trader",
          "Contractor",
          "Fitness Professional",
          "Security Professional",
          "Social Worker / Volunteer / NGO",
          "Sportsperson",
          "Travel Professional",
          "Singer",
          "Writer",
          "Politician",
          "Associate",
          "Builder",
          "Chemist",
          "CNC Operator",
          "Distributor",
          "Driver",
          "Freelancer",
          "Mechanic",
          "Musician",
          "Photo / Videographer",
          "Surveyor",
          "Tailor",
          "Not working",
          "Others",
        ],
    },
    
    partnerOccupations: [
      {
      label: 'ADMINISTRATION',
      options: [
      { value: 'Manager', label: 'Manager' },
      { value: 'Supervisor', label: 'Supervisor' },
      { value: 'Officer', label: 'Officer' },
      { value: 'Administrative Professional', label: 'Administrative Professional' },
      { value: 'Executive', label: 'Executive' },
      { value: 'Clerk', label: 'Clerk' },
      { value: 'Human Resources Professional', label: 'Human Resources Professional' },
      { value: 'Secretary / Front Office', label: 'Secretary / Front Office' },
      ],
      },
      {
      label: 'AGRICULTURE',
      options: [
      { value: 'Agriculture & Farming Professional', label: 'Agriculture & Farming Professional' },
      { value: 'Horticulturist', label: 'Horticulturist' },
      ],
      },
      {
      label: 'AIRLINE',
      options: [
      { value: 'Pilot', label: 'Pilot' },
      { value: 'Air Hostess / Flight Attendant', label: 'Air Hostess / Flight Attendant' },
      { value: 'Airline Professional', label: 'Airline Professional' },
      ],
      },
      {
      label: 'ARCHITECTURE & DESIGN',
      options: [
      { value: 'Architect', label: 'Architect' },
      { value: 'Interior Designer', label: 'Interior Designer' },
      ],
      },
      {
      label: 'BANKING & FINANCE',
      options: [
      { value: 'Chartered Accountant', label: 'Chartered Accountant' },
      { value: 'Company Secretary', label: 'Company Secretary' },
      { value: 'Accounts / Finance Professional', label: 'Accounts / Finance Professional' },
      { value: 'Banking Service Professional', label: 'Banking Service Professional' },
      { value: 'Auditor', label: 'Auditor' },
      { value: 'Financial Accountant', label: 'Financial Accountant' },
      { value: 'Financial Analyst / Planning', label: 'Financial Analyst / Planning' },
      { value: 'Investment Professional', label: 'Investment Professional' },
      ],
      },
      {
      label: 'BEAUTY & FASHION',
      options: [
      { value: 'Fashion Designer', label: 'Fashion Designer' },
      { value: 'Beautician', label: 'Beautician' },
      { value: 'Hair Stylist', label: 'Hair Stylist' },
      { value: 'Jewellery designer', label: 'Jewellery designer' },
      { value: 'Designer (others)', label: 'Designer (others)' },
      { value: 'Makeup Artist', label: 'Makeup Artist' },
      ],
      },
      {
      label: 'BPO & CUSTOMER SERVICE',
      options: [
      { value: 'BPO / KPO / ITes Professional', label: 'BPO / KPO / ITes Professional' },
      { value: 'Customer Service Professional', label: 'Customer Service Professional' },
      ],
      },
      {
      label: 'CIVIL SERVICES',
      options: [
      { value: 'Civil Services (IAS / IES / IFS / IPS / IRS)', label: 'Civil Services (IAS / IES / IFS / IPS / IRS)' },
      ],
      },
      {
      label: 'CORPORATE PROFESSIONALS',
      options: [
      { value: 'Analyst', label: 'Analyst' },
      { value: 'Consultant', label: 'Consultant' },
      { value: 'Corporate Communication', label: 'Corporate Communication' },
      { value: 'Corporate Planning', label: 'Corporate Planning' },
      { value: 'Marketing Professional', label: 'Marketing Professional' },
      { value: 'Operations Management', label: 'Operations Management' },
      { value: 'Sales Professional', label: 'Sales Professional' },
      { value: 'Senior Manager / Manager', label: 'Senior Manager / Manager' },
      { value: 'Subject Matter Expert', label: 'Subject Matter Expert' },
      { value: 'Business Development Professional', label: 'Business Development Professional' },
      { value: 'Content Writer', label: 'Content Writer' },
      ],
      },
      {
      label: 'DEFENCE',
      options: [
      { value: 'Army', label: 'Army' },
      { value: 'Navy', label: 'Navy' },
      { value: 'Defence Services (Others)', label: 'Defence Services (Others)' },
      { value: 'Air Force', label: 'Air Force' },
      { value: 'Paramilitary', label: 'Paramilitary' },
      ],
      },
    ],
    
    castes: ["Any","Achari", "Adhi Karnataka", "Adi Dravidar", "Agamudaiyar", "Agarwal", 
      "Anglo Indian", "Arora", "Arunthathiyar", "Arya Vysya", "Ayyaraka", "Badaga", 
      "Bajantri", "Balija", "BC", "Besta", "Bharathar", "Bhatraju", "Bhovi", "Billava", 
      "Boyar", "Brahmin Bhatta", "Brahmin Daivadnya", "Brahmin Deshastha", "Brahmin Dhiman", 
      "Brahmin Garhwali", "Brahmin Gurukkal", "Brahmin Iyengar", "Brahmin Niyogi", "Brahmin Others", 
      "Caste No Bar", "Cheraman", "Cherumar", "Chettiar", "Devanga", "Devendra Kula Vellalar", 
      "Ediga", "Ezhava", "Fernando", "Garo", "Gavara", "Gounder", "Gowda", "Gramani", 
      "Intercaste", "Jagamar", "Kallar", "Kamalar", "Kamma", "Kammalar", "Kapu", "Karuneegar", 
      "Khasi", "Knanaya", "Kongu Vellala Gounder", "Kuki", "Kulal", "Kulalar", "Kuravan", 
      "Lal", "Linga Yath", "Madiga", "Mahar", "Mahendra", "Mala", "Mannadiyar", "Maravar", 
      "Maruthuvar", "Matang", "Meenavar", "Mizo", "Mogaveera", "Moopanar", "Mudaliar", 
      "Mukkuvar", "Muslim Ansari", "Muslim Bohra", "Muthuraja", "NAA", "Nadar", "Naga", 
      "Naicker", "Naidu", "Nair", "Navithar", "OBC", "OC", "Oraon", "Other", "Padaiyachi", 
      "Padmasali", "Pallar", "Pandaram", "Paravar", "Parayar", "Parkava Kulam", "Penwj", 
      "Perika", "Pillai", "Pillaimar"
    ],
    income: [
        "0 - 1 Lakh",
        "1 - 2 Lakhs",
        "2 - 3 Lakhs",
        "3 - 4 Lakhs",
        "4 - 5 Lakhs",
        "5 - 6 Lakhs",
        "6 - 7 Lakhs",
        "7 - 8 Lakhs",
        "8 - 9 Lakhs",
        "9 - 10 Lakhs",
        "10 - 12 Lakhs",
        "12 - 14 Lakhs",
        "14 - 16 Lakhs",
        "16 - 18 Lakhs",
        "18 - 20 Lakhs",
        "20 - 25 Lakhs",
        "25 - 30 Lakhs",
        "30 - 35 Lakhs",
        "35 - 40 Lakhs",
        "40 - 45 Lakhs",
        "45 - 50 Lakhs",
        "50 - 60 Lakhs",
        "60 - 70 Lakhs",
        "70 - 80 Lakhs",
        "80 - 90 Lakhs",
        "90 Lakhs - 1 Crore",
        "1 Crore & Above",
    ],
    localLanguages: ['English', 'Hindi', 'Kannada', 'Malayalam', 'Tamil', 'Telugu'],
    heights : [
      4.0,
      4.1,
      4.2,
      4.3,
      4.4,
      4.5,
      4.6,
      4.7,
      4.8,
      4.9, 
      5.0,
      5.1,
      5.2,
      5.3,
      5.4,
      5.5,
      5.6,
      5.7,
      5.8,
      5.9,
      6.0,
      6.1,
      6.2,
      6.3,
      6.4,
      6.5,
      6.6,
      6.7,
      6.8,
      6.9,
      7.0,
      7.1,
      7.2,
      7.3,
      7.4,
      7.5,
      7.6,
      7.7,
      7.8,
      7.9,
    ],
    motherTongue : [
        
        "Assamese",
        "Bengali",
        "English",
        "Gujarati",
        "Hindi",
        "Kannada",
        "Konkani",
        "Malayalam",
        "Marathi",
        "Marwari",
        "Odiya",
        "Punjabi",
        "Sindhi",
        "Tamil",
        "Telugu",
        "Urdu",
        "Angika",
        "Arunachali",
        "Awadhi",
        "Badaga",
        "Bhojpuri",
        "Bihari",
        "Brij",
        "Chatisgarhi",
        "Dogri",
        "French",
        "Garhwali",
        "Garo",
        "Haryanvi",
        "Himachali/Pahari",
        "Kanauji",
        "Kashmiri",
        "Khandesi",
        "Khasi",
        "Koshali",
        "Kumoani",
        "Kutchi",
        "Ladacki",
        "Lepcha",
        "Magahi",
        "Maithili",
        "Manipuri",
        "Miji",
        "Mizo",
        "Monpa",
        "Nepali",
        "Nicobarese",
        "Rajasthani",
        "Sanskrit",
        "Santhali",
        "Sourashtra",
        "Tripuri",
        "Tulu",
    ],      
    residentialLocation : {
        A: ["Adugodi", "Agara", "Anjanapura", "Arekere", "Austin Town"],
        B: [
          "Babusapalya",
          "Bagalur",
          "Bahubalinagar",
          "Baiyyappanahalli",
          "Banashankari",
          "Banaswadi",
          "Basavanagudi",
          "Basaveshwaranagara",
          "Bellandur",
          "Bengaluru Pete",
          "Bharathnagar",
          "Bilekahalli",
          "Binnamangala",
          "Bommanahalli",
          "Bommasandra",
          "Brookefield",
          "BTM Layout",
          "Byatarayanapura",
        ],
        C: [
          "Carmelaram",
          "Chamarajpet",
          "Chandapura",
          "Chickpet",
          "Chikkalasandra",
          "Cooke Town",
          "Cox Town",
          "CV Raman Nagar",
        ],
        D: [
          "Dasarahalli",
          "Devara Jeevanahalli",
          "Devarachikkanahalli",
          "Dollars Colony",
          "Domlur",
        ],
        E: ["Ejipura", "Electronic City"],
        F: ["Fraser Town"],
        G: [
          "G. M. Palya",
          "Gandhi Bazaar",
          "Gandhi Nagar",
          "Gangamma Circle",
          "Ganganagar",
          "Garudacharpalya",
          "Girinagar",
          "Gottigere",
          "Gowdanapalya",
        ],
        H: [
          "Hanumanthanagar",
          "Hebbagodi",
          "Hebbal",
          "Hoodi",
          "Horamavu",
          "HSR Layout",
          "Hulimavu",
        ],
        I: ["Immadihalli", "Indiranagar", "Ittamadu"],
        J: [
          "Jakkasandra",
          "Jakkur",
          "Jalahalli",
          "Jayanagar",
          "Jayaprakash Nagar",
          "Jeevanbimanagar",
          "Jigani",
        ],
        K: [
          "Kadugodi",
          "Kaggadasapura",
          "Kalasipalyam",
          "Kalkere",
          "Kalyan Nagar",
          "Kammanahalli",
          "Kengeri",
          "Kodigehalli",
          "Kodihalli",
          "Konanakunte",
          "Koppa gate",
          "Koramangala",
          "Krishnarajapuram",
          "Kumaraswamy Layout",
          "Kundalahalli",
        ],
        L: ["Lingarajapuram"],
        M: [
          "Madiwala",
          "Mahadevapura",
          "Mahalakshmi Layout",
          "Malleshwaram",
          "Marathahalli",
          "Mariyannapalya",
          "Milk Colony",
          "Murphy Town",
          "Murugeshpalya",
          "Muthyalanagar",
        ],
        N: ["Nagarbhavi", "Nandini Layout", "Nayandahalli"],
        P: ["Padmanabhanagar", "Palace Guttahalli", "Peenya", "Puttenahalli"],
        R: [
          "R. T. Nagar",
          "Rajajinagar",
          "Rajarajeshwari Nagar",
          "Ramachandrapura",
          "Ramagondanahalli",
          "Ramamurthy Nagar",
          "Ramanjaneyanagar",
          "Richmond Town",
        ],
        S: [
          "Sadashivanagar",
          "Sahakara Nagar",
          "Sanjaynagar",
          "Seshadripura",
          "Shikaripalya",
          "Shivajinagar",
          "Shree Ananthnagara",
          "Siddapura",
          "Singasandra",
          "Statue of Edward VII",
          "Statue of Queen Victoria",
          "Suddaguntepalya",
          "Sulikunte",
        ],
        T: ["Tannery Road", "Thindlu", "Thyagarajanagar"],
        U: ["Ulsoor", "Uttarahalli"],
        V: [
          "Varthur",
          "Vasanth Nagar",
          "Vidyaranyapura",
          "Vijayanagar",
          "Vimanapura",
          "Vyalikaval",
        ],
        W: ["Whitefield", "Wilson Garden"],
        Y: ["Yelachenahalli", "Yelahanka", "Yeswanthpur"],
    },
    // countries : [
    //   "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Antigua and Barbuda", 
    //   "Argentina", "Armenia", "Australia", "Austria", "Azerbaijan", "Bahamas", "Bahrain", 
    //   "Bangladesh", "Barbados", "Belarus", "Belgium", "Belize", "Benin", "Bhutan", 
    //   "Bolivia", "Bosnia and Herzegovina", "Botswana", "Brazil", "Brunei", "Bulgaria", 
    //   "Burkina Faso", "Burundi", "Cabo Verde", "Cambodia", "Cameroon", "Canada", 
    //   "Central African Republic", "Chad", "Chile", "China", "Colombia", "Comoros", 
    //   "Congo (Congo-Brazzaville)", "Costa Rica", "Croatia", "Cuba", "Cyprus", 
    //   "Czechia (Czech Republic)", "Democratic Republic of the Congo", "Denmark", 
    //   "Djibouti", "Dominica", "Dominican Republic", "Ecuador", "Egypt", "El Salvador", 
    //   "Equatorial Guinea", "Eritrea", "Estonia", "Eswatini", "Ethiopia", 
    //   "Fiji", "Finland", "France", "Gabon", "Gambia", "Georgia", "Germany", "Ghana", 
    //   "Greece", "Grenada", "Guatemala", "Guinea", "Guinea-Bissau", "Guyana", "Haiti", 
    //   "Honduras", "Hungary", "Iceland", "India", "Indonesia", "Iran", "Iraq", "Ireland", 
    //   "Israel", "Italy", "Jamaica", "Japan", "Jordan", "Kazakhstan", "Kenya", "Kiribati", 
    //   "Kuwait", "Kyrgyzstan", "Laos", "Latvia", "Lebanon", "Lesotho", "Liberia", "Libya", 
    //   "Liechtenstein", "Lithuania", "Luxembourg", "Madagascar", "Malawi", "Malaysia", 
    //   "Maldives", "Mali", "Malta", "Marshall Islands", "Mauritania", "Mauritius", 
    //   "Mexico", "Micronesia", "Moldova", "Monaco", "Mongolia", "Montenegro", "Morocco", 
    //   "Mozambique", "Myanmar (formerly Burma)", "Namibia", "Nauru", "Nepal", "Netherlands", 
    //   "New Zealand", "Nicaragua", "Niger", "Nigeria", "North Korea", "North Macedonia", 
    //   "Norway", "Oman", "Pakistan", "Palau", "Palestine State", "Panama", "Papua New Guinea", 
    //   "Paraguay", "Peru", "Philippines", "Poland", "Portugal", "Qatar", "Romania", 
    //   "Russia", "Rwanda", "Saint Kitts and Nevis", "Saint Lucia", "Saint Vincent and the Grenadines", 
    //   "Samoa", "San Marino", "Sao Tome and Principe", "Saudi Arabia", "Senegal", 
    //   "Serbia", "Seychelles", "Sierra Leone", "Singapore", "Slovakia", "Slovenia", 
    //   "Solomon Islands", "Somalia", "South Africa", "South Korea", "South Sudan", 
    //   "Spain", "Sri Lanka", "Sudan", "Suriname", "Sweden", "Switzerland", "Syria", 
    //   "Tajikistan", "Tanzania", "Thailand", "Timor-Leste", "Togo", "Tonga", "Trinidad and Tobago", 
    //   "Tunisia", "Turkey", "Turkmenistan", "Tuvalu", "Uganda", "Ukraine", "United Arab Emirates", 
    //   "United Kingdom", "United States of America", "Uruguay", "Uzbekistan", "Vanuatu", 
    //   "Vatican City", "Venezuela", "Vietnam", "Yemen", "Zambia", "Zimbabwe"
    // ],
   
    
    states : [
      "Andhra Pradesh", "Arunachal Pradesh", "Assam", "Bihar", "Chhattisgarh", 
      "Goa", "Gujarat", "Haryana", "Himachal Pradesh", "Jharkhand", "Karnataka", 
      "Kerala", "Madhya Pradesh", "Maharashtra", "Manipur", "Meghalaya", 
      "Mizoram", "Nagaland", "Odisha", "Punjab", "Rajasthan", "Sikkim", 
      "Tamil Nadu", "Telangana", "Tripura", "Uttar Pradesh", "Uttarakhand", 
      "West Bengal"
    ],
    districts : [
      "Bagalkot", "Bangalore Rural", "Bangalore Urban", "Belagavi", "Bellary", "Bidar", 
      "Chamarajanagar", "Chikballapur", "Chikkamagaluru", "Chitradurga", "Dakshina Kannada", 
      "Davanagere", "Dharwad", "Gadag", "Hassan", "Haveri", "Kalaburagi", "Kodagu", 
      "Kolar", "Koppal", "Mandya", "Mysuru", "Raichur", "Ramanagara", "Shivamogga", 
      "Tumakuru", "Udupi", "Uttara Kannada", "Vijayapura", "Yadgir"
    ],  
    complexion : [
        "Dark",
        "Wheatish",
        "Wheatish Brown",
        "Fair",
        "Very Fair",
    ],
    createdFor : [
        "Myself",
        "Son",
        "Daughter",
        "Brother",
        "Sister",
        "Relative",
        "Friend",
    ],
    maritalStatus: [ "Unmarried", "Widow/Widower", "Divorced", "Separated"],
    familyType : ["Nuclear Family", "Joint Family"],
    familyStatus : [
        "Middle Class",
        "Upper Middle Class",
        "Rich/Affluent",
    ],      
    denominations : [
      
      "Adventist",
      "Anglican / Episcopal",
      "Apostolic",
      "Assyrian",
      "Assembly of God (AG)",
      "Baptist",
      "Born Again",
      "Brethren",
      "Calvinist",
      "Catholic",
      "Church of God",
      "Church of South India (CSI)",
      "Church of Christ",
      "Church of North India",
      "Congregational",
      "East Indian Catholic",
      "Evangelical",
      "Knanaya",
      "Knanaya Catholic",
      "Knanaya Jacobite",
      "Jacobite",
      "Jehovah's Witnesses",
      "Latin Catholic",
      "Latter day saints",
      "Lutheran",
      "Malankara",
      "Malabar Independent Syrian Church",
      "Marthoma",
      "Melkite",
      "Mennonite",
      "Methodist",
      "Moravian",
      "Orthodox",
      "Pentecostal",
      "Protestant",
      "Presbyterian",
      "Reformed Baptist",
      "Reformed Presbyterian",
      "Seventh-day Adventist",
      "St. Thomas Evangelical",
      "Syro Malabar",
      "Syrian Catholic",
      "Syrian Jacobite",
      "Syrian Orthodox",
      
    ],      
    residenceType : ["Rented", "Own"],
    timeSlots: ["1200 hrs - 1500 hrs", "1700 hrs - 2100 hrs"],
    physicalStatus: ["Normal", "Physically Challenged"],
    bodyType: ["Slim", "Athletic", "Average", "Heavy"],
    weight: ["41 kg", "42 kg", "43 kg", "44 kg", "45 kg", "46 kg", "47 kg", "48 kg", "49 kg", "50 kg",
    "51 kg", "52 kg", "53 kg", "54 kg", "55 kg", "56 kg", "57 kg", "58 kg", "59 kg", "60 kg",
    "61 kg", "62 kg", "63 kg", "64 kg", "65 kg", "66 kg", "67 kg", "68 kg", "69 kg", "70 kg",
    "71 kg", "72 kg", "73 kg", "74 kg", "75 kg", "76 kg", "77 kg", "78 kg", "79 kg", "80 kg",
    "81 kg", "82 kg", "83 kg", "84 kg", "85 kg", "86 kg", "87 kg", "88 kg", "89 kg", "90 kg",
    "91 kg", "92 kg", "93 kg", "94 kg", "95 kg", "96 kg", "97 kg", "98 kg", "99 kg", "100 kg",
    "101 kg", "102 kg", "103 kg", "104 kg", "105 kg", "106 kg", "107 kg", "108 kg", "109 kg", "110 kg",
    "111 kg", "112 kg", "113 kg", "114 kg", "115 kg", "116 kg", "117 kg", "118 kg", "119 kg", "120 kg",
    "121 kg", "122 kg", "123 kg", "124 kg", "125 kg", "126 kg", "127 kg", "128 kg", "129 kg", "130 kg",
    "131 kg", "132 kg", "133 kg", "134 kg", "135 kg", "136 kg", "137 kg", "138 kg", "139 kg", "140 kg"],
    smokingHabits: ["Non-smoker", "Social Smoker", "Regular Smoker"],
    drinkingHabits: ["Non-drinker", "Social Drinker", "Regular Drinker"],
    eatingHabits: ["Vegetarian", "Non-vegetarian", "Eggetarian", "Vegan"],
    spirituality: ["Very religious",
    "Believe in Jesus not in religion",
    "Sunday Church Goer",
    "Average Christian",
    "Not religious",
    "Not given it a thought"],
    churchActivity: ["Church Choir", "Worship Leader", "Youth Fellowship", "Sunday School", "Music & Ministry"],
    familyValues: ["Orthodox", "Traditional", "Moderate", "Liberal"],
    employmentType: ["Government/PSU",
    "Private",
    "Business",
    "Defence",
    "Self Employed",
    "Not Working"],
    hobbies: ["Acting",
    "Art / Handicraft",
    "Astrology / Palmistry / Numerology",
    "Bird watching",
    "Collecting antiques",
    "Collecting Coins",
    "Collecting Stamps",
    "Cooking",
    "Dancing",
    "Film-making",
    "Fishing",
    "Gardening / Landscaping",
    "Graphology",
    "Ham radio",
    "Model building",
    "Painting",
    "Photography",
    "Playing musical instruments",
    "Singing",
    "Solving Crosswords, Puzzles",
    "Taking care of pets"],
    interests: ["Alternative healing",
    "Blogging",
    "Health & Fitness",
    "Learning new languages",
    "Listening to music",
    "Movies",
    "Net surfing",
    "Politics",
    "Reading / Book clubs",
    "Sports - Indoor",
    "Sports - Outdoor",
    "Theatre",
    "Travel / Sightseeing",
    "Trekking / Adventure sports",
    "Video / Computer games",
    "Volunteering / Social Service",
    "Watching television",
    "Writing",
    "Yoga / Meditation"],
    favouriteMusic: ["Acid Rock",
    "Bhajans / Devotional",
    "Blues",
    "Classical - Carnatic",
    "Classical - Hindustani",
    "Classical - Western",
    "Disco",
    "Ghazals",
    "Heavy Metal",
    "Hip-Hop",
    "House Music",
    "Indipop",
    "Instrumental - Indian",
    "Instrumental - Western",
    "Jazz",
    "Latest film songs",
    "Old film songs",
    "Pop",
    "Qawalis",
    "Rap",
    "Reggae",
    "Sufi music",
    "Techno"],
    favouriteReads: ["Biographies",
    "Business / Occupational",
    "Classic literature",
    "Comics",
    "Fantasy",
    "History",
    "Humour",
    "Magazines & Newspapers",
    "Philosophy / Spiritual",
    "Poetry",
    "Romance",
    "Science Fiction",
    "Self-help",
    "Short stories",
    "Thriller / Suspense"],
    favouriteFood: ["Arabic",
    "Bengali",
    "Chinese",
    "Continental",
    "Fast food",
    "Gujarati",
    "Italian",
    "Konkan",
    "Latin American",
    "Lebanese",
    "Mexican",
    "Moghlai",
    "Punjabi",
    "Rajasthani",
    "South Indian",
    "Spanish",
    "Sushi",
    "Thai"],
    sports: ["Adventure sports",
    "Aerobics",
    "Badminton",
    "Baseball",
    "Basketball",
    "Billiards / Snooker / Pool",
    "Bowling",
    "Card games",
    "Carrom",
    "Chess",
    "Cricket",
    "Cycling",
    "Football",
    "Golf",
    "Hockey",
    "Jogging / Walking",
    "Martial Arts",
    "Rugby",
    "Scrabble",
    "Squash",
    "Swimming / Water sports",
    "Table-tennis",
    "Tennis",
    "Volleyball",
    "Weight training",
    "Yoga / Meditation"],
    dressStyles: ["Casual - usually in jeans and T-shirts",
    "Classic Indian - typically Indian formal wear",
    "Classic Western - typically western formal wear",
    "Designer - only leading brands will do",
    "Trendy - in line with the latest fashion"],
    howDidYouKnowAboutUs: ["Our Website", "Marketing Campaign", "Referral", "Advertisement", "Friend"]
}


export const countriesWithPhoneCodes = [
  { country: "Afghanistan", code: "+93", isoCode: "AF" },
  { country: "Albania", code: "+355", isoCode: "AL" },
  { country: "Algeria", code: "+213", isoCode: "DZ" },
  { country: "Andorra", code: "+376", isoCode: "AD" },
  { country: "Angola", code: "+244", isoCode: "AO" },
  { country: "Antigua and Barbuda", code: "+1-268", isoCode: "AG" },
  { country: "Argentina", code: "+54", isoCode: "AR" },
  { country: "Armenia", code: "+374", isoCode: "AM" },
  { country: "Australia", code: "+61", isoCode: "AU" },
  { country: "Austria", code: "+43", isoCode: "AT" },
  { country: "Azerbaijan", code: "+994", isoCode: "AZ" },
  { country: "Bahamas", code: "+1-242", isoCode: "BS" },
  { country: "Bahrain", code: "+973", isoCode: "BH" },
  { country: "Bangladesh", code: "+880", isoCode: "BD" },
  { country: "Barbados", code: "+1-246", isoCode: "BB" },
  { country: "Belarus", code: "+375", isoCode: "BY" },
  { country: "Belgium", code: "+32", isoCode: "BE" },
  { country: "Belize", code: "+501", isoCode: "BZ" },
  { country: "Benin", code: "+229", isoCode: "BJ" },
  { country: "Bhutan", code: "+975", isoCode: "BT" },
  { country: "Bolivia", code: "+591", isoCode: "BO" },
  { country: "Bosnia and Herzegovina", code: "+387", isoCode: "BA" },
  { country: "Botswana", code: "+267", isoCode: "BW" },
  { country: "Brazil", code: "+55", isoCode: "BR" },
  { country: "Brunei", code: "+673", isoCode: "BN" },
  { country: "Bulgaria", code: "+359", isoCode: "BG" },
  { country: "Burkina Faso", code: "+226", isoCode: "BF" },
  { country: "Burundi", code: "+257", isoCode: "BI" },
  { country: "Cabo Verde", code: "+238", isoCode: "CV" },
  { country: "Cambodia", code: "+855", isoCode: "KH" },
  { country: "Cameroon", code: "+237", isoCode: "CM" },
  { country: "Canada", code: "+1", isoCode: "CA" },
  { country: "Central African Republic", code: "+236", isoCode: "CF" },
  { country: "Chad", code: "+235", isoCode: "TD" },
  { country: "Chile", code: "+56", isoCode: "CL" },
  { country: "China", code: "+86", isoCode: "CN" },
  { country: "Colombia", code: "+57", isoCode: "CO" },
  { country: "Comoros", code: "+269", isoCode: "KM" },
  { country: "Congo (Congo-Brazzaville)", code: "+242", isoCode: "CG" },
  { country: "Costa Rica", code: "+506", isoCode: "CR" },
  { country: "Croatia", code: "+385", isoCode: "HR" },
  { country: "Cuba", code: "+53", isoCode: "CU" },
  { country: "Cyprus", code: "+357", isoCode: "CY" },
  { country: "Czechia (Czech Republic)", code: "+420", isoCode: "CZ" },
  { country: "Democratic Republic of the Congo", code: "+243", isoCode: "CD" },
  { country: "Denmark", code: "+45", isoCode: "DK" },
  { country: "Djibouti", code: "+253", isoCode: "DJ" },
  { country: "Dominica", code: "+1-767", isoCode: "DM" },
  { country: "Dominican Republic", code: "+1-809", isoCode: "DO" },
  { country: "Ecuador", code: "+593", isoCode: "EC" },
  { country: "Egypt", code: "+20", isoCode: "EG" },
  { country: "El Salvador", code: "+503", isoCode: "SV" },
  { country: "Equatorial Guinea", code: "+240", isoCode: "GQ" },
  { country: "Eritrea", code: "+291", isoCode: "ER" },
  { country: "Estonia", code: "+372", isoCode: "EE" },
  { country: "Eswatini", code: "+268", isoCode: "SZ" },
  { country: "Ethiopia", code: "+251", isoCode: "ET" },
  { country: "Fiji", code: "+679", isoCode: "FJ" },
  { country: "Finland", code: "+358", isoCode: "FI" },
  { country: "France", code: "+33", isoCode: "FR" },
  { country: "Gabon", code: "+241", isoCode: "GA" },
  { country: "Gambia", code: "+220", isoCode: "GM" },
  { country: "Georgia", code: "+995", isoCode: "GE" },
  { country: "Germany", code: "+49", isoCode: "DE" },
  { country: "Ghana", code: "+233", isoCode: "GH" },
  { country: "Greece", code: "+30", isoCode: "GR" },
  { country: "Grenada", code: "+1-473", isoCode: "GD" },
  { country: "Guatemala", code: "+502", isoCode: "GT" },
  { country: "Guinea", code: "+224", isoCode: "GN" },
  { country: "Guinea-Bissau", code: "+245", isoCode: "GW" },
  { country: "Guyana", code: "+592", isoCode: "GY" },
  { country: "Haiti", code: "+509", isoCode: "HT" },
  { country: "Honduras", code: "+504", isoCode: "HN" },
  { country: "Hungary", code: "+36", isoCode: "HU" },
  { country: "Iceland", code: "+354", isoCode: "IS" },
  { country: "India", code: "+91", isoCode: "IN" },
  { country: "Indonesia", code: "+62", isoCode: "ID" },
  { country: "Iran", code: "+98", isoCode: "IR" },
  { country: "Iraq", code: "+964", isoCode: "IQ" },
  { country: "Ireland", code: "+353", isoCode: "IE" },
  { country: "Israel", code: "+972", isoCode: "IL" },
  { country: "Italy", code: "+39", isoCode: "IT" },
  { country: "Jamaica", code: "+1-876", isoCode: "JM" },
  { country: "Japan", code: "+81", isoCode: "JP" },
  { country: "Jordan", code: "+962", isoCode: "JO" },
  { country: "Kazakhstan", code: "+7", isoCode: "KZ" },
  { country: "Kenya", code: "+254", isoCode: "KE" },
  { country: "Kiribati", code: "+686", isoCode: "KI" },
  { country: "Kuwait", code: "+965", isoCode: "KW" },
  { country: "Kyrgyzstan", code: "+996", isoCode: "KG" },
  { country: "Laos", code: "+856", isoCode: "LA" },
  { country: "Latvia", code: "+371", isoCode: "LV" },
  { country: "Lebanon", code: "+961", isoCode: "LB" },
  { country: "Lesotho", code: "+266", isoCode: "LS" },
  { country: "Liberia", code: "+231", isoCode: "LR" },
  { country: "Libya", code: "+218", isoCode: "LY" },
  { country: "Liechtenstein", code: "+423", isoCode: "LI" },
  { country: "Lithuania", code: "+370", isoCode: "LT" },
  { country: "Luxembourg", code: "+352", isoCode: "LU" },
  { country: "Madagascar", code: "+261", isoCode: "MG" },
  { country: "Malawi", code: "+265", isoCode: "MW" },
  { country: "Malaysia", code: "+60", isoCode: "MY" },
  { country: "Maldives", code: "+960", isoCode: "MV" },
  { country: "Mali", code: "+223", isoCode: "ML" },
  { country: "Malta", code: "+356", isoCode: "MT" },
  { country: "Marshall Islands", code: "+692", isoCode: "MH" },
  { country: "Mauritania", code: "+222", isoCode: "MR" },
  { country: "Mauritius", code: "+230", isoCode: "MU" },
  { country: "Mexico", code: "+52", isoCode: "MX" },
  { country: "Micronesia", code: "+691", isoCode: "FM" },
  { country: "Moldova", code: "+373", isoCode: "MD" },
  { country: "Monaco", code: "+377", isoCode: "MC" },
  { country: "Mongolia", code: "+976", isoCode: "MN" },
  { country: "Montenegro", code: "+382", isoCode: "ME" },
  { country: "Morocco", code: "+212", isoCode: "MA" },
  { country: "Mozambique", code: "+258", isoCode: "MZ" },
  { country: "Myanmar (Burma)", code: "+95", isoCode: "MM" },
  { country: "Namibia", code: "+264", isoCode: "NA" },
  { country: "Nauru", code: "+674", isoCode: "NR" },
  { country: "Nepal", code: "+977", isoCode: "NP" },
  { country: "Netherlands", code: "+31", isoCode: "NL" },
  { country: "New Zealand", code: "+64", isoCode: "NZ" },
  { country: "Nicaragua", code: "+505", isoCode: "NI" },
  { country: "Niger", code: "+227", isoCode: "NE" },
  { country: "Nigeria", code: "+234", isoCode: "NG" },
  { country: "North Korea", code: "+850", isoCode: "KP" },
  { country: "North Macedonia", code: "+389", isoCode: "MK" },
  { country: "Norway", code: "+47", isoCode: "NO" },
  { country: "Oman", code: "+968", isoCode: "OM" },
  { country: "Pakistan", code: "+92", isoCode: "PK" },
  { country: "Palau", code: "+680", isoCode: "PW" },
  { country: "Panama", code: "+507", isoCode: "PA" },
  { country: "Papua New Guinea", code: "+675", isoCode: "PG" },
  { country: "Paraguay", code: "+595", isoCode: "PY" },
  { country: "Peru", code: "+51", isoCode: "PE" },
  { country: "Philippines", code: "+63", isoCode: "PH" },
  { country: "Poland", code: "+48", isoCode: "PL" },
  { country: "Portugal", code: "+351", isoCode: "PT" },
  { country: "Qatar", code: "+974", isoCode: "QA" },
  { country: "Romania", code: "+40", isoCode: "RO" },
  { country: "Russia", code: "+7", isoCode: "RU" },
  { country: "Rwanda", code: "+250", isoCode: "RW" },
  { country: "Saint Kitts and Nevis", code: "+1-869", isoCode: "KN" },
  { country: "Saint Lucia", code: "+1-758", isoCode: "LC" },
  { country: "Saint Vincent and the Grenadines", code: "+1-784", isoCode: "VC" },
  { country: "Samoa", code: "+685", isoCode: "WS" },
  { country: "San Marino", code: "+378", isoCode: "SM" },
  { country: "Sao Tome and Principe", code: "+239", isoCode: "ST" },
  { country: "Saudi Arabia", code: "+966", isoCode: "SA" },
  { country: "Senegal", code: "+221", isoCode: "SN" },
  { country: "Serbia", code: "+381", isoCode: "RS" },
  { country: "Seychelles", code: "+248", isoCode: "SC" },
  { country: "Sierra Leone", code: "+232", isoCode: "SL" },
  { country: "Singapore", code: "+65", isoCode: "SG" },
  { country: "Slovakia", code: "+421", isoCode: "SK" },
  { country: "Slovenia", code: "+386", isoCode: "SI" },
  { country: "Solomon Islands", code: "+677", isoCode: "SB" },
  { country: "Somalia", code: "+252", isoCode: "SO" },
  { country: "South Africa", code: "+27", isoCode: "ZA" },
  { country: "South Korea", code: "+82", isoCode: "KR" },
  { country: "South Sudan", code: "+211", isoCode: "SS" },
  { country: "Spain", code: "+34", isoCode: "ES" },
  { country: "Sri Lanka", code: "+94", isoCode: "LK" },
  { country: "Sudan", code: "+249", isoCode: "SD" },
  { country: "Suriname", code: "+597", isoCode: "SR" },
  { country: "Sweden", code: "+46", isoCode: "SE" },
  { country: "Switzerland", code: "+41", isoCode: "CH" },
  { country: "Syria", code: "+963", isoCode: "SY" },
  { country: "Taiwan", code: "+886", isoCode: "TW" },
  { country: "Tajikistan", code: "+992", isoCode: "TJ" },
  { country: "Tanzania", code: "+255", isoCode: "TZ" },
  { country: "Thailand", code: "+66", isoCode: "TH" },
  { country: "Timor-Leste", code: "+670", isoCode: "TL" },
  { country: "Togo", code: "+228", isoCode: "TG" },
  { country: "Tonga", code: "+676", isoCode: "TO" },
  { country: "Trinidad and Tobago", code: "+1-868", isoCode: "TT" },
  { country: "Tunisia", code: "+216", isoCode: "TN" },
  { country: "Turkey", code: "+90", isoCode: "TR" },
  { country: "Turkmenistan", code: "+993", isoCode: "TM" },
  { country: "Tuvalu", code: "+688", isoCode: "TV" },
  { country: "Uganda", code: "+256", isoCode: "UG" },
  { country: "Ukraine", code: "+380", isoCode: "UA" },
  { country: "United Arab Emirates", code: "+971", isoCode: "AE" },
  { country: "United Kingdom", code: "+44", isoCode: "GB" },
  { country: "United States", code: "+1", isoCode: "US" },
  { country: "Uruguay", code: "+598", isoCode: "UY" },
  { country: "Uzbekistan", code: "+998", isoCode: "UZ" },
  { country: "Vanuatu", code: "+678", isoCode: "VU" },
  { country: "Vatican City", code: "+379", isoCode: "VA" },
  { country: "Venezuela", code: "+58", isoCode: "VE" },
  { country: "Vietnam", code: "+84", isoCode: "VN" },
  { country: "Yemen", code: "+967", isoCode: "YE" },
  { country: "Zambia", code: "+260", isoCode: "ZM" },
  { country: "Zimbabwe", code: "+263", isoCode: "ZW" }
];




export const memberships = {
  features: [
    "View Contact Details",
    "Express Interest",
    "Profile Highlighter",
    "Featured Profile",
    "Membership Frame",
    "Email Alert",
    "WhatsApp Alert",
    "Dedicated Relationship Manager",
    "Marriage Registration",
    "Marriage Counselling - Pastor",
    "Marriage Counselling - Doctor",
    "Marriage Expo Entry",
    "Background Verification",
  ],
}

export const testimonials:{quote: string; name: string; title: string;}[] = [
  {
    quote:
      "It was the best of times, it was the worst of times, it was the age of wisdom, it was the age of foolishness, it was the epoch of belief, it was the epoch of incredulity, it was the season of Light, it was the season of Darkness, it was the spring of hope, it was the winter of despair.",
    name: "Charles Dickens",
    title: "A Tale of Two Cities",
  },
  {
    quote:
      "To be, or not to be, that is the question: Whether 'tis nobler in the mind to suffer The slings and arrows of outrageous fortune, Or to take Arms against a Sea of troubles, And by opposing end them: to die, to sleep.",
    name: "William Shakespeare",
    title: "Hamlet",
  },
  {
    quote: "All that we see or seem is but a dream within a dream.",
    name: "Edgar Allan Poe",
    title: "A Dream Within a Dream",
  },
  {
    quote:
      "It is a truth universally acknowledged, that a single man in possession of a good fortune, must be in want of a wife.",
    name: "Jane Austen",
    title: "Pride and Prejudice",
  },
  {
    quote:
      "Call me Ishmael. Some years ago—never mind how long precisely—having little or no money in my purse, and nothing particular to interest me on shore, I thought I would sail about a little and see the watery part of the world.",
    name: "Herman Melville",
    title: "Moby-Dick",
  },
];

export const locations = [
  {
    "state": "Andaman And nicobar islands",
    "cities": [
      {
        "name": "Nicobar",
        "localities": ["Campbelbay", "Carnicobar", "Champin"]
      },
      {
        "name": "North And middle andaman ",
        "localities": ["Aerial Bay", "Bakultala", "Betapur"]
      }
    ]
  },
  {
    "state": "2Andaman And nicobar islands",
    "cities": [
      {
        "name": "Nicobar",
        "localities": ["Campbelbay", "Carnicobar", "Champin"]
      },
      {
        "name": "North And middle andaman ",
        "localities": ["Aerial Bay", "Bakultala", "Betapur"]
      }
    ]
  },
  {
    "state": "3Andaman And nicobar islands",
    "cities": [
      {
        "name": "Nicobar",
        "localities": ["Campbelbay", "Carnicobar", "Champin"]
      },
      {
        "name": "North And middle andaman ",
        "localities": ["Aerial Bay", "Bakultala", "Betapur"]
      }
    ]
  }
]

export const newUser = {
  basicDetails: {
	  id: "CSI-5f4777a8196c5234e839b2b2",
	  photos: ["String"],
    mainPhoto: "PhotoLink",
	  profileCreatedFor:"Father",
	  name:"Rabecca",
	  age:32, 
	  dateOfBirth:"11/4/1992",
	  bodyType:"Average",
	  physicalStatus:"Normal",
	  complexion:"Dark",
	  height:"5 feet 4in", 
	  weight:"70 kg",
	  maritalStatus:"Divorced",
	  numberOfGirlChild:"1", 
	  numberOfBoyChild:"1",
	  eatingHabits:"Non Vegetarian",
	  drinkingHabits:"Never Drinks",
	  smokingHabits:"Never Smokes",
	  motherTongue:"Tamil",
	  caste:"Christian",
	  residenceCountry: "India",
	  residenceState: "Karnataka",
    residenceCity: "Bangalore",
	  residenceLocality: "Whitefield",
	  residenceType:"Rented",
	  knownLanguages: ["Tamil", "Hindi", "English", "Kannada"],
	  aboutMe:"Good looking Girl"
	},

  familyDetails: {
	  fathersName:"Steppen",
	  mothersName:"Marry",
	  fathersOccupation:"Enginer",
	  mothersOccupation:"Housewife",
	  fathersNative:"Bangalore",
	  mothersNative:"Bangalore",
	  familyValue:"Moderate",
	  familyType:"Nucler Family",
	  familyStatus:"Upper Middle Class",
	  numberOfBrothers: 0,
	  numberOfSisters: 1 ,
  },

  religiousInformation: {
	  denomination:"CSI",
	  churchName:"St.Peters Church",
	  churchLocation:"Chamarajpet",
	  churchActivity:"Choir",
	  pastorsName:"Rev.Peter",
	  spirituality:"Very Spiritual",
	  religiousDetail:"Regular churchgoer",
  },

  contactInformation: {
    contactPersonName:"Steppen",
	  relationship:"Father",
	  mobileNumber: "1234567890",
	  alternateMobileNumber: "1234567890", 
	  email:"Steppen@gmail.com",
	  landlineNumber: "0", 
	  currentAddress:"11, 1st Cross, 1st Main road HAL",
	  permanentAddress:"Same",
	  county:"India",
	  state: "Kanataka",
    city: "Bangalore",
	  locality: "Whitefield",
	  citizenOf:"India",
  },

  professionalInformation: {
	  education:"BE",
	  additionalEducation:"MBA",
	  latestCollegeName:"IITB",
	  educationInDetail:"Computer Science",
	  employmentType:"Governament",
	  occupation:"Banking",
	  companyName:"SBI",
	  employmentDesignation:"Manager",
	  annualIncome: "10 - 12 Lakhs",
	  workLocation:"Hennur",
  },
  
  lifeStyle: {
	hobbies: ["Reading", "Traveling"],
    interests: ["Technology", "Music"],
    favouriteMusic: ["Classical", "Rock"],
    favouriteReads: ["Fiction", "Science Journals"],
    favouriteFoods: ["Indian", "Italian"],
    sports: ["Football", "Cricket"],
    dressStyles: ["Casual", "Formal"],
  },

  partnerPreference: {
    basicPreferences: {
      minAge: 18,
      maxAge: 30,
      minHeight: "5 feet",
      maxHeight: "5 feet 6 inches",  
      maritalStatus: ["Divorced"],
      numberOfBoyChild: 0, 
      numberOfGirlChild: 0,
      motherTongue:["Kannada"],
      knownLanguages:["Kannada", "English"],
      caste:["Christian"],
      complexion:["Fair"],
      bodyType:["Slim"],
      physicalStatus:["Normal"],
      eatingHabits: ["Non Vegetarian"],
      drinkingHabits: ["Never Drinks"],
      smokingHabits: ["Never Smokes"],
      denomination: ["Any"],
      spirituality: ["Very Spiritual"],
      residenceType:["Own"],
      },	
    professionalPreferences: {
      state:"Karnataka",
      education:["Any"],
      employmentType:["Any"],
      occupation:["Doctor"],
      annualIncome: ["10 - 12 Lakhs"], 
      workLocation:"Bangalore"
      },
    locationPreferences: {
     country:"India",
     state:["Karnataka", "Rajasthan"],
     city:["Bangalore"],
     locality: ["Whitefield"]
     },
  },  
	  
  backgroundVerification: {
    addressVerified: true,  
    educationVerified: false, 
    employmentVerified: true, 
    identityVerified: false, 
    cibilVerified: true, 
    criminalVerified: false, 
    bgvVerificationAsOn: "9/8/2024",
  },
  
  accountSummary: {
    membershipPlanName: "Bronze",
    membershipPlanStatus: "Paid/Active",
    membershipPlanStartDate: "9/8/2024",
    membershipPlanEndDate: "9/8/2025",
    viewContactDetails: 5,
    expressInterest: 1,
    profileHighlightedDays: 60, 
    emailAlert: 3, 
    whatsappAlert: 11, 
    featuredProfile: 13,
  },
  
  userActivitySummary: {
    whoViewedMyProfile: ["ProfileID#1", 'asdfasdf'], 
    whoShortlistedMe: ["ProfileID#2"], 
    whoViewedMyMobileNo: ["ProfileID#3"],
    interestsIReceived: ["ProfileID#3", "ProfileID#2"],
    profilesShortlistedByMe: ["ProfileID#4"],
    mobileNoViewedByMe: ["ProfileID#3", "ProfileID#2"] 
  },
  
  userAccountSetting: {
    freezeProfile: false,
    deleteProfile: false,
  },  
};

export const newUsers = [
  {
    basicDetails: {
      id: "CSI-5f4777a8196c5234e839b2b2",
      photos: [
        "https://images.squarespace-cdn.com/content/v1/5c188a95620b85575bb604fa/b7f32895-c01f-4e60-a8e3-72b01d550739/Alexander+Owens9437.jpg",
        "https://images.squarespace-cdn.com/content/v1/5c188a95620b85575bb604fa/62f2f600-348f-4629-b361-2f4494e1d8e8/Paul+Scace7254.jpg",
        "https://images.squarespace-cdn.com/content/v1/5c188a95620b85575bb604fa/1626136627912-BCQ540L2WTNH8O1NA03C/seattle-headshot-andrew.jpg",
        "https://images.squarespace-cdn.com/content/v1/5c188a95620b85575bb604fa/1626136627912-BCQ540L2WTNH8O1NA03C/seattle-headshot-andrew.jpg",
        "https://images.squarespace-cdn.com/content/v1/5c188a95620b85575bb604fa/1626136627912-BCQ540L2WTNH8O1NA03C/seattle-headshot-andrew.jpg",
        "https://images.squarespace-cdn.com/content/v1/5c188a95620b85575bb604fa/1626136627912-BCQ540L2WTNH8O1NA03C/seattle-headshot-andrew.jpg",
        "https://images.squarespace-cdn.com/content/v1/5c188a95620b85575bb604fa/1626136627912-BCQ540L2WTNH8O1NA03C/seattle-headshot-andrew.jpg",
      ],
      mainPhoto: "https://images.squarespace-cdn.com/content/v1/5c188a95620b85575bb604fa/1626136627912-BCQ540L2WTNH8O1NA03C/seattle-headshot-andrew.jpg",
      profileCreatedFor:"Father",
      name:"Rabecca",
      age:32, 
      dateOfBirth:"11/4/1992",
      bodyType:"Average",
      physicalStatus:"Normal",
      complexion:"Dark",
      height:"5 feet 4 inches", 
      weight:"70 kg",
      maritalStatus:"Divorced",
      numberOfGirlChild:"1", 
      numberOfBoyChild:"1",
      eatingHabits:"Vegan",
      drinkingHabits:"Non-drinker",
      smokingHabits:"Non-smoker",
      motherTongue:"Tamil",
      caste:"BC",
      residenceCountry: "India",
      residenceState: "Karnataka",
      residenceCity: "Bangalore",
      residenceLocality: "Whitefield",
      residenceType:"Rented",
      knownLanguages: ["Tamil", "Hindi", "English", "Kannada"],
      aboutMe:"Good looking Girl"
    },
  
    familyDetails: {
      fathersName:"Steppen",
      mothersName:"Marry",
      fathersOccupation:"Enginer",
      mothersOccupation:"Housewife",
      fathersNative:"Bangalore",
      mothersNative:"Bangalore",
      familyValue:"Moderate",
      familyType:"Nuclear Family",
      familyStatus:"Upper Middle Class",
      numberOfBrothers: 0,
      numberOfSisters: 1 ,
    },
  
    religiousInformation: {
      denomination:"Catholic",
      churchName:"St.Peters Church",
      churchLocation:"Chamarajpet",
      churchActivity:"Church Choir",
      pastorsName:"Rev.Peter",
      spirituality:"Not religious",
      religiousDetail:"Regular churchgoer",
    },
  
    contactInformation: {
      contactPersonName:"Steppen",
      relationship:"Father",
      mobileNumber: "1234567890",
      alternateMobileNumber: "1234567890", 
      email:"Steppen@gmail.com",
      landlineNumber: "0", 
      currentAddress:"11, 1st Cross, 1st Main road HAL",
      permanentAddress:"Same",
      county:"India",
      state: "Kanataka",
      city: "Bangalore",
      locality: "Whitefield",
      citizenOf:"India",
    },
  
    professionalInformation: {
      education:"BE",
      additionalEducation:"MBA",
      latestCollegeName:"IITB",
      educationInDetail:"Computer Science",
      employmentType:"Government/PSU",
      occupation:"Officer",
      companyName:"SBI",
      employmentDesignation:"Manager",
      annualIncome: "10 - 12 Lakhs",
      workLocation:"Hennur",
    },
    
    lifeStyle: {
    hobbies: ["Reading", "Traveling"],
      interests: ["Technology", "Music"],
      favouriteMusic: ["Classical", "Rock"],
      favouriteReads: ["Fiction", "Science Journals"],
      favouriteFoods: ["Indian", "Italian"],
      sports: ["Football", "Cricket"],
      dressStyles: ["Casual", "Formal"],
    },
  
    partnerPreference: {
      basicPreferences: {
        minAge: 18,
        maxAge: 30,
        minHeight: "5 feet",
        maxHeight: "5 feet 6 inches",  
        maritalStatus: ["Never Married"],
        numberOfBoyChild: 0, 
        numberOfGirlChild: 0,
        motherTongue:["Kannada"],
        knownLanguages:["Kannada", "English"],
        caste:["Christian"],
        complexion:["Fair"],
        bodyType:["Slim"],
        physicalStatus:["Normal"],
        eatingHabits: ["Non Vegetarian"],
        drinkingHabits: ["Never Drinks"],
        smokingHabits: ["Never Smokes"],
        denomination: ["Any"],
        spirituality: ["Very Spiritual"],
        residenceType:["Own"],
        },	
      professionalPreferences: {
        state:"Karnataka",
        education:["Any"],
        employmentType:["Any"],
        occupation:["Doctor"],
        annualIncome: ["10 - 12 Lakhs"], 
        workLocation:"Bangalore"
        },
      locationPreferences: {
       country: "India",
       state: ["Karnataka", "Rajasthan"],
       city: ["Bangalore"],
       locality: ["Whitefield"]
       },
    },  
      
    backgroundVerification: {
      addressVerified: true,  
      educationVerified: false, 
      employmentVerified: true, 
      identityVerified: false, 
      cibilVerified: true, 
      criminalVerified:false, 
      bgvVerificationAsOn: "9/8/2024",
    },
    
    accountSummary: {
      membershipPlanName: "Diamond Plus",
      membershipPlanStatus: "Paid/Active",
      membershipPlanStartDate: "9/8/2024",
      membershipPlanEndDate: "9/8/2025",
      viewContactDetails: 5,
      expressInterest: 1,
      profileHighlightedDays: 60, 
      emailAlert: 3, 
      whatsappAlert: 11, 
      featuredProfile: 13,
    },
    
    userActivitySummary: {
      whoViewedMyProfile: ["ProfileID#1"], 
      whoShortlistedMe: ["ProfileID#2"], 
      whoViewedMyMobileno: ["ProfileID#3"],
      interestsIReceived: 5,
      profilesShortlistedByMe: ["ProfileID#4"],
      MobilenoViewedByMe: 4 
    },
    
    userAccountSetting: {
      freezeProfile: false,
      deleteProfile: false,
    },  
  },
  {
    basicDetails: {
      id: "CSI-5f4777a8196c5234e839b2b2",
      photos: [
        "https://images.squarespace-cdn.com/content/v1/5c188a95620b85575bb604fa/b7f32895-c01f-4e60-a8e3-72b01d550739/Alexander+Owens9437.jpg",
        "https://images.squarespace-cdn.com/content/v1/5c188a95620b85575bb604fa/1626136627912-BCQ540L2WTNH8O1NA03C/seattle-headshot-andrew.jpg",
      ],
      mainPhoto: "https://images.squarespace-cdn.com/content/v1/5c188a95620b85575bb604fa/1626136627912-BCQ540L2WTNH8O1NA03C/seattle-headshot-andrew.jpg",
      profileCreatedFor:"Father",
      name:"Rabecca2",
      age:32, 
      dateOfBirth:"11/4/1992",
      bodyType:"Average",
      physicalStatus:"Normal",
      complexion:"Fair",
      height:"5 feet 4in", 
      weight:"70 kg",
      maritalStatus:"Unmarried",
      numberOfGirlChild:"11", 
      numberOfBoyChild:"12",
      eatingHabits:"Non Vegetarian",
      drinkingHabits:"Never Drinks",
      smokingHabits:"Never Smokes",
      motherTongue:"Tamil",
      caste:"Christian",
      residenceCountry: "India",
      residenceState: "Karnataka",
      residenceCity: "Bangalore",
      residenceLocality: "Whitefield",
      residenceType:"Rented",
      knownLanguages: ["Tamil", "Hindi", "English", "Kannada"],
      aboutMe:"Good looking Girl"
    },
  
    familyDetails: {
      fathersName:"Steppen",
      mothersName:"Marry",
      fathersOccupation:"Enginer",
      mothersOccupation:"Housewife",
      fathersNative:"Bangalore",
      mothersNative:"Bangalore",
      familyValue:"Moderate",
      familyType:"Nucler Family",
      familyStatus:"Upper Middle Class",
      numberOfBrothers: 0,
      numberOfSisters: 1 ,
    },
  
    religiousInformation: {
      denomination:"CSI",
      churchName:"St.Peters Church",
      churchLocation:"Chamarajpet",
      churchActivity:"Choir",
      pastorsName:"Rev.Peter",
      spirituality:"Very Spiritual",
      religiousDetail:"Regular churchgoer",
    },
  
    contactInformation: {
      contactPersonName:"Steppen",
      relationship:"Father",
      mobileNumber: "1234567890",
      alternateMobileNumber: "1234567890", 
      email:"Steppen@gmail.com",
      landlineNumber: "0", 
      currentAddress:"11, 1st Cross, 1st Main road HAL",
      permanentAddress:"Same",
      county:"India",
      state: "Kanataka",
      city: "Bangalore",
      locality: "Whitefield",
      citizenOf:"India",
    },
  
    professionalInformation: {
      education:"BE",
      additionalEducation:"MBA",
      latestCollegeName:"IITB",
      educationInDetail:"Computer Science",
      employmentType:"Governament",
      occupation:"Banking",
      companyName:"SBI",
      employmentDesignation:"Manager",
      annualIncome: "10 - 12 Lakhs",
      workLocation:"Hennur",
    },
    
    lifeStyle: {
    hobbies: ["Reading", "Traveling"],
      interests: ["Technology", "Music"],
      favouriteMusic: ["Classical", "Rock"],
      favouriteReads: ["Fiction", "Science Journals"],
      favouriteFoods: ["Indian", "Italian"],
      sports: ["Football", "Cricket"],
      dressStyles: ["Casual", "Formal"],
    },
  
    partnerPreference: {
      basicPreferences: {
        minAge: 18,
        maxAge: 30,
        minHeight: "5 feet",
        maxHeight: "5 feet 6 inches",  
        maritalStatus: ["Never Married"],
        numberOfBoyChild: 0, 
        numberOfGirlChild: 0,
        motherTongue:["Kannada"],
        knownLanguages:["Kannada", "English"],
        caste:["Christian"],
        complexion:["Fair"],
        bodyType:["Slim"],
        physicalStatus:["Normal"],
        eatingHabits: ["Non Vegetarian"],
        drinkingHabits: ["Never Drinks"],
        smokingHabits: ["Never Smokes"],
        denomination: ["Any"],
        spirituality: ["Very Spiritual"],
        residenceType:["Own"],
        },	
      professionalPreferences: {
        state:"Karnataka",
        education:["Any"],
        employmentType:["Any"],
        occupation:["Doctor"],
        annualIncome: ["10 - 12 Lakhs"], 
        workLocation:"Bangalore"
        },
      locationPreferences: {
       country:"India",
       state:["Karnataka"],
       city:["Bangalore"],
       locality: ["Whitefield"]
       },
    },  
      
    backgroundVerification: {
      addressVerified: true,  
      educationVerified: true, 
      employmentVerified: true, 
      identityVerified: true, 
      cibilVerified: true, 
      criminalVerified:true, 
      bgvVerificationAsOn: "9/8/2024",
    },
    
    accountSummary: {
      membershipPlanName: "Gold Plus",
      membershipPlanStatus: "Paid/Active",
      membershipPlanStartDate: "9/8/2024",
      membershipPlanEndDate: "9/8/2025",
      viewContactDetails: 5,
      expressInterest: 1,
      profileHighlightedDays: 60, 
      emailAlert: 3, 
      whatsappAlert: 11, 
      featuredProfile: 13,
    },
    
    userActivitySummary: {
      whoViewedMyProfile: ["ProfileID#1"], 
      whoShortlistedMe: ["ProfileID#2"], 
      whoViewedMyMobileno: ["ProfileID#3"],
      interestsIReceived: 5,
      profilesShortlistedByMe: ["ProfileID#4"],
      MobilenoViewedByMe: 4 
    },
    
    userAccountSetting: {
      freezeProfile: false,
      deleteProfile: false,
    },  
  },
  {
    basicDetails: {
      id: "CSI-5f4777a8196c5234e839b2b3",
      photos: ["https://images.squarespace-cdn.com/content/v1/5c188a95620b85575bb604fa/1626136627912-BCQ540L2WTNH8O1NA03C/seattle-headshot-andrew.jpg"],
      mainPhoto: "https://images.squarespace-cdn.com/content/v1/5c188a95620b85575bb604fa/1626136627912-BCQ540L2WTNH8O1NA03C/seattle-headshot-andrew.jpg",
      profileCreatedFor:"Father",
      name:"Rabecca3",
      age:32, 
      dateOfBirth:"11/4/1992",
      bodyType:"Average",
      physicalStatus:"Normal",
      complexion:"Fair",
      height:"5 feet 4 inches", 
      weight:"70 kg",
      maritalStatus:"Divorced",
      numberOfGirlChild:"1", 
      numberOfBoyChild:"1",
      eatingHabits:"Non Vegetarian",
      drinkingHabits:"Never Drinks",
      smokingHabits:"Never Smokes",
      motherTongue:"Tamil",
      caste:"Christian",
      residenceCountry: "India",
      residenceState: "Karnataka",
      residenceCity: "Bangalore",
      residenceLocality: "Whitefield",
      residenceType:"Rented",
      knownLanguages: ["Tamil", "Hindi", "English", "Kannada"],
      aboutMe:"Good looking Girl"
    },
  
    familyDetails: {
      fathersName:"Steppen",
      mothersName:"Marry",
      fathersOccupation:"Enginer",
      mothersOccupation:"Housewife",
      fathersNative:"Bangalore",
      mothersNative:"Bangalore",
      familyValue:"Moderate",
      familyType:"Nucler Family",
      familyStatus:"Upper Middle Class",
      numberOfBrothers: 0,
      numberOfSisters: 1 ,
    },
  
    religiousInformation: {
      denomination:"CSI",
      churchName:"St.Peters Church",
      churchLocation:"Chamarajpet",
      churchActivity:"Choir",
      pastorsName:"Rev.Peter",
      spirituality:"Very Spiritual",
      religiousDetail:"Regular churchgoer",
    },
  
    contactInformation: {
      contactPersonName:"Steppen",
      relationship:"Father",
      mobileNumber: "1234567890",
      alternateMobileNumber: "1234567890", 
      email:"Steppen@gmail.com",
      landlineNumber: "0", 
      currentAddress:"11, 1st Cross, 1st Main road HAL",
      permanentAddress:"Same",
      county:"India",
      state: "Kanataka",
      city: "Bangalore",
      locality: "Whitefield",
      citizenOf:"India",
    },
  
    professionalInformation: {
      education:"BE",
      additionalEducation:"MBA",
      latestCollegeName:"IITB",
      educationInDetail:"Computer Science",
      employmentType:"Governament",
      occupation:"Banking",
      companyName:"SBI",
      employmentDesignation:"Manager",
      annualIncome: "10 - 12 Lakhs",
      workLocation:"Hennur",
    },
    
    lifeStyle: {
    hobbies: ["Reading", "Traveling"],
      interests: ["Technology", "Music"],
      favouriteMusic: ["Classical", "Rock"],
      favouriteReads: ["Fiction", "Science Journals"],
      favouriteFoods: ["Indian", "Italian"],
      sports: ["Football", "Cricket"],
      dressStyles: ["Casual", "Formal"],
    },
  
    partnerPreference: {
      basicPreferences: {
        minAge: 18,
        maxAge: 30,
        minHeight: "5 feet 1 inches",
        maxHeight: "6 feet 2 inches",  
        maritalStatus: ["Never Married"],
        numberOfBoyChild: 0, 
        numberOfGirlChild: 0,
        motherTongue:["Kannada"],
        knownLanguages:["Kannada", "English"],
        caste:["Christian"],
        complexion:["Fair"],
        bodyType:["Slim"],
        physicalStatus:["Normal"],
        eatingHabits: ["Non Vegetarian"],
        drinkingHabits: ["Never Drinks"],
        smokingHabits: ["Never Smokes"],
        denomination: ["Any"],
        spirituality: ["Very Spiritual"],
        residenceType:["Own"],
        },	
      professionalPreferences: {
        state:"Karnataka",
        education:["Any"],
        employmentType:["Any"],
        occupation:["Doctor"],
        annualIncome: ["10 - 12 Lakhs"], 
        workLocation:"Bangalore"
        },
      locationPreferences: {
       country:"India",
       state:["Karnataka"],
       city:["Bangalore"],
       locality: ["Whitefield"]
       },
    },  
      
    backgroundVerification: {
      addressVerified: false,  
      educationVerified: true, 
      employmentVerified: false, 
      identityVerified: true, 
      cibilVerified: true, 
      criminalVerified: true, 
      bgvVerificationAsOn: "9/8/2024",
    },
    
    accountSummary: {
      membershipPlanName: "Gold",
      membershipPlanStatus: "Paid/Active",
      membershipPlanStartDate: "9/8/2024",
      membershipPlanEndDate: "9/8/2025",
      viewContactDetails: 5,
      expressInterest: 1,
      profileHighlightedDays: 60, 
      emailAlert: 3, 
      whatsappAlert: 11, 
      featuredProfile: 13,
    },
    
    userActivitySummary: {
      whoViewedMyProfile: ["ProfileID#1"], 
      whoShortlistedMe: ["ProfileID#2"], 
      whoViewedMyMobileno: ["ProfileID#3"],
      interestsIReceived: 5,
      profilesShortlistedByMe: ["ProfileID#4"],
      MobilenoViewedByMe: 4 
    },
    
    userAccountSetting: {
      freezeProfile: false,
      deleteProfile: false,
    },  
  },
  {
    basicDetails: {
      id: "CSI-5f4777a8196c5234e839b2b4",
      photos: ["https://images.squarespace-cdn.com/content/v1/5c188a95620b85575bb604fa/d5a74032-28cf-454e-86fb-893eb321793f/James+Rankin1039.jpg"],
      mainPhoto: "https://images.squarespace-cdn.com/content/v1/5c188a95620b85575bb604fa/1626136627912-BCQ540L2WTNH8O1NA03C/seattle-headshot-andrew.jpg",
      profileCreatedFor:"Father",
      name:"Rabecca",
      age:32, 
      dateOfBirth:"11/4/1992",
      bodyType:"Average",
      physicalStatus:"Normal",
      complexion:"Fair",
      height:"5 feet 4 inches", 
      weight:"70 kg",
      maritalStatus:"Divorced",
      numberOfGirlChild:"1", 
      numberOfBoyChild:"1",
      eatingHabits:"Non Vegetarian",
      drinkingHabits:"Never Drinks",
      smokingHabits:"Never Smokes",
      motherTongue:"Tamil",
      caste:"Christian",
      residenceCountry: "India",
      residenceState: "Karnataka",
      residenceCity: "Bangalore",
      residenceLocality: "Whitefield",
      residenceType:"Rented",
      knownLanguages: ["Tamil", "Hindi", "English", "Kannada"],
      aboutMe:"Good looking Girl"
    },
  
    familyDetails: {
      fathersName:"Steppen",
      mothersName:"Marry",
      fathersOccupation:"Enginer",
      mothersOccupation:"Housewife",
      fathersNative:"Bangalore",
      mothersNative:"Bangalore",
      familyValue:"Moderate",
      familyType:"Nucler Family",
      familyStatus:"Upper Middle Class",
      numberOfBrothers: 0,
      numberOfSisters: 1 ,
    },
  
    religiousInformation: {
      denomination:"CSI",
      churchName:"St.Peters Church",
      churchLocation:"Chamarajpet",
      churchActivity:"Choir",
      pastorsName:"Rev.Peter",
      spirituality:"Very Spiritual",
      religiousDetail:"Regular churchgoer",
    },
  
    contactInformation: {
      contactPersonName:"Steppen",
      relationship:"Father",
      mobileNumber: "1234567890",
      alternateMobileNumber: "1234567890", 
      email:"Steppen@gmail.com",
      landlineNumber: "0", 
      currentAddress:"11, 1st Cross, 1st Main road HAL",
      permanentAddress:"Same",
      county:"India",
      state: "Kanataka",
      city: "Bangalore",
      locality: "Whitefield",
      citizenOf:"India",
    },
  
    professionalInformation: {
      education:"BE",
      additionalEducation:"MBA",
      latestCollegeName:"IITB",
      educationInDetail:"Computer Science",
      employmentType:"Governament",
      occupation:"Banking",
      companyName:"SBI",
      employmentDesignation:"Manager",
      annualIncome: "10 - 12 Lakhs",
      workLocation:"Hennur",
    },
    
    lifeStyle: {
    hobbies: ["Reading", "Traveling"],
      interests: ["Technology", "Music"],
      favouriteMusic: ["Classical", "Rock"],
      favouriteReads: ["Fiction", "Science Journals"],
      favouriteFoods: ["Indian", "Italian"],
      sports: ["Football", "Cricket"],
      dressStyles: ["Casual", "Formal"],
    },
  
    partnerPreference: {
      basicPreferences: {
        minAge: 18,
        maxAge: 30,
        minHeight: "5 feet",
        maxHeight: "5 feet 6 inches",  
        maritalStatus: ["Never Married"],
        numberOfBoyChild: 0, 
        numberOfGirlChild: 0,
        motherTongue:["Kannada"],
        knownLanguages:["Kannada", "English"],
        caste:["Christian"],
        complexion:["Fair"],
        bodyType:["Slim"],
        physicalStatus:["Normal"],
        eatingHabits: ["Non Vegetarian"],
        drinkingHabits: ["Never Drinks"],
        smokingHabits: ["Never Smokes"],
        denomination: ["Any"],
        spirituality: ["Very Spiritual"],
        residenceType:["Own"],
        },	
      professionalPreferences: {
        state:"Karnataka",
        education:["Any"],
        employmentType:["Any"],
        occupation:["Doctor"],
        annualIncome: ["10 - 12 Lakhs"], 
        workLocation:"Bangalore"
        },
      locationPreferences: {
       country:"India",
       state:["Karnataka"],
       city:["Bangalore"],
       locality: ["Whitefield"]
       },
    },  
      
    backgroundVerification: {
      addressVerified: false,  
      educationVerified: false, 
      employmentVerified: false, 
      identityVerified: false, 
      cibilVerified: false, 
      criminalVerified:false, 
      bgvVerificationAsOn: "9/8/2024",
    },
    
    accountSummary: {
      membershipPlanName: "Diamond",
      membershipPlanStatus: "Paid/Active",
      membershipPlanStartDate: "9/8/2024",
      membershipPlanEndDate: "9/8/2025",
      viewContactDetails: 5,
      expressInterest: 1,
      profileHighlightedDays: 60, 
      emailAlert: 3, 
      whatsappAlert: 11, 
      featuredProfile: 13,
    },
    
    userActivitySummary: {
      whoViewedMyProfile: ["ProfileID#1"], 
      whoShortlistedMe: ["ProfileID#2"], 
      whoViewedMyMobileno: ["ProfileID#3"],
      interestsIReceived: 5,
      profilesShortlistedByMe: ["ProfileID#4"],
      MobilenoViewedByMe: 4 
    },
    
    userAccountSetting: {
      freezeProfile: false,
      deleteProfile: false,
    },  
  } 
]

export type UserType =  typeof newUser

