import { Outlet, Link } from "react-router-dom";
import { useEffect, useState } from "react";

const apiUrl = import.meta.env.VITE_API_URL;

// Define types for the links
type ProfileApprovalLink = {
  text: string;
  url: string;
  profileCount: number;
  photoCount: number;
  albumCount: number;
};

type DeleteApprovalLink = {
  text: string;
  url: string;
  count: number;
};



// Define your link structure with two types
const initialLinks: { profileApproval: ProfileApprovalLink; deleteApproval: DeleteApprovalLink } = {
  profileApproval: {
    text: "Profile/Photo/Album Approvals",
    url: "/admin/approvals/profileapproval", // Corrected URL
    profileCount: 0, // Initial profile approval count
    photoCount: 0,   // Initial photo approval count
    albumCount:0,    // Initial album approval count
  },
  deleteApproval: {
    text: "Delete Approval",
    url: "/admin/approvals/deleteapproval",
    count: 7, // Static count for this example
  },
};

const AdminApprovals: React.FC = () => {
  const [links, setLinks] = useState(initialLinks);

  // Function to fetch the number of pending profiles and photos
  const fetchPendingCount = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/profilesapproval/count/pending`);
      const data = await response.json();
      setLinks((prevLinks) => ({
        ...prevLinks,
        profileApproval: {
          ...prevLinks.profileApproval,
          profileCount: data.pendingProfileCount || 0, // Safely updating profile count
          photoCount: data.pendingPhotoCount || 0,     // Safely updating photo count
          albumCount:data.pendingAlbumCount || 0
        },
      }));
    } catch (error) {
      console.error("Error fetching pending counts:", error);
    }
  };

  useEffect(() => {
    fetchPendingCount();
  }, []);

  return (
    <>
      <div className="flex">
        <div className="w-[20%] flex flex-col px-6 py-8 gap-y-4 min-h-[32rem] border-r">
          {Object.values(links).map((link) => (
            <Link key={link.url} to={link.url}>
              <div className="bg-accent/50 focus:bg-accent relative border border-accent py-2 px-4 text-lg text-black font-medium rounded-xl">
                {link.text}
                {'profileCount' in link && 'photoCount' in link ? (
                  <div className="absolute text-center -right-4 -top-2 rounded-full bg-accentSec h-8 w-16 flex justify-center items-center text-white font-bold">
                    {link.profileCount}/{link.photoCount}/{link.albumCount}
                  </div>
                ) : (
                  <div className="absolute text-center -right-4 -top-2 rounded-full bg-accentSec h-8 w-8 flex justify-center items-center text-white font-bold">
                    {link.count}
                  </div>
                )}
              </div>
            </Link>
          ))}
        </div>
        <div className="w-[80%]">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default AdminApprovals;



