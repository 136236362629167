import React from "react";
import { Link } from "react-router-dom";
import { useUser } from "../pages/UserContext"; 
import { useNavigate } from 'react-router-dom';
import onlineBgV from "/images/onlineBgV.png";
import videoBgV from "/images/videoBgV.png";
import marriageRegistration from "/images/marriageRegistration.png";
import advertise from "/images/advertise.png";
import marriageCounselling from "/images/marriageCounselling.png";
import { useAuthUser } from '../pages/AuthUserContext';
import events from "/images/events.png";
const apiUrl = import.meta.env.VITE_API_URL;
import { useState,useEffect } from "react";
import useScreenType from "../hooks/useScreenType";
import { useTranslationContext } from '../TranslationContext';
import translateText from '../TranslationService';


import { cn } from "../utils/utils";
import { HoveredLink, HoveredP, Menu, MenuItem, ProductItem, ProductItemP,} from "../components/NavbarMenu";
import axios from "axios";



interface TranslatedItems {
  services: string;
  membershipPlans: string;
  registerFree: string;
}


const executiveLinks = {
  
  approvals: ["Approvals", "/admin/approvals/profileapproval"],
  membershipDiscount: ["Membership & Discount", "/admin/membership/plans"],
  eventsGallery: ["Events & Gallery", "/admin/events/newevent"],
  ads: ["Ads", "/admin/ads/ourads"],
  tmiUsers: ["TM Users Management", "/admin/approvals/accountsummary"]
  
  
};

const adminLinks = {
  approvals: ["Approvals", "/admin/approvals/profileapproval"],
  eventsGallery: ["Events & Gallery", "/admin/events/newevent"],
  ads: ["Ads", "/admin/ads/ourads"],
  tmiUsers: ["TM Users Management", "/admin/approvals/accountsummary"]
  
};

const customerLinks = {
  tmiUsers: ["TM Users Management", "/customer/accountsummary"],
  coupons:  ["Available coupons", "/customer/couponlist"],
  couponssent:  ["Sent coupons", "/customer/couponssent"],
  followUp:["Follow Up","/customer/followup"],
};

const Header: React.FC = ({className}: { className?: string }) => {

  const { language } = useTranslationContext();
  const [translatedItems, setTranslatedItems] = useState<TranslatedItems>({
    services: 'Services',
    membershipPlans: 'Membership Plans',
    registerFree: 'Register Free',
  });

  useEffect(() => {
    const applyTranslations = async () => {
      const newItems: TranslatedItems = {
        services: await translateText('Services', language),
        membershipPlans: await translateText('Membership Plans', language),
        registerFree: await translateText('Register Free', language),
      };
      setTranslatedItems(newItems);
    };

    applyTranslations();
  }, [language]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);

  const [profileSubMenuOpen, setProfileSubMenuOpen] = useState(false);
  const [servicesSubMenuOpen, setServicesSubMenuOpen] = useState(false);
  const [dashboardSubMenuOpen, setDashboardSubMenuOpen] = useState(false);
  const [searchSubMenuOpen, setSearchSubMenuOpen] = useState(false);

  let timer: NodeJS.Timeout | null = null;

  const handleMouseEnter = () => {
    if (timer) clearTimeout(timer); // Clear any existing timeout to prevent closing
    setIsMenuOpen(true);
  };

  const handleMouseLeave = () => {
    // Add a delay before closing the menu to allow smooth interaction
    timer = setTimeout(() => {
      setIsMenuOpen(false);
    }, 1000); // You can adjust the delay time (300ms here)
  };

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const handleLogout = () => {
    // Clear user details and user type
    setUserDetails(null);
    setUserType("guest"); // Reset to 'guest' or the default type

    // Clear cookies if necessary
    document.cookie = "accessToken=; Max-Age=0";
    document.cookie = "refreshToken=; Max-Age=0";

    // Redirect to home or a public route
    navigate("/admin");

    setIsMenuOpen(false); // Close dropdown on logout click
  };
  const { user } = useAuthUser(); // Get current user's data
  const [active, setActive] = useState<string | null>(null);
  const navigate = useNavigate();
  const TMI=user;
  console.log(`Header TMI: ${TMI}`)
  const { userType, userDetails, setUserDetails, setUserType } = useUser();

  const handleFollowUp = async (TMI: string) => {
    console.log("TMI being sent:", TMI);
  
    if (!TMI) {
      alert('TMI is required');
      return; // Exit if TMI is not defined
    }
  
    try {
      const response = await axios.post(`${apiUrl}/api/customer-follow`, { TMI });
      console.log("Follow Up Added", response.data);
    } catch (error: unknown) { // Explicitly declare error as 'unknown'
      if (axios.isAxiosError(error)) {
        // Handle the error as AxiosError
        console.error('Error selecting membership plan:', error.response?.data || error.message);
        alert('There was an error selecting the membership plan: ' + (error.response?.data?.message || error.message));
      } else {
        // Handle other types of errors
        console.error('Unexpected error:', error);
        alert('An unexpected error occurred. Please try again.');
      }
    }
  };
  
  
  console.log("userType:", userType);
  console.log("userDetails:", userDetails);
  const screenType = useScreenType();

  
  

  return (
    <>
      {/* Guest User Header */}
      {userType === "guest" && (
        <header className="flex header-text flex-wrap gap-5 justify-between content-center py-2 pr-8 pl-5 border-b border-solid bg-[color:var(--sds-color-background-default-default)] border-zinc-300 max-md:pr-5">
          <Link to="/" className="flex items-center">
            <img src="/images/triune-christian-matrimony-logo.png" alt="Logo" className="w-36" />
          </Link>
          <div className="flex gap-x-6 text-lg">
            <nav className={cn("z-50", className)}>
              <Menu setActive={setActive}>
                <MenuItem setActive={setActive} onClick={() => navigate("/signup")} active={active} item={translatedItems.services}>
                  <div className="text-sm grid grid-cols-2 gap-10 p-4">
                    <ProductItem title="Online BG Verification" to="#" icon={onlineBgV} description="BG Verification" />
                    <ProductItem title="Marriage Counselling" to="#" icon={marriageCounselling} description="Counselling" />
                    <ProductItem title="Marriage Registration" to="#" icon={marriageRegistration} description="Registration" />
                    <ProductItem title="Advertising" to="#" icon={advertise} description="Advertising" />
                    <ProductItem title="Events & Meetups" to="#" icon={events} description="Events" />
                  </div>
                </MenuItem>
                
              </Menu>
            </nav>
            <div className="flex gap-x-6 py-4">
              <Link to="/signup">
                <button className="relative w-[8rem] group/btn border-2 btn rounded-md h-10 font-medium shadow-[0px_1px_0px_0px_#ffffff40_inset,0px_-1px_0px_0px_#ffffff40_inset] transition-opacity duration-300">
                  {translatedItems.registerFree}
                </button>
              </Link>
            </div>
           
          </div>
        </header>
      )}

      {/* LoggedIn User Header */}
      {userType === "user" && userDetails && (
          <header className="sticky top-0 z-50 flex justify-between items-center bg-white shadow-lg p-4 transition-all duration-300 ease-in-out">
            {/* Logo */}
            <Link to="/userhome" className="flex items-center">
              <img
                src="/images/triune-christian-matrimony-logo.png"
                alt="triune-christian-matrimony-logo"
                className="w-28 sm:w-36 transition-transform duration-300 ease-in-out hover:scale-105"
              />
            </Link>

            {/* Mobile View */}
            {screenType !== "desktop" && (
              <div className="relative flex items-center ml-auto">
                

                {/* User Profile Section */}
                <div className="relative z-50 ml-4 mt-5">
                  <div
                    onClick={() => {
                      setIsProfileMenuOpen(!isProfileMenuOpen);
                      setIsMenuOpen(false); // Close hamburger menu if profile menu is opened
                    }}
                    className="cursor-pointer hover:opacity-80 transition-opacity duration-300"
                  >
                    <img
                      src={`${apiUrl}${userDetails.photo}`}
                      alt="Profile Logo"
                      className="w-10 h-10 rounded-full"
                    />
                    <p className="text-center mt-1 text-sm text-gray-700">
                      {userDetails.firstname}
                    </p>
                  </div>
                  {isProfileMenuOpen && (
                    <div className="absolute right-0 mt-2 w-48 bg-white border rounded-lg shadow-lg z-50 transition-opacity duration-300">
                      <button
                        onClick={() => {
                          navigate(`profilelisting/profile/${user}`);
                          setIsProfileMenuOpen(false); // Close profile menu after navigating
                        }}
                        className="block w-full text-left px-4 py-2 text-black hover:bg-blue-100 transition-colors duration-200"
                      >
                        My Profile
                      </button>
                      <button
                        onClick={() => {
                          setUserDetails(null);
                          setUserType('guest');
                          document.cookie = "accessToken=; Max-Age=0";
                          document.cookie = "refreshToken=; Max-Age=0";
                          navigate('/');
                          setIsProfileMenuOpen(false); // Close profile menu after logging out
                        }}
                        className="block w-full text-left px-4 py-2 text-red-600 hover:bg-red-100 transition-colors duration-200"
                      >
                        Logout
                      </button>
                    </div>
                  )}

                  
                </div>


                {/* Hamburger Menu Icon */}
                <button
                  onClick={() => {
                    console.log("Hi")
                    setIsMenuOpen(!isMenuOpen);
                    setIsProfileMenuOpen(false); // Close profile menu if hamburger menu is opened
                  }}
                  className="text-3xl focus:outline-none transition-transform duration-300 ease-in-out hover:scale-110"
                >
                  &#9776; {/* Hamburger Icon */}
                </button>
                {/* Hamburger Menu Dropdown */}
                {isMenuOpen && (
          <div className="absolute top-16 right-10 w-[250px] bg-gradient-to-r from-blue-100 to-blue-300 border border-gray-200 rounded-lg shadow-lg z-50 transition-opacity duration-300">
            <nav className="flex flex-col space-y-4 p-4 relative z-50">
              {/* Home Button */}
              <button
                onClick={() => {
                  navigate("/userhome");
                  setIsMenuOpen(false); // Close hamburger menu after navigating
                }}
                className="flex items-center px-4 py-2 text-left text-gray-800 hover:bg-blue-500 hover:text-white rounded-lg transition duration-200"
              >
                Home
              </button>

              {/* Profiles Button with Submenu */}
              <div>
                <button
                  onClick={() => {
                    setProfileSubMenuOpen((prev) => !prev); // Toggle submenu open/close
                  }}
                  className="flex items-center justify-between px-4 py-2 text-left text-gray-800 hover:bg-blue-500 hover:text-white rounded-lg transition duration-200"
                >
                  Profiles
                  <span className="text-sm">{profileSubMenuOpen ? "▲" : "▼"}</span>
                </button>
                {profileSubMenuOpen && (
                  <div className="ml-4 flex flex-col space-y-1">
                    <button
                      onClick={() => {
                        navigate(`/profilelisting`);
                        setIsMenuOpen(false);
                      }}
                      className="px-2 py-1 text-gray-700 hover:bg-blue-400 hover:text-white rounded transition duration-200"
                    >
                      All Profiles
                    </button>
                    <button
                      onClick={() => {
                        navigate(`/profilelisting/suggested/?tmi=${TMI}`);
                        setIsMenuOpen(false);
                      }}
                      className="px-2 py-1 text-gray-700 hover:bg-blue-400 hover:text-white rounded transition duration-200"
                    >
                      Suggested Profiles
                    </button>
                    <button
                      onClick={() => {
                        navigate(`/profilelisting/featured/?tmi=${TMI}`);
                        setIsMenuOpen(false);
                      }}
                      className="px-2 py-1 text-gray-700 hover:bg-blue-400 hover:text-white rounded transition duration-200"
                    >
                      Featured Profiles
                    </button>
                    <button
                      onClick={() => {
                        navigate(`/profilelisting/premium/?tmi=${TMI}`);
                        setIsMenuOpen(false);
                      }}
                      className="px-2 py-1 text-gray-700 hover:bg-blue-400 hover:text-white rounded transition duration-200"
                    >
                      Premium Profiles
                    </button>
                    <button
                      onClick={() => {
                        navigate("/profilelisting");
                        setIsMenuOpen(false);
                      }}
                      className="px-2 py-1 text-gray-700 hover:bg-blue-400 hover:text-white rounded transition duration-200"
                    >
                      All Denominations
                    </button>
                  </div>
                )}
              </div>

              {/* Services Button with Submenu */}
              <div>
                <button
                  onClick={() => {
                    setServicesSubMenuOpen((prev) => !prev); // Toggle submenu open/close
                  }}
                  className="flex items-center justify-between px-4 py-2 text-left text-gray-800 hover:bg-blue-500 hover:text-white rounded-lg transition duration-200"
                >
                  Services
                  <span className="text-sm">{servicesSubMenuOpen ? "▲" : "▼"}</span>
                </button>
                {servicesSubMenuOpen && (
                  <div className="ml-4 grid grid-cols-1 gap-2">
                    <button
                      onClick={() => {
                        navigate("/bgverification");
                        setIsMenuOpen(false);
                      }}
                      className="px-2 py-1 text-gray-700 hover:bg-blue-400 hover:text-white rounded transition duration-200"
                    >
                      Online BG Verification
                    </button>
                    <button
                      onClick={() => {
                        navigate("/vbgverification");
                        setIsMenuOpen(false);
                      }}
                      className="px-2 py-1 text-gray-700 hover:bg-blue-400 hover:text-white rounded transition duration-200"
                    >
                      Video BG Verification
                    </button>
                    <button
                      onClick={() => {
                        navigate("/services");
                        setIsMenuOpen(false);
                      }}
                      className="px-2 py-1 text-gray-700 hover:bg-blue-400 hover:text-white rounded transition duration-200"
                    >
                      Marriage Counselling
                    </button>
                    <button
                      onClick={() => {
                        navigate("/services");
                        setIsMenuOpen(false);
                      }}
                      className="px-2 py-1 text-gray-700 hover:bg-blue-400 hover:text-white rounded transition duration-200"
                    >
                      Marriage Registration
                    </button>
                    <button
                      onClick={() => {
                        navigate("/advertising");
                        setIsMenuOpen(false);
                      }}
                      className="px-2 py-1 text-gray-700 hover:bg-blue-400 hover:text-white rounded transition duration-200"
                    >
                      Advertising
                    </button>
                    <button
                      onClick={() => {
                        navigate("/events");
                        setIsMenuOpen(false);
                      }}
                      className="px-2 py-1 text-gray-700 hover:bg-blue-400 hover:text-white rounded transition duration-200"
                    >
                      Events & Meetups
                    </button>
                    {/* Add other service items similarly */}
                  </div>
                )}
              </div>

              {/* Membership Plans  */}
              <div>
              <button
                onClick={() => {
                  navigate("/membershipplans");
                  setIsMenuOpen(false); // Close hamburger menu after navigating
                }}
                className="flex items-center px-4 py-2 text-left text-gray-800 hover:bg-blue-500 hover:text-white rounded-lg transition duration-200"
              >
                Membership Plans
              </button>
                
              </div>

              {/* Dashboard Button with Submenu */}
              <div>
                <button
                  onClick={() => {
                    setDashboardSubMenuOpen((prev) => !prev); // Toggle submenu open/close
                  }}
                  className="flex items-center justify-between px-4 py-2 text-left text-gray-800 hover:bg-blue-500 hover:text-white rounded-lg transition duration-200"
                >
                  Dashboard
                  <span className="text-sm">{dashboardSubMenuOpen ? "▲" : "▼"}</span>
                </button>
                {dashboardSubMenuOpen && (
                  <div className="ml-4 flex flex-col space-y-1">
                    <button
                      onClick={() => {
                        navigate("/userdashboard");
                        setIsMenuOpen(false);
                      }}
                      className="px-2 py-1 text-gray-700 hover:bg-blue-400 hover:text-white rounded transition duration-200"
                    >
                      User Dashboard
                    </button>
                    <button
                      onClick={() => {
                        navigate("userdashboard/account");
                        setIsMenuOpen(false);
                      }}
                      className="px-2 py-1 text-gray-700 hover:bg-blue-400 hover:text-white rounded transition duration-200"
                    >
                      My Account
                    </button>
                    <button
                      onClick={() => {
                        navigate("userdashboard/settings");
                        setIsMenuOpen(false);
                      }}
                      className="px-2 py-1 text-gray-700 hover:bg-blue-400 hover:text-white rounded transition duration-200"
                    >
                      Profile Settings
                    </button>
                  </div>
                )}
              </div>

              {/* Search Button with Submenu */}
              <div>
                <button
                  onClick={() => {
                    setSearchSubMenuOpen((prev) => !prev); // Toggle submenu open/close
                  }}
                  className="flex items-center justify-between px-4 py-2 text-left text-gray-800 hover:bg-blue-500 hover:text-white rounded-lg transition duration-200"
                >
                  Search
                  <span className="text-sm">{searchSubMenuOpen ? "▲" : "▼"}</span>
                </button>
                {searchSubMenuOpen && (
                  <div className="ml-4 flex flex-col space-y-1">
                    <button
                      onClick={() => {
                        navigate("userdashboard/search", { state: { showSearch: 0 } });
                        setIsMenuOpen(false);
                      }}
                      className="px-2 py-1 text-gray-700 hover:bg-blue-400 hover:text-white rounded transition duration-200"
                    >
                      Search by ID
                    </button>
                    <button
                      onClick={() => {
                        navigate("userdashboard/search", { state: { showSearch: 1 } });
                        setIsMenuOpen(false);
                      }}
                      className="px-2 py-1 text-gray-700 hover:bg-blue-400 hover:text-white rounded transition duration-200"
                    >
                      Quick Search
                    </button>
                    <button
                      onClick={() => {
                        navigate("userdashboard/search", { state: { showSearch: 2 } });
                        setIsMenuOpen(false);
                      }}
                      className="px-2 py-1 text-gray-700 hover:bg-blue-400 hover:text-white rounded transition duration-200"
                    >
                      Regular Search
                    </button>
                    <button
                      onClick={() => {
                        navigate("userdashboard/search", { state: { showSearch: 3 } });
                        setIsMenuOpen(false);
                      }}
                      className="px-2 py-1 text-gray-700 hover:bg-blue-400 hover:text-white rounded transition duration-200"
                    >
                      Advance Search
                    </button>
                    {/* Add other search items similarly */}
                  </div>
                )}
              </div>
            </nav>
          </div>
        )}



              </div>
              
            )}

            {/* Full Menu for larger screens */}
            {screenType === "desktop" && (
              <div className="flex gap-x-8 text-lg items-center z-50">
                <nav className="relative z-50">
                  <Menu setActive={setActive}>
                    <MenuItem
                      onClick={() => navigate("/userhome")}
                      setActive={setActive}
                      active={active}
                      item="Home"
                    />
                    <MenuItem
                      onClick={() => navigate("/profilelisting")}
                      setActive={setActive}
                      active={active}
                      item="Profiles"
                    >
                      <div className="flex flex-col space-y-2 relative z-50">
                        <HoveredLink to="/profilelisting">All Profiles</HoveredLink>
                        <HoveredLink to={`/profilelisting/suggested/?tmi=${TMI}`}>Suggested Profiles</HoveredLink>
                        <HoveredLink to={`/profilelisting/featured/?tmi=${TMI}`}>Featured Profiles</HoveredLink>
                        <HoveredLink to={`/profilelisting/premium/?tmi=${TMI}`}>Premium Profiles</HoveredLink>
                        <HoveredLink to="/profilelisting">All Denominations</HoveredLink>
                      </div>
                    </MenuItem>

                    {/* Services Menu Item */}
                    <MenuItem
                      setActive={setActive}
                      onClick={() => navigate("/bgverification")}
                      active={active}
                      item="Services"
                    >
                      <div className="text-sm grid grid-cols-2 gap-4 p-4 relative z-50">
                        <ProductItem title="Online BG Verification" to="/bgverification" icon={onlineBgV} description="Background verification services" />
                        <ProductItem title="Video BG Verification" to="/vbgverification" icon={videoBgV} description="Video background verification" />
                        <ProductItemP title="Marriage Counselling" onClick={() => navigate("/services", { state: { service: 0 } })} icon={marriageCounselling} description="Marriage counselling services" />
                        <ProductItemP title="Marriage Registration" onClick={() => navigate("/services", { state: { service: 1 } })} icon={marriageRegistration} description="Marriage registration services" />
                        <ProductItem title="Advertising" to="/advertising" icon={advertise} description="Advertising services" />
                        <ProductItem title="Events & Meetups" to="/events" icon={events} description="Event and meetup organization" />
                      </div>
                    </MenuItem>

                    {/* Membership Plans Menu Item */}
                    <Link to="/membershipplans">
                    <MenuItem 
          setActive={setActive} 
          active={active} 
          onClick={() => {
            handleFollowUp(TMI);
            navigate("/membershipplans");
          }} 
          item="Membership Plans"
        >
          <div className="flex flex-col space-y-2">
            <HoveredLink href="">Bronze</HoveredLink>
            <HoveredLink href="">Silver</HoveredLink>
            <HoveredLink href="">Gold</HoveredLink>
            <HoveredLink href="">Gold Plus</HoveredLink>
            <HoveredLink href="">Diamond</HoveredLink>
            <HoveredLink href="">Diamond Plus</HoveredLink>
          </div>
        </MenuItem>

                    </Link>

                    {/* Dashboard Menu Item */}
                    <MenuItem setActive={setActive} onClick={() => navigate("/userdashboard")} active={active} item="Dashboard">
                      <div className="flex flex-col gap-y-2 relative z-50">
                        <HoveredLink to="/userdashboard">User Dashboard</HoveredLink>
                        <HoveredLink to="userdashboard/account">My Account</HoveredLink>
                        <HoveredLink to="userdashboard/settings">Profile Settings</HoveredLink>
                      </div>
                    </MenuItem>

                    {/* Search Menu Item */}
                    <MenuItem setActive={setActive} onClick={() => navigate("userdashboard/search/?section=0")} active={active} item="Search">
                      <div className="flex flex-col space-y-2 relative z-50">
                        <HoveredP onClick={() => navigate("userdashboard/search", { state: { showSearch: 0 } })}>Search by ID</HoveredP>
                        <HoveredP onClick={() => navigate("userdashboard/search", { state: { showSearch: 1 } })}>Quick Search</HoveredP>
                        <HoveredP onClick={() => navigate("userdashboard/search", { state: { showSearch: 2 } })}>Regular Search</HoveredP>
                        <HoveredP onClick={() => navigate("userdashboard/search", { state: { showSearch: 3 } })}>Advanced Search</HoveredP>
                      </div>
                    </MenuItem>
                  </Menu>
                </nav>

                {/* Profile Logo and Dropdown */}
                <div className="relative z-50">
                  <div
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <img
                      src={`${apiUrl}${userDetails.photo}`}
                      alt="Profile Logo"
                      className="w-10 h-10 rounded-full cursor-pointer hover:opacity-80 transition-opacity duration-300"
                    />
                    <p className="text-center mt-1 text-sm text-gray-700">
                      {userDetails.firstname}
                    </p>
                  </div>
                  {isMenuOpen && (
                    <div
                      className="absolute right-0 mt-2 w-48 bg-white border rounded-lg shadow-lg z-50 transition-opacity duration-300"
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    >
                      <button
                        onClick={() => navigate(`profilelisting/profile/${user}`)}
                        className="block w-full text-left px-4 py-2 text-black hover:bg-blue-100 transition-colors duration-200"
                      >
                        My Profile
                      </button>
                      <button
                        onClick={() => {
                          setUserDetails(null);
                          setUserType('guest');
                          document.cookie = "accessToken=; Max-Age=0";
                          document.cookie = "refreshToken=; Max-Age=0";
                          navigate('/');
                          setIsMenuOpen(false);
                        }}
                        className="block w-full text-left px-4 py-2 text-red-600 hover:bg-red-100 transition-colors duration-200"
                      >
                        Logout
                      </button>
                    </div>
                  )}
                </div>
              </div>
            )}
          </header>
      )}

      {/* LoggedIn Executive Header */}
      {userType === "executive" && (
          <header className="flex header-text flex-wrap gap-5 justify-between content-center py-2 pr-8 pl-5 border-b border-solid bg-[color:var(--sds-color-background-default-default)] border-zinc-300 max-md:pr-5">
            <Link to="/" replace className="flex items-center">
              <img
                src="/images/triune-christian-matrimony-logo.png"
                alt="triune-christian-matrimony-logo"
                className="w-36"
              />
            </Link>
            <div className="flex gap-x-6">
              <nav className="flex gap-5 my-auto font-medium">
                {Object.values(executiveLinks).map((value) => (
                  <Link
                    key={value[1]}
                    to={value[1]}
                    replace
                    className="border border-accent py-2 px-4 text-lg text-black font-medium rounded-xl hover:bg-accent"
                  >
                    {value[0]}
                  </Link>
                ))}
              </nav>

              {/* Dropdown Menu */}
              <div
                className="relative inline-block text-left"
                onMouseEnter={() => setIsDropdownOpen(true)}
                onMouseLeave={() => setIsDropdownOpen(false)}
              >
                <button
                  type="button"
                  className="inline-flex justify-center w-full rounded-md border border-accent shadow-sm px-4 py-2 bg-white text-lg text-black font-medium hover:bg-accent mt-9"
                  id="menu-button"
                  aria-expanded={isDropdownOpen}
                  aria-haspopup="true"
                >
                  Admin Menu
                  <svg
                    className="-mr-1 ml-2 h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.707a1 1 0 011.414 0L10 11.414l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>

                {/* Dropdown Items */}
                {isDropdownOpen && (
                  <div
                    className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-[200]"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="menu-button"
                  >
                    <div className="py-1" role="none">
                      {/* User Management Link */}
                      <Link
                        to="/admin/user-management"
                        replace
                        className="text-gray-700 block px-4 py-2 text-lg hover:bg-gray-100"
                        role="menuitem"
                        id="menu-item-0"
                      >
                        User Management
                      </Link>

                      {/* Logout Button */}
                      <button
                        onClick={handleLogout}
                        className="text-red-600 block w-full text-left px-4 py-2 text-lg hover:bg-red-100"
                        role="menuitem"
                        id="menu-item-1"
                      >
                        Logout
                      </button>
                    </div>
                  </div>
                )}
              </div>

              <div className="flex flex-col items-center p-4 bg-white rounded-lg shadow-lg border border-gray-200">
                {/* Admin Name */}
                <p className="text-lg font-semibold text-gray-900">
                  {userDetails?.name || "Admin Name"}
                </p>

                {/* Admin ID */}
                <p className="text-sm font-medium text-gray-600">
                  {userDetails?.employeeId || "Admin ID"}
                </p>

                {/* User Type */}
                <p className="mt-2 px-3 py-1 bg-blue-100 text-blue-600 rounded-full text-xs font-medium uppercase">
                  {userType || "User Type"}
                </p>
              </div>
            </div>
          </header>
      )}


      {/* LoggedIn Admin Header */}
      {userType === "admin" && (
              <header className="flex header-text flex-wrap gap-5 justify-between content-center py-2 pr-8 pl-5 border-b border-solid bg-[color:var(--sds-color-background-default-default)] border-zinc-300 max-md:pr-5">
                <Link to="/" className="flex items-center">
                  <img
                    src="/images/triune-christian-matrimony-logo.png"
                    alt="triune-christian-matrimony-logo"
                    className="w-36"
                  />
                </Link>
                <div className="flex gap-x-6">
                  <nav className="flex gap-5 my-auto font-medium">
                    {Object.values(adminLinks).map((value) => (
                      <Link
                        key={value[1]}
                        to={value[1]}
                        className="border border-accent py-2 px-4 text-lg text-black font-medium rounded-xl hover:bg-accent"
                      >
                        {value[0]}
                      </Link>
                    ))}
                  </nav>


                  {/* Dropdown Menu */}
                  <div
                    className="relative inline-block text-left"
                    onMouseEnter={() => setIsDropdownOpen(true)}
                    onMouseLeave={() => setIsDropdownOpen(false)}
                  >
                    <button
                      type="button"
                      className="inline-flex justify-center w-full rounded-md border border-accent shadow-sm px-4 py-2 bg-white text-lg text-black font-medium hover:bg-accent mt-9"
                      id="menu-button"
                      aria-expanded={isDropdownOpen}
                      aria-haspopup="true"
                    >
                      Admin Menu
                      <svg
                        className="-mr-1 ml-2 h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5.293 7.707a1 1 0 011.414 0L10 11.414l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>

                

                    {/* Dropdown Items */}
                    {isDropdownOpen && (
                      <div
                        className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="menu-button"
                      >
                        <div className="py-1" role="none">
                          

                          {/* Logout Button */}
                          <button
                            onClick={handleLogout}
                            className="text-red-600 block w-full text-left px-4 py-2 text-lg hover:bg-red-100"
                            role="menuitem"
                            id="menu-item-1"
                          >
                            Logout
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col items-center p-4 bg-white rounded-lg shadow-lg border border-gray-200">
                      {/* Admin Name */}
                      <p className="text-lg font-semibold text-gray-900">
                        {userDetails?.name || "Admin Name"}
                      </p>

                      {/* Admin ID */}
                      <p className="text-sm font-medium text-gray-600">
                        {userDetails?.employeeId || "Admin ID"}
                      </p>

                      {/* User Type */}
                      <p className="mt-2 px-3 py-1 bg-blue-100 text-blue-600 rounded-full text-xs font-medium uppercase">
                        {userType || "User Type"}
                      </p>
                    </div>


                </div>

              </header>
      )}

      {/* LoggedIn Customer Header */}
      {userType === "customer" && (
                    <header className="flex header-text flex-wrap gap-5 justify-between content-center py-2 pr-8 pl-5 border-b border-solid bg-[color:var(--sds-color-background-default-default)] border-zinc-300 max-md:pr-5">
                      <Link to="/" className="flex items-center">
                        <img
                          src="/images/triune-christian-matrimony-logo.png"
                          alt="triune-christian-matrimony-logo"
                          className="w-36"
                        />
                      </Link>
                      <div className="flex gap-x-6">
                        <nav className="flex gap-5 my-auto font-medium">
                          {Object.values(customerLinks).map((value) => (
                            <Link
                              key={value[1]}
                              to={value[1]}
                              className="border border-accent py-2 px-4 text-lg text-black font-medium rounded-xl hover:bg-accent"
                            >
                              {value[0]}
                            </Link>
                          ))}
                        </nav>


                        {/* Dropdown Menu */}
                        <div
                          className="relative inline-block text-left"
                          onMouseEnter={() => setIsDropdownOpen(true)}
                          onMouseLeave={() => setIsDropdownOpen(false)}
                        >
                          <button
                            type="button"
                            className="inline-flex justify-center w-full rounded-md border border-accent shadow-sm px-4 py-2 bg-white text-lg text-black font-medium hover:bg-accent mt-9"
                            id="menu-button"
                            aria-expanded={isDropdownOpen}
                            aria-haspopup="true"
                          >
                            Menu
                            <svg
                              className="-mr-1 ml-2 h-5 w-5"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                fillRule="evenodd"
                                d="M5.293 7.707a1 1 0 011.414 0L10 11.414l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </button>

                      

                          {/* Dropdown Items */}
                          {isDropdownOpen && (
                            <div
                              className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                              role="menu"
                              aria-orientation="vertical"
                              aria-labelledby="menu-button"
                            >
                              <div className="py-1" role="none">
                                

                                {/* Logout Button */}
                                <button
                                  onClick={handleLogout}
                                  className="text-red-600 block w-full text-left px-4 py-2 text-lg hover:bg-red-100"
                                  role="menuitem"
                                  id="menu-item-1"
                                >
                                  Logout
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="flex flex-col items-center p-4 bg-white rounded-lg shadow-lg border border-gray-200">
                            {/* Customer Name */}
                            <p className="text-lg font-semibold text-gray-900">
                              {userDetails?.name || "Customer Name"}
                            </p>

                            {/* Customer ID */}
                            <p className="text-sm font-medium text-gray-600">
                              {userDetails?.employeeId || "Customer ID"}
                            </p>

                            {/* User Type */}
                            <p className="mt-2 px-3 py-1 bg-blue-100 text-blue-600 rounded-full text-xs font-medium uppercase">
                              {userType+" Service" || "User Type"}
                            </p>
                          </div>


                      </div>

                    </header>
            )}
    </>
  );
};

export default Header;
