import { PayloadAction, createSlice } from '@reduxjs/toolkit';
//import { users } from '../../utils/data';
import { UserType } from '../../utils/data';

export interface UsersState {
  users: UserType[]
  selectedUserId: string | null;
}

const initialState: UsersState = {
  users: [],
  selectedUserId: null,
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    // Add reducer functions here
    fetchUsersSuccess(state, action: PayloadAction<UserType[]>) {
      state.users = action.payload;
    },
    setSelectedUserId(state, action: PayloadAction<string>) {
      state.selectedUserId = action.payload;
    }
  },
});

//export const { fetchUsersSuccess } = usersSlice.actions;
export const { actions, reducer } = usersSlice;