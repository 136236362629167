import { Link } from "react-router-dom";
import { newUser as user } from "../../utils/data";

const MyAccount: React.FC = () => {
  return (
      
      <div className="p-8 m-3 tablet:p-8 flex flex-col gap-y-8 bg-gray-100  rounded-xl shadow-md ">
        <h1 className="home-heading font-semibold text-center text-2xl">
          Account Summary
        </h1>
        
        {/* Payment and plan details */}
        <div className="grid grid-cols-1 laptop:grid-cols-3 border border-gray-300 rounded-xl overflow-hidden shadow-lg">
          <SummaryCell title="Profile ID" value={user.basicDetails.id} />
          <SummaryCell
            title="Plan Name"
            value={user.accountSummary.membershipPlanName}
          />
          <SummaryCell
            title="Plan Status"
            value={user.accountSummary.membershipPlanStatus}
          />
          <SummaryCell
            title="Start Date"
            value={user.accountSummary.membershipPlanStartDate}
          />
          <SummaryCell
            title="End Date"
            value={user.accountSummary.membershipPlanEndDate}
          />
          <Link to="/membershipplans">
            <button className="w-full flex items-center justify-center gap-x-4 border  ">
              <p className="text-xl btnMembership font-semibold py-3 px-6 rounded-lg text-white">
                UPGRADE ⬆
              </p>
            </button>
          </Link>
        </div>

        {/* Features Used */}
        <div className="grid grid-cols-1 laptop:grid-cols-3 gap-4 border border-gray-300 rounded-xl overflow-hidden shadow-lg">
          {[
            { title: "View Contact Details", used: user.accountSummary.viewContactDetails },
            { title: "Express Interest", used: user.accountSummary.expressInterest },
            { title: "Profile Highlighted Days", used: user.accountSummary.profileHighlightedDays },
            { title: "Email Alert", used: user.accountSummary.emailAlert },
            { title: "Whatsapp Alert", used: user.accountSummary.whatsappAlert },
            { title: "Featured Profile", used: user.accountSummary.featuredProfile },
          ].map((feature) => (
            <FeatureCell
              key={feature.title}
              title={feature.title}
              used={feature.used}
            />
          ))}
        </div>
      </div>
      
    
  );
};

export default MyAccount;

const SummaryCell: React.FC<{ title: string; value: string }> = ({
  title,
  value,
}) => {
  return (
    <div className="flex items-center text-wrap px-4 py-3 gap-x-4 border border-gray-300 bg-white rounded-xl">
      <p className="text-lg font-semibold text-gray-700">{title}:</p>
      <p className="text-lg text-gray-900">{value}</p>
    </div>
  );
};

const FeatureCell: React.FC<{ used: number; title: string }> = ({
  used,
  title,
}) => {
  return (
    <div className="flex flex-col text-center items-center px-4 py-6 gap-y-2 border border-gray-300 bg-white rounded-xl shadow-sm hover:shadow-lg transition-shadow duration-300 ease-in-out">
      <p className="text-2xl font-semibold text-green-600">{used}/20</p>
      <p className="text-lg text-gray-700">{title}</p>
    </div>
  );
};

