import React, { useState } from 'react';
import { Checkbox, Button, Card, CardContent, Typography, Grid } from '@mui/material';


// Feature Interface
interface VerificationFeature {
  isSelected: boolean;
  numberOfTimes: number;
}

// Verification Plans Interface
interface VerificationPlan {
  identity: VerificationFeature;
  addressVerification: VerificationFeature;
  employmentVerification?: VerificationFeature;
  educationVerification?: VerificationFeature;
  creditScoreVerification: VerificationFeature;
  courtRecordChecks: VerificationFeature;
  socialMediaVerification?: VerificationFeature; // Optional for essential plan
  addOnVerification?: {
    socialMediaVerification?: VerificationFeature; // Only for essential plan
    courtRecordChecks?: VerificationFeature; // Add-On for advanced plan
    employmentVerification?: VerificationFeature; // Add-On for advanced plan
  };
}

// Dashboard Component
const BackgroundVerificationDashboard: React.FC = () => {
  // State for selected plan
  const [selectedPlan, setSelectedPlan] = useState<string>('elite');

  // Default state for all verification plans
  const defaultPlanState: VerificationPlan = {
    identity: { isSelected: false, numberOfTimes: 0 },
    addressVerification: { isSelected: false, numberOfTimes: 0 },
    employmentVerification: { isSelected: false, numberOfTimes: 0 },
    educationVerification: { isSelected: false, numberOfTimes: 0 },
    creditScoreVerification: { isSelected: false, numberOfTimes: 0 },
    courtRecordChecks: { isSelected: false, numberOfTimes: 0 },
    socialMediaVerification: { isSelected: false, numberOfTimes: 0 }, // Optional for essential
  };

  // State for the plans (Elite, Advanced, Essential)
  const [elitePlan, setElitePlan] = useState<VerificationPlan>({
    ...defaultPlanState,
    socialMediaVerification: { isSelected: false, numberOfTimes: 0 },
  });

  const [advancedPlan, setAdvancedPlan] = useState<VerificationPlan>({
    ...defaultPlanState,
    addOnVerification: {
      courtRecordChecks: { isSelected: false, numberOfTimes: 0 }, // Add-On for advanced plan
      employmentVerification: { isSelected: false, numberOfTimes: 0 }, // Add-On for advanced plan
    },
  });

  const [essentialPlan, setEssentialPlan] = useState<VerificationPlan>({
    ...defaultPlanState,
    addOnVerification: {
      socialMediaVerification: { isSelected: false, numberOfTimes: 0 },
    },
  });

  // Get the currently selected plan
  const currentPlan = selectedPlan === 'elite' ? elitePlan : selectedPlan === 'advanced' ? advancedPlan : essentialPlan;

  // Toggle isSelected for a feature
  // Toggle isSelected for a feature
const handleToggle = (featureKey: keyof VerificationPlan | 'addOnVerification') => {
  // Create a deep copy of the current plan to maintain immutability
  const updatedPlan: VerificationPlan = {
    ...currentPlan,
    addOnVerification: {
      ...currentPlan.addOnVerification,
    },
  };

  if (featureKey === 'addOnVerification') {
    if (selectedPlan === 'advanced') {
      // Toggle for courtRecordChecks and employmentVerification
      if (updatedPlan.addOnVerification) {
        // Safely toggle courtRecordChecks if it exists
        if (updatedPlan.addOnVerification.courtRecordChecks) {
          updatedPlan.addOnVerification.courtRecordChecks = {
            ...updatedPlan.addOnVerification.courtRecordChecks,
            isSelected: !updatedPlan.addOnVerification.courtRecordChecks.isSelected,
          };
        }
        // Safely toggle employmentVerification if it exists
        if (updatedPlan.addOnVerification.employmentVerification) {
          updatedPlan.addOnVerification.employmentVerification = {
            ...updatedPlan.addOnVerification.employmentVerification,
            isSelected: !updatedPlan.addOnVerification.employmentVerification.isSelected,
          };
        }
      }
    } else if (selectedPlan === 'essential') {
      // Toggle for social media verification
      if (updatedPlan.addOnVerification?.socialMediaVerification) {
        updatedPlan.addOnVerification.socialMediaVerification = {
          ...updatedPlan.addOnVerification.socialMediaVerification,
          isSelected: !updatedPlan.addOnVerification.socialMediaVerification.isSelected,
        };
      }
    }
  } else {
    // Ensure featureKey is a valid property of updatedPlan
    if (updatedPlan[featureKey]) {
      updatedPlan[featureKey] = {
        ...updatedPlan[featureKey],
        isSelected: !updatedPlan[featureKey].isSelected,
      };
    }
  }

  // Call the function to update the state with the modified plan
  updatePlanState(updatedPlan);
};



  // Update the plan state based on the selected plan
  const updatePlanState = (updatedPlan: VerificationPlan ) => {
    if (selectedPlan === 'elite') {
      setElitePlan(updatedPlan);
    } else if (selectedPlan === 'advanced') {
      setAdvancedPlan(updatedPlan);
    } else {
      setEssentialPlan(updatedPlan);
    }
  };

  // Handle Order Now button click
  const handleOrder = (featureKey: keyof VerificationPlan) => {
    console.log(`Ordering ${featureKey} for ${selectedPlan} plan.`);
    // Implement your order functionality here
  };

  const isVerificationFeature = (feature: any): feature is VerificationFeature => {
    return feature && typeof feature.isSelected === 'boolean' && typeof feature.numberOfTimes === 'number';
  };

  // Render the feature cards
  const renderFeature = (featureKey: keyof VerificationPlan, label: string, description: string) => {
    const feature = currentPlan[featureKey];

    return (
      <Grid item xs={12} sm={6} md={4} key={featureKey}>
        <Card>
          <CardContent>
            <Typography variant="h6">{label}</Typography>
            <Typography variant="body2">{description}</Typography>
            <Checkbox
              checked={isVerificationFeature(feature) ? feature.isSelected : false}
              onChange={() => handleToggle(featureKey)}
              color="primary"
            />
            <Typography variant="body2">Number of Times: {isVerificationFeature(feature) ? feature.numberOfTimes : 0}</Typography>
            <Button variant="contained" color="primary" onClick={() => handleOrder(featureKey)} style={{ marginLeft: '10px' }}>
              Order Now
            </Button>
          </CardContent>
        </Card>
      </Grid>
    );
  };

  // Render Add-On Features
  // Render Add-On Features
  const renderAddOnFeature = (
    featureKey: keyof VerificationPlan['addOnVerification'], // Key for addOnVerification features
    label: string,
    description: string
  ) => {
    // Determine the current plan features using optional chaining
    const feature = selectedPlan === 'advanced'
      ? advancedPlan.addOnVerification?.[featureKey] // Use optional chaining
      : essentialPlan.addOnVerification?.[featureKey]; // Use optional chaining
  
    // Type assertion for feature
    const verifiedFeature = feature as VerificationFeature | undefined; // Assert that feature can be VerificationFeature or undefined
  
    // Check if feature is defined
    if (!verifiedFeature) {
      return null; // Or render a fallback UI if needed
    }
  
    return (
      <Grid item xs={12} sm={6} md={4} key={featureKey}>
        <Card>
          <CardContent>
            <Typography variant="h6">{label}</Typography>
            <Typography variant="body2">{description}</Typography>
            <Checkbox
              checked={verifiedFeature.isSelected} // Now TypeScript knows feature has these properties
              onChange={() => handleToggle('addOnVerification')}
              color="primary"
            />
            <Typography variant="body2">
              Number of Times: {verifiedFeature.numberOfTimes}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleOrder(featureKey)}
              style={{ marginLeft: '10px' }}
            >
              Order Now
            </Button>
          </CardContent>
        </Card>
      </Grid>
    );
  };
  
  
  


  return (
    <div style={{ padding: '20px', backgroundColor: '#f5f5f5', borderRadius: '8px', boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)' }}>
  <Typography variant="h4" align="center" gutterBottom style={{ color: '#333', fontWeight: 'bold' }}>
    Background Verification Dashboard
  </Typography>

  <Typography variant="h5" align="center" gutterBottom style={{ color: '#666' }}>
    Selected Plan: {selectedPlan.charAt(0).toUpperCase() + selectedPlan.slice(1)} Plan
  </Typography>

  {/* Plan Selector */}
  <div style={{ marginBottom: '20px', textAlign: 'center' }}>
    <Button 
      variant={selectedPlan === 'elite' ? 'contained' : 'outlined'} 
      onClick={() => setSelectedPlan('elite')}
      style={{ margin: '0 10px', backgroundColor: selectedPlan === 'elite' ? '#3f51b5' : '#fff', color: selectedPlan === 'elite' ? '#fff' : '#3f51b5' }}
    >
      Elite Plan
    </Button>
    <Button
      variant={selectedPlan === 'advanced' ? 'contained' : 'outlined'}
      onClick={() => setSelectedPlan('advanced')}
      style={{ margin: '0 10px', backgroundColor: selectedPlan === 'advanced' ? '#3f51b5' : '#fff', color: selectedPlan === 'advanced' ? '#fff' : '#3f51b5' }}
    >
      Advanced Plan
    </Button>
    <Button
      variant={selectedPlan === 'essential' ? 'contained' : 'outlined'}
      onClick={() => setSelectedPlan('essential')}
      style={{ margin: '0 10px', backgroundColor: selectedPlan === 'essential' ? '#3f51b5' : '#fff', color: selectedPlan === 'essential' ? '#fff' : '#3f51b5' }}
    >
      Essential Plan
    </Button>
  </div>

  {/* Render Main Features */}
  <Typography variant="h5" align="center" gutterBottom style={{ color: '#333' }}>
    Main Features
  </Typography>
  <Grid container spacing={3}>
    {renderFeature('identity', 'Identity Verification', 'Verification of identity using Aadhar and PAN card.')}
    {renderFeature('addressVerification', 'Address Verification', 'Confirm physical address and inquire about family using a customized questionnaire.')}
    {renderFeature('employmentVerification', 'Employment Verification', 'Verify employment status through company HR or self-employment verification.')}
    {renderFeature('educationVerification', 'Education Verification', 'Verify the latest educational qualifications.')}
    {renderFeature('creditScoreVerification', 'Credit Score Verification', 'Credit assessment of the individual or business.')}
    {renderFeature('courtRecordChecks', 'Court Record Checks', 'Standard court records checks for background verification.')}
    {selectedPlan === 'elite' && renderFeature('socialMediaVerification', 'Social Media Verification', 'Verify social media presence and activity for authenticity.')}
  </Grid>

  {/* Conditionally Render Add-On Features Section */}
  {selectedPlan !== 'elite' && (
    <>
      <Typography variant="h5" align="center" gutterBottom style={{ marginTop: '30px', color: '#333' }}>
        Add-On Features
      </Typography>
      <Grid container spacing={3}>
        {selectedPlan === 'advanced' && (
          <>
            {renderAddOnFeature(
              'employmentVerification' as keyof VerificationPlan['addOnVerification'],
              'Employment Verification (Add-On)',
              'Extended employment verification checks with previous employers.'
            )}
            {renderAddOnFeature(
              'courtRecordChecks' as keyof VerificationPlan['addOnVerification'],
              'Court Record Checks (Add-On)',
              'Additional court record checks beyond the standard verifications.'
            )}
          </>
        )}
        {selectedPlan === 'essential' && (
          <>
            {renderAddOnFeature(
              'socialMediaVerification' as keyof VerificationPlan['addOnVerification'],
              'Social Media Verification (Add-On)',
              'Verify social media presence and activity for authenticity.'
            )}
          </>
        )}
      </Grid>
    </>
  )}
</div>

  );
};

export default BackgroundVerificationDashboard;