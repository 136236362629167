import React from "react";
import ReactDOM from "react-dom/client";
import { UserProvider } from './pages/UserContext';
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';


import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";

import { store } from "./store/store.ts";
import App from "./App.tsx";
import "./index.css";
import ScrollToTop from "./components/ScrollToTop.tsx";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <UserProvider>
    <Provider store={store}>
   
      <Router>
        <ScrollToTop />
        <App />
      </Router>
      
    </Provider>
    </UserProvider>
  </React.StrictMode>
);
