import { Outlet } from "react-router-dom";
import { Link } from "react-router-dom";
import useScreenType from "../hooks/useScreenType";
import { useEffect, useState } from "react";
import { useUser } from './UserContext';

// Define links for both main and verification sections
const links = {
  userDashboard: ["User Dashboard", "/userdashboard", "dashboard_8600265.png"],
  search: ["Search", "/userdashboard/search", "search_4774685.png"],
  uploadPhoto: ["Upload Photo", "/userdashboard/uploadphoto", "avatar_15862580.png"],
  myAccount: ["My Account", "/userdashboard/account", "user_6610970.png"],
  settings: ["Settings", "/userdashboard/settings", "assistance_2270752.png"],
  membershipPlans: ["Membership Plans", "/userdashboard/membershipplans", "subscription_9582449.png"],
  myEmails: ["My Emails", "/userdashboard/my-mails", "sending-email_17698101.png"],
};

// Define verification links that need to be grouped
const verificationLinks = {
  essentialverification: ["Essential Verification", "/userdashboard/essentialverification", "essential.png"],
  advancedverification: ["Advanced Verification", "/userdashboard/advancedverification", "advance.png"],
  eliteverification: ["Elite Verification", "/userdashboard/eliteverification", "elite.png"],
  orderstatus: ["BGV Status", "/userdashboard/orderstatus", "survey_5931343.png"],
};

// Same structure for mobile links
const mobileLinks = {
  userDashboard: ["User Dashboard", "/userdashboard", "dashboard_8600265.png"],
  search: ["Search", "/userdashboard/search", "search_4774685.png"],
  uploadPhoto: ["Upload Photo", "/userdashboard/uploadphoto", "avatar_15862580.png"],
  myAccount: ["My Account", "/userdashboard/account", "user_6610970.png"],
  settings: ["Settings", "/userdashboard/settings", "assistance_2270752.png"],
  membershipPlans: ["Membership Plans", "/userdashboard/membershipplans", "subscription_9582449.png"],
  myEmails: ["My Emails", "/userdashboard/my-mails", "sending-email_17698101.png"],
};

const mobileVerificationLinks = {
  essentialverification: ["Essential Verification", "/userdashboard/essentialverification", "essential.png"],
  advancedverification: ["Advanced Verification", "/userdashboard/advancedverification", "advance.png"],
  eliteverification: ["Elite Verification", "/userdashboard/eliteverification", "elite.png"],
  orderstatus: ["BGV Status", "/userdashboard/orderstatus", "survey_5931343.png"],
};

const UserDashboard: React.FC = () => {
  const screenType = useScreenType();
  const { setUserType } = useUser();

  useEffect(() => {
    setUserType('user');
  }, [setUserType]);

  const [currSection, setCurrSection] = useState("User Dashboard");

  return (
    <div className="flex flex-col md:flex-row relative">
      {/* Sidebar for Desktop */}
      {screenType === "desktop" && (
        <div className="w-[20%] flex flex-col px-6 py-8 gap-y-4 min-h-[32rem] border-r">
          {/* Regular Links */}
          {Object.values(links).map((value) => (
            <Link
              to={value[1]}
              key={value[1]}
              onClick={() => setCurrSection(value[0])}
              className={`${
                currSection === value[0] ? "bg-accent" : "bg-accent/50"
              } border border-accent py-2 px-4 text-lg text-black font-medium rounded-xl flex items-center`}
            >
              <img
                src={`/images/${value[2]}`} // Use the icon filename from the array
                alt={`${value[0]} icon`}
                className="w-10 h-10 mr-2" // Adjust size and margin as needed
              />
              {value[0]}
            </Link>
          ))}

          {/* Divider */}
          <hr className="my-4 border-t border-gray-400" />

          {/* Background Verification Section */}
          <div className="flex flex-col gap-y-2">
            <h2 className="text-lg font-bold text-black">Background Verification</h2>
            {Object.values(verificationLinks).map((value) => (
              <Link
                to={value[1]}
                key={value[1]}
                onClick={() => setCurrSection(value[0])}
                className={`${
                  currSection === value[0] ? "bg-accent" : "bg-accent/50"
                } border border-accent py-2 px-3 text-lg text-black font-medium rounded-xl flex items-center`}
              >
                <img
                  src={`/images/${value[2]}`} // Use the icon filename from the array
                  alt={`${value[0]} icon`}
                  className="w-10 h-10 mr-2" // Adjust size and margin as needed
                />
                {value[0]}
              </Link>
            ))}
          </div>
        </div>
      )}

      {/* Mobile */}
      {screenType !== "desktop" && (
        <div className="w-full h-auto grid grid-cols-3 gap-2 p-4">
          {Object.values(mobileLinks).map((value) => (
            <Link
              to={value[1]}
              key={value[1]}
              onClick={() => setCurrSection(value[0])}
              className={`${
                currSection === value[0] ? "bg-accent" : "bg-accent/50"
              } border border-l-0 border-accent p-3 flex flex-col items-center text-sm text-center text-black font-medium`}
            >
              <img
                src={`/images/${value[2]}`} // Use the icon filename from the array
                alt={`${value[0]} icon`}
                className="w-8 h-8 mb-1" // Adjust size and margin as needed
              />
              {value[0]}
            </Link>
          ))}

          {/* Divider */}
          <hr className="col-span-3 my-2 border-t border-gray-400" />

          {/* Mobile Background Verification Section */}
          <div className="col-span-3 flex flex-col gap-y-2">
            <h2 className="text-lg font-bold text-black">Background Verification</h2>
            {Object.values(mobileVerificationLinks).map((value) => (
              <Link
                to={value[1]}
                key={value[1]}
                onClick={() => setCurrSection(value[0])}
                className={`${
                  currSection === value[0] ? "bg-accent" : "bg-accent/50"
                } border border-l-0 border-accent p-3 flex flex-col items-center text-sm text-center text-black font-medium`}
              >
                <img
                  src={`/images/${value[2]}`} // Use the icon filename from the array
                  alt={`${value[0]} icon`}
                  className="w-8 h-8 mb-1" // Adjust size and margin as needed
                />
                {value[0]}
              </Link>
            ))}
          </div>
        </div>
      )}

      {/* Outlet for rendering nested routes */}
      <div className="w-full mb-20 md:mb-0 md:w-[80%]">
        <Outlet />
      </div>
    </div>
  );
};

export default UserDashboard;


