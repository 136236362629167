const RefundPolicy = () => {
    return (
      <div className="overflow-y-auto h-[100vh] p-4 md:p-6 lg:p-8 bg-white rounded shadow-lg">
        <h2 className="text-2xl font-bold mb-4 text-center">Refund and Cancellation</h2>
        <p className="mb-4 text-justify">
          At Triune Matrimonial, we, Triune Techmentors LLP, LLPIN: ACC-5349, a limited liability partnership
          incorporated and existing under the provisions of Limited Liability Partnership Act, 2008 and having
          its registered office at #79, 4th Cross, Rajanna Layout Bangalore North, Karnataka,
          India – 560043, aim to provide quality services that meet our users' expectations. However, we
          understand that certain situations may require the consideration of a refund. Please review our refund
          policy carefully.
        </p>
  
        <h3 className="font-bold text-lg mt-4">1. No Cancellation Policy:</h3>
        <ul className="list-disc pl-6 mb-4">
          <li>All services, once purchased, are non-cancellable</li>
          <li>Memberships, renewals, and supplementary services cannot be cancelled or reversed once the payment is processed.</li>
          <li>By subscribing to or purchasing our 
          services, you agree that the transaction is final, and no cancellations will be permitted.</li>
        </ul>

        <h3 className="font-bold text-lg mt-4">2. Non-Refundable Services:</h3>
        <ul className="list-disc pl-6 mb-4">
          <li>Membership Fees: All membership purchases and renewals are final and non-refundable once the payment has been processed.</li>
          <li>Additional Services: Payments for profile highlighting, accessing premium features or availing supplementary services like Partnered Services offered through third-party service providers, are non-refundable.</li>
        </ul>
  
        <h3 className="font-bold text-lg">3. Refund Consideration in Exceptional Cases:</h3>
        <ul className="list-disc pl-6 mb-4">
          <li>Technical Issues: If there are technical malfunctions on our website/application that prevent you from using our services.</li>
          <li>Duplicate Payments: In case of unintentional duplicate transactions, a refund for the excess amount may be processed.</li>
        </ul>
  
        <h3 className="font-bold text-lg">4. Refund Process:</h3>
        <ul className="list-disc pl-6 mb-4">
          <li>To request a refund, you must contact our customer support team at <a href="mailto:customersupport@triunematrimony.com" className="text-blue-600">customersupport@triunematrimony.com</a> within 2 days of the transaction.</li>
          <li>Please provide your account details, transaction ID, and a detailed explanation of your request.</li>
          <li>Refund requests will be evaluated and approved on a case-by-case basis, at our sole discretion. We reserve the right to request additional documentation or information, if necessary.</li>
        </ul>
  
        <h3 className="font-bold text-lg">5. Refund Approval and Timeline:</h3>
        <ul className="list-disc pl-6 mb-4">
          <li>Approved refunds will be processed through the original payment method within 30 days of approval.</li>
          <li>Please note that any deductions or fees levied by banks or payment gateways are not under the control of Triune and may affect the final refund amount.</li>
        </ul>
  
        <h3 className="font-bold text-lg">6. Non-Eligibility for Refund:</h3>
        <ul className="list-disc pl-6 mb-4">
          <li>Refunds will not be granted in the following scenarios:</li>
          <li>Violation of our Terms and Conditions.</li>
          <li>Failure to use the services within the membership term.</li>
          <li>Personal decisions to cancel the service without valid grounds for refund consideration.</li>
        </ul>
  
        <h3 className="font-bold text-lg">7. Changes to the Refund Policy:</h3>
        <p className="mb-4">
          Triune reserves the right to amend or update this refund policy at any time. Changes will be posted on
          the website/application, and it is your responsibility to review the policy periodically.
        </p>
      </div>
    );
  };
  
  export default RefundPolicy;
  