import { useState, useEffect } from "react";
const apiUrl = import.meta.env.VITE_API_URL;

const Events: React.FC = () => {
  const [isComing, setIsComing] = useState<boolean>(false);
  const [eventData, setEventData] = useState<any[]>([]);  // Changed to array to hold multiple events
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  // Fetch event data when the component mounts
  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/events`);
        const text = await response.text();  // Get raw text
        console.log(text);  // Check what is returned
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = JSON.parse(text);  // Manually parse if it is a valid JSON
        setEventData(data);  // Set all events into state
      } catch (err) {
        console.error("Error fetching events:", err);
        setError("Failed to load events");
      } finally {
        setIsLoading(false);
      }
    };
    fetchEventData();
  }, []);

  // If loading, show a loading message
  if (isLoading) return <p>Loading...</p>;

  // If there's an error, display the error
  if (error) return <p>Error: {error}</p>;

  return (
    <>
      <div className="p-8">
        {/* Title */}
        <h1 className="text-center text-2xl tablet:text-3xl laptop:text-4xl desktop:text-5xl font-semibold">
          Events
        </h1>

        {/* Render each event */}
        {eventData.length === 0 ? (
          <p>No events available.</p>
        ) : (
          eventData.map((event, index) => (
            <div key={index} className="mt-12">
              <h2 className="text-center text-2xl tablet:text-3xl laptop:text-4xl font-semibold">
                {event?.heading || 'Event Title'}
              </h2>

              <p className="mt-8 tablet:text-2xl">
                {event?.description || 'Event description not available.'}
              </p>

              <div className="flex flex-wrap place-items-center mt-8 gap-x-6">
                <p className="text-2xl laptop:text-3xl font-semibold">Event Date:</p>
                <p className="text-xl laptop:text-2xl">{event?.date || 'Date not available'}</p>
              </div>

              <div className="flex flex-wrap place-items-center mt-4 gap-x-6">
                <p className="text-2xl laptop:text-3xl font-semibold">Venue:</p>
                <p className="text-xl laptop:text-2xl">{event?.venue || 'Venue not available'}</p>
              </div>

              <div className="relative flex flex-col laptop:flex-row place-content-center mt-12">
                <img
                  src={event?.image || '/images/card.png'} // Display the event image
                  alt="Event Invitation"
                  className="laptop:w-[60%] desktop:w-[40%]"
                />
              </div>

              <div className="flex flex-wrap place-content-center items-center gap-2 laptop:gap-x-6 mt-12">
                <div className="flex gap-x-3 place-content-center">
                  <input
                    checked={isComing}
                    onChange={() => setIsComing(!isComing)}
                    type="checkbox"
                    name="availability"
                    id="availability"
                    className="tablet:h-[2rem] tablet:w-[2rem]"
                  />
                  <label htmlFor="availability" className="tablet:text-2xl">
                    Confirm your availability
                  </label>
                </div>
                <button
                  onClick={() => console.log({ isComing })}
                  className="relative px-4 group/btn border-2 btn h-10 font-medium shadow-[0px_1px_0px_0px_#ffffff40_inset,0px_-1px_0px_0px_#ffffff40_inset]]"
                  type="button"
                >
                  I'm Coming
                  <BottomGradient />
                </button>
              </div>
              <hr className="my-12 border-2 border-black" />
            </div>
          ))
        )}
      </div>
    </>
  );
};

export default Events;

const BottomGradient = () => {
  return (
    <>
      <span className="group-hover/btn:opacity-100 block transition duration-500 opacity-0 absolute h-px w-full -bottom-px inset-x-0 bg-gradient-to-r from-transparent via-cyan-500 to-transparent" />
      <span className="group-hover/btn:opacity-100 blur-sm block transition duration-500 opacity-0 absolute h-px w-1/2 mx-auto -bottom-px inset-x-10 bg-gradient-to-r from-transparent via-indigo-500 to-transparent" />
    </>
  );
};
