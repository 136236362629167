import { createContext, useContext, useState, ReactNode } from 'react';

// Create the context with a default value of null
const AuthUserContext = createContext<any>(null);

export const AuthUserProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<any>(null);

  return (
    <AuthUserContext.Provider value={{ user, setUser }}>
      {children}
    </AuthUserContext.Provider>
  );
};

// Custom hook to use the AuthUserContext
export const useAuthUser = () => useContext(AuthUserContext);

