import { useEffect, useState } from "react";

const useScreenType = (): string => {
  const getScreenType = (width: number): string => {
    if (width < 600) return "mobile";
    if (width >= 600 && width < 1014) return "tablet";
    if (width >= 1014 && width < 1280) return "laptop"
    return "desktop";
  };

  const [screenType, setScreenType] = useState(getScreenType(window.innerWidth));

  useEffect(() => {
    const handleResize = () => setScreenType(getScreenType(window.innerWidth));
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return screenType;
};

export default useScreenType;
