import React, { useState } from 'react';
import axios from 'axios';
import { useAuthUser } from "../pages/AuthUserContext";

type CheckStatus = {
  check_id: string;
  status: string;
};

type OrderStatusResponse = {
  checks_status: CheckStatus[];
  report_status: string;
  order_status: string;
};

const eliteVerificationCategories = [
  {
    category: "Identity",
    checklists: [
      { name: "Aadhar Card / Pan Card / Passport", id: "AAC" },
    ],
  },
  {
    category: "Address Verification",
    checklists: [
      { name: "Physical address verification - Enquire about the family and the person with customized questionnaire", id: "PHA" },
      
    ],
  },
  {
    category: "Employment Verification",
    checklists: [
      { name: "Employment verification - With Company HR", id: "EM1" },
      { name: "Self-employment verification (Only physical address verification)", id: "SEC" },
    ],
  },
  {
    category: "Education Verification",
    checklists: [
      { name: "Latest education", id: "ED1" },
    ],
  },
  {
    category: "Credit Score Verification",
    checklists: [
      { name: "Credit assessment - Individual", id: "CRD" },
      { name: "Credit assessment - Business", id: "BCC" },
    ],
  },
  {
    category: " Criminal Court Record Checks",
    checklists: [
      { name: "Criminal court", id: "CRC" },
    ],
  },
  {
    category: "Civil Court Record Checks",
    checklists: [
      { name: "Civil court", id: "CCC" },
    ],
  },
  {
    category: "Family Court Record Checks",
    checklists: [
      
      { name: "Family court ", id: "FCC" },
      { name: "Family Court Check with multiple address location verification", id: "FCX" },
    ],
  },
  {
    category: "Social Media Verification",
    checklists: [
      { name: "Facebook/Twitter/Instagram/LinkedIn", id: "SMC" }
    ],
  },
];

const OrderStatus: React.FC = () => {
  const user = useAuthUser();
  const tmi = user?.user;
  const [orderStatus, setOrderStatus] = useState<OrderStatusResponse | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const response = await axios.get<OrderStatusResponse>(`https://order-api-sandbox.verifyin.ai/v1/order/status/${tmi}`, {
        headers: {
          Authorization: `Bearer G1OfeGbPxo3yqnhcCSES`,
        },
      });
      setOrderStatus(response.data);
    } catch (err) {
      setError('Failed to retrieve order status. Please check the member ID and try again.');
    } finally {
      setLoading(false);
    }
  };

  const getChecklistNameById = (checkId: string) => {
    for (const category of eliteVerificationCategories) {
      for (const checklist of category.checklists) {
        if (checklist.id === checkId) {
          return { name: checklist.name, category: category.category };
        }
      }
    }
    return null;
  };

  const renderCheckStatus = () => {
    if (!orderStatus) return null;

    return orderStatus.checks_status.map((check, index) => {
      const checklistData = getChecklistNameById(check.check_id);
      if (!checklistData) return null;

      return (
        <div key={index} className={`my-4 p-4 bg-white rounded-lg shadow-md border-l-8  relative ${check.status === 'completed' ? 'border-green-500' : 'border-orange-500'}`}>
            <div className="pl-4">
              <h3 className="text-lg font-semibold text-gray-800">{checklistData.category}</h3>
              <p className="text-sm text-gray-600 mt-2">Checklist: <span className="font-medium text-gray-900">{checklistData.name}</span></p>
              <div className={`mt-2 px-2 py-1 rounded-md inline-block text-sm font-semibold ${check.status === 'completed' ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'}`}>
                Status: {check.status === 'completed' ? 'Completed' : 'Pending'}
              </div>
            </div>
</div>

      );
    });
  };

  return (
    <div className="max-w-4xl mx-auto mt-10 p-8 bg-gray-50 shadow-lg rounded-lg">
      <h1 className="text-3xl font-bold text-gray-800 mb-6 text-center">Background Verification Report</h1>
      
      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label htmlFor="memberId" className="block text-gray-700 text-sm font-medium">
            Member ID
          </label>
          <input
            id="memberId"
            type="text"
            value={tmi}
            disabled
            className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-orange-500 focus:border-orange-500 bg-gray-100"
          />
        </div>
        <button
          type="submit"
          className={`w-full py-3 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 ${loading && 'opacity-50 cursor-not-allowed'}`}
          disabled={loading}
        >
          {loading ? 'Checking...' : 'Check Status'}
        </button>
      </form>

      {error && <div className="mt-4 text-red-600 text-center">{error}</div>}

      {orderStatus && (
        <div className="mt-6 space-y-6">
          <div className="bg-white p-4 shadow rounded-lg">
            <h2 className="text-xl font-semibold text-gray-800">Report Summary</h2>
            <p className="text-gray-600 mt-2"><span className="font-medium">Report Status:</span> {orderStatus.report_status}</p>
            <p className="text-gray-600 mt-1"><span className="font-medium">Order Status:</span> {orderStatus.order_status}</p>
          </div>

          <div className="space-y-4">
            {renderCheckStatus()}
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderStatus;
