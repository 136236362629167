import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
const apiUrl = import.meta.env.VITE_API_URL;

interface Event {
  id: string;
  eventId: string;
  heading: string;
  description: string;
  date: string;
  postDate: string;
  venue: string;
  image: string; // Base64 string for image
}

const OldEvents: React.FC = () => {
  const [oldEvents, setOldEvents] = useState<Event[]>([]);
  const [image, setImage] = useState<File | null>(null);

  // Fetch old events from the backend API
  useEffect(() => {
    const fetchOldEvents = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/events`);
        if (response.ok) {
          const data = await response.json();
          setOldEvents(data);
        } else {
          console.error("Failed to fetch events");
        }
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };
    fetchOldEvents();
  }, []);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setImage(e.target.files[0]);
    }
  };

  return (
    <div className="container mx-auto p-8 space-y-8">
      {/* Header Section */}
      <div className="flex justify-between items-center">
        <h1 className="text-4xl font-semibold">Old Events</h1>
        <div className="space-x-4">
          <Link to="/admin/events/newevent">
            <button className="px-6 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600">Back</button>
          </Link>
          <Link to="/events">
            <button className="px-6 py-2 bg-green-500 text-white rounded-md hover:bg-green-600">View Events</button>
          </Link>
        </div>
      </div>

      {/* Events Table */}
      <div className="overflow-x-auto shadow-lg rounded-lg bg-white">
        <table className="w-full table-auto text-left">
          <thead className="bg-gray-100">
            <tr>
              {["SNo", "Event ID", "Event Date", "Venue", "Title", "Details", "Posted Date", "Action"].map((header) => (
                <th key={header} className="py-3 px-6 text-lg font-medium text-gray-700">{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {oldEvents.length > 0 ? (
              oldEvents.map((event, index) => (
                <tr key={event.id} className="border-b hover:bg-gray-50">
                  <td className="py-4 px-6">{index + 1}</td>
                  <td className="py-4 px-6">{event.eventId}</td>
                  <td className="py-4 px-6">{event.date}</td>
                  <td className="py-4 px-6">{event.venue}</td>
                  <td className="py-4 px-6">{event.heading}</td>
                  <td className="py-4 px-6">{event.description}</td>
                  <td className="py-4 px-6">{event.postDate}</td>
                  <td className="py-4 px-6 flex gap-4">
                    <div className="flex flex-col items-center">
                      <label className="cursor-pointer flex items-center justify-center w-24 py-2 bg-gray-200 rounded-md hover:bg-gray-300">
                        <span>{image ? "Deleted" : "Delete"}</span>
                        <input
                          type="file"
                          accept="image/*"
                          className="hidden"
                          onChange={handleImageChange}
                        />
                      </label>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={8} className="py-4 text-center text-gray-500">No events available</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Image Preview */}
      {image && (
        <div className="mt-8 flex justify-center">
          <img
            src={URL.createObjectURL(image)}
            alt="Uploaded Preview"
            className="max-w-xs max-h-48 object-cover rounded-lg"
          />
        </div>
      )}

      
    </div>
  );
};

export default OldEvents;
