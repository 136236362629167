import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';

interface UserDetails {
  firstname?: string;
  photo?: string;
  name?: string;        // Admin's name
  employeeId?: string;   // Admin's employee ID
  // Add other user details here as needed
}

interface UserContextType {
  userType: 'admin' | 'executive' | 'user' | 'guest' | 'customer';
  setUserType: (userType: 'admin' | 'executive' | 'user' | 'guest'|'customer') => void;
  userDetails: UserDetails | null;
  setUserDetails: (details: UserDetails | null) => void;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [userType, setUserType] = useState<'admin' | 'executive' | 'user' | 'guest'|'customer'>('guest');
  const [userDetails, setUserDetails] = useState<UserDetails | null>(null);

  // Initialize user context from localStorage
  useEffect(() => {
    const storedUserDetails = localStorage.getItem('userDetails');
    if (storedUserDetails) {
      setUserDetails(JSON.parse(storedUserDetails));
    }
  }, []);

  // Update localStorage when userDetails change
  useEffect(() => {
    if (userDetails) {
      localStorage.setItem('userDetails', JSON.stringify(userDetails));
    } else {
      localStorage.removeItem('userDetails');
    }
  }, [userDetails]);

  return (
    <UserContext.Provider value={{ userType, setUserType, userDetails, setUserDetails }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};



