import { useState } from "react";
import { Link } from "react-router-dom";
const apiUrl = import.meta.env.VITE_API_URL;

interface UserData {
  id: string;
  firstname: string;
  lastname: string;
  membershipPlanName: string;
  membershipPlanStatus: string;
  membershipPlanStartDate: string;
  membershipPlanEndDate: string;
  viewContactDetails: number;
  expressInterest: number;
  profileHighlightedDays: number;
  emailAlert: number;
  whatsappAlert: number;
  featuredProfile: string;
}

const MyAccount: React.FC = () => {
  const [tmi, setTmi] = useState("");
  const [userData, setUserData] = useState<UserData | null>(null);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSearch = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/getUserByTMI/${tmi}`);
      const result = await response.json();

      if (response.ok && result) {
        setUserData(result); // Set fetched user data
        setErrorMessage(""); // Clear any previous error message
      } else {
        setUserData(null);
        setErrorMessage("User with this TMI does not exist.");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      setErrorMessage("Failed to fetch data. Please try again.");
    }
  };

  return (
    <>
      {/* Search Section */}
      <div className="p-4 flex justify-center items-center gap-4 border-b mb-4">
        <input
          type="text"
          placeholder="Enter TMI ID"
          value={tmi}
          onChange={(e) => setTmi(e.target.value)}
          className="px-3 py-2 border rounded-lg w-full tablet:w-1/2"
        />
        <button
          onClick={handleSearch}
          className="px-4 py-2 bg-blue-600 text-white rounded-lg"
        >
          Search
        </button>
      </div>

      {errorMessage && <p className="text-red-600 text-center">{errorMessage}</p>}

      {userData && (
        <div className="p-2 tablet:p-8 flex flex-col gap-y-8">
          <h1 className="home-heading font-semibold text-center">Account Summary</h1>
          
          {/* Payment and Plan Details */}
          <div className="grid grid-cols-1 laptop:grid-cols-3 border border-black">
            <SummaryCell title="Profile ID" value={userData.id || "Not Available"} />
            <SummaryCell title="First Name" value={userData.firstname || "Not Available"} />
            <SummaryCell title="Last Name" value={userData.lastname || "Not Available"} />
            <SummaryCell title="Plan Name" value={userData.membershipPlanName || "Not Available"} />
            <SummaryCell title="Remaining Days" value={userData.membershipPlanStatus || "Not Available"} />
            <SummaryCell title="Start Date" value={userData.membershipPlanStartDate || "Not Available"} />
            <SummaryCell title="End Date" value={userData.membershipPlanEndDate || "Not Available"} />
            <Link to="/membershipplans">
              <button className="w-full flex place-items-center place-content-center gap-x-4 border h-full border-black">
                <p className="text-xl btnMembership font-semibold my-2 py-3 px-6 rounded-lg">
                  UPGRADE ⬆
                </p>
              </button>
            </Link>
          </div>
          
          {/* Features Used */}
          <div className="grid grid-cols-2 laptop:grid-cols-3 border border-black">
            <FeatureCell title="View Contact Details" used={userData.viewContactDetails || 0} />
            <FeatureCell title="Express Interest" used={userData.expressInterest || 0} />
            <FeatureCell title="Profile Highlighted Days" used={userData.profileHighlightedDays || 0} />
            <FeatureCell title="Email Alert" used={userData.emailAlert || 0} />
            <FeatureCell title="WhatsApp Alert" used={userData.whatsappAlert || 0} />
            <FeatureCell title="Featured Profile" used={userData.featuredProfile === "Yes" ? 1 : 0} />
          </div>
        </div>
      )}
    </>
  );
};

export default MyAccount;

const SummaryCell: React.FC<{ title: string; value: string }> = ({ title, value }) => (
  <div className="flex place-items-center text-wrap px-4 py-2 gap-x-4 border border-black">
    <p className="text-lg font-semibold">{title}: </p>
    <p className="text-lg">{value}</p>
  </div>
);

const FeatureCell: React.FC<{ used: number; title: string }> = ({ used, title }) => (
  <div className="flex flex-col text-center place-items-center px-4 py-3 gap-y-1 border border-black">
    <p className="text-2xl font-semibold">{used}/20</p>
    <p className="text-lg">{title}</p>
  </div>
);
