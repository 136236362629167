import { useEffect } from "react";
import { useParams } from "react-router-dom";
import useScreenType from "../hooks/useScreenType";
const apiUrl = import.meta.env.VITE_API_URL;
//Badges
import socialCheckGrey from "/svgs/socialCheckGrey.svg";
import socialCheckGreen from "/svgs/socialCheckGreen.svg";
import familyCheckGrey from "/svgs/familyCheckGrey.svg";
import familyCheckGreen from "/svgs/familyCheckGreen.svg";
import employmentCheckGrey from "/svgs/employmentCheckGrey.svg";
import employmentCheckGreen from "/svgs/employmentCheckGreen.svg";
import educationCheckGrey from "/svgs/educationCheckGrey.svg";
import educationCheckGreen from "/svgs/educationCheckGreen.svg";
import criminalCheckGrey from "/svgs/criminalCheckGrey.svg";
import criminalCheckGreen from "/svgs/criminalCheckGreen.svg";
import creditCheckGrey from "/svgs/creditCheckGrey.svg";
import creditCheckGreen from "/svgs/creditCheckGreen.svg";
import rejectedImage from "../assets/rejectedimage.jpeg";

//For alert function
import { toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import axios from "axios";

import { useState } from "react";
import { useLocation } from "react-router-dom";

import {useForm} from "react-hook-form";
import {  z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";


interface Photo {
  data: string; // Base64 image string
  approved: boolean; // Approval status
}



//User Interface


interface User {
 
  firstname: string;
  lastname: string;
  photo: string | null;
  emailid: string;
  createdFor: string;
  TMI: string;
  isVerified:boolean;
  profileApproved:boolean,
  photoApproved:boolean,
  lastVerified?: Date | null; // Allow null explicitly
  
}

interface About{
  aboutMe: string,

}

interface MembershipDetails {
  membership: string;
}

interface ApiResponse {
  user: User;
  about:About;
  membershipDetails: MembershipDetails;
}


  const schema = z.object({
    about: z.object({
      aboutMe: z.string(),
    }),

      user: z.object({
        firstname: z.string(),
        lastname: z.string(),
        photo: z.string().nullable(),
        emailid: z.string(),
        createdFor: z.string(),
        TMI: z.string(),
        isVerified: z.boolean(),
        profileApproved: z.boolean(),
        lastVerified:z.date()
      }),

      membershipDetails: z.object({
        membership: z.string(),
      }),
    
});
  


const ApprovalProfile: React.FC = () => {

  const [isApproved, setIsApproved] = useState(false); // State to track approval
  const [isRejected, setIsRejected] = useState(false); // State to track rejection

  const [dbuserdetails, setdbuserdetails] = useState<ApiResponse | null>(null);
  const [photoArray, setPhotoArray] = useState<Photo[]>([]);
 
  const [isPhotoApproved, setIsPhotoApproved] = useState(false);
  const [isPhotoRejected, setIsPhotoRejected] = useState(false);

  const screenType = useScreenType();
  const location = useLocation();
  const [isEditing, setIsEditing] = useState(location.state?.isEditing || false);
  
  const { TMI } = useParams<{ TMI: string }>();
  const { register,reset ,getValues,setValue} =
  useForm<ApiResponse>({
    defaultValues: {
      
      user:{
        lastVerified : dbuserdetails?.user?.lastVerified || null,
        profileApproved:dbuserdetails?.user?.profileApproved || false,
        photo:dbuserdetails?.user?.photo || '',
        photoApproved:dbuserdetails?.user?.photoApproved || false
      },
      about :{
        aboutMe: dbuserdetails?.about?.aboutMe || '',

      },
      
    },
    resolver: zodResolver(schema),
  });


  const setFormValues = (details: ApiResponse) => {
    

    // About Information
    setValue('about.aboutMe', details.about.aboutMe || '');
    
  };

  useEffect(() => {
    if (isEditing && dbuserdetails) {
      console.log("dbuserdetails:", dbuserdetails); // Log to verify
      setFormValues(dbuserdetails);
    }
    console.log(getValues()); // Log the form values after setting them
  }, [isEditing, dbuserdetails, setValue]);
  
const fetchUserData = async () => {
    try {
      if (!TMI) return; // Exit early if TMI is not available
      console.log('TMI:', TMI);
      const response = await axios.get(`${apiUrl}/api/profile/${TMI}`);
      const apiResponse = response.data;
      console.log("API RESPONSE",apiResponse)

      if (apiResponse) {
        const {
          user,
          membershipDetails,
          about
        } = apiResponse;

        const mainPhotoUrl = user.photo ? `${apiUrl}${user.photo}` : ''; // Ensure it's not a literal string
        console.log("Main Photo URL:", mainPhotoUrl); // Debugging: Check if the URL is correct

        // Consolidate state updates
        setdbuserdetails({
          user: { ...user, photo: mainPhotoUrl },
          membershipDetails,
          about,
        });

        // Format the main photo URL correctly
      
        // Reset form state with fetched user data
        reset({
          user: {
            TMI: user.TMI || '',
            createdFor:user.createdFor || '',
            photo:mainPhotoUrl 
          },
          about: {
            aboutMe: about.aboutMe || '',
          },
          
        });
        console.log("Image src URL:", dbuserdetails?.user?.photo);


        // Fetch photos
        const photosResponse = await axios.get(`${apiUrl}/api/profile/${TMI}/photos`);
        const albumPhotos = photosResponse.data.albumPhotos.map((photo: any) => ({
          data: `${apiUrl}${photo.data}`,  // Full URL of the photo, assuming 'data' is a relative path
          approved: photo.approved ?? false // Default to false if 'approved' field is missing
        }));
      
        setPhotoArray(albumPhotos); // Set the state with the new structure
      } else {
        console.warn('No user data available.');
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const approveCheck = async () => {
    try {
      // Make a GET request to check the profile approval status
      const response = await axios.get(`${apiUrl}/api/profile/check-approval/${TMI}`);
      
      // Destructure the approval status from the response
      const { isProfileApproved, isPhotoApproved } = response.data;  // Adjust the API response structure
  
      // Update state based on the approval status
      setIsApproved(isProfileApproved);
      setIsPhotoApproved(isPhotoApproved);  // Update photo approval state
      setIsRejected(!isProfileApproved);    // Mark as rejected if profile is not approved
      setIsPhotoRejected(!isPhotoApproved); // Mark as photo rejected if photo is not approved
  
    } catch (error) {
      console.error('Error checking profile approval status:', error);
      toast.error('Failed to check the profile approval status');
    }
  };
  

  useEffect(() => {
    fetchUserData();
    approveCheck();
  }, [TMI]);

    if (!dbuserdetails) {
    return (
      <div className="h-[80vh] flex place-content-center place-items-center">
        <h1 className="text-6xl">Loading.......</h1>
      </div>
    );
  }

  const aboutMeIntro=[{
        title: "aboutMe",
        value: dbuserdetails.about.aboutMe,
        registerValue: "aboutMeIntro",
  }]


  const handleEditClick = () => {
    setIsEditing(true);

  };
  

  // Trigger onSubmit manually
  const handleSaveClick = async () => {
    console.log("Save button clicked");
    const formData = getValues(); // Use a method from your form library to get the values
    try {
          console.log("Submitting with data:", formData);
          await axios.post(`${apiUrl}/api/profile/aboutme/${TMI}`, formData);
          toast.success('Profile updated successfully');
          setIsEditing(false);
          fetchUserData();
        } catch (error) {
          console.error('Error updating profile:', error);
        }
    // You can now handle your form submission logic here, e.g., send it to an API
  };

  // Handle Approve button click
  const handleApproveClick = async () => {
      try {
        // Make a PUT request to approve the profile
        await axios.post(`${apiUrl}/api/profile/approve/${TMI}`);
        toast.success('Profile approved successfully');
        setIsApproved(true); // Set approval state to true
        setIsRejected(false); 
         // Optionally refresh the data after rejection
      } catch (error) {
        console.error('Error approving profile:', error);
        toast.error('Failed to approve the profile');
      }
    };
    
  // Handle Reject button click
  const handleRejectClick = async () => {
        try {
          await axios.post(`${apiUrl}/api/profile/reject/${TMI}`);
          toast.success('Profile rejected successfully');
          setIsRejected(true); // Optionally reset approval state
          setIsApproved(false);
          fetchUserData(); // Optionally refresh the data after rejection
        } catch (error) {
          console.error('Error rejecting profile:', error);
          toast.error('Failed to reject the profile');
        }
      };
  
  // Assuming you have dbuserdetails state available
  const handleImageApprove = async () => {
    try {
      setIsPhotoApproved(true); // Update UI to reflect approval status
      setIsPhotoRejected(false)
  
      // Send API request to approve the profile photo
      await axios.post(`${apiUrl}/api/photos/approveProfilePhoto`, {
        TMI, // TMI identifies the user whose photo is being approved
        photoApproved: true // Approve the photo
      });
  
      // Show success message
      toast.success('Profile Photo approved successfully');
      
    } catch (error) {
      console.error('Error approving profile photo:', error);
      toast.error('Error approving profile photo');
    }
  };
  

  const handleImageReject = async () => {
    try {
      setIsPhotoRejected(true);
      setIsPhotoApproved(false);
      // Fetch the static image and create a Blob (or File) from it
      const response = await fetch(rejectedImage); // Fetch the static image
      const blob = await response.blob(); // Convert it to a Blob object
  
      // Create a new File object
      const uploadImage = new File([blob], "rejected-profile.png", { type: blob.type });
  
      const formData = new FormData();
      formData.append('file', uploadImage); // Key name must be 'file'
  
      // Make the API request
      await axios.post(`${apiUrl}/api/photos/uploadProfilePhoto/${TMI}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
  
      toast.success('Profile Photo Rejected successfully');
      fetchUserData();
    } catch (error) {
      console.error("Error uploading profile photo:", error);
    }
  };


  const handleAlbumReject = async (index: number) => {
    try {
      // Construct the static URL for the rejected image
      const rejectedImageUrl = `/uploads/rejected-images/rejected.png`; // Adjust the path if needed
  
      // Prepare the data to send to the backend
      const requestData = {
        photoIndex: index,  // Send the index of the photo to be rejected
        rejectedImageUrl,   // Send the URL of the rejected image
      };
  
      // Send the static image URL and the index to the backend
      const apiResponse = await axios.post(
        `${apiUrl}/api/replaceAlbumPhoto/${TMI}`, 
        requestData
      );
  
      if (apiResponse.status === 200) {
        toast.success('Photo rejected successfully');
        fetchUserData();  // Refresh user data to reflect the changes
        // Optionally refresh the album data to show updated photo
      }
    } catch (error) {
      console.error('Error replacing photo:', error);
      toast.error('Failed to replace photo');
    }
  };
  

  const handleAlbumApprove = async (index: number) => {
    try {
      // Assuming we send approval info to the backend
      const response = await axios.post(`${apiUrl}/api/approveAlbumPhoto/${TMI}`, { photoIndex: index });

      if (response.status === 200) {
        
        toast.success('Photo approved successfully');
        fetchUserData();
        // Optionally update state to reflect approval
      }
    } catch (error) {
      console.error('Error approving photo:', error);
      toast.error('Failed to approve photo');
    }
  };


  
      




  return (
    <>
      <div className="flex w-full">
        <form
          className="w-full border-2 p-2 mobileLg:p-8"
        >
          <div className="flex flex-wrap gap-4 desktop:gap-0 justify-between">
            <div className="">
              <h1 className="text-6xl font-bold">{dbuserdetails.user.firstname}</h1>
              <p className="ml-4">{dbuserdetails.user.TMI}</p>
              <p className="ml-4">
                Profile created for/By {dbuserdetails.user.createdFor}
              </p>
              <div className="flex place-items-center ml-4 gap-x-2">
                <p>Plan Name: </p>
                <p
                  className={`text-xl mobileLg:text-2xl laptop:text-3xl font-medium ${
                    dbuserdetails.membershipDetails.membership === "Gold" &&
                    "text-[#FFCE05]"
                  } ${
                    dbuserdetails.membershipDetails.membership === "Silver" &&
                    "text-gray-[#AFA3A7]"
                  } ${
                    dbuserdetails.membershipDetails.membership === "Bronze" &&
                    "text-[#E85306]"
                  }
                  ${
                    dbuserdetails.membershipDetails.membership === "Gold Plus" &&
                    "text-accentSec"
                  }
                  ${
                    dbuserdetails.membershipDetails.membership === "Diamond" &&
                    "text-[#7b61ff]/80"
                  }
                  ${
                    dbuserdetails.membershipDetails.membership === "Diamond Plus" &&
                    "text-cyan-600"
                  }`}
                >
                  {dbuserdetails.membershipDetails.membership}
                </p>
              </div>
            </div>
            
            {/* Badges */}
            <div className="flex order-2 tablet:order-1 flex-col gap-y-4 items-center place-content-center">
              <h2 className="text-2xl font-medium text-center">
                Background Verification Badges
              </h2>
              {/* Badges */}
              <div className="flex gap-x-4 items-center text-2xl">
                <Badge
                  title="Family Check"
                  isVerified={dbuserdetails.user.isVerified}
                  imageGreen={familyCheckGreen}
                  imageGrey={familyCheckGrey}
                />
                <Badge
                  title="Education Check"
                  isVerified={dbuserdetails.user.isVerified}
                  imageGreen={educationCheckGreen}
                  imageGrey={educationCheckGrey}
                />
                <Badge
                  title="Employment Check"
                  isVerified={dbuserdetails.user.isVerified}
                  imageGreen={employmentCheckGreen}
                  imageGrey={employmentCheckGrey}
                />
                <Badge
                  title="Socials Check"
                  isVerified={dbuserdetails.user.isVerified}
                  imageGreen={socialCheckGreen}
                  imageGrey={socialCheckGrey}
                />
                <Badge
                  title="Credit Check"
                  isVerified={dbuserdetails.user.isVerified}
                  imageGreen={creditCheckGreen}
                  imageGrey={creditCheckGrey}
                />
                <Badge
                  title="Criminal Check"
                  isVerified={dbuserdetails.user.isVerified}
                  imageGreen={criminalCheckGreen}
                  imageGrey={criminalCheckGrey}
                />
              </div>
              <p>
                Last verified on{" "}
                <strong>
                  {dbuserdetails.user.lastVerified !== undefined && dbuserdetails.user.lastVerified !== null 
                    ? new Date(dbuserdetails.user.lastVerified).toLocaleDateString() 
                    : 'Not verified yet'}
                </strong>

              </p>
            </div>
            {/* Edit Profile */}
            
              <div className="items-center place-content-center">
                {!isEditing && (
                  <button
                    className="btn relative w-[150px] px-8 group/btn border-2 rounded-md h-10 font-medium shadow-[0px_1px_0px_0px_#ffffff40_inset,0px_-1px_0px_0px_#ffffff40_inset]]"
                    type="button"
                    id="btn-editProfile"
                    onClick={handleEditClick}
                  >
                    Edit Profile
                    <BottomGradient />
                  </button>
                )}
                {isEditing && (
                   <button
                   className="btn relative w-[175px] px-8 group/btn border-2 rounded-md h-10 font-medium shadow-[0px_1px_0px_0px_#ffffff40_inset,0px_-1px_0px_0px_#ffffff40_inset]]"
                   type="button" // Keep as "button" to prevent default form submission
                   id="btn-submitProfile"
                   onClick={handleSaveClick}
                 >
                   Save Profile
                   <BottomGradient />
                 </button>
                )}

                  <button
                          style={{
                            backgroundColor: isApproved ? 'green' : 'gray',
                          }}
                          className={`btn relative w-[150px] px-8 group/btn border-2 rounded-md h-10 font-medium shadow-[0px_1px_0px_0px_#ffffff40_inset,0px_-1px_0px_0px_#ffffff40_inset] transition duration-200 ease-in-out`}
                          type="button"
                          id="btn-submitProfile"
                          onClick={handleApproveClick}
                        >
                          {isApproved ? 'Approved' : 'Approve'}
                          <BottomGradient />
                    </button>



                        <button
                            style={{
                              backgroundColor: isRejected ? 'red' : 'gray',
                            }}
                            className={`btn relative w-[150px] px-8 group/btn border-2 rounded-md h-10 font-medium shadow-[0px_1px_0px_0px_#ffffff40_inset,0px_-1px_0px_0px_#ffffff40_inset] transition duration-200 ease-in-out`}
                            type="button"
                            id="btn-rejectProfile"
                            onClick={handleRejectClick}
                          >
                            {isRejected ? 'Rejected' : 'Reject'}
                            <BottomGradient />
                    </button>            
              </div>
             
            
          </div>
          <hr className="border-red-600 my-12" />
              <div className="flex flex-col gap-y-8">
                {/* About Me Intro */}
                <div className="border-2 p-8 shadow-md">
                <h2 className="text-3xl font-medium">ABOUT ME</h2>
                  {aboutMeIntro.map((field)=>(
                    <>
                        {field.title==="aboutMe" && (
                          <>
                          {!isEditing && (
                            <p className="my-4">{dbuserdetails.about.aboutMe}</p>
                          )}
        
                        {isEditing && (
                          <textarea
                            className="my-4 w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                            {...register("about.aboutMe")}
                            placeholder="Tell us about yourself..."
                            defaultValue={dbuserdetails.about.aboutMe} // Set initial value for editing
                          />
                          )}
                          </>
                        )}
                    </>

                  )
                )}
                  
                 
                 
                 
                  </div>
            </div>
        </form>
        {screenType === "desktop" && (
            
            <div className="laptop:w-[24rem] desktop:w-[28rem] relative flex flex-col gap-y-6 h-full">
  <br />

  {/* Display the images in a grid or vertical stack */}
  <div className="grid grid-cols-1 gap-y-4">
  {/* Profile Photo Section */}
  <div className="flex flex-col items-center mb-4">
      <h2 className="text-xl font-bold mb-2">Profile Photo</h2>
      <img
        src={dbuserdetails.user.photo||''}
        alt="Profile Photo"
        className="rounded-lg h-[12rem] w-[12rem] object-cover mb-2"
        onError={(e) => {
          e.currentTarget.src = 'fallback-image-url.jpg'; // Path to your fallback image
        }}
      />

      <div className="flex justify-around w-full mb-2">
      <button
              style={{
                backgroundColor: isPhotoApproved ? 'green' : 'gray',
              }}
              className={`btn relative w-[200px] px-8 group/btn border-2 rounded-md h-10 font-medium shadow-[0px_1px_0px_0px_#ffffff40_inset,0px_-1px_0px_0px_#ffffff40_inset] transition duration-200 ease-in-out`}
              onClick={handleImageApprove}
            >
              {isPhotoApproved ? 'Photo Approved' : 'Approve'}
              <BottomGradient />
    </button>
    <button
              style={{
                backgroundColor: isPhotoRejected ? 'red' : 'gray', // Optional inline style for the background
              }}
              className={`py-1 px-3 rounded-md ${isPhotoRejected ? 'bg-red-600' : 'bg-red-500'} text-white hover:bg-red-600`}
              onClick={handleImageReject}
            >
              {isPhotoRejected ? 'Reject' : 'Reject'}  {/* Conditional text */}
    </button>
      </div>
    </div>

  {/* Additional Profile Photos Section */}
  <div className="grid grid-cols-1 gap-y-4">
  <h2 className="text-xl font-bold mb-2">Album Photos</h2>
  {photoArray?.map((photo: Photo, index: number) => (
    <div key={index} className="flex flex-col items-center mb-4">
      <img
        src={photo.data} // Assuming `photo` is the URL or binary data converted to a displayable format
        alt={`Image ${index + 2}`} // Adjusting index for better alt text
        className="rounded-lg h-[12rem] w-[12rem] object-cover mb-2" // Adjust the height and width
      />
      <div className="flex justify-around w-full">
        <button
          className={`bg-green-500 text-white py-1 px-3 rounded-md hover:bg-green-600 ${
            photo.approved ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          onClick={() => handleAlbumApprove(index)} // Adjusted index for correct handling
          disabled={photo.approved} // Disable the button if photo is already approved
        >
          {photo.approved ? 'Approved' : 'Approve'}
        </button>
        <button
          className={`bg-red-500 text-white py-1 px-3 rounded-md hover:bg-red-600 `}
          onClick={() => handleAlbumReject(index)} // Adjusted index for correct handling
           // Disable the button if photo is already rejected
        >
          {photo.approved ? 'Reject' : 'Reject'}
        </button>
      </div>
    </div>
  ))}
</div>


</div>




 
</div>

          
        )}
      </div>
    </>
  );
};

const BottomGradient = () => {
  return (
    <>
      <span className="group-hover/btn:opacity-100 block transition duration-500 opacity-0 absolute h-px w-full -bottom-px inset-x-0 bg-gradient-to-r from-transparent via-cyan-500 to-transparent" />
      <span className="group-hover/btn:opacity-100 blur-sm block transition duration-500 opacity-0 absolute h-px w-1/2 mx-auto -bottom-px inset-x-10 bg-gradient-to-r from-transparent via-indigo-500 to-transparent" />
    </>
  );
};

const Badge: React.FC<{
  title: string;
  imageGrey: string;
  imageGreen: string;
  isVerified: boolean;
}> = ({ title, imageGrey, imageGreen, isVerified }) => {
  return (
    <div className="relative group">
      <p className="absolute bottom-16 text-sm w-max bg-slate-300 font-medium py-1 px-2 rounded z-10 hidden group-hover:block">
        {title}
      </p>
      {isVerified ? (
        <img
          src={imageGreen}
          alt="Crime Check Badge"
          className="h-8 mobileLg:h-12"
        />
      ) : (
        <img
          src={imageGrey}
          alt="Crime Check Badge"
          className="h-8 mobileLg:h-12"
        />
      )}
    </div>
  );
};

export default ApprovalProfile;