import { useState, useEffect } from "react";
const apiUrl = import.meta.env.VITE_API_URL;

interface Plan {
  _id: string;
  name: string;
}

interface ApplicablePlan {
  months: number;
  plans: Plan[];
  _id: string;
}

interface Coupon {
  _id: string;
  code: string;
  validFrom: string;
  validUntil: string;
  discount: number;
  applicablePlans: ApplicablePlan[];
}

const AvailableCoupons: React.FC = () => {
  const [coupons, setCoupons] = useState<Coupon[]>([]);

  useEffect(() => {
    fetchCoupons();
  }, []);

  const fetchCoupons = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/coupons`);
      if (response.ok) {
        const data = await response.json();
        setCoupons(data);
      } else {
        console.error("Failed to fetch coupons");
      }
    } catch (error) {
      console.error("Error fetching coupons:", error);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center p-8 border-2 rounded-lg shadow-lg bg-white max-w-5xl mx-auto">
      <h3 className="text-2xl font-semibold text-gray-800 mb-4">Available Coupons</h3>
      <div className="overflow-auto w-full">
        <table className="min-w-full bg-white border rounded-lg shadow-sm">
          <thead>
            <tr className="bg-gray-200 text-gray-600 text-left text-sm uppercase tracking-wider">
              <th className="px-6 py-3">Code</th>
              <th className="px-6 py-3">Discount</th>
              <th className="px-6 py-3">Valid From</th>
              <th className="px-6 py-3">Valid Until</th>
              <th className="px-6 py-3">Applicable Plans</th>
            </tr>
          </thead>
          <tbody>
            {coupons.map((coupon) => (
              <tr key={coupon._id} className="border-t text-gray-700">
                <td className="px-6 py-4 font-semibold text-green-600">{coupon.code}</td>
                <td className="px-6 py-4">{coupon.discount} INR</td>
                <td className="px-6 py-4">
                  {new Date(coupon.validFrom).toLocaleDateString()}
                </td>
                <td className="px-6 py-4">
                  {new Date(coupon.validUntil).toLocaleDateString()}
                </td>
                <td className="px-6 py-4">
                  {coupon.applicablePlans.map((appPlan) => (
                    <div key={appPlan._id} className="mb-1">
                      <span className="font-medium">{appPlan.months} Month(s):</span>
                      {appPlan.plans.map((plan) => (
                        <span key={plan._id} className="ml-2 text-blue-500">{plan.name}</span>
                      ))}
                    </div>
                  ))}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AvailableCoupons;
