import ad1 from "/images/ad1.jpg";

const News: React.FC = () => {
  const newsArticles = [
    {
      id: 1,
      image: ad1,
      title:
        "Triune Matrimony Launches New Features to Enhance User Experience",
      date: "August 20, 2024",
      summary:
        "Triune Matrimony has introduced new features aimed at improving the user experience, including advanced search filters and a revamped profile creation process.",
      content:
        "In an effort to make the platform more user-friendly, Triune Matrimony has rolled out several new features. The updated search filters allow users to narrow down their search for potential matches more effectively, while the new profile creation process ensures that members can present themselves in the best possible light. These changes are part of Triune Tech Mentors' ongoing commitment to providing a high-quality experience for all users of Triune Matrimony.",
    },
    {
      id: 2,
      image: ad1,
      title:
        "Success Stories: How Triune Matrimony is Bringing Christian Couples Together",
      date: "August 10, 2024",
      summary:
        "Since its launch, Triune Matrimony has helped countless Christian singles find their life partners. We share some of the success stories that have emerged from our platform.",
      content:
        "Triune Matrimony has become a beacon for Christian singles looking for a faith-centered relationship. Our platform has facilitated numerous marriages, with couples praising the emphasis on shared values and beliefs. In this article, we highlight several success stories, showcasing how Triune Matrimony has played a pivotal role in bringing these couples together.",
    },
    {
      id: 3,
      image: ad1,
      title: "Upcoming Event: Virtual Matrimony Meet & Greet",
      date: "July 30, 2024",
      summary:
        "Join us for our first-ever Virtual Matrimony Meet & Greet, where members can interact and connect with potential matches in a safe, virtual environment.",
      content:
        "Triune Matrimony is excited to announce our first Virtual Matrimony Meet & Greet, scheduled for September 15, 2024. This event will provide an opportunity for members to meet and interact with others who share their faith, in a safe and structured online environment. We believe this event will be a great way for members to connect and potentially find their life partners. More details on how to register will be shared soon.",
    },
  ];

  return (
    <div className="min-h-screen bg-gray-100 py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto bg-white shadow-md rounded-lg overflow-hidden">
        <div className="bg-accent text-white text-center py-6">
          <h1 className="text-4xl font-bold">Latest News</h1>
        </div>
        <div className="p-8">
          {newsArticles.map((article) => (
            <div key={article.id} className="mb-8">
              <div className="mb-4">
                <img src={article.image} alt="preview new image" />
              </div>
              <h2 className="text-2xl font-semibold mb-2 text-accent">
                {article.title}
              </h2>
              <p className="text-gray-600 mb-2">{article.date}</p>
              <p className="text-gray-700 mb-4">{article.summary}</p>
              <details className="text-accent cursor-pointer">
                <summary>Read More</summary>
                <p className="text-gray-700 mt-2">{article.content}</p>
              </details>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default News;
