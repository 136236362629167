import { Link, Outlet } from "react-router-dom";



const links = {
  membershipPrice: ["Membership Plans", "/admin/membership/plans"],
  planFeatures: ["Plan Features", "/admin/membership/planfeatures"],
  generateDiscount: ["Generate Discount", "/admin/membership/discount"],
  CurrentPlansView:["Current Plans","/admin/membership/currentmembershipplans"],
  AvailableCoupons:["Available Coupons","/admin/membership/availablecoupons"],
  CouponsSent:["Coupons Sent","/admin/membership/couponssent"],
  CouponsUsed:["Coupons Used","/admin/membership/couponsused"],
  CouponsAnalysis:["Coupons Analysis","/admin/membership/couponanalysis"] 
};

const MembershipDiscounts: React.FC = () => {
  return (
    <>
      <div className="flex">
        <div className="w-[20%] flex flex-col px-6 py-8 gap-y-4 min-h-[32rem] border-r">
          {Object.values(links).map((value) => (
            <Link
              to={value[1]}
              key={value[1]}
              className="bg-accent/50 border border-accent py-2 px-4 text-lg text-black font-medium rounded-xl"
            >
              {value[0]}
            </Link>
          ))}
        </div>
        <div className="w-[80%]">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default MembershipDiscounts;
