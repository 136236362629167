import { useState } from "react";
import Cat from "/images/cat.jpg";
import Test from "/images/test.jpeg";
import Test2 from "/images/test2.png";

interface AdSpace {
  adSpaceId: string;
  section: number;
  adLocations:
    | {
        adId: string;
        location: number;
        title: string;
        type: string;
      }[]
    | null;
}

const adSpaces: AdSpace[] = [
  {
    adSpaceId: "adspace1",
    section: 1,
    adLocations: [
      {
        adId: "ad1.1",
        location: 1,
        title: "Ad Space 1.1 with text",
        type: "image",
      },
    ],
  },
  {
    adSpaceId: "adspace2",
    section: 2,
    adLocations: [
      {
        adId: "ad2.1",
        location: 1,
        title: "Ad Space 2.1 with image Ratio -> (h = 512px, w = 640px)",
        type: "image",
      },
      {
        adId: "ad2.2",
        location: 2,
        title: "Ad Space 2.2 with image Ratio -> (h = 244px, w = 640px)",
        type: "image",
      },
      {
        adId: "ad2.3",
        location: 3,
        title: "Ad Space 2.3 with image Ratio -> (h = 244px, w = 640px)",
        type: "image",
      },
    ],
  },
  {
    adSpaceId: "adspace3",
    section: 3,
    adLocations: [
      {
        adId: "ad3.1",
        location: 1,
        title: "Ad Space 3.1 with image Ratio -> (h = 288px, w = 208px)",
        type: "image",
      },
      {
        adId: "ad3.2",
        location: 2,
        title: "Ad Space 3.2 with image Ratio -> (h = 288px, w = 208px)",
        type: "image",
      },
    ],
  },
  {
    adSpaceId: "adspace4",
    section: 4,
    adLocations: [
      {
        adId: "ad4.1",
        location: 1,
        title: "Ad Space 4.1 with image Ratio -> (h = 640px, w = 416px)",
        type: "image",
      },
      {
        adId: "ad4.2",
        location: 2,
        title: "Ad Space 4.2 with image Ratio -> (h = 640px, w = 416px)",
        type: "image",
      },
      {
        adId: "ad4.3",
        location: 3,
        title: "Ad Space 4.3 with image Ratio -> (h = 640px, w = 416px)",
        type: "image",
      },
    ],
  },
];

// const verifications = {
//   "Criminal Verification": true,
// };

const OurAds: React.FC = () => {
  const [image, setImage] = useState(null);

  const [selectedAd, setSelectedAd] = useState<{
    adId: string;
    location: number;
    title: string;
    type: string;
  } | null>(null);
  const [selectedSection, setSelectedSection] = useState<number | null>(null);

  const handleDropdown = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedId = e.target.value;
    // First we flatten all the ads into a single array
    const ad =
      adSpaces
        .flatMap((adSpace) => adSpace.adLocations)
        .find((ad) => ad?.adId === selectedId) || null;

    // console.log("Ad: ", ad);
    if (ad) {
      const section = parseInt(ad.adId.split(".")[0].replace("ad", ""));
      setSelectedAd(ad);
      setSelectedSection(section);
      //console.log(section);
    } else {
      setSelectedSection(0);
    }
  };

  const handleImageChange = (e: any) => setImage(e.target.files[0]);

  const handleImageSubmit = () => {
    const data = { adId: selectedAd?.adId, image };
    console.log(data);
  };

  return (
    <>
      <div className="p-8 flex flex-col gap-y-8">
        <h1 className="text-4xl text-center font-semibold">Advertisements</h1>

        {/* Dropdown ad spaces */}
        <div className="w-full">
          <p className="text-lg">Choose an ad Location</p>
          <select id="ads" className="w-full p-2" onChange={handleDropdown}>
            <option value="">Choose an ad space</option>
            {adSpaces.map((adSpace) =>
              adSpace?.adLocations?.map((ad) => (
                <option id={ad.adId} value={ad.adId}>
                  {ad.title}
                </option>
              ))
            )}
          </select>
        </div>

        {/* Input fields */}
        <div className="w-full">
          {selectedSection === 0 && <div>please select an ad location</div>}
          {selectedSection === 1 && (
            <div>
              <p className="text-lg">Enter Ad Text</p>
              <input
                type="text"
                placeholder="Enter the ad text here"
                className="p-2 border-2 border-black w-full"
              />
              {/* Preview */}
              <div className="scrolling-container mt-12 relative h-20 flex place-content-center bg-gradient-to-r from-accent to-accent/50 py-2 border-t-[1px] border-b-[1px] border-black">
                <span className="scrolling-content flex place-content-center font-medium text-3xl text-black">
                  Find your God-ordained partner with Triune Matrimony - Enjoy{" "}
                  <strong className="text-accentSec font-bold">
                    50% OFF on premium membership
                  </strong>{" "}
                  - To discover your perfect match in faith,{" "}
                  <strong className="text-accentSec font-bold">
                    CLICK HERE!
                  </strong>
                </span>
              </div>
            </div>
          )}
          {selectedSection === 2 && (
            <div>
              <p className="text-lg">Choose File</p>
              <input
                type="file"
                onChange={handleImageChange}
                className="p-2 border border-black w-full"
              />
              <button
                className="border border-black w-full mt-6 p-2"
                onClick={() => setImage(null)}
              >
                CLEAR
              </button>
              <button
                className="border border-black w-full mt-6 p-2"
                onClick={handleImageSubmit}
              >
                SUBMIT
              </button>
              {/* Preview */}
              <div className="flex mt-10 gap-x-6">
                <div
                  className={`${
                    selectedAd?.adId === "ad2.1"
                      ? "border-4 border-red-500"
                      : "border border-black"
                  } w-full `}
                >
                  {image && selectedAd?.adId === "ad2.1" ? (
                    <img
                      src={URL.createObjectURL(image)}
                      className="w-full h-full object-fit"
                      alt="ad2.1"
                    />
                  ) : (
                    <img
                      src="/images/ad1.jpg"
                      className="w-full h-full object-fit"
                      alt="ad2.1"
                    />
                  )}
                </div>
                <div className="flex flex-col gap-y-6 w-full">
                  <div
                    className={`${
                      selectedAd?.adId === "ad2.2"
                        ? "border-4 border-red-500"
                        : "border border-black"
                    } h-[50%]`}
                  >
                    {image && selectedAd?.adId === "ad2.2" ? (
                      <img
                        src={URL.createObjectURL(image)}
                        className="w-full h-full object-fit"
                        alt="ad2.2"
                      />
                    ) : (
                      <img
                        src="/images/ad2.jpg"
                        className="w-full h-full object-fit"
                        alt="ad2.2"
                      />
                    )}
                  </div>
                  <div
                    className={`${
                      selectedAd?.adId === "ad2.3"
                        ? "border-4 border-red-500"
                        : "border border-black"
                    } h-[50%]`}
                  >
                    {image && selectedAd?.adId === "ad2.3" ? (
                      <img
                        src={URL.createObjectURL(image)}
                        className="w-full h-full object-fit"
                        alt="ad2.3"
                      />
                    ) : (
                      <img
                        src="/images/ad3.jpg"
                        className="w-full h-full object-fit"
                        alt="ad2.3"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          {selectedSection === 4 && (
            <div className="flex justify-between">
              {/* File Select */}
              <div>
                <p className="text-lg">Choose File</p>
                <input
                  type="file"
                  onChange={handleImageChange}
                  className="p-2 border border-black w-full"
                />
                <button
                  className="border border-black w-full mt-6 p-2"
                  onClick={() => setImage(null)}
                >
                  CLEAR
                </button>
                <button
                  className="border border-black w-full mt-6 p-2"
                  onClick={handleImageSubmit}
                >
                  SUBMIT
                </button>
              </div>
              {/* Preview */}
              <div className="w-[28rem] border-l border-black flex flex-col gap-y-20 h-full">
                <div className="flex flex-col gap-y-8 px-4">
                  {/* 4.1 */}
                  <div
                    className={`${
                      selectedAd?.adId === "ad4.1"
                        ? "border-4 border-red-500"
                        : ""
                    }  flex-grow h-[40rem] w-[26rem]`}
                  >
                    {image && selectedAd?.adId === "ad4.1" ? (
                      <img
                        src={URL.createObjectURL(image)}
                        className="w-full h-full object-fit"
                        alt="ad4.1"
                      />
                    ) : (
                      <img
                        src={Cat}
                        className="w-full h-full object-fit"
                        alt="ad4.1"
                      />
                    )}
                  </div>
                  {/* 4.2 */}
                  <div
                    className={`${
                      selectedAd?.adId === "ad4.2"
                        ? "border-4 border-red-500"
                        : ""
                    } flex-grow h-[40rem] w-[26rem]`}
                  >
                    {image && selectedAd?.adId === "ad4.2" ? (
                      <img
                        src={URL.createObjectURL(image)}
                        className="w-full h-full object-fit"
                        alt="ad4.2"
                      />
                    ) : (
                      <img
                        src={Test}
                        className="w-full h-full object-fit"
                        alt="ad4.2"
                      />
                    )}
                  </div>
                  <div
                    className={`${
                      selectedAd?.adId === "ad4.3"
                        ? "border-4 border-red-500"
                        : ""
                    } flex-grow h-[40rem] w-[26rem]`}
                  >
                    {image && selectedAd?.adId === "ad4.3" ? (
                      <img
                        src={URL.createObjectURL(image)}
                        className="w-full h-full object-fit"
                        alt="ad4.3"
                      />
                    ) : (
                      <img
                        src={Test2}
                        className="w-full h-full object-fit"
                        alt="ad4.3"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          {selectedSection === 3 && (
            <div className="flex gap-x-8">
              {/* Preview */}
              <div className="w-[14rem] border-x-2">
                <div
                  className={`${
                    selectedAd?.adId === "ad3.1"
                      ? "border-4 border-red-500"
                      : "border"
                  } w-[13rem] h-[18rem] mt-6 mx-auto`}
                >
                  {image && selectedAd?.adId === "ad3.1" && (
                    <img
                      src={URL.createObjectURL(image)}
                      className="w-full h-full object-fit"
                      alt="ad3.1"
                    />
                  )}
                </div>
                <div
                  className={`${
                    selectedAd?.adId === "ad3.2"
                      ? "border-4 border-red-500"
                      : "border"
                  } h-[18rem] w-[13rem] mt-6 mx-auto`}
                >
                  {image && selectedAd?.adId === "ad3.2" && (
                    <img
                      src={URL.createObjectURL(image)}
                      className="w-full h-full object-fit"
                      alt="ad3.2"
                    />
                  )}
                </div>
              </div>
              {/* File selector */}
              <div>
                <p className="text-lg">Choose File</p>
                <input
                  type="file"
                  onChange={handleImageChange}
                  className="p-2 border border-black w-full"
                />
                <button
                  className="border border-black w-full mt-6 p-2"
                  onClick={() => setImage(null)}
                >
                  CLEAR
                </button>
                <button
                  className="border border-black w-full mt-6 p-2"
                  onClick={handleImageSubmit}
                >
                  SUBMIT
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default OurAds;
