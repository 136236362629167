import { useState } from "react";
const apiUrl = import.meta.env.VITE_API_URL;


const DiscountGenerator: React.FC = () => {
  const [discountCode, setDiscountCode] = useState<string | null>(null);
  const [flatDiscount, setFlatDiscount] = useState<string>("0");
  const [discountTag, setDiscountTag] = useState<string>("DISCOUNT");
  const [selectedMonth, setSelectedMonth] = useState<"1" | "3" | "6" | "9" | "12">("1");
  const [selectedPlan, setSelectedPlan] = useState<string>("");
  const [validFrom, setValidFrom] = useState<string>("");
  const [validTill, setValidTill] = useState<string>("");
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [successMessage, setSuccessMessage] = useState('');
  


  const plansForMonth: { [key: string]: string[] } = {
    "1": ["Bronze", "Silver"],
    "3": ["Bronze", "Silver", "Gold", "GoldPlus", "Diamond", "DiamondPlus"],
    "6": ["Bronze", "Silver", "Gold", "GoldPlus", "Diamond", "DiamondPlus"],
    "9": ["Bronze", "Silver", "Gold", "GoldPlus", "Diamond", "DiamondPlus"],
    "12": ["Bronze", "Silver", "Gold", "GoldPlus", "Diamond", "DiamondPlus"],
  };

  const validateFields = () => {
    const newErrors: { [key: string]: string } = {};

    if (!selectedPlan) newErrors.selectedPlan = "Please select a plan.";
    if (isNaN(Number(flatDiscount)) || Number(flatDiscount) <= 0) newErrors.flatDiscount = "Enter a valid discount amount.";
    if (!discountTag) newErrors.discountTag = "Discount tag is required.";
    if (!validFrom) newErrors.validFrom = "Valid from date is required.";
    if (!validTill) newErrors.validTill = "Valid till date is required.";
    if (validFrom && validTill && new Date(validFrom) >= new Date(validTill)) {
      newErrors.validTill = "Valid till date must be after the valid from date.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const generateCode = () => {
    if (!validateFields()) return;
    const code = `${discountTag}-${Math.random().toString(36).substring(2, 8).toUpperCase()}`;
    setDiscountCode(code);
  };

  const clearForm = () => {
    setDiscountCode(null);
    setFlatDiscount("0");
    setDiscountTag("DISCOUNT");
    setSelectedMonth("1");
    setSelectedPlan("");
    setValidFrom("");
    setValidTill("");
    setErrors({});
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (validateFields()) {
      logCode();
      try {
        const response = await fetch(`${apiUrl}/api/coupons/create`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            code: discountCode,
            validFrom,
            validUntil: validTill,
            selectedMonth,
            selectedPlan,
            discount: flatDiscount,
          }),
        });
  
        if (response.ok) {
          const data = await response.json();
          console.log('Coupon saved:', data);
          setSuccessMessage('Coupon created successfully!');
          clearForm();
        } else {
          console.error('Failed to save coupon');
        }
      } catch (error) {
        console.error('Error saving coupon:', error);
      }
    }
  };


  const logCode = () => {
    if (discountCode) {
      console.log("Generated Discount Code:", {
        discountCode,
        flatDiscount: `INR ${flatDiscount}`,
        discountTag,
        selectedMonth,
        selectedPlan,
        validFrom,
        validTill,
      });
      setDiscountCode(null);
    } else {
      console.log("No discount code generated yet");
    }
  };

  const handleMonthChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedMonth(e.target.value as "1" | "3" | "6" | "9" | "12");
    setSelectedPlan("");
  };

  // Function to clear the discount code
  const clearCode = () => {
    setDiscountCode(null);
  };

  
  

  return (
    <div className="flex flex-col items-center justify-center p-8 border-2 rounded-lg shadow-lg bg-white max-w-3xl mx-auto">
      <h2 className="home-heading font-semibold mb-4 text-xl text-center text-gray-800">Discount Code Generator</h2>
      <form onSubmit={handleSubmit} className="flex flex-col items-center gap-6 w-full">
        <div className="flex flex-wrap gap-6 w-full">
          <div className="flex-1 min-w-[200px]">
            <label className="block mb-1 text-gray-700">Select Month:</label>
            <select
              value={selectedMonth}
              onChange={handleMonthChange}
              className="block w-full p-2 border rounded-lg bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-400"
            >
              <option value="1">1 Month</option>
              <option value="3">3 Months</option>
              <option value="6">6 Months</option>
              <option value="9">9 Months</option>
              <option value="12">12 Months</option>
            </select>
          </div>
  
          <div className="flex-1 min-w-[200px]">
            <label className="block mb-1 text-gray-700">Select Plan:</label>
            <select
              value={selectedPlan}
              onChange={(e) => setSelectedPlan(e.target.value)}
              className="block w-full p-2 border rounded-lg bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-400"
            >
              <option value="">Select a Plan</option>
              {plansForMonth[selectedMonth].map((plan) => (
                <option key={plan} value={plan}>
                  {plan}
                </option>
              ))}
            </select>
            {errors.selectedPlan && <p className="text-red-600">{errors.selectedPlan}</p>}
          </div>
  
          <div className="flex-1 min-w-[200px]">
            <label className="block mb-1 text-gray-700">Flat Discount (INR):</label>
            <input
              type="text"
              placeholder="Flat discount amount"
              value={flatDiscount}
              onChange={(e) => setFlatDiscount(e.target.value)}
              className="block w-full p-2 border rounded-lg bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-400"
            />
            {errors.flatDiscount && <p className="text-red-600">{errors.flatDiscount}</p>}
          </div>
  
          <div className="flex-1 min-w-[200px]">
            <label className="block mb-1 text-gray-700">Discount Tag:</label>
            <input
              type="text"
              placeholder="Tag of the discount"
              value={discountTag}
              onChange={(e) => setDiscountTag(e.target.value)}
              className="block w-full p-2 border rounded-lg bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-400"
            />
            {errors.discountTag && <p className="text-red-600">{errors.discountTag}</p>}
          </div>
  
          <div className="flex-1 min-w-[200px]">
            <label className="block mb-1 text-gray-700">Valid From:</label>
            <input
              type="date"
              value={validFrom}
              onChange={(e) => setValidFrom(e.target.value)}
              className="block w-full p-2 border rounded-lg bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-400"
            />
            {errors.validFrom && <p className="text-red-600">{errors.validFrom}</p>}
          </div>
  
          <div className="flex-1 min-w-[200px]">
            <label className="block mb-1 text-gray-700">Valid Till:</label>
            <input
              type="date"
              value={validTill}
              onChange={(e) => setValidTill(e.target.value)}
              className="block w-full p-2 border rounded-lg bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-400"
            />
            {errors.validTill && <p className="text-red-600">{errors.validTill}</p>}
          </div>
        </div>
  
        <div className="flex flex-row items-center justify-start space-x-10">
              <button
                type="button"
                onClick={generateCode}
                className="mt-4 px-6 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 transition duration-300"
              >
                Generate Code
              </button>

                {discountCode && (
                  <div className="mt-4 text-2xl font-bold text-green-600">
                    {discountCode}
                  </div>
                )}

                <button
                  type="button"
                  onClick={clearCode}
                  className="mt-4 px-6 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition duration-300"
                >
                  Clear Code
                </button>

                <button
                  type="submit"
                  className="mt-4 px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition duration-300"
                >
                  Submit
                </button>
        </div>

      </form>

      {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
  
      

    </div>
  );
  
};



export default DiscountGenerator;
