import { useEffect, useState, useCallback } from "react";
import { useLocation, Link } from "react-router-dom";
import axios from 'axios';
import { useDispatch } from "react-redux";
import { actions as userActions } from "../../../features/users/usersSlice";
import { useUser } from '../../../pages/UserContext';

// Import API URL
const apiUrl = import.meta.env.VITE_API_URL;

// Badges
import socialCheckGrey from "/svgs/socialCheckGrey.svg";
import socialCheckGreen from "/svgs/socialCheckGreen.svg";
import familyCheckGrey from "/svgs/familyCheckGrey.svg";
import familyCheckGreen from "/svgs/familyCheckGreen.svg";
import employmentCheckGrey from "/svgs/employmentCheckGrey.svg";
import employmentCheckGreen from "/svgs/employmentCheckGreen.svg";
import educationCheckGrey from "/svgs/educationCheckGrey.svg";
import educationCheckGreen from "/svgs/educationCheckGreen.svg";
import criminalCheckGrey from "/svgs/criminalCheckGrey.svg";
import criminalCheckGreen from "/svgs/criminalCheckGreen.svg";
import creditCheckGrey from "/svgs/creditCheckGrey.svg";
import creditCheckGreen from "/svgs/creditCheckGreen.svg";
import { BackgroundGradient } from "../../../components/BackgroundGradient";

export interface User {
  TMI: string;
  _id: string;
  firstname: string;
  lastname: string;
  photo: string; // Base64 encoded image
  emailid: string;
  isVerified: boolean;
  isApproved: boolean; // Profile approval status
  photoApproved: boolean; // Photo approval status
  albumApproved:  boolean;
  membership?: string;
  Education?: string;
  Occupation?: string;
  Height?: string;
  age?: number;
  motherTongue?: string;
  complexion?: string;
  FamilyType?: string;
  FamilyStatus?: string;
  ResidenceState?: string;
  ResidenceCity?: string;
  ResidenceLocality?: string;
}

const ProfileApproval: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { setUserType } = useUser();

  const fetchUsers = useCallback(async () => {
    try {
      const response = await axios.get<User[]>(`${apiUrl}/api/profilesapproval`, { withCredentials: true });
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    fetchUsers();
  }, [location.state, location.search, location.pathname, setUserType, fetchUsers]);

  const handleProfileClick = async (profileTMI: string) => {
    try {
      await axios.post(`${apiUrl}/api/viewed-profiles/profile/${profileTMI}/view`, {}, { withCredentials: true });
    } catch (error) {
      console.error('Error recording profile view:', error);
    }
  };

  return (
    <div className="flex min-h-screen">
      <br />
      <br />
      <br />
      {/* Profile Listings */}
      <div className="laptop:w-[100%] flex flex-col mx-auto">
        <div className="flex flex-col items-center justify-center mr-10">
          <br />
          <button
            type="button"
            className="flex justify-center items-center w-[500px] h-[40px] bg-blue-500 hover:bg-blue-600 text-white font-medium rounded-md shadow-md transition duration-300 ease-in-out transform hover:scale-105"
          >
            Profiles/Photos those are not approved
          </button>
        </div>
        {loading ? (
          <div className="loading-spinner">
            <p>Loading...</p>
          </div>
        ) : users.length === 0 ? (
          <p className="text-center text-xl font-semibold mt-8">No profiles to display</p>
        ) : (
          <ul className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10 p-2 ml-0">
            {users.map((user) => (
              <Link
                to={`/admin/approvals/profileapproval/${user.TMI}`}
                replace={true}
                key={user.TMI}
                onClick={() => {
                  dispatch(userActions.setSelectedUserId(user.TMI));
                  handleProfileClick(user.TMI);
                }}
              >
                <BackgroundGradient
                  className="p-1"
                  key={user._id}
                  planName={user.membership || 'Free'}
                >
                  <li className="flex flex-col rounded-3xl bg-gray-50 border-2 px-4 pb-4 cursor-pointer max-w-[500px]">
                    {/* Badges */}
                    <div className="flex gap-x-4 place-content-center py-2 text-2xl">
                      <Badge isVerified={user.isVerified} imageGreen={familyCheckGreen} imageGrey={familyCheckGrey} />
                      <Badge isVerified={user.isVerified} imageGreen={educationCheckGreen} imageGrey={educationCheckGrey} />
                      <Badge isVerified={user.isVerified} imageGreen={employmentCheckGreen} imageGrey={employmentCheckGrey} />
                      <Badge isVerified={user.isVerified} imageGreen={socialCheckGreen} imageGrey={socialCheckGrey} />
                      <Badge isVerified={user.isVerified} imageGreen={creditCheckGreen} imageGrey={creditCheckGrey} />
                      <Badge isVerified={user.isVerified} imageGreen={criminalCheckGreen} imageGrey={criminalCheckGrey} />
                    </div>

                    <div className="flex flex-col items-center">
                      <img
                        src={`${user.photo}`}
                        alt="User Photo"
                        className="w-32 h-32 object-cover rounded-3xl"
                      />
                      <h4 className="text-2xl py-3 text-center font-bold">
                        {user.firstname} {user.lastname}
                      </h4>

                      {/* Approval Status */}
                      <h4 className="text-lg mt-3 font-bold border-b-2">APPROVAL STATUS</h4>
                      <div className="flex gap-x-4 mb-2">
                        <ApprovalBadge isApproved={user.isApproved} label="Profile Approval" />
                        <ApprovalBadge photoApproved={user.photoApproved} label="Photo Approval" />
                        <ApprovalBadge albumApproved={user.albumApproved} label="Album Approval" />
                      </div>

                      {/* User details */}
                      <div className="grid text-lg px-2 gap-x-5 gap-y-2 grid-cols-2 items-center">
                        <p className="truncate">{user.TMI}</p>
                        <p className="truncate">{user.Height}</p>
                        <p className="truncate">{user.Education}</p>
                        <p className="truncate">{user.age}</p>
                        <p className="truncate">{user.Occupation}</p>
                        <p className="truncate">{user.motherTongue}</p>
                        <p className="truncate">{user.complexion}</p>
                      </div>
                    </div>
                  </li>
                </BackgroundGradient>
              </Link>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

// Badge Component for verification status
interface BadgeProps {
  isVerified: boolean;
  imageGreen: string;
  imageGrey: string;
}

const Badge: React.FC<BadgeProps> = ({ isVerified, imageGreen, imageGrey }) => {
  return (
    <div className="flex flex-col items-center">
      <img
        src={isVerified ? imageGreen : imageGrey}
        alt="Badge"
        className="w-10 h-10"
      />
    </div>
  );
};

// ApprovalBadge Component with conditional styling
interface ApprovalBadgeProps {
  label: string;
  isApproved?: boolean;
  photoApproved?: boolean;
  albumApproved?:boolean;
}

const ApprovalBadge: React.FC<ApprovalBadgeProps> = ({ isApproved, photoApproved,albumApproved, label }) => {
  const approved = isApproved !== undefined ? isApproved : (photoApproved !== undefined ? photoApproved : albumApproved);
  return (
    <div className={`flex flex-col items-center p-2 rounded-lg ${approved ? 'bg-green-100' : 'bg-red-200'} shadow-md`}>
      <p className={`font-bold ${approved ? 'text-green-600' : 'text-red-600'}`}>{label}</p>
      <div className={`w-6 h-6 rounded-full flex items-center justify-center ${approved ? 'bg-green-500' : 'bg-red-300'}`}>
        <span className="text-white font-bold">{approved ? '✔️' : '❌'}</span>
      </div>
    </div>
  );
};

export default ProfileApproval;

