import React, { useState } from "react";
import axios from "axios";
import { useAuthUser } from "../pages/AuthUserContext";
const apiUrl = import.meta.env.VITE_API_URL;

// Constant for input types
const CHECKBOX = "checkbox";
const RADIO = "radio";

// Verification categories
const verificationCategories = [
  {
    category: "Identity",
    checklists: [{ name: "Aadhar Card / Pan Card / Passport", type: CHECKBOX, id: "AAC" }],
  },
  {
    category: "Address verification",
    checklists: [
      {
        name: "Physical address verification - Enquire about the family and the person with a customized questionnaire",
        type: CHECKBOX,
        id: "PHA",
      },
    ],
  },
  {
    category: "Employment Verification",
    checklists: [
      { name: "Employment verification - With Company HR", type: RADIO, group: "employment", id: "EM1" },
      { name: "Self-employment verification (Only physical address verification)", type: RADIO, group: "employment", id: "SEC" },
    ],
  },
  {
    category: "Education verification",
    checklists: [{ name: "Latest education", type: CHECKBOX, id: "ED1" }],
  },
  {
    category: "Credit Score Verification",
    checklists: [
      { name: "Credit assessment - Individual", type: RADIO, group: "credit", id: "CRD" },
      { name: "Credit assessment - Business", type: RADIO, group: "credit", id: "BCC" },
    ],
  },
  {
    category: "Court record checks",
    checklists: [
      { name: "Criminal records verification", type: CHECKBOX, id: "CRC" },
      { name: "Civil records verification", type: CHECKBOX, id: "CCC" },
      { name: "Family court", type: CHECKBOX, id: "FCC" },
    ],
  },
  {
    category: "Social media Verification",
    checklists: [{ name: "Facebook,Twitter,Instagram,LinkedIn", type: CHECKBOX, id: "SMC" }],
    
  },
];

// Add-On Verifications
const addOnVerifications = [
  {
    category: "Employment Verification",
    checklists: [{ name: "Company verification - MCA & Physical verification", type: CHECKBOX, id: "EV" }],
  },
  {
    category: "Reference check",
    checklists: [{ name: "Reference check – Customized questionnaire (optional)", type: CHECKBOX, id: "MRC" }],
  },
  {
    category: "Court record checks",
    checklists: [{ name: "Family Court Check Comprehensive multiple address", type: CHECKBOX, id: "FCX" }],
  },
];

// Advanced Verification Component
const AdvancedVerification: React.FC = () => {
  const [selectedVerifications, setSelectedVerifications] = useState<Record<string, any>>({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [link, setLink] = useState<string | null>(null);
  const user = useAuthUser();
  const tmi = user?.user; // Make sure `user` exists

  const handleChange = (category: string, checklist: string, type: string, group?: string) => {
    setSelectedVerifications((prev) => {
      if (type === RADIO && group) {
        return { ...prev, [group]: checklist };
      }
      const updatedCategory = prev[category] || [];
      return {
        ...prev,
        [category]: updatedCategory.includes(checklist)
          ? updatedCategory.filter((item: string) => item !== checklist)
          : [...updatedCategory, checklist],
      };
    });
  };

  const renderChecklist = ( category: string, checklists: any[]) => (
    <div key={category} className="mb-6">
      <h3 className="font-semibold text-lg text-gray-800">{category}</h3>
      {checklists.map((checklist, index) => (
        <div key={index} className="mb-2">
          <input
            type={checklist.type}
            name={checklist.group || category}
            checked={
              checklist.type === RADIO
                ? selectedVerifications[checklist.group] === checklist.name
                : selectedVerifications[category]?.includes(checklist.name)
            }
            onChange={() => handleChange(category, checklist.name, checklist.type, checklist.group)}
            className="mr-2 h-4 w-4 accent-orange-600 border-gray-300 rounded"
          />
          <label className="text-gray-700">{checklist.name}</label>
        </div>
      ))}
    </div>
  );

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    setError(null);

    const requestBody = {
      identity: {
        aadharCardPanCardPassport: {
          checkID: "AAC",
          isSelected: selectedVerifications["Identity"]?.includes("Aadhar Card / Pan Card / Passport") || false,
        },
      },
      addressVerification: {
        physicalAddressVerification: {
          checkID: "PHA",
          isSelected:
            selectedVerifications["Address verification"]?.includes(
              "Physical address verification - Enquire about the family and the person with a customized questionnaire"
            ) || false,
        },
      },
      employmentVerification: {
        employmentVerificationHR: {
          checkID: "EM1",
          isSelected: selectedVerifications["employment"] === "Employment verification - With Company HR" || false,
        },
        selfEmploymentVerification: {
          checkID: "SEC",
          isSelected: selectedVerifications["employment"] === "Self-employment verification (Only physical address verification)" || false,
        },
      },
      educationVerification: {
        latestEducation: {
          checkID: "ED1",
          isSelected: selectedVerifications["Education verification"]?.includes("Latest education") || false,
        },
      },
      creditScoreVerification: {
        individualCreditScore: {
          checkID: "CRD",
          isSelected: selectedVerifications["credit"] === "Credit assessment - Individual" || false,
        },
        businessCreditScore: {
          checkID: "BCC",
          isSelected: selectedVerifications["credit"] === "Credit assessment - Business" || false,
        },
      },
      courtRecordVerification: {
        criminal: {
          checkID: "CRC",
          isSelected: selectedVerifications["Court record checks"]?.includes("Criminal records verification") || false,
        },
        civil: {
          checkID: "CCC",
          isSelected: selectedVerifications["Court record checks"]?.includes("Civil records verification") || false,
        },
        familyCourt: {
          checkID: "FCC",
          isSelected: selectedVerifications["Court record checks"]?.includes("Family court") || false,
        },
      },
      socialMediaVerification: {
        FacebookTwitterInstagramLinkedin: {
          checkID: "SMC",
          isSelected: selectedVerifications["Social media Verification"]?.includes("Facebook,Twitter,Instagram,LinkedIn") || false,
        },
      },
      addOnVerifications: {
        employmentVerification: {
          
            checkID: "EV",
            isSelected: selectedVerifications["Employment Verification"]?.includes(
              "Company verification - MCA & Physical verification"
            ) || false,
          },
        
        referenceCheck: {
          
            checkID: "MRC",
            isSelected: selectedVerifications["Reference check"]?.includes(
              "Reference check – Customized questionnaire (optional)"
            ) || false,
          },
        
        courtRecordCheck: {
          
            checkID: "FCX",
            isSelected: selectedVerifications["Court record checks"]?.includes(
              "Family Court Check Comprehensive multiple address"
            ) || false,
          },
        
      
    }
  }

    console.log(requestBody)

    try {
      const response = await axios.post(`${apiUrl}/api/advanced-verification/${tmi}`, requestBody);
      setLink(response.data.link);
    } catch (error) {
      setError("Failed to submit verifications. Please try again.");
    } finally {
      setLoading(false);
    }
  };


  return (
    <div className="max-w-3xl mx-auto p-8 bg-white border-4 border-transparent rounded-lg relative">
      <div className="absolute inset-0 rounded-lg border-4 border-gradient-to-r from-blue-500 to-purple-600"></div>

      <h2 className="text-2xl font-semibold text-center mb-6 text-gray-800 relative z-10">Advance Verification</h2>

      <form onSubmit={handleSubmit} className="relative z-10">
        <div className="flex justify-between mb-6">
          <div className="w-1/2 pr-4">
            <h3 className="text-xl font-semibold mb-4 text-orange-600">Main Verification Categories</h3>
            {verificationCategories.map((category) =>
              renderChecklist( category.category, category.checklists)
            )}
            <p className="mt-4 text-gray-600">
              Ensure your social profiles are unlocked. We can't perform checks if they are locked.
            </p>
          </div>

          <div className="w-1/2 pl-4">
            <h3 className="text-xl font-semibold mb-4 text-orange-600">Add-On Verifications</h3>
            {addOnVerifications.map((category) =>
              renderChecklist( category.category, category.checklists)
            )}
          </div>
        </div>

        <div className="mt-6 flex justify-center">
          <button
            type="submit"
            className="px-4 py-2 bg-orange-600 text-white font-semibold rounded-lg shadow hover:bg-orange-700 transition duration-300"
            disabled={loading}
          >
            {loading ? "Submitting..." : "Submit Verifications"}
          </button>
        </div>
      </form>

      {error && <p className="text-red-500 text-center mt-4">{error}</p>}
      {link && (
        <div className="mt-6 text-center">
          <p className="text-green-500 font-semibold">Success! Your verifications have been submitted.</p>
          <a href={link} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
            View Verification Results
          </a>
        </div>
      )}
    </div>
  );
};

export default AdvancedVerification;

