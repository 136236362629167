const apiUrl = import.meta.env.VITE_API_URL;
import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useLocation } from "react-router-dom";

import { dropdownData } from "../../utils/data";

import locations from "../../utils/locations.json";

import { SubmitHandler, useForm, Controller } from "react-hook-form";
import {  z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

import Select from "react-select";
import axios from "axios";

// interface SearchData {
//   profileId: string;
// }

interface SectionedData {
  [key: string]: string[]; // Each group key maps to an array of strings
}

interface Option {
  value: string;
  label: string;
}

interface GroupedOption {
  label: string;
  options: Option[];
}


const createGroupedOptions = (sectionedData: SectionedData): GroupedOption[] =>
  Object.keys(sectionedData).map((group) => ({
    label: group,
    options: sectionedData[group].map((item: string) => ({
      value: item,
      label: item,
    })),
  }));

//console.log(groupedOptions(dropdownData.occupations));

//Search By ID
const searchByIdSchema = z.object({
  profileId: z.string().min(1, { message: "Required" }),
});
type SearchByIdFields = z.infer<typeof searchByIdSchema>;

//Quick Search
const quickSearchSchema = z.object({
    minAge: z.string().min(1, { message: "required" }),
    maxAge: z.string().min(1, { message: "required" }),
    caste: z.string().optional(), 
    maritalStatus: z.string().min(1, { message: "Required" }),
    denomination: z.string().min(1, { message: "Required" }),
  });

type QuickSearchFields = z.infer<typeof quickSearchSchema>;

//Regular Search
const regularSearchSchema = z.object({
  minAge: z.string().min(1, { message: "required" }),
  maxAge: z.string().min(1, { message: "required" }),
  minHeight: z.string().min(1, { message: "required" }),
  maxHeight: z.string().min(1, { message: "required" }),
  motherTongue: z.string().min(1, { message: "Required" }),
  caste: z.string().min(1, { message: "Required" }),
  maritalStatus: z.string().min(1, { message: "Required" }),
  denomination: z.string().min(1, { message: "Required" }),
  education: z.string().min(1, { message: "Required" }),
});
type RegularSearchFields = z.infer<typeof regularSearchSchema>;

//Advanced Search
const advancedSearchSchema = z.object({
  minAge: z.string(),
  maxAge: z.string(),
  minHeight: z.string(),
  maxHeight: z.string(),
  motherTongue: z.array(z.string()).optional(),
  maritalStatus: z.array(z.string()),
  caste: z.array(z.string()).optional(),
  physicalStatus: z.array(z.string()),
  denomination: z.array(z.string()).optional(),

  education: z
    .array(z.object({ value: z.string(), label: z.string() }))
    .optional(),
  occupation: z
    .array(z.object({ value: z.string(), label: z.string() }))
    .optional(),
  annualIncome: z.array(z.string()).optional(),

  state: z.array(z.string()).optional(),
  city: z.array(z.string()).optional(),
  locality: z.array(z.string()).optional(),

  familyStatus: z.array(z.string()),
  
});
type AdvancedSearchFields = z.infer<typeof advancedSearchSchema>;

export const SearchSection: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [showSearch, setShowSearch] = useState(location.state?.showSearch || 0);

  
  //Search By Id Form
  const searchByIdForm = useForm<SearchByIdFields>({
    resolver: zodResolver(searchByIdSchema),
  });
  //On form Submit
  const onSearchById = async (data: SearchByIdFields) => {
    console.log("Form Data Submitted:", data);
    console.log(data.profileId)
    try {
      // Adjusting to include search query in the URL
      const response = await axios.get(`${apiUrl}/api/search?TMI=${data.profileId}`, { withCredentials: true });
  
      if (response.status === 200) {
        const user = response.data;
        navigate(`/profilelisting/${user.TMI}`, { state: { user } });
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response && error.response.status === 404) {
          alert('TMI does not exist');
        } else {
          console.error('Error searching for user:', error.message);
        }
      } else {
        console.error('An unknown error occurred:', error);
      }
    }
  };
  

  //Quick Search
  const quickSearchForm = useForm<QuickSearchFields>({
    resolver: zodResolver(quickSearchSchema),
    defaultValues: {
      minAge: "18",
      maxAge: "25",
      denomination: "Any",
      maritalStatus: "Any",
      caste:"Any"
      
    },
  });

  const onQuickSearch = async (data: any) => {
    try {
      // Build params object
      const params: any = {
        minAge: data.minAge,
        maxAge: data.maxAge,
        maritalStatus:data.maritalStatus,
        denomination: data.denomination
      };
  
      // Conditionally add fields if they are provided
     
       
  
      if (data.caste) {
        params.caste = data.caste;
      }
  
      // Make API request
      const response = await axios.get(`${apiUrl}/api/quicksearch`, {
        params,
        withCredentials: true
      });
  
      console.log(response.data);
      navigate('/profilelisting', {
        state: { filteredUsers: response.data }
      });
    } catch (error) {
      console.error('Error performing quick search:', error);
    }
  };
  
  

  //Regular Search
  const regularSearchForm = useForm<RegularSearchFields>({
    resolver: zodResolver(regularSearchSchema),
    defaultValues: {
      minAge: "18",
      maxAge: "25",
      minHeight: "4",
      maxHeight: "5",
      motherTongue: "",
      caste: "",
      maritalStatus: "",
      denomination: "",
      education: "",
    },
  });
  const onRegularSearch = async (data: any) => {
    console.log("Hi")
    try {
      // Prepare the parameters
      const params: any = {
        minAge: data.minAge,
        maxAge: data.maxAge,
        minHeight: parseFloat(data.minHeight), // Ensure correct format
        maxHeight: parseFloat(data.maxHeight),
        motherTongue: data.motherTongue ,
        maritalStatus: data.maritalStatus ,
        caste: data.caste ,
        denomination: data.denomination ,
        education: data.education ,
      };
  
      const response = await axios.get(`${apiUrl}/api/regularsearch`, {
        params,
        withCredentials: true
      });
  
      console.log('Search Response:', response.data);
  
      if (response.data.message === 'No Matches') {
        alert('No matches found.');
      } else {
        // Navigate to ProfileListing with filtered results
        navigate('/profilelisting', { state: { filteredUsers: response.data } });
      }
    } catch (error) {
      console.error('Error performing regular search:', error);
    }
  };
  
  
  
  

  //Advanced Search
  const advancedSearchForm = useForm<AdvancedSearchFields>({
    resolver: zodResolver(advancedSearchSchema),
    defaultValues: {
      minAge: "18",
      maxAge: "25",
      minHeight: "4",
      maxHeight: "5",
      motherTongue: ["Any"],
      maritalStatus: ["Unmarried"],
      caste: ["Any"],
      physicalStatus: ["Any"],
      denomination: ["Any"],
      education: [{ value: "Any", label: "Any" }],
      occupation: [{ value: "Any", label: "Any" }],
      annualIncome: ["Any"],
      state: ["Any"],
      city: ["Any"],
      locality: ["Any"],
      familyStatus: ["Any"],
    },
  });
  
  const onAdvancedSearch: SubmitHandler<AdvancedSearchFields> = async (data) => {
    try {
      // Filter out fields with "Any" values before sending the request
      const filteredData = {
        ...data,
        motherTongue:
          data.motherTongue?.length && data.motherTongue[0] !== "Any"
            ? data.motherTongue
            : undefined,
        maritalStatus:
          data.maritalStatus.length && data.maritalStatus[0] !== "Unmarried"
            ? data.maritalStatus
            : undefined,
        caste:
          data.caste?.length && data.caste[0] !== "Any" ? data.caste : undefined,
        physicalStatus:
          data.physicalStatus.length && data.physicalStatus[0] !== "Any"
            ? data.physicalStatus
            : undefined,
        denomination:
          data.denomination?.length && data.denomination[0] !== "Any"
            ? data.denomination
            : undefined,
        education:
          data.education?.length && data.education[0].value !== "Any"
            ? data.education.map((e) => e.value)
            : undefined,
        occupation:
          data.occupation?.length && data.occupation[0].value !== "Any"
            ? data.occupation.map((o) => o.value)
            : undefined,
        annualIncome:
          data.annualIncome?.length && data.annualIncome[0] !== "Any"
            ? data.annualIncome
            : undefined,
        state: data.state?.length && data.state[0] !== "Any" ? data.state : undefined,
        city: data.city?.length && data.city[0] !== "Any" ? data.city : undefined,
        locality:
          data.locality?.length && data.locality[0] !== "Any" ? data.locality : undefined,
        familyStatus:
          data.familyStatus.length && data.familyStatus[0] !== "Any"
            ? data.familyStatus
            : undefined,
      };
  
      const response = await axios.get(`${apiUrl}/api/advancedsearch`, {
        params: filteredData,
        withCredentials: true,
      });
  
      // Handle response
      console.log(response.data);
      if (response.data.message === "No Matches") {
        alert("No Matches");
      } else {
        navigate('/profilelisting', { state: { filteredUsers: response.data } });
      }
    } catch (error) {
      console.error('Error performing advanced search:', error);
    }
  };
  
  
  
  const stateArray = advancedSearchForm.watch("state");
  const cityArray = advancedSearchForm.watch("city");
  //const localityArray = advancedSearchForm.watch("locality");

  useEffect(() => {
    setShowSearch(location.state?.showSearch || 0);
  }, [location]);

  
  

  return (
    <div
      className="min-h-[32rem] border p-3 laptop:p-8"
      key={location.state?.showSearch}
    >


      <div className="">
        {/* Buttons */}
        <div className="grid grid-cols-2 mb-6 tablet:mb-0 tablet:grid-cols-4 text-sm tablet:text-lg">
          <button
            className={`relative group/btn border-2 h-full py-2 font-medium shadow-[0px_1px_0px_0px_#ffffff40_inset,0px_-1px_0px_0px_#ffffff40_inset]] ${
              showSearch === 0 ? "bg-accent/30" : "bg-white"
            }`}
            type="button"
            onClick={() => setShowSearch(0)}
          >
            Search by ID
            <BottomGradient />
          </button>
          <button
            className={`relative w-full group/btn border-2 h-full py-2 border-l-0 font-medium shadow-[0px_1px_0px_0px_#ffffff40_inset,0px_-1px_0px_0px_#ffffff40_inset]] ${
              showSearch === 1 ? "bg-accent/30" : "bg-white"
            }`}
            type="button"
            onClick={() => setShowSearch(1)}
          >
            Quick Search
            <BottomGradient />
          </button>
          <button
            className={`relative group/btn border-2 h-full py-2 border-x-0 font-medium shadow-[0px_1px_0px_0px_#ffffff40_inset,0px_-1px_0px_0px_#ffffff40_inset]] ${
              showSearch === 2 ? "bg-accent/30" : "bg-white"
            }`}
            type="button"
            onClick={() => setShowSearch(2)}
          >
            Regular Search
            <BottomGradient />
          </button>
          <button
            className={`relative group/btn border-2 h-full py-2 font-medium shadow-[0px_1px_0px_0px_#ffffff40_inset,0px_-1px_0px_0px_#ffffff40_inset]] ${
              showSearch === 3 ? "bg-accent/30" : "bg-white"
            } `}
            type="button"
            onClick={() => setShowSearch(3)}
          >
            Advanced Search
            <BottomGradient />
          </button>
        </div>
        {/* Search fields */}
        <div>
          {/* Search by ID */}
          <form
            onSubmit={searchByIdForm.handleSubmit(onSearchById)}
            className={`${
              showSearch === 0 ? "flex" : "hidden"
            } flex-col place-content-center items-center gap-y-4 p-12 laptop:p-24 text-center border border-t-0`}
          >
            <p className="text-2xl w-full font-semibold">Type the TM ID</p>
            <input
              {...searchByIdForm.register("profileId")}
              type="text"
              placeholder="ID"
              className="px-4 py-2 border"
              onChange={(e) => console.log("Input value:", e.target.value)}  // Log input changes
/>
            <p className="text-red-500">
              {searchByIdForm.formState.errors.profileId?.message}
            </p>
            <button
              className="relative w-[12rem] px-4 mt-6 group/btn border-2 btn rounded-md h-10 font-medium shadow-[0px_1px_0px_0px_#ffffff40_inset,0px_-1px_0px_0px_#ffffff40_inset]]"
              type="submit"
            >
              Submit
              <BottomGradient />
            </button>
          </form>
          {/* Quick Search */}
          <form
            onSubmit={quickSearchForm.handleSubmit(onQuickSearch)}
            className={`${
              showSearch === 1 ? "flex" : "hidden"
            } flex-col border border-t-0 px-4 tablet:p-12 gap-y-4`}
          >
            {/* Age */}
            <div className="flex flex-wrap items-center">
              <p className="text-xl w-[25%]">Age:</p>
              <div className="flex items-center gap-x-4">
                <select
                  {...quickSearchForm.register("minAge")}
                  className="p-2 border border-accent rounded-lg"
                >
                  {dropdownData.age.map((val) => (
                    <option value={val} key={val}>
                      {val}
                    </option>
                  ))}
                </select>
                <p>To</p>
                <select
                  {...quickSearchForm.register("maxAge")}
                  className="p-2 border border-accent rounded-lg"
                >
                  {dropdownData.age.map((val) => (
                    <option value={val} key={val}>
                      {val}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {/* Caste */}
            <div className="flex flex-wrap items-center">
            <p className="text-xl w-[25%]">
                Caste <span className="text-gray-500">(Optional)</span>:
            </p>
                  <select
                    {...quickSearchForm.register("caste")}
                    className="p-2 border border-accent rounded-lg"
                  >
                    <option value="">Any</option>
                    {dropdownData.castes.map((val) => (
                      <option value={val} key={val}>
                        {val}
                      </option>
                    ))}
                  </select>
            </div>

            {/* Marital Status */}
            <div className="flex flex-wrap items-center">
              <p className="text-xl w-[25%]">Marital Status:</p>
              <select
                {...quickSearchForm.register("maritalStatus")}
                className="p-2 border border-accent rounded-lg"
              >
                {dropdownData.maritalStatus.map((val) => (
                  <option value={val} key={val}>
                    {val}
                  </option>
                ))}
              </select>
            </div>
            {/* Denomination */}
            <div className="flex flex-wrap items-center">
              <p className="text-xl tablet:w-[25%]">Denomination:</p>
              <select
  {...quickSearchForm.register("denomination")}
  className="p-2 border border-accent rounded-lg"
>
  {dropdownData.denominations.map((val) => (
    <option value={val} key={val}>
      {val}
    </option>
  ))}
</select>
            </div>
            <button
              className="relative self-center mt-6 w-[12rem] px-4 group/btn border-2 btn rounded-md h-10 font-medium shadow-[0px_1px_0px_0px_#ffffff40_inset,0px_-1px_0px_0px_#ffffff40_inset]]"
              type="submit"
            >
              Submit
              <BottomGradient />
            </button>
          </form>
          {/* Regular Search */}
          <form
            onSubmit={regularSearchForm.handleSubmit(onRegularSearch)}
            className={`${
              showSearch === 2 ? "flex" : "hidden"
            } flex-col border border-t-0 p-4 tablet:p-12 gap-y-4`}
          >
            {/* Age */}
            <div className="flex gap-4 flex-wrap items-center">
              <p className="tablet:text-xl text-lg  tablet:w-[25%]">Age:</p>
              <div className="flex items-center gap-x-4">
                <select
                  {...regularSearchForm.register("minAge")}
                  className="p-2 border border-accent rounded-lg"
                >
                  {dropdownData.age.map((val) => (
                    <option value={val} key={val}>
                      {val}
                    </option>
                  ))}
                </select>
                <p>To</p>
                <select
                  {...regularSearchForm.register("maxAge")}
                  className="p-2 border border-accent rounded-lg"
                >
                  {dropdownData.age.map((val) => (
                    <option value={val} key={val}>
                      {val}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {/* Height */}
            <div className="flex  gap-4 flex-wrap items-center">
              <p className="tablet:text-xl text-lg  tablet:w-[25%]">Height: <span className="text-gray-500">(feet,inches)</span></p>
              <div className="flex flex-wrap items-center gap-y-2 gap-x-4">
                <select
                  {...regularSearchForm.register("minHeight")}
                  className="p-2 border border-accent rounded-lg"
                >
                  {dropdownData.heights.map((val) => (
                    <option value={val} key={val}>
                      {val}
                    </option>
                  ))}
                </select>
                <p>To</p>
                <select
                  {...regularSearchForm.register("maxHeight")}
                  className="p-2 border border-accent rounded-lg"
                >
                  {dropdownData.heights.map((val) => (
                    <option value={val} key={val}>
                      {val}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {/* Mother Tongue */}
            <div className="flex  gap-4 flex-wrap items-center">
              <p className="tablet:text-xl text-lg  tablet:w-[25%]">
                Mother Tongue:
              </p>
              <select
                {...regularSearchForm.register("motherTongue")}
                className="p-2 border border-accent rounded-lg"
              >
                {dropdownData.motherTongue.map((val) => (
                  <option value={val} key={val}>
                    {val}
                  </option>
                ))}
              </select>
            </div>
            {/* Caste */}
            <div className="flex  gap-4 flex-wrap items-center">
              <p className="tablet:text-xl text-lg  tablet:w-[25%]">Caste:</p>
              <select
                {...regularSearchForm.register("caste")}
                className="p-2 border border-accent rounded-lg"
              >
                {dropdownData.castes.map((val) => (
                  <option value={val} key={val}>
                    {val}
                  </option>
                ))}
              </select>
            </div>
            {/* Marital Status */}
            <div className="flex  gap-4 flex-wrap items-center">
              <p className="tablet:text-xl text-lg  tablet:w-[25%]">
                Marital Status:
              </p>
              <select
                {...regularSearchForm.register("maritalStatus")}
                className="p-2 border border-accent rounded-lg"
              >
                {dropdownData.maritalStatus.map((val) => (
                  <option value={val} key={val}>
                    {val}
                  </option>
                ))}
              </select>
            </div>
            {/* Denomination */}
            <div className="flex  gap-4 flex-wrap items-center">
              <p className="tablet:text-xl text-lg  tablet:w-[25%]">
                Denomination:
              </p>
              <select
                {...regularSearchForm.register("denomination")}
                className="p-2 border border-accent rounded-lg"
              >
                {dropdownData.denominations.map((val) => (
                  <option value={val} key={val}>
                    {val}
                  </option>
                ))}
              </select>
            </div>
            {/* Education */}
            <div className="flex  gap-4 flex-wrap items-center">
              <p className="tablet:text-xl text-lg  tablet:w-[25%]">
                Education:
              </p>
              <select
                {...regularSearchForm.register("education")}
                className="p-2 border border-accent rounded-lg w-[50%] tablet:w-[60%] laptop:w-[40%]"
              >
                
                {Object.entries(dropdownData.education).map(([key, values]) => (
                  <optgroup label={key} key={key}>
                    {values.map((value) => (
                      <option value={value} key={value}>
                        {value}
                      </option>
                    ))}
                  </optgroup>
                ))}
              </select>


            </div>
            <button
              className="relative self-center mt-6 w-[12rem] px-4 group/btn border-2 btn rounded-md h-10 font-medium shadow-[0px_1px_0px_0px_#ffffff40_inset,0px_-1px_0px_0px_#ffffff40_inset]]"
              type="submit"
            >
              Submit
              <BottomGradient />
            </button>
          </form>
          {/* Advanced Search */}
          <form
            onSubmit={advancedSearchForm.handleSubmit(onAdvancedSearch)}
            className={`${
              showSearch === 3 ? "flex" : "hidden"
            } flex-col border border-t-0 p-3 mobileLg:p-6 laptop:p-12`}
          >
            {/* General Details */}
            <div className="flex flex-col gap-y-4">
              {/* Age */}
              <div className="flex gap-4 flex-wrap items-center">
                <p className="tablet:text-xl text-lg  tablet:w-[25%]">Age:</p>
                <div className="flex items-center gap-x-4">
                  <select
                    {...advancedSearchForm.register("minAge")}
                    className="p-2 border border-accent rounded-lg"
                  >
                    {dropdownData.age.map((val) => (
                      <option value={val} key={val}>
                        {val}
                      </option>
                    ))}
                  </select>
                  <p>To</p>
                  <select
                    {...advancedSearchForm.register("maxAge")}
                    className="p-2 border border-accent rounded-lg"
                  >
                    {dropdownData.age.map((val) => (
                      <option value={val} key={val}>
                        {val}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              {/* Height */}
              <div className="flex gap-4 flex-wrap items-center">
                <p className="tablet:text-xl text-lg  tablet:w-[25%]">
                  Height: <span className="text-black-500 text-sm">[feet,inches:] </span>
                </p>
               
                <div className="flex flex-wrap items-center gap-1 laptop:gap-4">
                
                  <select
                    {...advancedSearchForm.register("minHeight")}
                    className="p-2 border border-accent rounded-lg"
                  >
                    {dropdownData.heights.map((val) => (
                      <option value={val} key={val}>
                        {val}
                      </option>
                    ))}
                  </select>
                  <p>To</p>
                 
                  <select
                    {...advancedSearchForm.register("maxHeight")}
                    className="p-2 border border-accent rounded-lg"
                  >
                    {dropdownData.heights.map((val) => (
                      <option value={val} key={val}>
                        {val}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              {/* Mother Tongue */}
              <div className="flex gap-4 flex-wrap items-center">
                <p className="tablet:text-xl text-lg  tablet:w-[25%]">
                  Mother Tongue:
                </p>
                <Controller
                  control={advancedSearchForm.control}
                  name="motherTongue"
                  render={({ field: formField }) => (
                    <Select
                      {...formField}
                      isMulti
                      options={dropdownData.motherTongue.map((option) => ({
                        label: option,
                        value: option,
                      }))}
                      value={formField?.value?.map((val: string) => ({
                        label: val,
                        value: val,
                      }))}
                      onChange={(selectedOptions) => {
                        // Ensure 'onChange' updates the form field value correctly
                        formField.onChange(
                          selectedOptions.map((option) => option.value)
                        );
                      }}
                    />
                  )}
                />
              </div>
              {/* Marital Status */}
              <div className="flex gap-4 flex-wrap items-center">
                <p className="tablet:text-xl text-lg  tablet:w-[25%]">
                  Marital Status:
                </p>
                <Controller
                  control={advancedSearchForm.control}
                  name="maritalStatus"
                  render={({ field }) => (
                    <ul className="flex gap-2 flex-wrap">
                      {dropdownData.maritalStatus.map((stat) => (
                        <li key={stat} className="flex items-center gap-x-2">
                          <input
                            type="checkbox"
                            id={stat}
                            checked={field?.value?.includes(stat)}
                            onChange={() => {
                              const newValue = field.value.includes(stat)
                                ? field.value.filter((value) => value !== stat)
                                : [...field.value, stat];
                              field.onChange(newValue);
                            }}
                            className="w-4 h-4"
                          />
                          <label htmlFor={stat} className="text-sm sm:text-lg">
                            {stat}
                          </label>
                        </li>
                      ))}
                    </ul>
                  )}
                />
              </div>
              {/* Caste */}
              <div className="flex gap-4 flex-wrap items-center">
                <p className="tablet:text-xl text-lg  tablet:w-[25%]">Caste:</p>
                <Controller
                  control={advancedSearchForm.control}
                  name="caste"
                  render={({ field: formField }) => (
                    <Select
                      {...formField}
                      isMulti
                      options={dropdownData.castes.map((option) => ({
                        label: option,
                        value: option,
                      }))}
                      value={formField?.value?.map((val: string) => ({
                        label: val,
                        value: val,
                      }))}
                      onChange={(selectedOptions) => {
                        // Ensure 'onChange' updates the form field value correctly
                        formField.onChange(
                          selectedOptions.map((option) => option.value)
                        );
                      }}
                    />
                  )}
                />
              </div>
              {/* Physical Status */}
              <div className="flex gap-4 flex-wrap items-center">
                <p className="tablet:text-xl text-lg  tablet:w-[25%]">
                  Physical Status:
                </p>
                <Controller
                  control={advancedSearchForm.control}
                  name="physicalStatus"
                  render={({ field }) => (
                    <ul className="flex gap-2 flex-wrap">
                      {dropdownData.physicalStatus.map((stat) => (
                        <li key={stat} className="flex items-center gap-x-2">
                          <input
                            type="checkbox"
                            id={stat}
                            checked={field?.value?.includes(stat)}
                            onChange={() => {
                              const newValue = field.value.includes(stat)
                                ? field.value.filter((value) => value !== stat)
                                : [...field.value, stat];
                              field.onChange(newValue);
                            }}
                            className="w-4 h-4"
                          />
                          <label htmlFor={stat} className="text-sm sm:text-lg">
                            {stat}
                          </label>
                        </li>
                      ))}
                    </ul>
                  )}
                />
              </div>
              {/* Denomination */}
              <div className="flex gap-4 flex-wrap items-center">
                <p className="tablet:text-xl text-lg  tablet:w-[25%]">
                  Denomination:
                </p>
                <Controller
                  control={advancedSearchForm.control}
                  name="denomination"
                  render={({ field: formField }) => (
                    <Select
                      {...formField}
                      isMulti
                      options={dropdownData.denominations.map((option) => ({
                        label: option,
                        value: option,
                      }))}
                      value={formField?.value?.map((val: string) => ({
                        label: val,
                        value: val,
                      }))}
                      onChange={(selectedOptions) => {
                        // Ensure 'onChange' updates the form field value correctly
                        formField.onChange(
                          selectedOptions.map((option) => option.value)
                        );
                      }}
                    />
                  )}
                />
              </div>
            </div>

            {/* professional Details */}
            <div className="my-6">
              <hr className="border-2" />
              <h2 className="text-xl font-semibold text-center my-2">
                Professional Details
              </h2>
              <hr className="border-2" />
              <div className="flex flex-col gap-y-4 my-8">
                {/* Education */}
                <div className="flex flex-wrap gap-4 laptop:gap-0 items-center">
                  <p className="tablet:text-xl text-lg  tablet:w-[25%]">
                    Education:
                  </p>
                  <Controller
                    control={advancedSearchForm.control}
                    name="education"
                    render={({ field }) => (
                      <Select
                        {...field}
                        isMulti
                        options={createGroupedOptions(dropdownData.education)}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder="Select your occupations..."
                      />
                    )}
                  />
                </div>
                {/* Occupation */}
                <div className="flex flex-wrap gap-4 items-center">
                  <p className="tablet:text-xl text-lg  tablet:w-[25%]">
                    Occupation:
                  </p>
                  <Controller
                    control={advancedSearchForm.control}
                    name="occupation"
                    render={({ field }) => (
                      <Select
                        {...field}
                        isMulti
                        options={createGroupedOptions(dropdownData.occupations)}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder="Select your occupations..."
                      />
                    )}
                  />
                </div>
                {/* Annual Income */}
                <div className="flex flex-wrap gap-4 items-center">
                  <p className="tablet:text-xl text-lg  tablet:w-[25%]">
                    Annual Income:
                  </p>
                  <div className="flex flex-wrap items-center gap-2 laptop:gap-x-4">
                    <Controller
                      control={advancedSearchForm.control}
                      name="annualIncome"
                      render={({ field: formField }) => (
                        <Select
                          {...formField}
                          isMulti
                          options={dropdownData.income.map((option) => ({
                            label: option,
                            value: option,
                          }))}
                          value={formField?.value?.map((val: string) => ({
                            label: val,
                            value: val,
                          }))}
                          onChange={(selectedOptions) => {
                            // Ensure 'onChange' updates the form field value correctly
                            formField.onChange(
                              selectedOptions.map((option) => option.value)
                            );
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Location Details */}
            <div className="my-6">
              <hr className="border-2" />
              <h2 className="text-xl font-semibold text-center my-2">
                Location Details
              </h2>
              <hr className="border-2" />
              <div className="flex flex-wrap gap-4 my-8">
                {/* State selection */}
                <div className="">
                  <Controller
                    control={advancedSearchForm.control}
                    name="state"
                    render={({ field: formField }) => (
                      <Select
                        {...formField}
                        isMulti
                        options={locations
                          .map((location) => location.state)
                          .map((option) => ({
                            label: option,
                            value: option,
                          }))}
                        value={formField?.value?.map((val: string) => ({
                          label: val,
                          value: val,
                        }))}
                        onChange={(selectedOptions) => {
                          // Ensure 'onChange' updates the form field value correctly
                          formField.onChange(
                            selectedOptions.map((option) => option.value)
                          );
                        }}
                      />
                    )}
                  />
                </div>

                {/* If a state is selected */}
                {stateArray && stateArray.length > 0 && (
                  <div className="">
                    <Controller
                      control={advancedSearchForm.control}
                      name="city"
                      render={({ field: formField }) => (
                        <Select
                          {...formField}
                          isMulti
                          options={locations
                            .filter((location) =>
                              stateArray.includes(location.state)
                            )
                            .flatMap((location) =>
                              location.cities.map((city) => city.name)
                            )
                            .map((option) => ({
                              label: option,
                              value: option,
                            }))}
                          value={formField?.value?.map((val: string) => ({
                            label: val,
                            value: val,
                          }))}
                          onChange={(selectedOptions) => {
                            // Ensure 'onChange' updates the form field value correctly
                            formField.onChange(
                              selectedOptions.map((option) => option.value)
                            );
                          }}
                        />
                      )}
                    />
                  </div>
                )}

                {/* If a city is selected */}
                {cityArray && cityArray.length > 0 && (
                  <div className="">
                    <Controller
                      control={advancedSearchForm.control}
                      name="locality"
                      render={({ field: formField }) => (
                        <Select
                          {...formField}
                          isMulti
                          options={locations
                            .flatMap((location) => location.cities) // Flatten to get all cities
                            .filter((city) => cityArray.includes(city.name)) // Filter cities by name
                            .flatMap((city) => city.localities)
                            .map((option) => ({
                              label: option,
                              value: option,
                            }))}
                          value={formField?.value?.map((val: string) => ({
                            label: val,
                            value: val,
                          }))}
                          onChange={(selectedOptions) => {
                            // Ensure 'onChange' updates the form field value correctly
                            formField.onChange(
                              selectedOptions.map((option) => option.value)
                            );
                          }}
                        />
                      )}
                    />
                  </div>
                )}
              </div>
            </div>

            {/* Family Details */}
            <div className="flex flex-col gap-y-4">
              <hr className="border-2" />
              <h2 className="text-xl font-semibold text-center">
                Family Details
              </h2>
              <hr className="border-2" />
              <div className="flex flex-col gap-y-4">
                {/* Family Status */}
                <div className="flex flex-wrap gap-4 items-center">
                  <p className="tablet:text-xl text-lg  tablet:w-[25%]">
                    Family Status:
                  </p>
                  <Controller
                    control={advancedSearchForm.control}
                    name="familyStatus"
                    render={({ field }) => (
                      <ul className="flex gap-2 flex-wrap">
                        {dropdownData.familyStatus.map((stat) => (
                          <li key={stat} className="flex items-center gap-x-2">
                            <input
                              type="checkbox"
                              id={stat}
                              checked={field?.value?.includes(stat)}
                              onChange={() => {
                                const newValue = field.value.includes(stat)
                                  ? field.value.filter(
                                      (value) => value !== stat
                                    )
                                  : [...field.value, stat];
                                field.onChange(newValue);
                              }}
                              className="w-4 h-4"
                            />
                            <label
                              htmlFor={stat}
                              className="text-sm sm:text-lg"
                            >
                              {stat}
                            </label>
                          </li>
                        ))}
                      </ul>
                    )}
                  />
                </div>

                {/* Show Profiles with photo */}
                {/* <div className="flex flex-wrap gap-4 items-center">
                  <p className="tablet:text-xl text-lg  tablet:w-[25%]">
                    Show Profiles:
                  </p>
                  <Controller
                    control={advancedSearchForm.control}
                    name="showProfiles"
                    render={({ field }) => (
                      <ul className="flex gap-2 flex-wrap">
                        {showProfilesOptions.map((stat) => (
                          <li key={stat} className="flex items-center gap-x-2">
                            <input
                              type="checkbox"
                              id={stat}
                              checked={field?.value?.includes(stat)}
                              onChange={() => {
                                const newValue = field.value.includes(stat)
                                  ? field.value.filter(
                                      (value) => value !== stat
                                    )
                                  : [...field.value, stat];
                                field.onChange(newValue);
                              }}
                              className="w-4 h-4"
                            />
                            <label
                              htmlFor={stat}
                              className="text-sm sm:text-lg"
                            >
                              {stat}
                            </label>
                          </li>
                        ))}
                      </ul>
                    )}
                  />
                </div> */}

                {/* Don't show profiles */}
                {/* <div className="flex flex-wrap gap-4  items-center">
                  <p className="tablet:text-xl text-lg  tablet:w-[25%]">
                    Don't show:
                  </p>
                  <Controller
                    control={advancedSearchForm.control}
                    name="dontShow"
                    render={({ field }) => (
                      <ul className="flex gap-2 flex-wrap">
                        {dontShowOptions.map((stat) => (
                          <li key={stat} className="flex items-center gap-x-2">
                            <input
                              type="checkbox"
                              id={stat}
                              checked={field?.value?.includes(stat)}
                              onChange={() => {
                                const newValue = field.value.includes(stat)
                                  ? field.value.filter(
                                      (value) => value !== stat
                                    )
                                  : [...field.value, stat];
                                field.onChange(newValue);
                              }}
                              className="w-4 h-4"
                            />
                            <label
                              htmlFor={stat}
                              className="text-sm sm:text-lg"
                            >
                              {stat}
                            </label>
                          </li>
                        ))}
                      </ul>
                    )}
                  />
                </div> */}
              </div>
            </div>

            <button
              className="relative self-center mt-6 w-[12rem] px-4 group/btn border-2 btn rounded-md h-10 font-medium shadow-[0px_1px_0px_0px_#ffffff40_inset,0px_-1px_0px_0px_#ffffff40_inset]]"
              type="submit"
            >
              Submit
              <BottomGradient />
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

const BottomGradient = () => {
  return (
    <>
      <span className="group-hover/btn:opacity-100 block transition duration-500 opacity-0 absolute h-px w-full -bottom-px inset-x-0 bg-gradient-to-r from-transparent via-cyan-500 to-transparent" />
      <span className="group-hover/btn:opacity-100 blur-sm block transition duration-500 opacity-0 absolute h-px w-1/2 mx-auto -bottom-px inset-x-10 bg-gradient-to-r from-transparent via-indigo-500 to-transparent" />
    </>
  );
};
