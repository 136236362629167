const dummyPlans = [
  {
    name: "Bronze",
    options: {
      "1 Month": 1000,
      "3 Months": 3000,
      "6 Months": 6000,
      "9 Months": 9000,
      "12 Months": 12000,
    },
  },
  {
    name: "Silver",
    options: {
      "1 Month": 1000,
      "3 Months": 3000,
      "6 Months": 6000,
      "9 Months": 9000,
      "12 Months": 12000,
    },
  },
  {
    name: "Gold",
    options: {
      "1 Month": 1000,
      "3 Months": 3000,
      "6 Months": 6000,
      "9 Months": 9000,
      "12 Months": 12000,
    },
  },
  {
    name: "Gold Plus",
    options: {
      "1 Month": 1000,
      "3 Months": 3000,
      "6 Months": 6000,
      "9 Months": 9000,
      "12 Months": 12000,
    },
  },
  {
    name: "Diamond",
    options: {
      "1 Month": 1000,
      "3 Months": 3000,
      "6 Months": 6000,
      "9 Months": 9000,
      "12 Months": 12000,
    },
  },
  {
    name: "Diamond Plus",
    options: {
      "1 Month": 1000,
      "3 Months": 3000,
      "6 Months": 6000,
      "9 Months": 9000,
      "12 Months": 12000,
    },
  },
];

import { Controller, useForm } from "react-hook-form";

const MembershipPlans: React.FC = () => {
  const { control, handleSubmit } = useForm();

  const onSubmit = async (data: any) => {
    console.log(data);
  };

  //console.log(watch());

  return (
    <>
      <div className="py-2 px-6">
        <h1 className="text-4xl text-center">Membership Plans</h1>
        <div className="my-4 flex flex-col gap-y-6">
          {/* Static Plans */}
          <div className="bg-white rounded-lg shadow-md p-6">
    <h2 className="text-2xl font-semibold text-gray-800 mb-4">Current Plans</h2>
    <ul className="text-center">
      <li className="grid grid-cols-6 font-semibold bg-gray-200 mb-2 rounded-lg shadow">
        <p className="border border-black p-2">Plan Name</p>
        <p className="border border-black p-2">1 Month</p>
        <p className="border border-black p-2">3 Months</p>
        <p className="border border-black p-2">6 Months</p>
        <p className="border border-black p-2">9 Months</p>
        <p className="border border-black p-2">12 Months</p>
      </li>
      {dummyPlans.map((plan) => (
        <li className="grid grid-cols-6 items-center mb-2" key={plan.name}>
          <p className="border border-black p-2 font-medium">{plan.name}</p>
          <p className="border border-black p-2">{plan.options["1 Month"]}</p>
          <p className="border border-black p-2">{plan.options["3 Months"]}</p>
          <p className="border border-black p-2">{plan.options["6 Months"]}</p>
          <p className="border border-black p-2">{plan.options["9 Months"]}</p>
          <p className="border border-black p-2">{plan.options["12 Months"]}</p>
        </li>
      ))}
    </ul>
  </div>

          
          {/* Revise Plans */}
          <form onSubmit={handleSubmit(onSubmit)}>
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">Revise Plans</h2>
            <ul className="text-center">
              <li className="grid grid-cols-6 font-semibold bg-gray-200 mb-2 rounded-lg shadow">
                <p className="border border-black p-2">Plan Name</p>
                <p className="border border-black p-2">1 Month</p>
                <p className="border border-black p-2">3 Months</p>
                <p className="border border-black p-2">6 Months</p>
                <p className="border border-black p-2">9 Months</p>
                <p className="border border-black p-2">12 Months</p>
              </li>
              {dummyPlans.map((plan) => (
                <li className="grid grid-cols-6 items-center mb-2" key={plan.name}>
                  <p className="border border-black p-2 bg-gray-100 font-medium">{plan.name}</p>
                  {Object.entries(plan.options).map(([option, price]) => (
                    <CellDiv key={plan.name + option}>
                      <Controller
                        name={
                          plan.name.toLowerCase().replace(" ", "") +
                          option.toLowerCase().replace(" ", "")
                        }
                        defaultValue={price.toString()}
                        control={control}
                        render={({ field }) => (
                          <>
                            <input
                              type="text"
                              {...field}
                              className="border border-black text-center p-1 rounded focus:outline-none focus:border-blue-500"
                            />
                            <p className="flex justify-center items-center text-sm text-gray-600">
                              {((price - parseInt(field.value)) / price * 100).toFixed(1)}%
                            </p>
                          </>
                        )}
                      />
                    </CellDiv>
                  ))}
                </li>
              ))}
            </ul>
            <div className="w-full flex justify-center items-center mt-6">
              <button
                type="submit"
                className="bg-blue-500 text-white font-semibold py-2 px-4 rounded-lg border border-black hover:bg-blue-600 transition duration-200"
              >
                Submit
              </button>
            </div>
          </form>

        </div>
      </div>
    </>
  );
};

export default MembershipPlans;

const CellDiv: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  return (
    <>
      <div className="border border-black p-2 grid grid-cols-2">{children}</div>
    </>
  );
};
