import { cn } from "../utils/utils";
import React from "react";
import { motion } from "framer-motion";

export const BackgroundGradient = ({
  children,
  className,
  containerClassName,
  planName,
  animate = true,
}: {
  children?: React.ReactNode;
  className?: string;
  containerClassName?: string;
  planName: string;
  animate?: boolean;
}) => {
  const variants = {
    initial: {
      backgroundPosition: "0 50%",
    },
    animate: {
      backgroundPosition: ["0, 50%", "100% 50%", "0 50%"],
    },
  };

  const planClassNames: Record<string, { outer: string; inner: string }> = {
    Gold: {
      outer: cn(
        "absolute inset-0 rounded-3xl z-[1] opacity-0 group-hover:opacity-100 blur-xl  transition duration-500 will-change-transform",
        "bg-[radial-gradient(circle_farthest-side_at_0_100%,#ffa600,transparent),radial-gradient(circle_farthest-side_at_100%_0,#ffa600,transparent),radial-gradient(circle_farthest-side_at_100%_100%,#ffa600,transparent),radial-gradient(circle_farthest-side_at_0_0,#ffa600,#ffa600)]"
      ),
      inner: cn(
        "absolute inset-0 rounded-3xl z-[1] will-change-transform",
        "bg-[radial-gradient(circle_farthest-side_at_0_100%,#ffe600,transparent),radial-gradient(circle_farthest-side_at_100%_0,#ffe600,transparent),radial-gradient(circle_farthest-side_at_100%_100%,#ffa600,transparent),radial-gradient(circle_farthest-side_at_0_0,#ffa600,#ffe600)]"
      ),
    },
    "Gold Plus": {
      outer: cn(
        "absolute inset-0 rounded-3xl z-[1] opacity-0 group-hover:opacity-100 blur-xl  transition duration-500 will-change-transform",
        "bg-[radial-gradient(circle_farthest-side_at_0_100%,#ff0055,transparent),radial-gradient(circle_farthest-side_at_100%_0,#ffa600,transparent),radial-gradient(circle_farthest-side_at_100%_100%,#ff0055,transparent),radial-gradient(circle_farthest-side_at_0_0,#ffa600,#ff0055)]"
      ),
      inner: cn(
        "absolute inset-0 rounded-3xl z-[1] will-change-transform",
        "bg-[radial-gradient(circle_farthest-side_at_0_100%,#ffe600,transparent),radial-gradient(circle_farthest-side_at_100%_0,#ff0055,transparent),radial-gradient(circle_farthest-side_at_100%_100%,#ffa600,transparent),radial-gradient(circle_farthest-side_at_0_0,#ffa600,#ffe600)]"
      ),
    },
    Silver: {
      outer: cn(
        "absolute inset-0 rounded-3xl z-[1] opacity-0 group-hover:opacity-100 blur-xl  transition duration-500 will-change-transform",
        "bg-[radial-gradient(circle_farthest-side_at_0_100%,#a3a3a3,transparent),radial-gradient(circle_farthest-side_at_100%_0,#a3a3a3,transparent),radial-gradient(circle_farthest-side_at_100%_100%,#a3a3a3,transparent),radial-gradient(circle_farthest-side_at_0_0,#a3a3a3,#a3a3a3)]"
      ),
      inner: cn(
        "absolute inset-0 rounded-3xl z-[1] will-change-transform",
        "bg-[radial-gradient(circle_farthest-side_at_0_100%,#a3a3a3,transparent),radial-gradient(circle_farthest-side_at_100%_0,#a3a3a3,transparent),radial-gradient(circle_farthest-side_at_100%_100%,#d4d4d4,transparent),radial-gradient(circle_farthest-side_at_0_0,#d4d4d4,#a3a3a3)]"
      ),
    },
    Bronze: {
      outer: cn(
        "absolute inset-0 rounded-3xl z-[1] opacity-0 group-hover:opacity-100 blur-xl  transition duration-500 will-change-transform",
        "bg-[radial-gradient(circle_farthest-side_at_0_100%,#e35802,transparent),radial-gradient(circle_farthest-side_at_100%_0,#e35802,transparent),radial-gradient(circle_farthest-side_at_100%_100%,#e35802,transparent),radial-gradient(circle_farthest-side_at_0_0,#e35802,#e35802)]"
      ),
      inner: cn(
        "absolute inset-0 rounded-3xl z-[1] will-change-transform",
        "bg-[radial-gradient(circle_farthest-side_at_0_100%,#ff6200,transparent),radial-gradient(circle_farthest-side_at_100%_0,#ff6200,transparent),radial-gradient(circle_farthest-side_at_100%_100%,#632701,transparent),radial-gradient(circle_farthest-side_at_0_0,#632701,#ff6200)]"
      ),
    },
    Diamond: {
      outer: cn(
        "absolute inset-0 rounded-3xl z-[1] opacity-0 group-hover:opacity-100 blur-xl  transition duration-500 will-change-transform",
        "bg-[radial-gradient(circle_farthest-side_at_0_100%,#7b61ff,transparent),radial-gradient(circle_farthest-side_at_100%_0,#7b61ff,transparent),radial-gradient(circle_farthest-side_at_100%_100%,#7b61ff,transparent),radial-gradient(circle_farthest-side_at_0_0,#7b61ff,#7b61ff)]"
      ),
      inner: cn(
        "absolute inset-0 rounded-3xl z-[1] will-change-transform",
        "bg-[radial-gradient(circle_farthest-side_at_0_100%,#7b61ff,transparent),radial-gradient(circle_farthest-side_at_100%_0,#7b61ff,transparent),radial-gradient(circle_farthest-side_at_100%_100%,#7b61ff,transparent),radial-gradient(circle_farthest-side_at_0_0,#7b61ff,#7b61ff)]"
      ),
    },
    "Diamond Plus": {
      outer: cn(
        "absolute inset-0 rounded-3xl z-[1] opacity-0 group-hover:opacity-100 blur-xl  transition duration-500 will-change-transform",
        "bg-[radial-gradient(circle_farthest-side_at_0_100%,#00ccb1,transparent),radial-gradient(circle_farthest-side_at_100%_0,#7b61ff,transparent),radial-gradient(circle_farthest-side_at_100%_100%,#ffc414,transparent),radial-gradient(circle_farthest-side_at_0_0,#1ca0fb,#141316)]"
      ),
      inner: cn(
        "absolute inset-0 rounded-3xl z-[1] will-change-transform",
        "bg-[radial-gradient(circle_farthest-side_at_0_100%,#00ccb1,transparent),radial-gradient(circle_farthest-side_at_100%_0,#7b61ff,transparent),radial-gradient(circle_farthest-side_at_100%_100%,#ffc414,transparent),radial-gradient(circle_farthest-side_at_0_0,#1ca0fb,#141316)]"
      ),
    },
  };

  return (
    <div className={cn("relative p-[4px] group", containerClassName)}>
      {/* For bleeding lights */}
      <motion.div
        variants={animate ? variants : undefined}
        initial={animate ? "initial" : undefined}
        animate={animate ? "animate" : undefined}
        transition={
          animate
            ? {
                duration: 5,
                repeat: Infinity,
                repeatType: "reverse",
              }
            : undefined
        }
        style={{
          backgroundSize: animate ? "10% 10%" : undefined,
        }}
        // Change opacity for bleeding

        className={planClassNames[planName]?.outer}
      />
      <motion.div
        variants={animate ? variants : undefined}
        initial={animate ? "initial" : undefined}
        animate={animate ? "animate" : undefined}
        transition={
          animate
            ? {
                duration: 5,
                repeat: Infinity,
                repeatType: "reverse",
              }
            : undefined
        }
        style={{
          backgroundSize: animate ? "300% 300%" : undefined,
        }}
        className={planClassNames[planName]?.inner}
      />

      <div className={cn("relative z-10", className)}>{children}</div>
    </div>
  );
};
