const TermsOfUse = () => {
  return (
    <div className="overflow-y-auto h-[100vh] p-4 md:p-6 lg:p-8 bg-white rounded shadow-lg">
      <h2 className="text-2xl font-bold mb-4 text-center">Terms and Conditions</h2>
      <p className="mb-4 text-justify">
        Welcome to Triune Matrimony, a matrimonial platform exclusively for the Christian community, provided by us, Triune Techmentors LLP, LLPIN: ACC-5349, a limited liability partnership incorporated and existing under the provisions of Limited Liability Partnership Act, 2008 and having its registered office at #79, 4th Cross, Rajanna Layout Bangalore North, Karnataka, India – 560043 (“Triune” or the “Company”). Our platform serves as a resource for members of the Christian community in their pursuit of meaningful matrimonial alliances (“Triune Matrimony Services”).
      </p>

      <p className="mb-4 text-justify">
        This document is published in accordance with the provisions of Rule 3 (1) of the Information Technology (Intermediaries Guidelines and Digital Media Ethics Code) Rules, 2021, which mandates the publication of terms and conditions, privacy policy and guidelines for accessing or using https://www.triunematrimony.com/ or the related application (the "Website"/ “App”).
      </p>

      <p className="mb-4 text-justify">
        In these Terms and Conditions, "Member", “You” or “User” refer to the individuals accessing the Website/App, its contents or using the Triune Matrimony Services, including any family members or friends (such as father, mother, brother, sister, or relative) registering on behalf of a prospective bride or groom.
      </p>

      <p className="mb-4 text-justify">
        By accessing, browsing, registering, or using Triune Matrimony Services, you irrevocably confirm that you have read, understood, and agree to these Terms and Conditions, which forms a clickwrap agreement (“Terms and Conditions”). By clicking "I agree to the T&C," you are entering into a legally binding contract with Company. PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING OUR WEBSITE / APP OR SUBSCRIBING TO TRIUNE MATRIMONY SERVICES. IF YOU DO NOT AGREE TO THESE TERMS, PLEASE DO NOT USE OR REGISTER ON THE WEBSITE/APP.
      </p>

      <h3 className="font-bold text-lg mt-4">A) Scope:</h3>
      <p className="mb-4 text-justify">
        The Company operates as an "Intermediary" as defined under clause (1), sub-clause (w) of Section 2 of the Information Technology Act, 2000. Triune Matrimony is a brand owned by the Company, which holds all rights and ownership over the Triune Matrimony’s Website and App, and Triune Matrimony Services. Triune Matrimony serves as a platform that allows users to register themselves by filling in the required (and optional) fields and voluntarily search for profiles within its database of registered users, for the purpose of seeking lawful matrimonial alliances. To register, you must have a valid mobile phone number and a working email address to use our Triune Matrimony Services. The profiles in the Triune database are broadly categorized based on language and region for the ease and convenience of members. You may also shortlist profiles of interest based on these preferences. While registration to the Website/App itself is free and includes limited access to search profiles, members must subscribe to a paid membership plan and pay the associated membership fees to connect with prospects and avail premium features of the Website/App. Additionally, Triune also offers certain supplementary services such as background verification services, pre and post marriage counselling services, and marriage registration (“Partnered Services”), through third-party vendors/service providers (“Third-Party Vendors”), to its subscribed Members, as detailed below under clause E.
      </p>

      <h3 className="font-bold text-lg">B) Eligibility:</h3>
      <ul className="list-disc pl-6 mb-4">
        <li>Membership is reserved for Indian Nationals & Citizens, Persons of Indian Origin (PIO), Non-Resident Indians (NRI), Persons of Indian Descent or Indian Heritage, and Individuals intending to marry someone of Indian Origin.</li>
        <li>You must be at least 18 years old, if female; or 21 years old, if male.</li>
        <li>You are a member of the Christian community.</li>
        <li>If you are awaiting divorce, you may register by indicating your status.</li>
        <li>If residing outside India, you must be legally competent to marry as per your local laws and comply with Indian laws if marrying a person of Indian Origin.</li>
        <li>If you are registering on behalf of a prospective bride or groom, your use of the Website/App implies that you have obtained their consent for profile creation and access to the Triune Matrimony Services.</li>
      </ul>

      <h3 className="font-bold text-lg">C) Registration:</h3>
      <ul className="list-disc pl-6 mb-4">
        <li>
          Registration on our Website/App is free and provides you with access to limited features. To access premium features, you must subscribe to our Triune Matrimony Subscription Services, as per clause E below.
        </li>
        <li>
          We expect you to complete the online registration process with fairness and honesty by providing true, accurate, current, and complete information, along with recent photos that meet your parameters. Please read the relevant sections carefully before entering details, selecting options, or uploading photos. Do not enter profile details in fields that do not apply (including mentioning IDs from other platforms or repeating information in multiple fields). If Triune requires additional information to serve you better, you agree to provide it. Additionally, you may fill in your email ID and phone number in any of our registration campaigns appearing on various websites as part of our advertisements, based on which you may receive calls from our Customer Service Center for assistance.
        </li>
        <li>
          If at any time Triune becomes aware, through third-party information or for any other reason, that any information provided by you for registration (including photos) is untrue, inaccurate, or incomplete, Triune reserves the right to suspend or terminate your membership without notice. Any amounts paid toward the membership fee shall be forfeited, and Triune may refuse to provide Triune Matrimony Services to you thereafter.
        </li>
        <li>
          Triune also reserves the right to block the registration of your profile on the Website/App if your contact details or links are entered in irrelevant fields or if there are errors in the data provided.
        </li>
        <li>
          Registration of duplicate profiles for the same individual is prohibited on the Website/App. Triune reserves the right to suspend or terminate any duplicate profiles without notice.
        </li>
        <li>
          You acknowledge and confirm that your registration with Triune and the use of its Triune Matrimony Services are undertaken with the bona fide intention of marriage and not for any other purpose. Triune membership is strictly limited to individual members registered with Triune. Organizations, companies, or individuals engaged in similar or competitive businesses are prohibited from becoming members of Triune Matrimony or using Triune Matrimony Services for any commercial purposes. Triune reserves the right to initiate appropriate legal action for any breach of these obligations.
        </li>
        <li>
          By registering, you also agree to receive communication from Triune, including promotional offers, unless you choose to opt out.
        </li>
      </ul>


      <h3 className="font-bold text-lg">D) Account Security:</h3>
      <ul className="list-disc pl-6 mb-4">
        <li>
          You are responsible for safeguarding the confidentiality of your login credentials, including your user ID, password, and any One-Time Passwords (OTPs).
        </li>
        <li>
          To prevent unauthorized access to your account, please restrict access to your computer or mobile device. We, as a Company, will never ask for your password, and you are cautioned against sharing it with anyone.
        </li>
        <li>
          You agree to accept responsibility for all activities that occur under your account. If you suspect any unauthorized access or use of your account, or if you become aware of any security breaches, you agree to notify us immediately.
        </li>
        <li>
          Additionally, ensure that you log out of your account at the end of each session.
        </li>
      </ul>


      <h3 className="font-bold text-lg">E) Triune Matrimony Subscription Services:</h3>
      <ul className="list-disc pl-6 mb-4">
        <li>
          <strong>a. Membership:</strong> A membership plan enables Users to connect with potential partners and unlock a variety of enhanced functionalities aimed at improving your matchmaking experience. To access these premium features of the Website/App, Users must select a paid membership plan. Details regarding membership plans and fees are available on the Website/App.
        </li>
        <li>
          <strong>b. Partnered Services:</strong> Triune offers a few supplementary matrimonial services, through Third-Party Vendors, including:
          <ul className="list-disc pl-6 mb-4">
            <li>
              <strong>i) Background Verification:</strong> A comprehensive check of both yours and your potential partner's background, covering identification, professional, educational, and personal details. The verification process varies based on the selected plan (i.e. Essential, Advanced, or Elite) and includes address verification, employment history, credit score checks, and court record assessments (criminal, civil, and family court records). Upon completion, the background check report will be shared directly with the respective User via email or through their account on the Website/App.
            </li>
            <li>
              <strong>ii) Pre-/Post-Marriage Consultation/Counselling:</strong> Expert guidance and consultation services to help you navigate both pre-marriage and post-marriage phases, offering support in spiritual and medical areas.
            </li>
            <li>
              <strong>iii) Marriage Registration:</strong> Assistance with the legal process of marriage registration to ensure compliance with applicable legal requirements. Triune Matrimony does not store any certificates or documents on the Website/App; only personal information necessary for account management and service delivery is stored. Users can choose whichever subscription plan best suits their needs to access the Partnered Services. Details regarding subscription plans and fees for these Partnered Services will be made available to Users during the login process and on the Website/App.
            </li>
          </ul>
        </li>
        <li>
          <strong>c. Mode of Payment:</strong> Any payments hereunder shall be made via UPI, cash, cheque, net banking, debit/credit card, PayPal, RTGS, or NEFT and it must comply with prevailing Reserve Bank of India (RBI) guidelines. Activation of paid Triune Matrimony Services will occur only after the payment has been successfully processed. In case of dishonor of a cheque or demand draft, you will be responsible for paying the applicable bank charges. Until the payment and bank charges are settled in cash, your Service will remain deactivated or suspended. Triune utilizes third-party payment gateways for processing payments via credit/debit cards or other payment methods. While we take reasonable care to ensure that these payment gateways are secure, we disclaim any responsibility or liability for any issues or damages arising from the use of these third-party services, including but not limited to security breaches, unauthorized transactions, or errors in processing payments. You are advised to review the terms and conditions, as well as the privacy policies, of these Third-Party Vendors before using their services. If you choose a paid package valued at Rs. 2,00,000/- (Rupees Two Lakhs) or more, you agree to provide your Permanent Account Number (PAN) or Form 60/61 as per the Income Tax Act, 1961, and the applicable rules.
        </li>
        <li>
          <strong>d. Renewal:</strong> All paid memberships will be automatically renewed before the expiry of the current term. By subscribing, you agree that your account is subject to this automatic renewal feature. Upon your initial subscription, you acknowledge that your paid membership will automatically renew upon expiration, for the same duration and at the last paid price. After the validity period of a package (which may vary depending on the package), it will automatically be renewed. If you do not wish to have your paid membership automatically renewed, you must cancel your subscription before the renewal date. To cancel, you may send an email to <a href="mailto:customersupport@triunematrimony.com" className="text-blue-600">customersupport@triunematrimony.com</a>.
        </li>
        <li>
          <strong>e. Cancellation, Refund and Assignment:</strong> You may cancel your subscription at any time, but cancellation will not entitle you to any refund. Payments made toward subscription services (whether membership, renewable, or auto-renewable) are non-refundable. Your membership shall be governed by the refund policy of Triune Matrimony as laid out in Refund Policy. Triune Matrimony membership cannot be assigned or transferred to any other person or entity. Any attempt to do so will violate the terms and conditions, allowing Triune Matrimony to take action as outlined in clause C (c). Payments made for Triune Matrimony Services cannot be assigned to others or adjusted toward other products or packages offered by the company.
        </li>
      </ul>


      <h3 className="font-bold text-lg">F) Role and Responsibility of BM:</h3>
      <p className="mb-4">
        Triune Matrimony reproduces your profile details as submitted during registration on our Website/App on an "as is" basis. Triune's role is to provide a platform that allows Members to independently search for potential matches without any assistance. The profile search results and matches displayed to any Members are automatically generated based on the partner preferences you set. If you change your partner preferences on the Website/App, the automated search results may also be updated accordingly. You have the freedom to accept or decline such interests.
      </p>
      <p className="mb-4">
        Triune Matrimony cannot guarantee specific outcomes from the use of its Triune Matrimony Services or any data available on the Website/App. Triune Matrimony is committed to safeguarding sensitive user information through robust security standards, authentication mechanisms, access controls, and encryption techniques. While the Company implements measures to protect user data, we cannot guarantee complete security during data transmission or prevent tampering by third parties with malicious intent before the data reaches our servers.
      </p>
      <p className="mb-4">
        Triune Matrimony does not authenticate or endorse the information provided in user profiles for non-subscribed Members. Therefore, it is the responsibility of users to verify the credentials and information shared by other members, unless they have opted for Triune Matrimony Subscription Services, for added assurance.
      </p>


      <h3 className="font-bold text-lg">G) Role and Responsibility of Member:</h3>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-2">
          You are responsible for safeguarding your profile information by creating a strong password during the registration process, using a combination of upper and lower-case letters, numbers, and symbols.
        </li>
        <li className="mb-2">
          Any information or data required by Triune for using Triune Matrimony Services must be provided by you as requested.
        </li>
        <li className="mb-2">
          You are urged to verify the credentials of potential matches and exercise due diligence regarding their profile information, including marital status, educational qualifications, financial status, occupation, character, and health. Triune Matrimony shall not be liable for any shortcomings due to misrepresentations made by its members.
        </li>
        <li className="mb-2">
          Triune Matrimony is not liable for any misbehaviour or misrepresentations made by a prospect during video calls.
        </li>
        <li className="mb-2">
          To receive optimal search results, Members should provide recent photographs that are no more than three months old. Submitting outdated photos or the photos of others, as well as inaccurate or false information, will be considered a violation of our terms and conditions, and Triune retains the right to take action as outlined in clause C (c).
        </li>
        <li className="mb-2">
          You must disclose any relevant health records during profile enrolment, including pre-existing illnesses or physical disabilities. Failure to disclose such information at the time of enrolment will be deemed a violation of Terms and Conditions, and Triune reserves the right to take appropriate action.
        </li>
        <li className="mb-2">
          You must refrain from:
          <ul className="list-disc pl-6">
            <li>Engaging in any financial transactions with prospects, including seeking or offering financial assistance.</li>
            <li>Using abusive language in communications with other members.</li>
            <li>Discriminating or making racially charged comments.</li>
            <li>Sharing confidential/personal information, including bank details.</li>
            <li>Initiating physical relationships with any prospects before marriage.</li>
            <li>Violating any applicable laws.</li>
            <li>Mentioning other matrimonial services while sending messages.</li>
          </ul>
        </li>
        <li className="mb-2">
          Exercise caution with prospects who ask for favours, request money, call from multiple phone numbers, insist on phone communication only, avoid face-to-face meetings (whether in-person or via video calls), and do not involve family or friends in matchmaking process. Be wary of suspended profiles before finalizing any alliance.
        </li>
        <li className="mb-2">
          To obtain effective search results on the Website/App, Members should regularly log into their profiles and send expressions of interest to prospects. As a free Triune member, you may send automated messages. Please note that the ability to send personalized messages to express interest or respond to prospects is a potential future feature associated with paid Triune Matrimony Service packages. The implementation of this feature is at Triune's discretion, and we do not guarantee its availability. Consequently, Triune shall not be held liable for any unavailability or changes regarding this feature.
        </li>
        <li className="mb-2">
          While using the Website/App, you agree to adhere to all terms and conditions pertaining to the use of Triune Matrimony Services, including the enhanced features provided under the Membership plan and the supplementary Partnered Services.
        </li>
        <li className="mb-2">
          If you fail to update any changes in your profile details on the Website/App, your membership may be suspended at Triune's discretion.
        </li>
        <li className="mb-2">
          Upon finalizing your marriage, you are required to delete your profile or notify Triune Matrimony for removal.
        </li>
        <li className="mb-2">
          You agree to use secure devices, software, and networks in a private setting to access Triune Matrimony Services.
        </li>
        <li className="mb-2">
          You shall not use any bots, including web robots, chatbots, or other automated processes/scripts, to interact with or respond on the Website/App.
        </li>
        <li className="mb-2">
          You are prohibited from probing, scanning, or testing the vulnerabilities of the Website/App or any connected network, nor may they breach the security measures instituted by Triune.
        </li>
        <li className="mb-2">
          All payments must be made directly to the Triune account and not to any Triune employee's account.
        </li>
        <li className="mb-2">
          You are expected to approach Triune for resolving complaints and grievances and are advised not to air grievances on social media.
        </li>
      </ul>


      <h3 className="font-bold text-lg">H) Medium of Communication to Triune Matrimony Members:</h3>
      <p className="mb-4">
        By registering, Members consent to receive communications, including promotional content, through various channels such as email, phone calls, SMS, and WhatsApp messages from Triune or affiliated portals. Triune utilizes an automated system to send members profiles of potential matches via images, documents, messages, or links.
      </p>
      <p className="mb-4">
        Members confirm that the mobile number provided for verification, or any alternative number, is not registered with the Do Not Disturb (DND) or National Customer Preference Register (NCPR), and they will not initiate any complaints in this regard. Furthermore, even if a person is registered with their telecom service provider under the DND or NCPR categories, calls from Triune to the verified mobile number or any alternative provided will not be considered promotional calls.
      </p>
      <p className="mb-4">
        Triune, at its discretion, may allow Members to have an option to set their preferred frequency for communications from Triune, such as only on weekends or bi-weekly. Triune, at its discretion, may also allow Members to choose to not receive calls from Triune by selecting the appropriate option on the Website/App. However, they agree to receive calls from the Relationship Manager of Triune Matrimony regarding their shortlisted profiles.
      </p>



      <h3 className="font-bold text-lg">I) Disputes between Members:</h3>
      <p className="mb-4">
        Notwithstanding anything mentioned herein, Members shall be solely responsible for their communications with other members or prospects, regardless of the medium used. Triune expressly disclaims any responsibility or liability for any monetary transactions, exchanges, interactions, or information sharing that occur between members via email, chat, WhatsApp, or any other communication method, whether on the Website/App or otherwise. 
      </p>
      <p className="mb-4">
        Triune has no obligation to monitor disputes that arise between members and shall not be a party to any such disputes or litigation. Triune does not act as an agent for any member and shall not participate in discussions or negotiations between members.
      </p>



      <h3 className="font-bold text-lg mt-6">J) Third Party Website:</h3>
      <p className="mb-4">
        Members understand and agree that Triune may allow third parties to display advertisements on the Triune Website and App. Triune does not control the content of these third-party advertisements. Links to third-party websites provided on the Triune platform are not governed by these Terms and Conditions, and each third-party website is subject to its own terms of use and privacy policy. In the event of any conflict, the terms of the third-party website shall prevail.
      </p>
      <p className="mb-4">
        The Company does not authenticate, endorse, verify, or certify these third-party websites or their content. Users access third-party links at their own risk and responsibility. Members acknowledge that Triune may facilitate third-party services on the Website/App, such as Partnered Services, through a Third-Party Vendors. 
      </p>
      <p className="mb-4">
        While we strive to offer accurate and reliable services, we do not guarantee the completeness or accuracy of the information and/or the quality of services provided by these Third-Party Vendors/other third-party service providers. Triune disclaims all liability for any errors, omissions, or delays that may occur in the provision of such third-party services. Furthermore, Triune is not liable for any issues arising from any such third-party services.
      </p>
      <p className="mb-4">
        Triune reserves the right to add, delete, alter, modify, or suspend any or all Triune Matrimony Services at any time, without prior notice. Members are encouraged to check the Website/App regularly for updates.
      </p>


      <h3 className="font-bold text-lg">K) Content Right:</h3>
      <p className="mb-4">
        You acknowledge that all content on the Website/App belongs to Triune, excluding your content or third-party content, including advertisements, for which Triune has the necessary licenses and rights to display on the Website/App. You are solely responsible for the content you provide on our platform, including profile details, photographs, and any other information entered during the creation of your Triune profile or subsequently modified. 
      </p>
      <p className="mb-4">
        This responsibility extends to all communications, including personalized messages sent by Triune paid members. You represent and warrant that you own all rights to your content or possess the necessary licenses and permissions to submit it. By providing this content, you grant Triune a worldwide, non-exclusive, royalty-free, sublicensable, and transferable license to use, reproduce, distribute, prepare derivative works of, display, publish, and communicate the content to the public, law enforcement, and courts, including for promotional and redistributive purposes across various media formats and channels.
      </p>
      <p className="mb-4">
        While Triune does not have an obligation to monitor your profile content, it reserves the right to delete any content that does not comply with these Terms and Conditions. Additionally, Triune may suspend or terminate your registration without refunding any subscription fees as outlined in Clause C (c). Triune may also impose restrictions on communication between members at its sole discretion. Triune retains the right to take appropriate legal action against any violations of its proprietary rights, including the illegal copying, modifying, publishing, transmitting, distributing, performing, displaying, or selling of its proprietary information for commercial purposes.
      </p>

      <h3 className="font-bold text-lg mt-6">L) Confidentiality:</h3>
      <p className="mb-4">
        Any feedback you provide to Triune will be considered non-confidential, and Triune is free to use such feedback or information without restriction. By submitting feedback, you represent and warrant that: (i) your feedback does not contain confidential or proprietary information belonging to you or any third parties; (ii) Triune has no obligation of confidentiality, either express or implied, concerning your feedback; and (iii) you are not entitled to any compensation or reimbursement from Triune for your feedback under any circumstances.
      </p>

      <h3 className="font-bold text-lg mt-6">M) Privacy of Membership:</h3>
      <p className="mb-4">
        To protect your privacy and inform you of our practices, please read and adhere to our Privacy Policy, which governs your visit to Triune’s Website and App. Any personal information or data you provide during your use of Triune will be treated as confidential and in accordance with our Privacy Policy, applicable laws, and regulations.
      </p>
      <p className="mb-4">
        Your personal information will be securely stored on Amazon Web Services (AWS) or other cloud service providers, as may be notified herein. We comply with relevant Indian data protection laws, including the Information Technology Act, 2000, its associated rules, and the Digital Personal Data Protection Act, 2023. By using this Website/App, you consent to the collection, storage, and processing of your personal data for the purposes outlined in our Privacy Policy. If you do not agree with the collection or use of your information as described, we kindly ask you to refrain from using the Website/App.
      </p>



      <h3 className="font-bold text-lg">N) Indemnity:</h3>
      <p className="mb-4">
        By using Triune Matrimony Services, you agree to defend, indemnify, and hold harmless the Company, its subsidiaries, affiliates, directors, officers, agents, and employees from any loss, damage, liability, claim, or demand, including reasonable attorney's fees, arising out of or related to: (i) your use or misuse of the Triune Matrimony Services; (ii) any content you submit, post, or transmit through the Website/App; (iii) your interactions with other members or third parties, whether in person or via any other medium; (iv) any breach of these terms and conditions, including but not limited to violation of any third-party rights.
      </p>

      <h3 className="font-bold text-lg mt-6">O) Limitation of liability:</h3>
      <p className="mb-4">
        Triune and its office bearers shall not be liable for any direct, indirect, special, exemplary, punitive, incidental, or consequential damages arising from the use of our Website, App, third-party websites, or Triune Matrimony Services. The exchange of profiles through Triune should not be construed as a matrimonial offer, recommendation, advice, or guarantee. Triune is not liable for any incorrect or improper matches due to unavailability of profiles from the Website/App, nor for financial transactions between members or with third parties. 
      </p>
      <p className="mb-4">
        Triune shall not be liable if members do not respond or reciprocate when approached for a matrimonial alliance. Additionally, Triune will not be responsible for any damages arising from your use of the Website/App, including issues related to Partnered Services or Third-Party Vendors’ errors or services.
      </p>

      <h3 className="font-bold text-lg mt-6">P) Disclaimer:</h3>
      <p className="mb-4">
        a. Your access to and use of Triune Matrimony Services and any content are at your own risk. The Triune Matrimony Services are provided on an "as is" and "as available" basis. To the fullest extent permitted by law, Triune disclaims all warranties, express or implied, including but not limited to warranties of merchantability, fitness for a particular purpose, and non-infringement. Triune does not guarantee that the Website/App, its servers, or any emails sent from Triune are free of viruses or harmful components.
      </p>
      <p className="mb-4">
        b. Triune does not provide any implied or explicit guarantees of marriage or alliance resulting from your registration on our Website/App or use of Triune Matrimony Services (both paid and free). You acknowledge that Triune is a platform for connecting individuals and does not endorse any resulting marriage, alliance, or engagement. This indemnification obligation will survive indefinitely.
      </p>
      <p className="mb-4">
        c. Triune shall not be responsible or liable for:
        <ul className="list-disc list-inside ml-4">
          <li>Any act or omission by payment gateways or alliance partners.</li>
          <li>Any false or incorrect information submitted by you or on your behalf.</li>
          <li>Any decisions based on information provided by other users, including the suspension or deletion of your profile.</li>
          <li>Any unauthorized or illegal actions taken by third parties in relation to your submitted information.</li>
          <li>Any cybercrime attempted or committed by anyone.</li>
          <li>Any incidents of force majeure or "acts of God."</li>
          <li>Issues already outlined in these terms and conditions, including limitations of liability.</li>
          <li>The use of Partnered Services provided by Third-Party Vendors.</li>
          <li>The outcome of interactions during meetings, calls, SMS, chats, emails, or social media posts.</li>
          <li>Technical malfunctions of telecommunication networks, software, hardware failures, network congestion, denial of service, or spamming.</li>
        </ul>
      </p>
      <p className="mb-4">
        d. Triune disclaims responsibility for the content and services provided by any third-party service providers, including Partnered Services.
      </p>
      <p className="mb-4">
        e. Triune is not liable for any damage to your equipment, including computers, handheld devices, or mobile phones, resulting from the installation or use of the Website/App.
      </p>



      <h3 className="font-bold text-lg">Q) Termination:</h3>
      <p className="mb-4">
        a. The Company reserves the right to terminate your membership/registration or suspend/delete your profile at its sole discretion, without prior notice, and take any action as outlined in clause C (c) of these Terms and Conditions, under the following circumstances:
      </p>
      <ul className="list-disc list-inside ml-4 mb-4">
        <li>If you seek physical or financial favors from other registered members;</li>
        <li>If you have provided false, misleading, or incomplete information on the Website/App;</li>
        <li>If you have engaged in any fraudulent, unlawful, or illegal activities through the Website/App;</li>
        <li>If you have violated any provisions of these Terms and Conditions;</li>
        <li>If you have misused another member's identity, likeness, email address, contact number, or other personally identifiable information, or created a profile through impersonation, fraud, or misrepresentation without the person's consent, including the unauthorized use of third-party photos or information.</li>
      </ul>
      <p className="mb-4">
        b. If the Company receives a complaint about your behavior and consequently suspends or deletes your profile in good faith, it will not be liable to you for any loss, costs, expenses, or other consequences arising from such actions.
      </p>
      <p className="mb-4">
        c. Upon termination or suspension of your membership, your access to the Website/App and related Triune Matrimony Services will cease immediately. All fees paid to the Company up to the date of termination will be forfeited. All provisions of these Terms and Conditions that by their nature should survive termination shall remain in effect, including but not limited to disclaimers and limitations of liability. Termination of your access to the Triune Matrimony Services does not relieve you of any obligations that arose prior to such termination.
      </p>

      <h3 className="font-bold text-lg mt-6">R) Grievance Officer:</h3>
      <p className="mb-4">
        If you encounter any violations by another user, including but not limited to obscene content, menacing behavior, grossly offensive material, harm to minors, copyright infringement, patent violations, or impersonation, please feel free to report your concerns in writing. You can email us with a digital signature at:
      </p>
      <p className="mb-4"><strong>Email:</strong> <a href="mailto:grievance@triunematrimony.com" className="text-blue-500">grievance@triunematrimony.com</a></p>
      <p className="mb-4">
        The Grievance Officer shall be available from 10 AM to 6 PM Indian Standard Time, Monday to Saturday, excluding Sundays and public holidays in India. The Grievance Officer has been appointed as per Rule 3(11) of The Information Technology (Intermediaries Guidelines) Rules, 2011. All complaints should be submitted in writing. The Grievance Officer will address complaints related to the following user violations:
      </p>
      <ul className="list-disc list-inside ml-4 mb-4">
        <li>Uploading, displaying, modifying, publishing, transmitting, storing, updating, or sharing any information on the Website/App that belongs to another person without the necessary rights.</li>
        <li>Sharing content that is obscene, pornographic, pedophilic, invasive of another's privacy, insulting or harassing based on gender, racially or ethnically objectionable, promoting money laundering or gambling, or inciting violence between different groups on the grounds of religion or caste.</li>
        <li>Content that is harmful to children.</li>
        <li>Infringing on any patent, trademark, copyright, or other proprietary rights.</li>
        <li>Misleading the recipient regarding the origin of the message or knowingly communicating misinformation or false information.</li>
        <li>Impersonating another person.</li>
        <li>Threatening the unity, integrity, defense, security, or sovereignty of India, friendly relations with foreign states, or public order, or inciting the commission of any cognizable offense, or obstructing the investigation of any offense.</li>
        <li>Containing software viruses or any other computer code, file, or program designed to interrupt, destroy, or limit the functionality of any computer resource.</li>
        <li>Violating any law currently in force.</li>
      </ul>



      <h3 className="font-bold text-lg">S) General:</h3>
      <p className="mb-4">
        a. In the event that you file a false complaint against another prospect on our Website/App, leading to the suspension or deletion of their profile based on your complaint, Triune reserves the right to take appropriate legal (civil or criminal) action against you. We may also claim any and all costs and expenses incurred as a result of such irresponsible, misrepresentative, or unlawful actions. Additionally, we reserve the right to suspend your profile and forfeit any amounts paid by you for Triune Matrimony Services in accordance with clause C (c) of these terms and conditions.
      </p>
      <p className="mb-4">
        b. Notwithstanding anything contained herein, Triune reserves the absolute right to delete or modify, in any manner it deems fit, any content in any profile listing on the Website/App. This action may be taken when it comes to Triune's attention that the content violates the laws of the time, including but not limited to obscenity, defamation, invasion of privacy, or any content that is harmful, abusive, threatening, hateful, or encourages illegal activity. We reserve the right to block, delete, or suspend profiles that may be offensive, illegal, or that violate the rights or threaten the safety of Triune members, employees, or office bearers. Furthermore, using the Website/App to communicate or disclose another member's identity across other matrimonial websites is prohibited.
      </p>
      <p className="mb-4">
        c. Upon the expiration of your paid membership, you will no longer have access to any unexpired balance of phone call credits or SMS counts. Additionally, access to contact information of already viewed Triune members will not be available unless you renew your membership within 30 days.
      </p>
      <p className="mb-4">
        d. Upon renewal of your membership, any unused phone calls or SMS balances from your previous membership period will be carried forward to your account, effective from the date of your previous membership’s expiration.
      </p>
      <p className="mb-4">
        e. We reserve the right to modify or update these Terms and Conditions at any time. Changes will be posted on the Website/App, and your continued use of the Website/App after such modifications signifies your acceptance of the revised terms. Triune may choose at its sole discretion to communicate any significant changes to you via email or through the Website/App.
      </p>

      <h3 className="font-bold text-lg mt-6">T) Applicable Law, Dispute Resolution, and Jurisdiction:</h3>
      <p className="mb-4">
        The registration on Website/App and all related Triune Matrimony Services are considered to have been entered into within the territorial jurisdiction of Bangalore, India. Members unconditionally agree that any disputes or differences arising shall be governed by the laws of India. Any dispute/claim arising out of these Terms and Conditions or Members’ use of Website/App and/or Triune Matrimony Services shall be solved by parties through mediation, and in case it is unable to reach any resolution, then parties agree that the courts of Bangalore, Karnataka, India shall have the exclusive jurisdiction to hear such dispute/claim. You acknowledge and agree that you will not initiate or participate in any class action or other class proceeding or class arbitration related to any dispute with Triune.
      </p>


      <p className="mb-4">
        In the event you come across any concerns in our Service, then please feel free to contact us at: 
        <span className="font-semibold">Email ID: customersupport@triunematrimony.com</span>.
      </p>
      <p className="mb-4">
        This document is an electronic record in terms of the Information Technology Act, 2000, and rules thereunder pertaining to electronic records as applicable and amended. It is generated electronically and does not require any physical or digital signatures.
      </p>









    </div>
  );
};

export default TermsOfUse;
