// src/TranslationService.ts
import axios from 'axios';

const translateText = async (text: string, targetLanguage: string): Promise<string> => {
  try {
    const response = await axios.post(
      `https://api.cognitive.microsofttranslator.com/translate?api-version=3.0&to=${targetLanguage}`,
      [{ Text: text }],
      {
        headers: {
          'Ocp-Apim-Subscription-Key': process.env.REACT_APP_AZURE_TRANSLATOR_KEY!,
          'Ocp-Apim-Subscription-Region': 'YOUR_REGION', // Example: "centralus"
          'Content-Type': 'application/json',
        },
      }
    );
    return response.data[0].translations[0].text || text;
  } catch (error) {
    console.error("Translation Error: ", error);
    return text;
  }
};

export default translateText;

