import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useUser } from './pages/UserContext';
const apiUrl = import.meta.env.VITE_API_URL;

interface PrivateAdminRouteProps {
  children: React.ReactNode;
}

const PrivateAdminRoute: React.FC<PrivateAdminRouteProps> = ({ children }) => {
  const { userType } = useUser();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/authenicateadmin/access`, {
          method: 'GET',
          credentials: 'include', // Include cookies in the request
        });
        const result = await response.json();
        setIsAuthenticated(result.authenticated);
      } catch (error) {
        console.error('Error checking authentication:', error);
        setIsAuthenticated(false);
      }
    };

    checkAuth();
  }, []);

  // Show a loading state while checking authentication
  if (isAuthenticated === null) {
    return <div>Loading...</div>;
  }

  // Redirect if not authenticated or user type is not 'user'
  if (!isAuthenticated || userType !==  'admin') {
    return <Navigate to="/" replace />;
  }

  

  return <>{children}</>;
};

export default PrivateAdminRoute;