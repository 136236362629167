import React, { useState } from "react";
import axios from "axios";
import { useAuthUser } from '../pages/AuthUserContext';
const apiUrl = import.meta.env.VITE_API_URL;



// Define the structure of the essential verification checklist
const essentialVerificationCategories = [
  {
    
    category: "Identity",
    checklists: [
      { name: "Aadhar Card / Pan Card / Passport", type: "checkbox",checkID: "AAC", }
    ],
  },
  {
    
    category: "Address Verification",
    checklists: [
      { name: "Physical address verification - Enquire about the family and the person with customized questionnaire", type: "checkbox",checkID: "PHA", }
    ],
  },
  {
    
    category: "Employment Verification",
    checklists: [
      { name: "Employment verification - With Company HR", type: "checkbox",checkID: "EM1", }
    ],
  },
  {
    
    category: "Education Verification",
    checklists: [
      { name: "Latest education", type: "checkbox" ,checkID: "ED1", }
    ],
  },
  {
    
    category: "Credit Score Verification",
    checklists: [
      { name: "Credit assessment - Individual", type: "checkbox",checkID: "CRD", }
    ],
  },
  {
    
    category: "Court Record Checks",
    checklists: [
      { name: "Criminal records verification", type: "checkbox",checkID: "CRC", },
      { name: "Civil records verification", type: "checkbox" ,checkID: "CCC", },
      { name: "Family court verification", type: "checkbox" ,checkID: "FCC",}
    ],
  },
];

// Add-On Verifications
const addOnVerifications = [
  {
    
    category: "Social Media Verification",
    checklists: [
      { name: "Facebook,Twitter,Instagram,Linkedin", type: "checkbox" ,checkID: "SMC",},
      
    ],
  },
];


const EssentialVerification: React.FC = () => {
  const [selectedVerifications, setSelectedVerifications] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [link, setLink] = useState<string | null>(null);
  const user = useAuthUser(); // Assuming this is always set
  const tmi = user.user;

  const handleChange = (category: string, checklist: string) => {
    setSelectedVerifications((prevState: any) => {
      const updatedCategory = prevState[category] || [];
      let newVerifications;
  
      if (updatedCategory.includes(checklist)) {
        newVerifications = {
          ...prevState,
          [category]: updatedCategory.filter((item: string) => item !== checklist),
        };
      } else {
        newVerifications = {
          ...prevState,
          [category]: [...updatedCategory, checklist],
        };
      }
  
      console.log('Category:', category);
      console.log('Checklist Item:', checklist);
      console.log('Updated Verifications:', newVerifications);
  
      return newVerifications;
    });
  };
  
  
  

  const renderChecklist = ( category: string, checklists: any[]) => {
    return (
      <div key={category} className="mb-6">
        <h3 className="font-semibold text-lg text-gray-800">{category}</h3>
        {checklists.map((checklist, index) => (
          <div key={index} className="mb-2">
            <input
                type={checklist.type}
                checked={selectedVerifications[category]?.includes(checklist.name) || false}
                onChange={() => handleChange(category, checklist.name)}
                className="mr-2 h-4 w-4 accent-orange-600 border-gray-300 rounded"
            />

            <label className="text-gray-700">{checklist.name}</label>
          </div>
        ))}
      </div>
    );
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    setError(null); // Reset error state
  
    // Prepare the payload
    const TMI = tmi; // Replace with the actual TMI value (you may get this from props or context)
    const requestBody = {
        identity: {
          aadharCardPanCardPassport: {
            checkID: "AAC",
            isSelected: selectedVerifications["Identity"]?.includes("Aadhar Card / Pan Card / Passport") || false,
          },
        },
        addressVerification: {
          physicalAddressVerification: {
            checkID: "PHA",
            isSelected: selectedVerifications["Address Verification"]?.includes(
              "Physical address verification - Enquire about the family and the person with customized questionnaire"
            ) || false,
          },
        },
        employmentVerification: {
          checkID: "EM1",
          isSelected: selectedVerifications["Employment Verification"]?.includes(
            "Employment verification - With Company HR"
          ) || false,
        },
        educationVerification: {
          checkID: "ED1",
          isSelected: selectedVerifications["Education Verification"]?.includes("Latest education") || false,
        },
        creditScoreVerification: {
          checkID: "CRD",
          isSelected: selectedVerifications["Credit Score Verification"]?.includes("Credit assessment - Individual") || false,
        },
        courtRecordVerification: {
          criminal: {
            checkID: "CRC",
            isSelected: selectedVerifications["Court Record Checks"]?.includes("Criminal records verification") || false,
          },
          civil: {
            checkID: "CCC",
            isSelected: selectedVerifications["Court Record Checks"]?.includes("Civil records verification") || false,
          },
          familyCourt: {
            checkID: "FCC",
            isSelected: selectedVerifications["Court Record Checks"]?.includes("Family court verification") || false,
          },
        },
        socialMediaVerification: {
          FacebookTwitterInstagramLinkedin: {
            checkID: "SMC",
            isSelected: selectedVerifications["Social Media Verification"]?.includes("Facebook,Twitter,Instagram,Linkedin") || false,
          },
          
        },
      };
      
      
      
  
    // Log the request body to the console
    console.log("Request Body:", requestBody);
  
    try {
      // Call the API
      const response = await axios.post(`${apiUrl}/api/essential-verification/${TMI}`, requestBody);
      setLink(response.data.link); // Set the link from the response
    } catch (error) {
      setError("Failed to submit verifications. Please try again.");
      console.error("Error submitting verifications:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-3xl mx-auto p-8 bg-white border-4 border-transparent rounded-lg relative">
      {/* Gradient Border */}
      <div className="absolute inset-0 rounded-lg border-4 border-gradient-to-r from-blue-500 to-purple-600"></div>

      <h2 className="text-2xl font-semibold text-center mb-6 text-gray-800 relative">Essential Verification</h2>

      <form onSubmit={handleSubmit}>
        <div className="flex justify-between relative z-10">
          {/* Essential Verification Categories on the Left */}
          <div className="w-1/2 pr-4">
            <h3 className="text-xl font-semibold mb-4 text-orange-600">Main Verification Categories</h3>
            {essentialVerificationCategories.map(category =>
              renderChecklist( category.category, category.checklists)
            )}
          </div>

          {/* Add-On Verification Categories on the Right */}
          <div className="w-1/2 pl-4">
            <h3 className="text-xl font-semibold mb-4 text-orange-600">Add-On Verifications</h3>
            {addOnVerifications.map(category =>
              renderChecklist( category.category, category.checklists)
            )}
            <div className="mt-4">
              <p className="text-gray-600">Please ensure your respective social profiles are unlocked; if locked, we can't perform the check, even if paid.</p>
            </div>
          </div>
        </div>

        {/* Submit Button */}
        <div className="mt-6 flex justify-center">
          <button
            type="submit"
            className={`px-4 py-2 bg-orange-600 text-white font-semibold rounded-lg shadow hover:bg-orange-700 transition duration-300 z-20 ${loading ? "opacity-50 cursor-not-allowed" : ""}`}
            disabled={loading} // Disable button when loading
          >
            {loading ? "Submitting..." : "Submit Verifications"}
          </button>
        </div>

        
      </form>
      {/* Display Link or Error Message */}
      {/* Display Link or Error Message */}
      {link && (
            <div className="mt-4 text-center relative z-50"> {/* Adjust z-index */}
              <a
                href={link}
                className="text-blue-600 hover:underline relative z-50"  /* Ensure link is on top */
              >
                Click here for your verification link
              </a>
            </div>
          )}
          {error && (
            <div className="mt-4 text-red-600 text-center">
              {error}
            </div>
      )}

      
    </div>
    
  );
};

export default EssentialVerification;

