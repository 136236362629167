import ad1 from "/images/ad1.jpg";

interface BlogPost {
  id: number;
  title: string;
  content: string;
  author: string;
  datePosted: string;
  imageUrl: string;
}

const blogPosts: BlogPost[] = [
  {
    id: 1,
    title: "Exploring the Beauty of Nature",
    content:
      "Nature has always been a source of inspiration and tranquility. In this post, we explore the beauty of natural landscapes, from lush forests to serene lakes.",
    author: "John Doe",
    datePosted: "August 25, 2024",
    imageUrl: ad1,
  },
  {
    id: 2,
    title: "The Future of Technology",
    content:
      "Technology is evolving at a rapid pace, shaping the future in ways we could never have imagined. In this post, we discuss the potential advancements and their impact on society.",
    author: "Jane Smith",
    datePosted: "August 26, 2024",
    imageUrl: ad1,
  },
  {
    id: 3,
    title: "Healthy Eating Habits",
    content:
      "Maintaining a balanced diet is essential for a healthy lifestyle. In this post, we provide tips and recipes for incorporating healthy eating habits into your daily routine.",
    author: "Emily Johnson",
    datePosted: "August 27, 2024",
    imageUrl: ad1,
  },
];

const Blogs: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-8 max-w-4xl">
      <h1 className="text-5xl font-bold text-center mb-12">Blog</h1>
      {blogPosts.map((post) => (
        <div key={post.id} className="mb-16">
          <img
            src={post.imageUrl}
            alt={post.title}
            className="w-full h-full rounded-lg mb-8 border-2 shadow-sm"
          />
          <h2 className="text-4xl font-semibold mb-4">{post.title}</h2>
          <p className="text-gray-500 mb-6">
            By <strong>{post.author}</strong> on {post.datePosted}
          </p>
          <p className="text-lg leading-relaxed mb-8">{post.content}</p>
          <hr className="border-t-2 border-gray-300" />
        </div>
      ))}
    </div>
  );
};

export default Blogs;
