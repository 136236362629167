import { Link } from "react-router-dom";
import PhotoViewer from "../../PhotoViewer";
const apiUrl = import.meta.env.VITE_API_URL;

import { useState, useEffect } from "react";

const DeleteApproval: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [deleteApprovals, setDeleteApprovals] = useState<any[]>([]); // Updated state to hold fetched data
  const pageSize = 5;
  const totalPages = Math.ceil(deleteApprovals.length / pageSize);
  const [approvals, setApprovals] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    // Fetch delete profile approvals from the backend
    const fetchDeleteApprovals = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/delete-profiles`); // Ensure the correct API endpoint
        if (response.ok) {
          const data = await response.json();
          setDeleteApprovals(data); // Set the fetched data to state
        } else {
          console.error('Failed to fetch delete profiles');
        }
      } catch (error) {
        console.error('Error fetching delete profiles:', error);
      }
    };

    fetchDeleteApprovals();
  }, []);

  const handleSelectChange = (TMI: string, status: string) => {
    setApprovals((prev) => ({
      ...prev,
      [TMI]: status,
    }));
  };

  const handleSubmit = () => {
    const result = Object.entries(approvals)
      .filter(([_, status]) => status)
      .map(([TMI, status]) => ({ TMI, status }));
    console.log(result);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <>
      <div className="p-6 max-w-7xl mx-auto bg-white shadow-lg rounded-lg">
        {/* Heading */}
        <div className="mb-8 flex items-center justify-between">
          <Link to="/admin/approvals/profileapproval" className="flex items-center">
            <button className="px-4 py-2 bg-gray-100 border border-gray-300 hover:bg-gray-200 transition rounded">
              BACK
            </button>
          </Link>
          <h1 className="text-3xl font-bold text-gray-800">Delete Account</h1>
        </div>

        {/* Table */}
        <div className="overflow-x-auto">
          <div className="grid grid-cols-9 gap-4 text-center font-semibold bg-gray-100 rounded-lg p-4">
            <p>SNo</p>
            <p>Profile ID</p>
            <p>Type</p>
            <p>Engagement Date</p>
            <p>Feedback</p>
            <p>Photo</p>
            <p>Request Time</p>
            <p>Action</p>
          </div>

          {/* Entries */}
          <ul>
            {deleteApprovals.length > 0 &&
              deleteApprovals
                .slice((currentPage - 1) * pageSize, currentPage * pageSize)
                .map((approval, index) => (
                  <li key={approval.TMI} className="grid grid-cols-9 gap-4 text-center py-4 border-b">
                    <div>{(currentPage - 1) * pageSize + index + 1}</div>
                    <div>{approval.TMI}</div>
                    <div>{approval.type}</div>
                    <div>{approval.triuneMatrimonyDetails?.engagementDate || approval.engagementDate || "N/A"}</div>
                    <div className="truncate">{approval.otherDetails?.feedback || approval.feedback || "N/A"}</div>
                    <div>{approval.triuneMatrimonyDetails?.photo.data ? <PhotoViewer photoView={approval.triuneMatrimonyDetails.photo.data} /> : "N/A"}</div>
                    <div>
                      <p>{approval.uploadDate}</p>
                      <p>{approval.uploadTime} Hrs</p>
                    </div>
                    <div>
                      <select
                        className="w-full px-2 py-1 border border-gray-300 rounded bg-gray-50"
                        value={approvals[approval.TMI] || ""}
                        onChange={(e) => handleSelectChange(approval.TMI, e.target.value)}
                      >
                        <option value="">Select</option>
                        <option value="approve">Approve</option>
                        <option value="reject">Reject</option>
                      </select>
                    </div>
                  </li>
                ))}
          </ul>
        </div>

        {/* Pagination */}
        <div className="flex justify-end mt-4 space-x-2">
          {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
            <button
              key={page}
              onClick={() => handlePageChange(page)}
              className={`px-4 py-2 border rounded ${
                page === currentPage ? "bg-gray-800 text-white" : "bg-white text-gray-800 border-gray-300"
              } hover:bg-gray-700 hover:text-white transition`}
            >
              {page}
            </button>
          ))}
        </div>

        {/* Action Buttons */}
        <div className="flex justify-center mt-8 space-x-6">
          <button
            className="px-6 py-3 bg-blue-600 text-white font-semibold rounded-lg hover:bg-blue-500 transition"
            onClick={handleSubmit}
          >
            SUBMIT
          </button>
          <Link to="/admin/approvals/photoapproval">
            <button className="px-6 py-3 bg-gray-600 text-white font-semibold rounded-lg hover:bg-gray-500 transition">
              BACK
            </button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default DeleteApproval;
