import React, {  useState } from "react";
import { useAuthUser } from "../pages/AuthUserContext";
const apiUrl = import.meta.env.VITE_API_URL;
import axios from "axios";

// Define the structure of the elite verification checklist
const eliteVerificationCategories = [
  {
    category: "Identity",
    checklists: [
      { name: "Aadhar Card / Pan Card / Passport", type: "checkbox", id: "AAC" },
    ],
  },
  {
    category: "Address Verification",
    checklists: [
      { name: "Physical address verification - Enquire about the family and the person with customized questionnaire", type: "checkbox", id: "PHA" },
    ],
  },
  {
    category: "Employment Verification",
    checklists: [
      { name: "Employment verification - With Company HR", type: "radio", id: "EM1" },
      { name: "Self-employment verification (Only physical address verification)", type: "radio", id: "SEC" },
    ],
  },
  {
    category: "Education Verification",
    checklists: [
      { name: "Latest education", type: "checkbox", id: "ED1" },
    ],
  },
  {
    category: "Credit Score Verification",
    checklists: [
      { name: "Credit assessment - Individual", type: "radio", id: "CRD" },
      { name: "Credit assessment - Business", type: "radio", id: "BCC" },
    ],
  },
  {
    category: "Court Record Checks",
    checklists: [
      { name: "Criminal records verification", type: "checkbox", id: "CRC" },
      { name: "Civil records verification", type: "checkbox", id: "CCC" },
      { name: "Family court", type: "checkbox", id: "FCC" },
      { name: "Family Court Check Comprehensive - multiple address location will be checked", type: "checkbox", id: "FCX" },
    ],
  },
  {
    category: "Social Media Verification",
    checklists: [
      { name: "Facebook/Twitter/Instagram/LinkedIn", type: "checkbox", id: "SMC" }
    ],
  },
];

// Add-On Verifications
const EliteVerification: React.FC = () => {
  const [selectedVerifications, setSelectedVerifications] = useState<Record<string, any>>({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [link, setLink] = useState<string | null>(null);
  const user = useAuthUser();
  const tmi = user?.user; // Make sure `user` exists
  const [employmentType, setEmploymentType] = useState<string | null>(null);
  const [creditType, setCreditType] = useState<string | null>(null);
  const [selfEmploymentChecked, setSelfEmploymentChecked] = useState<boolean>(false);

  const handleChange = (category: string, checklist: string, type?: string) => {
    const updatedCategory = selectedVerifications[category] || [];
  
    // Handle radio button inputs
    if (type === "radio") {
      switch (category) {
        case "Employment Verification":
          setEmploymentType(checklist);
          setSelfEmploymentChecked(checklist === "Self-employment verification (Only physical address verification)");
          break;
        case "Credit Score Verification":
          setCreditType(checklist);
          break;
        // Add more cases here if other categories have radio inputs
      }
      
      // For radio buttons, ensure only one selection is made
      setSelectedVerifications({
        ...selectedVerifications,
        [category]: [checklist],
      });
    } else {
      // Handle multi-select (e.g., checkboxes)
      if (updatedCategory.includes(checklist)) {
        setSelectedVerifications({
          ...selectedVerifications,
          [category]: updatedCategory.filter((item: string) => item !== checklist),
        });
      } else {
        setSelectedVerifications({
          ...selectedVerifications,
          [category]: [...updatedCategory, checklist],
        });
      }
    }
  };
  
  const renderChecklist = (category: string, checklists: any[]) => {
    return (
      <div key={category} className="mb-6">
        <h3 className="font-semibold text-lg text-gray-800">{category}</h3>
        {checklists.map((checklist, index) => (
          <div key={index} className="mb-2">
            <input
              type={checklist.type}
              checked={checklist.type === "radio" ? (category === "Employment Verification" ? employmentType === checklist.name : creditType === checklist.name) : selectedVerifications[category]?.includes(checklist.name) || (checklist.name === "Self-employment verification (Only physical address verification)" && selfEmploymentChecked)}
              onChange={() => handleChange(category, checklist.name, checklist.type)}
              className="mr-2 h-4 w-4 accent-orange-600 border-gray-300 rounded"
            />
            <label className="text-gray-700">{checklist.name}</label>
          </div>
        ))}
        {/* Conditional rendering for self-employment checkboxes only under Employment Verification */}
        {category === "Employment Verification" && employmentType === "Self-employment verification (Only physical address verification)" && (
          <div className="ml-6 mt-4">
            <h4 className="font-semibold text-md text-gray-800">Additional Self-Employment Checks</h4>
            <div className="mb-2">
              <input
                type="checkbox"
                checked={selfEmploymentChecked}
                onChange={() => {
                  setSelfEmploymentChecked(!selfEmploymentChecked);
                  handleChange("SEC", "Self-employment verification (Only physical address verification)");
                }}
                className="mr-2 h-4 w-4 accent-orange-600 border-gray-300 rounded"
              />
              <label className="text-gray-700">Self-employment verification (Only physical address verification)</label>
            </div>
            <div className="mb-2">
              <input
                type="checkbox"
                checked={selectedVerifications["EV"]?.includes("Company verification in Ministry of Corporate Affairs (MCA) website & Physical address verification")}
                onChange={() => handleChange("EV", "Company verification in Ministry of Corporate Affairs (MCA) website & Physical address verification")}
                className="mr-2 h-4 w-4 accent-orange-600 border-gray-300 rounded"
              />
              <label className="text-gray-700">Company verification in Ministry of Corporate Affairs (MCA) website & Physical address verification</label>
            </div>
            <div className="mb-2">
              <input
                type="checkbox"
                checked={selectedVerifications["MRC"]?.includes("Reference check – Customized questionnaire (optional)")}
                onChange={() => handleChange("MRC", "Reference check – Customized questionnaire (optional)")}
                className="mr-2 h-4 w-4 accent-orange-600 border-gray-300 rounded"
              />
              <label className="text-gray-700">Reference check – Customized questionnaire (optional)</label>
            </div>
          </div>
        )}
      </div>
    );
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    setError(null);

    const requestBody = {
      identity: {
        aadharCardPanCardPassport: {
          checkID: "AAC",
          isSelected: selectedVerifications["Identity"]?.includes("Aadhar Card / Pan Card / Passport") || false,
        },
      },
      addressVerification: {
        physicalAddressVerification: {
          checkID: "PHA",
          isSelected:
            selectedVerifications["Address Verification"]?.includes(
              "Physical address verification - Enquire about the family and the person with customized questionnaire"
            ) || false,
        },
      },
      employmentVerification: {
        employmentVerificationHR: {
          checkID: "EM1",
          isSelected: selectedVerifications["Employment Verification"] === "Employment verification - With Company HR" || false,
        },
        selfEmploymentVerification: {
          
          
          additionalChecks: {
            // This part handles the additional self-employment checks
            selfEmployment: {
              checkID: "SEC",
              isSelected:
                selectedVerifications["Employment Verification"]?.includes(
                  "Self-employment verification (Only physical address verification)"
                ) || false,
            },
            companyVerificationMCA: {
              checkID: "EV",
              isSelected:
                selectedVerifications["EV"]?.includes(
                  "Company verification in Ministry of Corporate Affairs (MCA) website & Physical address verification"
                ) || false,
            },
            referenceCheck: {
              checkID: "MRC",
              isSelected:
                selectedVerifications["MRC"]?.includes("Reference check – Customized questionnaire (optional)") || false,
            },
          },
        },
      },
      educationVerification: {
        latestEducation: {
          checkID: "ED1",
          isSelected: selectedVerifications["Education Verification"]?.includes("Latest education") || false,
        },
      },
      creditScoreVerification: {
        individualCreditScore: {
          checkID: "CRD",
          isSelected: creditType === "Credit assessment - Individual" || false, // Use creditType directly
        },
        businessCreditScore: {
          checkID: "BCC",
          isSelected: creditType === "Credit assessment - Business" || false, // Use creditType directly
        },
      },
      courtRecordVerification: {
        criminal: {
          checkID: "CRC",
          isSelected: selectedVerifications["Court Record Checks"]?.includes("Criminal records verification") || false,
        },
        civil: {
          checkID: "CCC",
          isSelected: selectedVerifications["Court Record Checks"]?.includes("Civil records verification") || false,
        },
        familyCourt: {
          checkID: "FCC",
          isSelected: selectedVerifications["Court Record Checks"]?.includes("Family court") || false,
        },
        familyCourtWithMultiAddress: {
          checkID: "FCX",
          isSelected: selectedVerifications["Court Record Checks"]?.includes(
            "Family Court Check Comprehensive - multiple address location will be checked"
          ) || false,
        },

      },
      socialMediaVerification: {
        FacebookTwitterInstagramLinkedin: {
          checkID: "SMC",
          isSelected: selectedVerifications["Social Media Verification"]?.includes("Facebook/Twitter/Instagram/LinkedIn") || false,
        },
      },
      
    };
    console.log(requestBody)

    try {
      const response = await axios.post(`${apiUrl}/api/elite-verification/${tmi}`, requestBody);
      setLink(response.data.link);
    } catch (error) {
      setError("Failed to submit verifications. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-3xl mx-auto p-8 bg-white shadow-lg rounded-lg">
      <h2 className="text-2xl font-semibold text-center mb-6 text-gray-800">Elite Verification</h2>

      <form onSubmit={handleSubmit}>
        {/* Elite Verification Categories */}
        <div className="mb-6">
          <h3 className="text-xl font-semibold mb-4 text-orange-600">Main Verification Categories</h3>
          {eliteVerificationCategories.map(category => renderChecklist(category.category, category.checklists))}
          <div className="mt-4">
            <p className="text-gray-600">Please ensure your respective social profiles are unlocked; if locked, we can't perform the check, even if paid.</p>
          </div>
        </div>

        {/* Submit Button */}
        <div className="mt-6 flex justify-center">
          <button
            type="submit"
            className="px-4 py-2 bg-orange-600 text-white font-semibold rounded-lg shadow hover:bg-orange-700 transition duration-300"
          >
            {loading ? "Submitting..." : "Submit Verifications"}
          </button>
        </div>
      </form>
      {error && <p className="text-red-500 text-center mt-4">{error}</p>}
      {link && (
        <div className="mt-6 text-center">
          <p className="text-green-500 font-semibold">Success! Your verifications have been submitted.</p>
          <a href={link} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
            View Verification Results
          </a>
        </div>
      )}
    </div>
  );
};

export default EliteVerification;
