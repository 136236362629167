import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
// import Filters from "../components/Filters";
import { Link } from "react-router-dom";
import axios from 'axios';
import { useDispatch } from "react-redux";
import { actions as userActions } from "../features/users/usersSlice";
import { useUser } from './UserContext';
import { useCallback } from 'react';
import { useAuthUser } from './AuthUserContext';
const apiUrl = import.meta.env.VITE_API_URL;
// Badges
import socialCheckGrey from "/svgs/socialCheckGrey.svg";
import socialCheckGreen from "/svgs/socialCheckGreen.svg";
import familyCheckGrey from "/svgs/familyCheckGrey.svg";
import familyCheckGreen from "/svgs/familyCheckGreen.svg";
import employmentCheckGrey from "/svgs/employmentCheckGrey.svg";
import employmentCheckGreen from "/svgs/employmentCheckGreen.svg";
import educationCheckGrey from "/svgs/educationCheckGrey.svg";
import educationCheckGreen from "/svgs/educationCheckGreen.svg";
import criminalCheckGrey from "/svgs/criminalCheckGrey.svg";
import criminalCheckGreen from "/svgs/criminalCheckGreen.svg";
import creditCheckGrey from "/svgs/creditCheckGrey.svg";
import creditCheckGreen from "/svgs/creditCheckGreen.svg";
import { BackgroundGradient } from "../components/BackgroundGradient";
import useScreenType from "../hooks/useScreenType";

export interface User {
  TMI: string;
  _id: string;
  firstname: string;
  lastname: string;
  photo: string; // Base64 encoded image
  emailid: string;
  createdFor?: string;
  phoneNumber?: string;
  motherTongue?: string;
  denomination?: string;
  Education?: string;
  Occupation?: string;
  Salary?: string;
  DateofBirth?: Date;
  age?: number;
  Height?: string;
  complexion?: string;
  FamilyType?: string;
  FamilyStatus?: string;
  ResidenceType?: string;
  ResidenceState?: string;
  ResidenceCity?: string;
  ResidenceLocality?: string;
  isVerified: boolean;
  membership?:string;
  maritalStatus?:string;
}



const ProfileListing: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuthUser(); // Get current user's data
  const loggedinuser=user;
  const dispatch = useDispatch();
  const location = useLocation();
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const screenType = useScreenType();
  const { setUserType } = useUser();
  console.log(loading)
  
  

    //Define fetchUsers with useCallback
    const fetchUsers = useCallback(async () => {
      try {
        const response = await axios.post<User[]>(`${apiUrl}/api/users`, 
          { TMI: user }, // Pass the TMI in the request body
          { withCredentials: true } // Maintain credentials if necessary
        );
        console.log(user.user)
        setUsers(response.data);
        console.log("Fetched users data:", response.data); // Log the full user data array
        
      } catch (error) {
        console.error('Error fetching users:', error);
      } finally {
        setLoading(false);
      }
    }, [user.user || user]); // Include TMI (user.user) in the dependency array
    

  const fetchViewedProfiles = useCallback(async (tmi: string) => {
    try {
      const response = await axios.get<User[]>(`${apiUrl}/api/viewed-profiles/profile/${tmi}`, { withCredentials: true });
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching viewed profiles:', error);
    }finally {
      setLoading(false);
    }
  }, []);

  const fetchShortlistedProfiles = useCallback(async (tmi: string) => {
    try {
      const response = await axios.get<User[]>(`${apiUrl}/api/shortlisted-profiles/${tmi}`);
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching shortlisted profiles:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchFeaturedProfiles = useCallback(async (tmi: string) => {
    try {
      const response = await axios.get<User[]>(`${apiUrl}/api/users/featured-profiles/${tmi}`);
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching shortlisted profiles:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchSuggestedProfiles = useCallback(async (tmi: string) => {
    try {
      const response = await axios.get<User[]>(`${apiUrl}/api/users/suggested-profiles/${tmi}`);
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching shortlisted profiles:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchPremiumProfiles = useCallback(async (tmi: string) => {
    try {
      const response = await axios.get<User[]>(`${apiUrl}/api/users/premium-members/${tmi}`);
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching shortlisted profiles:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchInterestsShown = useCallback(async (tmi: string) => {
   
    try {
      const response = await axios.get<User[]>(`${apiUrl}/api/interests-shown/${tmi}`, { withCredentials: true });
      const data =  response.data
      console.log('Interests shown by me:', data);
      setUsers(response.data);
      console.log(data);
    } catch (error) {
      console.error('Error fetching expressed interests:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchExpressedInterests = useCallback(async (tmi: string) => {
    try {
      const response = await axios.get<User[]>(`${apiUrl}/api/expressed-interest/${tmi}`, { withCredentials: true });
      const data =  response.data
      setUsers(response.data);
      console.log(data);
    } catch (error) {
      console.error('Error fetching expressed interests:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchMobileNumberViewedByMe = useCallback(async (tmi: string) => {
    try {
      const response = await axios.get<User[]>(`${apiUrl}/api/viewed-mobile/${tmi}`, { withCredentials: true });
      const data =  response.data
      setUsers(response.data);
      console.log(data);
    } catch (error) {
      console.error('Error fetching expressed interests:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchMyMobileNumberViewers = useCallback(async (tmi: string) => {
    try {
      const response = await axios.get<User[]>(`${apiUrl}/api/viewed-my-mobile/${tmi}`, { withCredentials: true });
      const data =  response.data
      setUsers(response.data);
      console.log(data);
    } catch (error) {
      console.error('Error fetching expressed interests:', error);
    } finally {
      setLoading(false);
    }
  }, []);



  const handleProfileClick = async (profileTMI: string) => {
    if (!user) return; // Ensure user is available

    try {
      // Use `user` directly if it represents the current user's TMI
      await axios.post(`${apiUrl}/api/viewed-profiles/profile/${profileTMI}/view`, {
        viewerTMI: user
      }, { withCredentials: true });
      console.log(`Successfully recorded view for profile ${profileTMI}`);


    } catch (error) {
      console.error('Error recording profile view:', error);
    }
  };


  useEffect(() => {
    setUserType('user');
  
    // Extract TMI from query parameters
    const queryParams = new URLSearchParams(location.search);
    const tmi = queryParams.get('tmi');
  
    // Check if location.state exists and has user data
    if (location.state?.user) {
      setLoading(true);
      // If location.state has a user, set users to an array with this user
      setUsers([location.state.user]);
      
    } else if (location.state?.filteredUsers) {
      setLoading(true);
      // If location.state has filteredUsers, set users to this array
      setUsers(location.state.filteredUsers);
      
    } else if (location.pathname.includes('/profilelisting/viewed') && tmi) {
      setLoading(true);
      // If a TMI is present in the query parameters, fetch profiles who viewed the current user's profile
      fetchViewedProfiles(tmi);
      
    } else if (location.pathname.includes('/profilelisting/interestsshown') && tmi) {
      console.log("executed");
      setLoading(true);
      // If on the interests page, fetch interests shown by me
      fetchInterestsShown(tmi);
      
    } else if (location.pathname.includes('/profilelisting/interests') && tmi) {
      console.log("executed");
      setLoading(true);
      // If on the interests page, fetch expressed interests
      fetchExpressedInterests(tmi);
      
    } else if (location.pathname.includes('/profilelisting/shortlisted') && tmi) {
      console.log("executed");
      setLoading(true);
      // If on the interests page, fetch expressed interests
      fetchShortlistedProfiles(tmi);
      
    } else if (location.pathname.includes('/profilelisting/featured') && tmi) {
      console.log("featured-executed");
      setLoading(true);
      // If on the featured page, fetch featured profiles
      fetchFeaturedProfiles(tmi);
      
    } else if (location.pathname.includes('/profilelisting/suggested') && tmi) {
      console.log("featured-executed");
      setLoading(true);
      // If on the featured page, fetch featured profiles
      fetchSuggestedProfiles(tmi);
      
    }
    else if (location.pathname.includes('/profilelisting/premium') && tmi) {
      console.log("featured-executed");
      setLoading(true);
      // If on the featured page, fetch featured profiles
      fetchPremiumProfiles(tmi);
      
    }
    else if (location.pathname.includes('/profilelisting/mobilenumberviewedbyme') && tmi) {
      console.log("MobileNumberViewedByMe executed");
      setLoading(true);
      // If on the featured page, fetch featured profiles
      fetchMobileNumberViewedByMe(tmi);   
    }

    else if (location.pathname.includes('/profilelisting/mymobilenumberviewers') && tmi) {
      console.log("MyMobileNumberViewers executed");
      setLoading(true);
      // If on the featured page, fetch featured profiles
      fetchMyMobileNumberViewers(tmi);
    }

    else {
      setLoading(true);
      // If no specific conditions are met, fetch all users
      fetchUsers();
    }
  }, [
    location.state,
    location.search,
    location.pathname,
    setUserType,
    fetchUsers,
    fetchViewedProfiles,
    fetchExpressedInterests,
    fetchInterestsShown,
    fetchShortlistedProfiles,
    fetchFeaturedProfiles
  ]);
  

  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [showDetailsIndex, setShowDetailsIndex] = useState<number | null>(null);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  const handleMouseEnter = (index: number) => {
    setHoveredIndex(index);
    const timeout = setTimeout(() => setShowDetailsIndex(index), 500);
    setTimeoutId(timeout);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
    setShowDetailsIndex(null);
    if (timeoutId) clearTimeout(timeoutId);
  };

  return (
<div className="flex min-h-screen">
      {/* Ad space */}
      {(screenType === "desktop" || screenType === "laptop") &&  (
        <div className="w-[20%] border-r-2">
          <div>
            <div className="text-5xl mt-16 text-center">
              <p className="font-extrabold">30%</p>
              <p>OFF</p>
            </div>
            <div className="mt-8 px-4">
              <p>
                Your company's ad can be here! Contact us NOW to get 30% off.
                Limited time offer.{" "}
              </p>
            </div>
          </div>
          <div className="bg-red-500 mt-6 h-[18rem] mx-auto">AdSpace 3.1</div>
          <div className="bg-green-500 h-[18rem] mt-6 mx-auto">AdSpace 3.2</div>
          <div className="bg-blue-500 h-[18rem] mt-6 mx-auto">AdSpace 3.3</div>
        </div>
      )}

      {/* Profile Listings */}
      <div className="laptop:w-[85%] flex flex-col">
        {/* Filters */}
        {/* <Filters className="z-20 max-w-[100%] grid border-b-2 shadow-sm bg-gray-50 px-4 py-1" /> */}
        <div className="flex flex-col items-center justify-center">
              <p className="text-lg font-semibold text-gray-700 mb-4">
                For a customized search, please click here
              </p>
              <button
                type="button"
                className="flex justify-center items-center w-[100px] h-[40px] bg-blue-500 hover:bg-blue-600 text-white font-medium rounded-md shadow-md transition duration-300 ease-in-out transform hover:scale-105"
                onClick={() => navigate('/userdashboard/search/')}
              >
                Search
              </button>
      </div>




        
        {/* Profiles */}
        
{users.length === 0 ? (
  <p className="text-center text-xl font-semibold mt-8">Loading......</p>
) : (
  <ul className="grid sm:grid-cols-2 desktop:grid-cols-3 gap-8 p-4">
    {users
      .filter(user => user.TMI !== loggedinuser) // Filter out the logged-in user
      .map((user, index) => (
        <Link
          to={`/profilelisting/profile/${user.TMI}`} // Adjust to match your route setup
          key={user.TMI}
          onClick={() => {
            dispatch(userActions.setSelectedUserId(user.TMI));
            handleProfileClick(user.TMI); // Call the API when profile is clicked
          }}
        >
          <BackgroundGradient
            className="p-1"
            key={user._id}
            planName={user.membership || 'Free'}
          >
            <li
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
              className="flex flex-col rounded-3xl bg-gray-50 border-2 px-4 pb-4 cursor-pointer"
            >
              {/* Badges */}
              <div className="flex gap-x-4 justify-center py-4 text-2xl">
  {/* Badges Section */}
  <Badge
    isVerified={user.isVerified}
    imageGreen={familyCheckGreen}
    imageGrey={familyCheckGrey}
    
  />
  <Badge
    isVerified={user.isVerified}
    imageGreen={educationCheckGreen}
    imageGrey={educationCheckGrey}
    
  />
  <Badge
    isVerified={user.isVerified}
    imageGreen={employmentCheckGreen}
    imageGrey={employmentCheckGrey}
    
  />
  <Badge
    isVerified={user.isVerified}
    imageGreen={socialCheckGreen}
    imageGrey={socialCheckGrey}
    
  />
  <Badge
    isVerified={user.isVerified}
    imageGreen={creditCheckGreen}
    imageGrey={creditCheckGrey}
    
  />
  <Badge
    isVerified={user.isVerified}
    imageGreen={criminalCheckGreen}
    imageGrey={criminalCheckGrey}
    
  />
              </div>

              {/* User Image and Basic Info Section */}
              <div className="flex flex-col items-center">
  {hoveredIndex !== index || showDetailsIndex !== index ? (
    <div className="min-h-max text-center transition-all duration-300 ease-in-out">
      {/* User Photo */}
      <div className="flex justify-center mb-4">
        <img
          src={`${user.photo}`}
          alt="User Photo"
          className="w-32 h-32 object-cover rounded-full shadow-lg transition-transform hover:scale-110 duration-300"
        />
      </div>
      
      {/* User Name */}
      <h4 className="text-2xl font-semibold py-3 text-gray-800">
        {user.firstname} {user.lastname}
      </h4>

      {/* User Basic Details */}
      <div className="grid text-lg px-4 gap-x-5 gap-y-2 grid-cols-2 items-center text-left">
        <p><span className="font-semibold">TMI ID:</span> {user.TMI}</p>
        <p><span className="font-semibold">Height:</span> {user.Height}</p>
        <p><span className="font-semibold">Education:</span> {user.Education}</p>
        <p><span className="font-semibold">Age:</span> {user.age}</p>
        <p><span className="font-semibold">Occupation:</span> {user.Occupation}</p>
        <p><span className="font-semibold">Mother Tongue:</span> {user.motherTongue}</p>
        <p><span className="font-semibold">Complexion:</span> {user.complexion}</p>
      </div>
    </div>
  ) : (
    <div className="flex flex-col min-h-max transition-all duration-300 ease-in-out bg-gradient-to-r from-accent to-accentSec rounded-lg shadow-lg p-6">
      {/* Basic Details Section */}
      <h4 className="text-lg font-bold text-white border-b-2 pb-2 mb-4">BASIC DETAILS</h4>
      <div className="grid text-lg gap-x-5 gap-y-2 grid-cols-2 text-left text-white">
        <p><span className="font-semibold">Name:</span> {user.firstname}</p>
        <p><span className="font-semibold">DOB:</span> {user.DateofBirth?.toString().slice(0, 10)}</p>
        <p><span className="font-semibold">Height:</span> {user.Height}</p>
        <p><span className="font-semibold">Education:</span> {user.Education}</p>
        <p><span className="font-semibold">Occupation:</span> {user.Occupation}</p>
        <p><span className="font-semibold">Mother Tongue:</span> {user.motherTongue}</p>
        <p><span className="font-semibold">Complexion:</span> {user.complexion}</p>
        <p><span className="font-semibold">Marital Status:</span> {user.maritalStatus}</p>
      </div>

      {/* Additional Details Section */}
      <hr className="my-4 border-white" />
      <h4 className="text-lg font-bold text-white">ADDITIONAL DETAILS</h4>
      <div className="grid text-lg gap-x-5 py-1 gap-y-1 grid-cols-2 text-left text-white">
        <p><span className="font-semibold">Annual Income:</span> {user.Salary}</p>
        <p><span className="font-semibold">Denomination:</span> {user.denomination}</p>
        <p><span className="font-semibold">Family Type:</span> {user.FamilyType}</p>
        <p><span className="font-semibold">Family Status:</span> {user.FamilyStatus}</p>
        <p><span className="font-semibold">Residence Type:</span> {user.ResidenceType}</p>
        <p><span className="font-semibold">Residence City:</span> {user.ResidenceCity}</p>
      </div>

      {/* CTA */}
      <hr className="my-4 border-white" />
      <h4 className="text-lg font-semibold text-white text-center">
        Click to view the complete profile
      </h4>
    </div>
  )}
</div>


            </li>
          </BackgroundGradient>
        </Link>
      ))}
  </ul>
)}

      </div>
    </div>
  );
};

const Badge: React.FC<{
  imageGrey: string;
  imageGreen: string;
  isVerified: boolean;
}> = ({ imageGrey, imageGreen, isVerified }) => {
  return (
    <div className="">
      {isVerified ? (
        <img
          src={imageGreen}
          alt="Verified Badge"
          className="h-8 laptop:h-10"
        />
      ) : (
        <img
          src={imageGrey}
          alt="Unverified Badge"
          className="h-8 laptop:h-10"
        />
      )}
    </div>
  );
};

export default ProfileListing;

