import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar, Pie, Doughnut } from 'react-chartjs-2';
import axios from 'axios';

ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, Title, Tooltip, Legend);

const apiUrl = import.meta.env.VITE_API_URL;

interface UsageData {
  totalCoupons: number;
  sentCoupons: number;
  usedCoupons: number;
  unusedCoupons: number;
}

interface DistributionData {
  _id: number;
  count: number;
}

interface PlanData {
  _id: string;
  count: number;
}

const CouponAnalysis: React.FC = () => {
  const [usageData, setUsageData] = useState<UsageData | null>(null);
  const [discountData, setDiscountData] = useState<DistributionData[]>([]);
  const [planData, setPlanData] = useState<PlanData[]>([]);

  useEffect(() => {
    fetchUsageData();
    fetchDiscountData();
    fetchPlanData();
  }, []);

  const fetchUsageData = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/coupons/usage`);
      setUsageData(response.data);
    } catch (error) {
      console.error('Error fetching usage data:', error);
    }
  };

  const fetchDiscountData = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/coupons/discounts`);
      setDiscountData(response.data);
    } catch (error) {
      console.error('Error fetching discount data:', error);
    }
  };

  const fetchPlanData = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/coupons/plans`);
      setPlanData(response.data);
    } catch (error) {
      console.error('Error fetching plan data:', error);
    }
  };

  return (
    <>
      <h1 className="text-3xl font-semibold mb-6 text-center">Coupon Data Analysis</h1>
      <div className="p-8 bg-gray-100 min-h-screen grid grid-cols-1 sm:grid-cols-2 gap-8 items-center justify-items-center">
        
        {/* Usage Data Chart */}
        {usageData && (
          <div className="max-w-xl mb-8 p-4">
            <h2 className="text-lg font-semibold mb-4">Coupon Usage Distribution</h2>
            <Pie
              data={{
                labels: ['Coupons Sent to User', 'Used Coupons', 'Unutilized User Coupons'],
                datasets: [
                  {
                    label: 'Coupons',
                    data: [usageData.sentCoupons, usageData.usedCoupons, usageData.unusedCoupons],
                    backgroundColor: ['#34D399', '#60A5FA', '#F87171'],
                  },
                ],
              }}
            />
          </div>
        )}
  
        {/* Discount Data Chart */}
        <div className="max-w-xl mb-8 p-4">
          <h2 className="text-lg font-semibold mb-4">Discount Distribution</h2>
          <Bar
            data={{
              labels: discountData.map((data) => `${data._id} INR`),
              datasets: [
                {
                  label: 'Coupons',
                  data: discountData.map((data) => data.count),
                  backgroundColor: discountData.map((_, index) => 
                    index % 2 === 0 ? '#4C51BF' : '#10B981'  // Alternating colors
                  ),
                },
              ],
            }}
            options={{
              scales: {
                x: { title: { display: true, text: 'Discount Amount' } },
                y: {
                  title: { display: true, text: 'Number of Coupons' },
                  suggestedMin: 0,
                  ticks: {
                    stepSize: 1,
                  },
                },
              },
            }}
          />
        </div>
  
        {/* Plan Data Chart */}
        <div className="max-w-xl mb-8 p-4 col-span-full">
          <h2 className="text-lg font-semibold mb-4">Plan Distribution</h2>
          <Doughnut
            data={{
              labels: planData.map((data) => data._id),
              datasets: [
                {
                  label: 'Coupons per Plan',
                  data: planData.map((data) => data.count),
                  backgroundColor: ['#CD7F32', '#10B981', '#3B82F6', '#ffbf00', '#ff9f00', '#00ffff'],
                },
              ],
            }}
          />
        </div>
      </div>
    </>
  );
  
};

export default CouponAnalysis;
