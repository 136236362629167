import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useUser } from './pages/UserContext';
const apiUrl = import.meta.env.VITE_API_URL;
interface PrivateCustomerRouteProps {
  children: React.ReactNode;
}

const PrivateCustomerRoute: React.FC<PrivateCustomerRouteProps> = ({ children }) => {
  const { userType } = useUser();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/authenicateadmin/access`, {
          method: 'GET',
          credentials: 'include', // Include cookies in the request
        });

        // Check if the response is a valid JSON
        const contentType = response.headers.get('Content-Type');
        if (contentType && contentType.includes('application/json')) {
          const result = await response.json();
          setIsAuthenticated(result.authenticated);
        } else {
          console.error('Received non-JSON response');
          setIsAuthenticated(false); // Set to false in case of non-JSON
        }
      } catch (error) {
        console.error('Error checking authentication:', error);
        setIsAuthenticated(false); // Handle fetch error
      }
    };

    checkAuth();
  }, []);

  // Show a loading state while checking authentication
  if (isAuthenticated === null) {
    return <div>Loading...</div>;
  }

  // Redirect if not authenticated or user type is not 'executive'
  if (!isAuthenticated || userType !== 'customer') {
    return <Navigate to="/" replace />;
  }

  return <>{children}</>;
};

export default PrivateCustomerRoute;