import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
const apiUrl = import.meta.env.VITE_API_URL;
type RouteParams = {
  verificationToken: string;
};

const Verify: React.FC = () => {
  const { verificationToken } = useParams<RouteParams>();
  const [isVerified, setIsVerified] = useState<boolean | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const verifyUser = async () => {
      try {
        if (verificationToken) {
        const response = await axios.get(`${apiUrl}/api/auth/verify/${verificationToken}`);;
          console.log('Verification response:', response.data);
  
          if (response.data.success) {
            setIsVerified(true);
            alert('Your email has been successfully verified!');
            navigate('/'); // Redirect to the login page
          } else {
            setIsVerified(false);
            alert('Verification failed. Invalid or expired token.');
          }
        } else {
          setIsVerified(false);
          alert('No verification token found.');
        }
      } catch (error) {
        setIsVerified(false);
        console.error('Error during verification:', error);
        alert('An error occurred. Please try again later.');
      }
    };
  
    verifyUser();
  }, [verificationToken, navigate]);
  

  if (isVerified === null) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="loader"></div>
      </div>
    );
  }

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="text-center">
        {isVerified ? (
          <p>Your email has been verified successfully. Redirecting to login...</p>
        ) : (
          <p>Verification failed. Please check the link or contact support.</p>
        )}
      </div>
    </div>
  );
};

export default Verify;

