import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuthUser } from './AuthUserContext';
import axios from 'axios';
const apiUrl = import.meta.env.VITE_API_URL;

interface SendEmailFormProps {
  RecipientTMI: string;
  firstname: string;
  lastname: string;
  emailid: string;
}

const SendEmailForm: React.FC = () => {
  const { user } = useAuthUser(); // Get current user's data
  const location = useLocation();
  const { RecipientTMI, firstname, lastname, emailid } = location.state as SendEmailFormProps;

  const [emailData, setEmailData] = useState({
    recipient: `${firstname} ${lastname}`,
    subject: '',
    message: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setEmailData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      // Send email data to the backend for admin approval
      await axios.post(`${apiUrl}/api/send-email`, {
        TMI: user, // Current user sending the email
        recipientTMI: RecipientTMI, // Recipient's TMI
        recipientEmail: emailid, // Recipient's email address
        recipientName:firstname+lastname,
        subject: emailData.subject, // Email subject
        message: emailData.message, // Email message
        isApproved: false, // Admin approval status
        status: 'Pending', // Email status (Pending approval)
      });

      alert('Email sent for approval.');
      setEmailData({ recipient: `${firstname} ${lastname}`, subject: '', message: '' });
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Error sending email for approval.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="max-w-lg mx-auto p-6 bg-white shadow-md rounded-lg">
      <h2 className="text-2xl font-semibold mb-4 text-center">Send Email</h2>

      <label className="block mb-2 text-gray-700 font-medium">Recipient:</label>
      <input
        type="text"
        name="recipient"
        value={emailData.recipient}
        readOnly
        className="w-full mb-4 p-2 border rounded-md bg-gray-100 cursor-not-allowed focus:outline-none"
      />

      <label className="block mb-2 text-gray-700 font-medium">Subject:</label>
      <input
        type="text"
        name="subject"
        value={emailData.subject}
        onChange={handleInputChange}
        required
        placeholder="Enter email subject"
        className="w-full mb-4 p-2 border rounded-md focus:outline-none focus:border-blue-500"
      />

      <label className="block mb-2 text-gray-700 font-medium">Message:</label>
      <textarea
        name="message"
        value={emailData.message}
        onChange={handleInputChange}
        required
        placeholder="Type your message here"
        className="w-full mb-4 p-2 h-40 border rounded-md focus:outline-none focus:border-blue-500"
      ></textarea>

      <button
        type="submit"
        className="w-full py-2 px-4 bg-blue-600 text-white font-semibold rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
        disabled={isSubmitting}
      >
        {isSubmitting ? 'Submitting...' : 'Send Email'}
      </button>
    </form>
  );
};

export default SendEmailForm;
