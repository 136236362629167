import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuthUser } from '../../pages/AuthUserContext';
const apiUrl = import.meta.env.VITE_API_URL;

interface Email {
  subject: string;
  message: string;
  isApproved: boolean;
  status: string;
  createdAt: string;
}

interface Recipient {
  recipientTMI: string;
  recipientName: string;
  recipientEmail: string;
  emails: Email[];
}

const SentEmailList: React.FC = () => {
  const { user } = useAuthUser(); // Retrieve current user
  const TMI = user; // Assuming user has a TMI field

  const [recipients, setRecipients] = useState<Recipient[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [openIndex, setOpenIndex] = useState<number | null>(null); // Track which recipient's emails are visible

  useEffect(() => {
    if (!TMI) return;

    const fetchEmails = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/emails/${TMI}`);
        setRecipients(response.data);
      } catch (err) {
        console.error('Error fetching emails:', err);
        setError('Failed to load emails.');
      } finally {
        setLoading(false);
      }
    };

    fetchEmails();
  }, [TMI]);

  const toggleAccordion = (index: number) => {
    setOpenIndex(openIndex === index ? null : index); // Toggle open/close for each recipient
  };

  if (loading) {
    return <p className="text-center text-lg">Loading emails...</p>;
  }

  if (error) {
    return <p className="text-center text-red-500">{error}</p>;
  }

  return (
    <div className="max-w-3xl mx-auto p-6 bg-white shadow-md rounded-lg">
      <h2 className="text-2xl font-semibold mb-4 text-center">Sent Emails</h2>

      {recipients.length === 0 ? (
        <p className="text-center text-gray-600">No emails sent yet.</p>
      ) : (
        <div className="space-y-4">
          {recipients.map((recipient, index) => (
            <div key={index} className="border rounded-md p-4 bg-gray-50">
              <button
                onClick={() => toggleAccordion(index)}
                className="w-full text-left focus:outline-none font-medium text-gray-700"
              >
                <div className="flex justify-between items-center">
                  <p>Recipient: {recipient.recipientName}</p>
                  <span>{openIndex === index ? '-' : '+'}</span>
                </div>
                <p className="text-sm text-gray-500">TMI: {recipient.recipientTMI}</p>
              </button>

              {openIndex === index && (
                <div className="mt-4 space-y-4">
                  {recipient.emails.map((email, emailIndex) => (
                    <div key={emailIndex} className="border-t pt-4">
                      <p className="font-medium text-gray-700">Subject: {email.subject}</p>
                      <p className="text-gray-600 mt-2">{email.message}</p>
                      <p className="text-sm text-gray-500 mt-2">
                        Sent on: {new Date(email.createdAt).toLocaleDateString()} at {new Date(email.createdAt).toLocaleTimeString()}
                    </p>


                      <span
                        className={`inline-block mt-3 px-3 py-1 rounded-md text-sm font-semibold ${
                          email.status === 'Approved'
                            ? 'bg-green-100 text-green-700'
                            : email.status === 'Pending'
                            ? 'bg-yellow-100 text-yellow-700'
                            : 'bg-red-100 text-red-700'
                        }`}
                      >
                        Status: {email.status}
                      </span>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SentEmailList;
