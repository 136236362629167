import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
const apiUrl = import.meta.env.VITE_API_URL;

interface EmailConfirmationProps {
  email?: string;
}

const EmailConfirmation: React.FC<EmailConfirmationProps> = ({ email }) => {
  const navigate = useNavigate();
  const [resendStatus, setResendStatus] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const handleResendVerification = async () => {
    setIsLoading(true);
    setIsButtonDisabled(true); // Disable the button to prevent multiple clicks

    try {
      const response = await fetch(`${apiUrl}/api/auth/resend-verification`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ emailid: email }),
      });

      if (response.ok) {
        setResendStatus("Verification email resent successfully.");
      } else {
        setResendStatus("Failed to resend verification email.");
      }
    } catch (error) {
      console.error("Error resending verification email:", error);
      setResendStatus("An error occurred. Please try again.");
    } finally {
      setIsLoading(false);
      setIsButtonDisabled(false); // Enable the button again after the request finishes
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-b from-pink-500 via-purple-600 to-indigo-700 px-4 py-12">
      <div className="bg-white shadow-lg rounded-lg max-w-md w-full p-8 text-center">
        <h2 className="text-3xl font-bold text-gray-800 mb-4">
          Welcome to Triune Matrimony!
        </h2>
        <p className="text-lg text-gray-600">
          Please check your email inbox to activate your account. Confirm your
          email address by clicking the link we sent to:
        </p>
        <p className="text-lg font-semibold text-gray-800 mt-2 mb-6">
          {email ? email : "your email address"}
        </p>
        <p className="text-gray-600">
          By verifying your account, you’re just one step away from finding your
          perfect match and beginning a beautiful journey.
        </p>
        <p className="mt-6 text-gray-600">
          Didn’t receive the email?{" "}
          <button
            onClick={handleResendVerification}
            className="text-blue-600 font-medium hover:text-blue-800 transition duration-150"
            disabled={isButtonDisabled} // Disable button when loading
          >
            {isLoading ? "Sending..." : "Click here"} {/* Display loading text */}
          </button>{" "}
          to request a new one.
        </p>

        {/* Display resend status message */}
        {resendStatus && (
          <p className="mt-4 text-sm text-green-600">{resendStatus}</p>
        )}

        {/* Login Button */}
        <button
          onClick={() => navigate("/")}
          className="mt-6 bg-indigo-600 text-white px-4 py-2 rounded-md font-medium hover:bg-indigo-700 transition duration-150"
        >
          Go to Login
        </button>
      </div>
    </div>
  );
};

export default EmailConfirmation;
