const apiUrl = import.meta.env.VITE_API_URL;
import { useEffect, useState } from "react";
import axios from 'axios'; // Import axios for API calls



const AdminMembershipPlans: React.FC = () => {

  const [months, setMonths] = useState(1);
  const [plans, setPlans] = useState<any[]>([]); // State to hold fetched plans
  


  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/admin/memberships/plans?duration=${months}`);
        console.log("Fetched plans:", response.data); // Log the fetched data
        setPlans(response.data); // Set the plans with the fetched data
      } catch (error) {
        console.error("Error fetching plans:", error);
        setPlans([]); // Clear plans in case of an error
      }
    };

    fetchPlans(); // Call fetchPlans whenever months changes
  }, [months]);

  // Color mapping for membership plans
  const planColors: { [key: string]: string } = {
    'Bronze': '#E85306', // Darker Bronze color
    'Silver': '#AFA3A7', // Darker Silver color
    'Gold': '#FFCE05',   // Darker Gold color
    'Gold Plus': '#f9a008', // Darker Gold Plus color
    'Diamond': '#62cff4', // Darker Diamond color
    'Diamond Plus': '#004ff9' // Darker Diamond Plus color
};



  return (
    <div className="relative w-full bg-gradient-to-b from-gray-50 to-gray-100 flex flex-col rounded-lg p-8 shadow-lg">
      <h1 className="text-4xl font-bold text-center mb-6">
        Choose the plan that suits you best
      </h1>

      {/* Duration Selection */}
      <div className="flex justify-center gap-4 mb-6">
        {[1, 3, 6, 9, 12].map((month) => (
          <DurationButton
            key={month}
            onClick={() => setMonths(month)}
            className={months === month ? "bg-accent text-white" : "bg-white text-gray-700"}
            title={`${month} Month${month > 1 ? 's' : ''}`}
          />
        ))}
      </div>

      {/* Plans */}
      <div className={`flex flex-wrap justify-center gap-8`}>
        {plans.length > 0 ? (
          plans.map((plan: any, ) => { // Directly map over plans
            console.log("Plan:", plan); // Log each plan
            return (
              <PlanCard
                key={plan._id} // Use the unique ID for each plan
                bgColour={planColors[plan.name] || '#f0f0f0'} // Use mapped color or default
                planName={plan.name}
                price={plan.price}
                discount={plan.discount}
                gst={plan.gst}
                discountedPrice={plan.discountedPrice} // Use the discounted price field
                totalPriceOnDiscount={plan.totalPriceOnDiscount} // Use the total price after discount
                totalPriceOnGst={plan.totalPriceOnGst} // Use the GST amount
                totalPrice={plan.totalPrice} // Use the final total price
                featureList={Object.keys(plan.features).filter(feature => plan.features[feature])} // Extracting features dynamically
                
              />
            );
          })
        ) : (
          <div className="text-center text-lg text-gray-500">No plans available for this duration.</div>
        )}
      </div>
    </div>
  );
};

export default AdminMembershipPlans;

interface PlanCardProps {
    bgColour: string;
    planName: string;
    price: number;
    discount: number;
    gst: number;
    discountedPrice: number;
    totalPriceOnDiscount: number;
    totalPriceOnGst: number;
    totalPrice: number;
    featureList: string[];
  }
  
  const PlanCard: React.FC<PlanCardProps> = ({
    bgColour,
    planName,
    price,
    discount,
    gst,
    discountedPrice,
    totalPriceOnGst,
    totalPrice,
    featureList,
  }) => {
    return (
      <div className="flex flex-col bg-white rounded-2xl shadow-lg transition-transform transform hover:scale-105 cursor-pointer p-4 w-80 min-h-[500px]"> {/* Set minimum height */}
        <div style={{ backgroundColor: bgColour }} className="rounded-t-2xl p-4 text-center text-white text-2xl font-semibold">
          {planName}
        </div>
        <div className="p-4 border-b">
          <div className="flex justify-between mb-2">
            <div className="text-xl">₹{totalPrice.toFixed(2)}</div>
            <div className="text-xl text-red-500 font-bold">{discount}% OFF</div>
          </div>
          <div className="text-blue-500 text-sm">
            <span>Including {gst}% GST</span>
          </div>
        </div>
        <ul className="flex-grow py-2 text-lg mx-4 list-disc"> {/* Allow feature list to grow */}
          {featureList.map((feature) => (
            <li key={feature} className="flex items-center gap-x-2">
              <span className="text-green-500">✅</span>
              <span>{feature}</span>
            </li>
          ))}
        </ul>
        <div className="py-3 border-t flex flex-col gap-y-1 text-lg">
          <div className="flex justify-between">
            <span>MRP :</span>
            <span> ₹{price}</span>
          </div>
          <div className="flex justify-between text-green-700">
            <span>Discount({discount}%):</span>
            <span>- ₹{discountedPrice.toFixed(2)}</span>
          </div>
          <hr/>
          <div className="flex justify-between">
            <span>GST({gst}%):</span>
            <span>+ ₹{totalPriceOnGst.toFixed(2)}</span>
          </div>
          <div className="flex justify-between font-semibold text-lg border-t pt-1">
            <span>Total:</span>
            <span>₹{totalPrice.toFixed(2)}</span>
          </div>
        </div>
        <div className="flex justify-center mt-auto"> {/* Ensure the button is at the bottom */}
          <button className="btnMembership rounded-2xl text-xl font-semibold py-2 px-4 bg-accent text-white transition-colors hover:bg-accentSec">
            Pay Now
          </button>
        </div>
      </div>
    );
  };
  

const DurationButton: React.FC<{
  title: string;
  className: string;
  onClick: () => void;
}> = ({ title, onClick, className }) => {
  return (
    <button
      onClick={onClick}
      className={`${className} rounded-full border border-gray-300 shadow-md transition-colors duration-200 hover:bg-accent hover:text-white py-2 px-4`}
    >
      {title}
    </button>
  );
};