import { useState } from "react";

const PhotoViewer: React.FC<{ photoView: string | null }> = ({ photoView }) => {
  const [enlarge, setEnlarge] = useState(false);

  const handleEnlarge = () => {
    setEnlarge(true);
  };

  const handleShrink = () => {
    setEnlarge(false);
  };

  return (
    <div>
      {enlarge ? (
        <div
          className="cursor-pointer fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center"
          onClick={handleShrink}
        >
          {photoView ? (
            <img
              src={photoView}
              alt="Enlarged Photo"
              className="max-w-full max-h-full"
            />
          ) : (
            <p>No photo available</p>
          )}
        </div>
      ) : (
        <div
          className="cursor-pointer border flex place-content-center place-items-center border-black col-span-2 p-2 text-lg"
          onClick={handleEnlarge}
        >
          {photoView ? (
            <img src={photoView} alt="Thumbnail" className="w-20 h-20" />
          ) : (
            <p>No photo available</p>
          )}
        </div>
      )}
    </div>
  );
};

export default PhotoViewer;

