// src/TranslationContext.tsx
import React, { createContext, useState, useContext, ReactNode } from 'react';

interface TranslationContextProps {
  language: string;
  setLanguage: (language: string) => void;
}

const TranslationContext = createContext<TranslationContextProps | undefined>(undefined);

export const TranslationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [language, setLanguage] = useState<string>('en'); // Default language is English

  return (
    <TranslationContext.Provider value={{ language, setLanguage }}>
      {children}
    </TranslationContext.Provider>
  );
};

export const useTranslationContext = (): TranslationContextProps => {
  const context = useContext(TranslationContext);
  if (!context) throw new Error("useTranslationContext must be used within a TranslationProvider");
  return context;
};

