import { Routes, Route  } from "react-router-dom";
import PrivateRoute from './PrivateRoute';


//import CommunitySignup from "./components/CommunitySignup";
import Header from "./components/Header";
import Home from "./pages/Home";
import Footer from "./components/Footer";
import Privacy from "./pages/Privacy";
import CustomerSupport from "./pages/CustomerSupport";
import Feedback from "./pages/Feedback";
import Plans from "./pages/Plans";
import SuccessStories from "./pages/SuccessStories";
import About from "./pages/About";
import UserHome from "./pages/UserHome";
import News from "./pages/News";
import Events from "./pages/Events";
import Gallery from "./pages/Gallery";
import Advertising from "./pages/Advertising";
import Careers from "./pages/Careers";
import Blogs from "./pages/Blogs";
import TermsOfUse from "./pages/TermsOfUse";
import ReportMisuse from "./pages/ReportMisuse";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UserDashboard from "./pages/UserDashboard";
import { SearchSection } from "./components/UserDashboard/SearchSection";
import { UserActivity } from "./components/UserDashboard/UserActivity";
import { AccountSettings } from "./components/UserDashboard/AccountSettings";
import ProfileListing from "./pages/ProfileListing";
import Profile from "./pages/ProfileUpdate";
import ApprovalProfile from "./pages/ApprovalProfile";
import Test from "./pages/Test";
import BackgroundVerification from "./pages/BackgroundVerification";
import ValueAddServices from "./pages/ValueAddServices";
import VideoBgV from "./pages/VideoBgV";
import AdminApprovals from "./pages/AdminApprovals";
import ProfileApproval from "./components/AdminDashboard/AdminApprovals/ProfileApproval";
import DeleteApproval from "./components/AdminDashboard/AdminApprovals/DeleteApproval";
import MembershipPlans from "./pages/MembershipPlans";
import MembershipDiscounts from "./pages/MembershipDiscounts";
import AdminMembershipPlans from "./components/AdminDashboard/AdminMembership/MembershipPlans";
import EventsGallery from "./pages/EventsGallery";
import AdminAds from "./pages/AdminAds";
import NewEvent from "./components/AdminDashboard/EventsGallery/NewEvent";
import OldEvents from "./components/AdminDashboard/EventsGallery/OldEvents";
import NewGalleryPhoto from "./components/AdminDashboard/EventsGallery/NewGalleryPhoto";
import PlanFeatures from "./components/AdminDashboard/AdminMembership/PlanFeatures";
import OurAds from "./components/AdminDashboard/Ads/OurAds";
import MyAccount from "./components/UserDashboard/MyAccount";
import SignUp from "./pages/SignUp";
import { PhotoUpload } from "./components/UserDashboard/PhotoUpload";
import NewNews from "./components/AdminDashboard/EventsGallery/NewNews";
import NewBlog from "./components/AdminDashboard/EventsGallery/NewBlog";
import DiscountGenerator from "./components/AdminDashboard/AdminMembership/DiscountGenerator";
import Verify from "./pages/Verify";
import { AuthUserProvider } from './pages/AuthUserContext';
import Admin from "./pages/Admin";
import UserManagement from "./pages/UserManagement";
import PrivateRoleRoute from './PrivateRoleRoute';
import CurrentPlanView from './pages/AdminMembershipPlans';
import AdvancedVerification from "./pages/AdvancedVerification";
import EssentialVerification from "./pages/EssentialVerification";
import EliteVerification from "./pages/EliteVerification";
import BackgroundVerificationDashboard from "./pages/BackgroundVerificationDashboard";
import OrderStatus from "./pages/OrderStatus";
import AccountSummary from "./components/CustomerDashboard/AccountSummary"
import CouponList from "./components/CustomerDashboard/CouponList";
import { UserProvider, useUser } from './pages/UserContext'; // Adjust the path as necessary
import AvailableCoupons from "./components/AdminDashboard/AdminMembership/AvailableCoupons";
import CouponSent from "./components/AdminDashboard/AdminMembership/CouponSent";
import CouponAnalysis from "./components/AdminDashboard/AdminMembership/CouponAnalysis";
import CustomerSupportDashboard from "./components/CustomerDashboard/CustomerSupportDashboard";
import { TranslationProvider } from "./TranslationContext";
import RefundPolicy from "./pages/RefundPolicy";
import SendEmailForm from "./pages/SendEmailForm";
import SentEmailsList from "./components/UserDashboard/SentEmailList";
import EmailConfirmation from "./pages/EmailConfirmation";


const App: React.FC = () => {
  return (
    <>
    <TranslationProvider>
    <UserProvider>
    <ToastContainer />
    <AuthUserProvider>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/profilelisting" element={<PrivateRoute><ProfileListing /></PrivateRoute>} />
        <Route path="/profilelisting/:TMI" element={<PrivateRoute><ProfileListing /></PrivateRoute>} />
        <Route path="/confirmation" element={<EmailConfirmation/>} />

        <Route path="/userhome" element={<PrivateRoute><UserHome /></PrivateRoute>} />
        
        <Route path="/profilelisting/profile/:TMI" element={<PrivateRoute><Profile /></PrivateRoute>} />
        <Route path="/send-email" element={<SendEmailForm />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/membershipplans" element={<PrivateRoute><MembershipPlans /></PrivateRoute>} />
        <Route path="/send-email" element={<PrivateRoute><SendEmailForm /></PrivateRoute>} />

        {/* User Dashboard */}
        <Route path="/userdashboard" element={<PrivateRoute><UserDashboard /></PrivateRoute>}>
          <Route path="" element={<UserActivity />} />
          <Route path="search" element={<SearchSection />} />
          <Route path="account" element={<MyAccount />} />
          <Route path="settings" element={<AccountSettings />} />
          <Route path="uploadphoto" element={<PhotoUpload />} />
          <Route path="membershipplans" element={<MembershipPlans />} />
          <Route path="my-mails" element={<SentEmailsList />} />
          <Route path="advancedverification" element={<AdvancedVerification />} />
          <Route path="essentialverification" element={<EssentialVerification />} />
          <Route path="eliteverification" element={<EliteVerification />} />
          <Route path="bgvd" element={<BackgroundVerificationDashboard />} />
          <Route path="orderstatus" element={<OrderStatus />} />

        </Route>

        <Route path="/admin" element={<Admin/>} />

        {/* Admin Membership Control */}
        <Route path="/admin/membership" element={<PrivateRoleRoute requiredRoles={['executive']} ><MembershipDiscounts /></PrivateRoleRoute>}>
          <Route path="plans" element={<AdminMembershipPlans />} />
          <Route path="planfeatures" element={<PlanFeatures />} />
          <Route path="discount" element={<DiscountGenerator />} />
          <Route path="currentmembershipplans" element={<CurrentPlanView />} />
          <Route path="availablecoupons" element={<AvailableCoupons />} />
          <Route path="couponssent" element={<CouponSent />} />
          <Route path="couponanalysis" element={<CouponAnalysis />} />
          

          
        </Route>
        {/* <Route path="/user/:userId" element={<EditProfile />} /> */}

        <Route path="/admin/user-management" element={<PrivateRoleRoute requiredRoles={['executive']}><UserManagement/></PrivateRoleRoute >} />

        {/* Admin Events & Gallery Control */}
        <Route path="/admin/events/oldevents" element={<PrivateRoleRoute requiredRoles={['executive','admin']}><OldEvents /></PrivateRoleRoute >} />
        <Route path="/admin/events" element={<PrivateRoleRoute requiredRoles={['executive','admin']}><EventsGallery /></PrivateRoleRoute >}>
          <Route path="newevent" element={<NewEvent />} />
          <Route path="newgallery" element={<NewGalleryPhoto />} />
          <Route path="oldgallery" element={<h1>oldgallery</h1>} />
          <Route path="newnews" element={<NewNews />} />
          <Route path="newblog" element={<NewBlog />} />
        </Route>

        {/* Admin Ads Control */}
        <Route path="/admin/ads" element={<PrivateRoleRoute requiredRoles={['executive','admin']}><AdminAds /></PrivateRoleRoute >}>
          <Route path="ourads" element={<OurAds />} />
          <Route path="customerads" element={<h1>Customer Ads</h1>} />
        </Route>

        {/*Admin Approvals */}
        
        <Route path="/admin/approvals" element={<PrivateRoleRoute requiredRoles={['executive','admin']}><AdminApprovals /></PrivateRoleRoute >}>
          {/* <Route path="photoapproval" element={<PhotoApproval />} /> */}
          <Route path="feedbackapproval"element={<h1>Feedback Approvals</h1>}/>
          <Route path="verificationapproval" element={<h1>Verification Approvals</h1>}/>
          <Route path="profileapproval" element={<ProfileApproval />} />
          <Route path="deleteapproval" element={<DeleteApproval />} />
          <Route path="deleteapproval" element={<DeleteApproval />} />
          <Route path="accountsummary" element={<AccountSummary />} />
          
          
        </Route>
        <Route path="/admin/approvals/profileapproval/:TMI" element={<PrivateRoleRoute requiredRoles={['executive','admin']}><ApprovalProfile /></PrivateRoleRoute>} />
        <Route path="/customer/accountsummary" element={<PrivateRoleRoute requiredRoles={['executive','admin','customer']}><AccountSummary /></PrivateRoleRoute>} />
        <Route path="/customer/couponlist" element={<PrivateRoleRoute requiredRoles={['executive','admin','customer']}><CouponList  /></PrivateRoleRoute>} />
        <Route path="/customer/couponssent" element={<PrivateRoleRoute requiredRoles={['executive','admin','customer']}><CouponSent  /></PrivateRoleRoute>} />
        <Route path="/customer/followup" element={<PrivateRoleRoute requiredRoles={['executive','admin','customer']}><CustomerSupportDashboard  /></PrivateRoleRoute>} />
        {/*Eamil Verification */}
        <Route path="/verify/:verificationToken" element={<Verify />} /> 


        <Route path="/bgverification" element={<PrivateRoute><BackgroundVerification /></PrivateRoute>} />
        <Route path="/vbgverification" element={<VideoBgV />} />
        <Route path="/services" element={<PrivateRoute><ValueAddServices /></PrivateRoute>} />

        <Route path="/support" element={<CustomerSupport />} />
        <Route path="/feedback" element={<Feedback />} />
        <Route path="/plans" element={<Plans />} />
        <Route path="/stories" element={<SuccessStories />} />
        <Route path="/about" element={<About />} />
        <Route path="/news" element={<News />} />
        <Route path="/events" element={<Events />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/advertising" element={<Advertising />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<TermsOfUse />} />
        <Route path="/refund" element={<RefundPolicy />} />
        <Route path="/misuse" element={<ReportMisuse />} />

        
        <Route path="/test" element={<Test />} />
      </Routes>
      
      <FooterCondition />

      </AuthUserProvider>
      </UserProvider>
      </TranslationProvider>
    </>
  );
};

const FooterCondition = () => {
  const { userType } = useUser();

  // Hide footer for 'admin', 'executive', and 'customer' roles
  if (userType === 'admin' || userType === 'executive' || userType === 'customer') {
    return null; // Don't render footer
  }

  return <Footer />; // Render footer for other roles
};

export default App;
