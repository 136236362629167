import React from 'react';
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useUser } from './UserContext';
import { useAuthUser } from './AuthUserContext';
import useScreenType from "../hooks/useScreenType";
import ImageSlider from "../components/ImageSlider";
import { dropdownData, countriesWithPhoneCodes } from "../utils/data"; 
const apiUrl = import.meta.env.VITE_API_URL;
//Badges
import socialCheckGrey from "/svgs/socialCheckGrey.svg";
import socialCheckGreen from "/svgs/socialCheckGreen.svg";
import familyCheckGrey from "/svgs/familyCheckGrey.svg";
import familyCheckGreen from "/svgs/familyCheckGreen.svg";
import employmentCheckGrey from "/svgs/employmentCheckGrey.svg";
import employmentCheckGreen from "/svgs/employmentCheckGreen.svg";
import educationCheckGrey from "/svgs/educationCheckGrey.svg";
import educationCheckGreen from "/svgs/educationCheckGreen.svg";
import criminalCheckGrey from "/svgs/criminalCheckGrey.svg";
import criminalCheckGreen from "/svgs/criminalCheckGreen.svg";
import creditCheckGrey from "/svgs/creditCheckGrey.svg";
import creditCheckGreen from "/svgs/creditCheckGreen.svg";

//For alert function
import { toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";

import axios from "axios";
import locations from "../utils/locations.json";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import {useForm,Controller} from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";



interface AlbumPhoto {
  approved: boolean;
  data: string; // base64 string
}


//User Interface
interface User {
 
  firstname: string;
  lastname: string;
  photo: string | null;
  emailid: string;
  createdFor: string;
  motherTongue?: string;
  denomination?: string;
  caste:string;
  gender: string;
  DateOfBirth: Date;
  age: number;
  Height: number;
  complexion: string;
  country:string
  ResidenceType: string;
  ResidenceState: string;
  ResidenceCity: string;
  ResidenceLocality: string;
  TMI: string;
  isVerified:boolean;
  
}

interface Contact {
  phoneNumber: string;
  churchName: string;
  churchLocation: string;
  
}

interface EducationAndEmployment {
  collegeName?: string;
  employmentType?: string;
  Occupation?: string;
  companyName?: string;
  Salary?: string;
  Education?:string;
}

interface FamilyDetails {
  fatherNative: string;
  motherNative: string;
  fatherOccupation: string;
  motherOccupation: string;
  familyValue: string;
  FamilyType: string;
  familyStatus: string;
  brothers:string;
  sisters:string;
  fatherName:string;
  motherName:string;
  [key: string]: any;
}

interface LifeStyle {
  hobbies: string[];
  interests: string[];
  favouriteMusic: string[];
  favouriteReads: string[];
  favouriteFood: string[];
  sports: string[];
  dressStyles: string[];
}

interface MembershipDetails {
  membership: string;
}

interface PartnerPreferences {
  partnerMinAge: string;
  partnerMaxAge: string;
  partnerMinHeight: string;
  partnerMaxHeight: string;
  partnerMaritalStatus: string[];
  partnerMotherTongue: string[];
  partnerCaste: string[];
  partnerPhysicalStatus: string[];
  partnerEatingHabits: string[];
  partnerDrinkingHabits: string[];
  partnerSmokingHabits: string[];
  partnerDenomination: string[];
  partnerSpirituality: string[];
  partnerEmploymentType: string[];
  partnerAnnualIncome: string[];
  partnerCountry:string[];
  partnerState: string[];
  partnerCity: string[];
  partnerLocality: string[];
  partnerEducation: string[];
  partnerOccupation: string[];
  
}

interface About{
  bodyType: string,
  weight: string,
  smokingHabits: string,
  drinkingHabits: string,
  eatingHabits: string,
  spirituality: string,
  knownLanguages: [string],
  physicalStatus: string,
  maritalStatus: string,
  numberOfBoyChild: string,
  numberOfGirlChild: string,
  churchActivity: string,
  aboutMe:string

  [key: string]: any;
}

interface ApiResponse {
  user: User;
  about:About;
  contact: Contact;
  educationAndEmployment: EducationAndEmployment;
  familyDetails: FamilyDetails;
  lifeStyle: LifeStyle;
  membershipDetails: MembershipDetails;
  partnerPreferences: PartnerPreferences;

}


  const schema = z.object({
    user: z.object({
      firstname: z.string(),
      lastname: z.string(),
      photo: z.string().nullable(),
      emailid: z.string(),
      createdFor: z.string(),
      motherTongue: z.string().optional(),
      denomination: z.string().optional(),
      caste: z.string(),
      gender: z.string(),
      DateOfBirth: z.date(),
      age: z.number(),
      Height: z.number(),
      complexion: z.string(),
      country: z.string(),
      ResidenceType: z.string(),
      ResidenceState: z.string(),
      ResidenceCity: z.string(),
      ResidenceLocality: z.string(),
      TMI: z.string(),
      isVerified: z.boolean(),
    }),
    about: z.object({
      bodyType: z.string(),
      weight: z.string(),
      smokingHabits: z.string(),
      drinkingHabits: z.string(),
      eatingHabits: z.string(),
      spirituality: z.string(),
      knownLanguages: z.array(z.string()),
      physicalStatus: z.string(),
      maritalStatus: z.string(),
      numberOfBoyChild: z.string(),
      numberOfGirlChild: z.string(),
      churchActivity: z.string(),
      aboutMe: z.string(),

    }),
    contact: z.object({
      phoneNumber: z.string().optional(),
      churchName: z.string(),
      churchLocation: z.string(),
      
    }),
    educationAndEmployment: z.object({
      collegeName: z.string(),
      employmentType: z.string(),
      Occupation: z.string(),
      companyName: z.string(),
      Salary: z.string(),
      Education: z.string(),
    }),
    familyDetails: z.object({
      fatherNative: z.string().optional(),
      motherNative: z.string().optional(),
      fatherOccupation: z.string(),
      motherOccupation: z.string(),
      familyValue: z.string(),
      FamilyType: z.string(),
      familyStatus: z.string(),
      brothers: z.string(),
      sisters: z.string(),
      fatherName: z.string(),
      motherName: z.string(),
    }),
    lifeStyle: z.object({
      hobbies: z.array(z.string()).optional(),
      interests: z.array(z.string()).optional(),
      favouriteMusic: z.array(z.string()).optional(),
      favouriteReads: z.array(z.string()).optional(),
      favouriteFood: z.array(z.string()).optional(),
      sports: z.array(z.string()).optional(),
      dressStyles: z.array(z.string()).optional(),
    }),
    membershipDetails: z.object({
      membership: z.string(),
    }),
    partnerPreferences: z.object({
      partnerMinAge: z.string().optional(),
      partnerMaxAge: z.string().optional(),
      partnerMinHeight: z.string().optional(),
      partnerMaxHeight: z.string().optional(),
      partnerMaritalStatus: z.array(z.string()).optional(),
      partnerMotherTongue: z.array(z.string()).optional(),
      partnerCaste: z.array(z.string()).optional(),
      partnerPhysicalStatus: z.array(z.string()).optional(),
      partnerEatingHabits: z.array(z.string()).optional(),
      partnerDrinkingHabits: z.array(z.string()).optional(),
      partnerSmokingHabits: z.array(z.string()).optional(),
      partnerDenomination: z.array(z.string()).optional(),
      partnerSpirituality: z.array(z.string()).optional(),
      partnerEducation: z.array(z.object({ value: z.string(), label: z.string() })).optional(),
      partnerEmploymentType: z.array(z.string()).optional(),
      partnerOccupation: z.array(z.object({ value: z.string(), label: z.string() })).optional(),
      partnerAnnualIncome: z.array(z.string()).optional(),
      partnerCountry: z.array(z.string()).optional(),
      partnerState: z.array(z.string()).optional(),
      partnerCity: z.array(z.string()).optional(),
      partnerLocality: z.array(z.string()).optional(),
    }),
  });
  


const Profile: React.FC = () => {
  const [expressInterestColor, setExpressInterestColor] = useState<string>('');
  const [shortlistColor, setShortlistColor] = useState<string>('');
  const [hasExpressedInterest, setHasExpressedInterest] = useState<boolean>(false);
  const [hasShortlisted, setHasShortlisted] = useState<boolean>(false);
  const [dbuserdetails, setdbuserdetails] = useState<ApiResponse | null>(null);
  const [photoArray, setPhotoArray] = useState<string[]>([]);
  const [isContactVisible, setContactVisible] = useState(false);
  const [selectedStates, setSelectedStates] = useState<string[]>([]);
  const [selectedCities, setSelectedCities] = useState<string[]>([]);
  const screenType = useScreenType();
  const location = useLocation();
  const [isEditing, setIsEditing] = useState(location.state?.isEditing || false);
  const {user} = useAuthUser();
  const { setUserType } = useUser();
  const navigate = useNavigate();
  const { TMI } = useParams<{ TMI: string }>();
  
  const { register, formState, watch, control,reset ,getValues} =
  useForm<ApiResponse>({
    defaultValues: {
      user: {
        age: dbuserdetails?.user?.age || 0,
        complexion: dbuserdetails?.user?.complexion || '',
        Height: dbuserdetails?.user?.Height || 0,
        caste: dbuserdetails?.user?.caste || '',
        ResidenceState: dbuserdetails?.user?.ResidenceState || '',
        ResidenceCity: dbuserdetails?.user?.ResidenceCity || '',
        ResidenceLocality: dbuserdetails?.user?.ResidenceLocality || '',
        motherTongue: dbuserdetails?.user?.motherTongue || '',
        denomination: dbuserdetails?.user?.denomination || '',
        ResidenceType: dbuserdetails?.user?.ResidenceType || '',
        TMI: dbuserdetails?.user?.TMI || '',
        emailid: dbuserdetails?.user?.emailid || '',
      },

      about :{
        physicalStatus: dbuserdetails?.about?.physicalStatus || '',
        bodyType: dbuserdetails?.about?.bodyType || '',
        weight: dbuserdetails?.about?.weight || '',
        smokingHabits: dbuserdetails?.about?.smokingHabits || '',
        knownLanguages: dbuserdetails?.about?.knownLanguages || [],
        drinkingHabits: dbuserdetails?.about?.drinkingHabits || '',
        maritalStatus: dbuserdetails?.about?.maritalStatus || '',
        numberOfBoyChild: dbuserdetails?.about?.numberOfBoyChild || '',
        numberOfGirlChild: dbuserdetails?.about?.numberOfGirlChild || '',
        eatingHabits: dbuserdetails?.about?.eatingHabits || '',
        spirituality: dbuserdetails?.about?.spirituality || '',
        churchActivity: dbuserdetails?.about?.churchActivity || '',

      },
      familyDetails: {
        familyValue: dbuserdetails?.familyDetails?.familyValue || '',
        FamilyType: dbuserdetails?.familyDetails?.FamilyType || '',
        familyStatus: dbuserdetails?.familyDetails?.familyStatus || '',
        brothers: dbuserdetails?.familyDetails?.brothers || '',
        sisters: dbuserdetails?.familyDetails?.sisters || '',
        fatherName: dbuserdetails?.familyDetails?.fatherName || '',
        motherName: dbuserdetails?.familyDetails?.motherName || '',
        fatherOccupation: dbuserdetails?.familyDetails?.fatherOccupation || '',
        motherOccupation: dbuserdetails?.familyDetails?.motherOccupation || '',
      },
      contact: {
        phoneNumber: dbuserdetails?.contact?.phoneNumber || '',
        churchName: dbuserdetails?.contact?.churchName || '',
        churchLocation: dbuserdetails?.contact?.churchLocation || '',
        
      },
      educationAndEmployment: {
        Education: dbuserdetails?.educationAndEmployment?.Education || '',
        collegeName: dbuserdetails?.educationAndEmployment?.collegeName || '',
        employmentType: dbuserdetails?.educationAndEmployment?.employmentType || '',
        Occupation: dbuserdetails?.educationAndEmployment?.Occupation || '',
        companyName: dbuserdetails?.educationAndEmployment?.companyName || '',
        Salary: dbuserdetails?.educationAndEmployment?.Salary || '',
      },
      lifeStyle: {
        hobbies: dbuserdetails?.lifeStyle?.hobbies || [],
        interests: dbuserdetails?.lifeStyle?.interests || [],
        favouriteMusic: dbuserdetails?.lifeStyle?.favouriteMusic || [],
        favouriteReads: dbuserdetails?.lifeStyle?.favouriteReads || [],
        favouriteFood: dbuserdetails?.lifeStyle?.favouriteFood || [],
        sports: dbuserdetails?.lifeStyle?.sports || [],
        dressStyles: dbuserdetails?.lifeStyle?.dressStyles || [],
      },
      partnerPreferences: {
        partnerMinAge: dbuserdetails?.partnerPreferences?.partnerMinAge || '',
        partnerMaxAge: dbuserdetails?.partnerPreferences?.partnerMaxAge || '',
        partnerMinHeight: dbuserdetails?.partnerPreferences?.partnerMinHeight || '',
        partnerMaxHeight: dbuserdetails?.partnerPreferences?.partnerMaxHeight || '',
        partnerMaritalStatus: dbuserdetails?.partnerPreferences?.partnerMaritalStatus || [],
        partnerMotherTongue: dbuserdetails?.partnerPreferences?.partnerMotherTongue || [],
        partnerCaste: dbuserdetails?.partnerPreferences?.partnerCaste || [],
        partnerPhysicalStatus: dbuserdetails?.partnerPreferences?.partnerPhysicalStatus || [],
        partnerEatingHabits: dbuserdetails?.partnerPreferences?.partnerEatingHabits || [],
        partnerDrinkingHabits: dbuserdetails?.partnerPreferences?.partnerDrinkingHabits || [],
        partnerSmokingHabits: dbuserdetails?.partnerPreferences?.partnerSmokingHabits || [],
        partnerDenomination: dbuserdetails?.partnerPreferences?.partnerDenomination || [],
        partnerSpirituality: dbuserdetails?.partnerPreferences?.partnerSpirituality || [],
        partnerEmploymentType: dbuserdetails?.partnerPreferences?.partnerEmploymentType || [],
        partnerAnnualIncome: dbuserdetails?.partnerPreferences?.partnerAnnualIncome || [],
        partnerState: dbuserdetails?.partnerPreferences?.partnerState || [],
        partnerCity: dbuserdetails?.partnerPreferences?.partnerCity || [],
        partnerLocality: dbuserdetails?.partnerPreferences?.partnerLocality || [],
      },
    },
    resolver: zodResolver(schema),
  });

  const getNestedValue = (obj: any, path: string) => {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  };

  

  useEffect(()=>{
    if (dbuserdetails){
      reset({
        user: {
          age: dbuserdetails.user.age || 0,
          complexion: dbuserdetails.user.complexion || '',
          Height: dbuserdetails.user.Height || 0,
          caste: dbuserdetails.user.caste || '',
          ResidenceState: dbuserdetails.user.ResidenceState || '',
          ResidenceCity: dbuserdetails.user.ResidenceCity || '',
          ResidenceLocality: dbuserdetails.user.ResidenceLocality || '',
          motherTongue: dbuserdetails.user.motherTongue || '',
          denomination: dbuserdetails.user.denomination || '',
          ResidenceType: dbuserdetails.user.ResidenceType || '',
          TMI: dbuserdetails.user.TMI || '',
          emailid: user.emailid || '',
        },
        about: {
          physicalStatus: dbuserdetails.about.physicalStatus || '',
          bodyType: dbuserdetails.about.bodyType || '',
          weight: dbuserdetails.about.weight || '',
          smokingHabits: dbuserdetails.about.smokingHabits || '',
          knownLanguages: dbuserdetails.about.knownLanguages || [],
          drinkingHabits: dbuserdetails.about.drinkingHabits || '',
          maritalStatus: dbuserdetails.about.maritalStatus || '',
          numberOfBoyChild: dbuserdetails.about.numberOfBoyChild || '',
          numberOfGirlChild: dbuserdetails.about.numberOfGirlChild || '',
          eatingHabits: dbuserdetails.about.eatingHabits || '',
          spirituality: dbuserdetails.about.spirituality || '',
          churchActivity: dbuserdetails.about.churchActivity || '',
        },
        familyDetails: {
          familyValue: dbuserdetails.familyDetails.familyValue || '',
          FamilyType: dbuserdetails.familyDetails.FamilyType || '',
          familyStatus: dbuserdetails.familyDetails.familyStatus || '',
          brothers: dbuserdetails.familyDetails.brothers || '',
          sisters: dbuserdetails.familyDetails.sisters || '',
          fatherName: dbuserdetails.familyDetails.fatherName || '',
          motherName: dbuserdetails.familyDetails.motherName || '',
          fatherOccupation: dbuserdetails.familyDetails.fatherOccupation || '',
          motherOccupation: dbuserdetails.familyDetails.motherOccupation || '',
        },
        contact: {
          phoneNumber: dbuserdetails.contact.phoneNumber || '',
          churchName: dbuserdetails.contact.churchName || '',
          churchLocation: dbuserdetails.contact.churchLocation || '',
          
        },
        educationAndEmployment: {
          Education: dbuserdetails.educationAndEmployment.Education || '',
          collegeName: dbuserdetails.educationAndEmployment.collegeName || '',
          employmentType: dbuserdetails.educationAndEmployment.employmentType || '',
          Occupation: dbuserdetails.educationAndEmployment.Occupation || '',
          companyName: dbuserdetails.educationAndEmployment.companyName || '',
          Salary: dbuserdetails.educationAndEmployment.Salary || '',
        },
        lifeStyle: {
          hobbies: dbuserdetails.lifeStyle.hobbies || [],
          interests: dbuserdetails.lifeStyle.interests || [],
          favouriteMusic: dbuserdetails.lifeStyle.favouriteMusic || [],
          favouriteReads: dbuserdetails.lifeStyle.favouriteReads || [],
          favouriteFood: dbuserdetails.lifeStyle.favouriteFood || [],
          sports: dbuserdetails.lifeStyle.sports || [],
          dressStyles: dbuserdetails.lifeStyle.dressStyles || [],
        },
        partnerPreferences: {
          partnerMinAge: dbuserdetails.partnerPreferences.partnerMinAge || '',
          partnerMaxAge: dbuserdetails.partnerPreferences.partnerMaxAge || '',
          partnerMinHeight: dbuserdetails.partnerPreferences.partnerMinHeight || '',
          partnerMaxHeight: dbuserdetails.partnerPreferences.partnerMaxHeight || '',
          partnerMaritalStatus: dbuserdetails.partnerPreferences.partnerMaritalStatus || [],
          partnerMotherTongue: dbuserdetails.partnerPreferences.partnerMotherTongue || [],
          partnerCaste: dbuserdetails.partnerPreferences.partnerCaste || [],
          partnerPhysicalStatus: dbuserdetails.partnerPreferences.partnerPhysicalStatus || [],
          partnerEatingHabits: dbuserdetails.partnerPreferences.partnerEatingHabits || [],
          partnerDrinkingHabits: dbuserdetails.partnerPreferences.partnerDrinkingHabits || [],
          partnerSmokingHabits: dbuserdetails.partnerPreferences.partnerSmokingHabits || [],
          partnerDenomination: dbuserdetails.partnerPreferences.partnerDenomination || [],
          partnerSpirituality: dbuserdetails.partnerPreferences.partnerSpirituality || [],
          partnerEmploymentType: dbuserdetails.partnerPreferences.partnerEmploymentType || [],
          partnerAnnualIncome: dbuserdetails.partnerPreferences.partnerAnnualIncome || [],
          partnerState: dbuserdetails.partnerPreferences.partnerState || [],
          partnerCity: dbuserdetails.partnerPreferences.partnerCity || [],
          partnerLocality: dbuserdetails.partnerPreferences.partnerLocality || [],
        }
      });
    }
  },[dbuserdetails])

  const fetchUserData = async () => {
    try {
      if (!TMI) return; // Exit early if TMI is not available

      setUserType('user');
      console.log('TMI:', TMI);

      // Check interests and shortlisted status if user is available
      if (user) {
        checkInterest(); // Check if the user has already expressed interest
        checkShortlisted(); // Check if the user has already shortlisted this profile
      }

      const response = await axios.get(`${apiUrl}/api/profile/${TMI}`);
      const apiResponse = response.data;
      console.log("API RESPONSE",apiResponse)

      if (apiResponse) {
        const {
          user,
          contact,
          educationAndEmployment,
          familyDetails,
          lifeStyle,
          membershipDetails,
          partnerPreferences,
          about
        } = apiResponse;

        // Consolidate state updates
        setdbuserdetails({
          user,
          contact,
          educationAndEmployment,
          familyDetails,
          lifeStyle,
          membershipDetails,
          partnerPreferences,
          about,
        });

        // Reset form state with fetched user data
        reset({
          user: {
            age: user.age || 0,
            complexion: user.complexion || '',
            Height: user.Height || '',
            caste: user.caste || '',
            ResidenceState: user.ResidenceState || '',
            ResidenceCity: user.ResidenceCity || '',
            ResidenceLocality: user.ResidenceLocality || '',
            motherTongue: user.motherTongue || '',
            denomination: user.denomination || '',
            ResidenceType: user.ResidenceType || '',
            TMI: user.TMI || '',
            emailid: user.emailid || '',
          },
          about: {
            physicalStatus: about.physicalStatus || '',
            bodyType: about.bodyType || '',
            weight: about.weight || '',
            smokingHabits: about.smokingHabits || '',
            knownLanguages: about.knownLanguages || [],
            drinkingHabits: about.drinkingHabits || '',
            maritalStatus: about.maritalStatus || '',
            numberOfBoyChild: about.numberOfBoyChild || '',
            numberOfGirlChild: about.numberOfGirlChild || '',
            eatingHabits: about.eatingHabits || '',
            spirituality: about.spirituality || '',
            churchActivity: about.churchActivity || '',
          },
          familyDetails: {
            familyValue: familyDetails.familyValue || '',
            FamilyType: familyDetails.FamilyType || '',
            familyStatus: familyDetails.familyStatus || '',
            brothers: familyDetails.brothers || '',
            sisters: familyDetails.sisters || '',
            fatherName: familyDetails.fatherName || '',
            motherName: familyDetails.motherName || '',
            fatherOccupation: familyDetails.fatherOccupation || '',
            motherOccupation: familyDetails.motherOccupation || '',
          },
          contact: {
            phoneNumber: contact.phoneNumber || '',
            churchName: contact.churchName || '',
            churchLocation: contact.churchLocation || '',
            
          },
          educationAndEmployment: {
            Education: educationAndEmployment.Education || '',
            collegeName: educationAndEmployment.collegeName || '',
            employmentType: educationAndEmployment.employmentType || '',
            Occupation: educationAndEmployment.Occupation || '',
            companyName: educationAndEmployment.companyName || '',
            Salary: educationAndEmployment.Salary || '',
          },
          lifeStyle: {
            hobbies: lifeStyle.hobbies || [],
            interests: lifeStyle.interests || [],
            favouriteMusic: lifeStyle.favouriteMusic || [],
            favouriteReads: lifeStyle.favouriteReads || [],
            favouriteFood: lifeStyle.favouriteFood || [],
            sports: lifeStyle.sports || [],
            dressStyles: lifeStyle.dressStyles || [],
          },
          partnerPreferences: {
            partnerMinAge: partnerPreferences.partnerMinAge || '',
            partnerMaxAge: partnerPreferences.partnerMaxAge || '',
            partnerMinHeight: partnerPreferences.partnerMinHeight || '',
            partnerMaxHeight: partnerPreferences.partnerMaxHeight || '',
            partnerMaritalStatus: partnerPreferences.partnerMaritalStatus || [],
            partnerMotherTongue: partnerPreferences.partnerMotherTongue || [],
            partnerCaste: partnerPreferences.partnerCaste || [],
            partnerPhysicalStatus: partnerPreferences.partnerPhysicalStatus || [],
            partnerEatingHabits: partnerPreferences.partnerEatingHabits || [],
            partnerDrinkingHabits: partnerPreferences.partnerDrinkingHabits || [],
            partnerSmokingHabits: partnerPreferences.partnerSmokingHabits || [],
            partnerDenomination: partnerPreferences.partnerDenomination || [],
            partnerSpirituality: partnerPreferences.partnerSpirituality || [],
            partnerEmploymentType: partnerPreferences.partnerEmploymentType || [],
            partnerAnnualIncome: partnerPreferences.partnerAnnualIncome || [],
            partnerState: partnerPreferences.partnerState || [],
            partnerCity: partnerPreferences.partnerCity || [],
            partnerLocality: partnerPreferences.partnerLocality || [],
          }
        });

        // Fetch photos
        const photosResponse = await axios.get(`${apiUrl}/api/profile/${TMI}/photos`);
        const albumPhotos: string[] = (photosResponse.data.albumPhotos as AlbumPhoto[])
          .filter((photo: AlbumPhoto) => photo.approved) // Only include approved photos
          .map((photo: AlbumPhoto) => `${apiUrl}${photo.data}`); // Construct full URL for each approved photo

        // Set the photoArray state with the user's main photo and approved album photos
        setPhotoArray([`${apiUrl}${user.photo}`, ...albumPhotos]);



      } else {
        console.warn('No user data available.');
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, [TMI]);

  useEffect(() => {
    if (user) {
      checkInterest();
      checkShortlisted();
    }
  }, [user, TMI]);
 
  


  const expressInterest = async (profileTMI: string) => {
    if (hasExpressedInterest) {
      toast.info('You have already shown interest for this profile!');
      return;
    }
    try {
      const response = await fetch(`${apiUrl}/api/express-interest`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          TMI: user,
          profileTMI: profileTMI,
        }),
      });
      
      if (response.ok) {
        toast.success('You have shown interest in this profile!');
        setExpressInterestColor('#ffa700');
        setHasExpressedInterest(true);
      } else {
        toast.error('Failed to express interest');
      }
    } catch (error) {
      toast.error('An error occurred. Please try again.');
    }
  };

  const viewContact = async (profileTMI: string) => {
    
    try {
      const response = await fetch(`${apiUrl}/api/view-mobile`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          TMI: user,
          profileTMI: profileTMI,
        }),
      });
      // Parse the JSON response from the API
        const data = await response.json();
      if (response.ok) {
        toast.success(data.message);
        console.log(data.message);
      } else {
        toast.error('Failed to view');
      }
    } catch (error) {
      toast.error('An error occurred. Please try again.');
    }
  };
  
  const shortlistProfiles = async (profileTMI: string) => {
    if (hasShortlisted) {
      toast.info('You have already shortlisted this profile!');
      return;
    }
  
    try {
      const response = await fetch(`${apiUrl}/api/shortlist-profiles`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          TMI: user,
          profileTMIs: [profileTMI], // Send profileTMI as an array
        }),
      });
  
      const result = await response.json();
      if (response.ok) {
        toast.success('Profile has been shortlisted!');
        setShortlistColor('#ffa700');
        setHasShortlisted(true);
      } else {
        toast.error(result.message || 'Failed to shortlist profile');
      }
    } catch (error) {
      toast.error('An error occurred. Please try again.');
    }
  };
  
  const checkInterest = async () => {
    if (!user || !TMI) {
      console.error("User or profile TMI is undefined:", user, TMI);
      return;
    }
    try {
      const response = await fetch(`${apiUrl}/api/check-interests?user=${user}&profile=${TMI}`);
      const result = await response.json();
      if (response.ok) {
        setExpressInterestColor(result.isInterested ? '#ffa700' : '');
        setHasExpressedInterest(result.isInterested);
      } else {
        console.error('Failed to check interest:', result.message);
      }
    } catch (error) {
      console.error('Error checking interest:', error);
    }
  };
  
  const checkShortlisted = async () => {
    if (!user || !TMI) {
      console.error("User or profile TMI is undefined:", user, TMI);
      return;
    }
    try {
      const response = await fetch(`${apiUrl}/api/check-shortlists?user=${user}&profile=${TMI}`);
      const result = await response.json();
      if (response.ok) {
        setShortlistColor(result.isShortlisted ? '#ffa700' : '');
        setHasShortlisted(result.isShortlisted);
      } else {
        toast.error('Failed to check shortlisted profiles');
      }
    } catch (error) {
      toast.error('An error occurred. Please try again.');
    }
  };

  const blockUser = async (profileTMI: string) => {
    
    try {
      const response = await fetch(`${apiUrl}/api/block-user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          blockerTMI: user,
          blockedTMI: profileTMI,
        }),
      });
      // Parse the JSON response from the API
        const data = await response.json();
      if (response.ok) {
        toast.success(data.message);
        navigate('/profilelisting')
      } else {
        toast.error('Failed to block');
      }
    } catch (error) {
      toast.error('An error occurred. Please try again.');
    }
  };

  const { errors } = formState;
  if (!dbuserdetails) {
    return (
      <div className="h-[80vh] flex place-content-center place-items-center">
        <h1 className="text-6xl">Loading.......</h1>
      </div>
    );
  }


const aboutMeIntro=[{
    title: "aboutMe",
    value: dbuserdetails.about.aboutMe,
    registerValue: "aboutMeIntro",
}]


const aboutMe = [
  {
    title: "Age",
    value: dbuserdetails?.user?.age || "",  // Correct binding to dbuserdetails
    options: dropdownData.age,              // Assuming this is a list of ages
    registerValue: "user.age",              // Matches the register() path in the form
  },
  {
    title: "Physical Status",
    value: dbuserdetails?.about?.physicalStatus || "",
    options: dropdownData.physicalStatus,
    registerValue: "about.physicalStatus",
  },
  {
    title: "Body Type",
    value: dbuserdetails?.about?.bodyType || "",
    options: dropdownData.bodyType,
    registerValue: "about.bodyType",
  },
  {
    title: "Complexion",
    value: dbuserdetails?.user?.complexion || "",
    options: dropdownData.complexion,
    registerValue: "user.complexion",
  },
  {
    title: "Weight",
    value: dbuserdetails?.about?.weight || "",
    options: dropdownData.weight,
    registerValue: "about.weight",
  },
  {
    title: "Height",
    value: dbuserdetails?.user?.Height || "",  // Correct height binding
    options: dropdownData.heights,
    registerValue: "user.Height",              // Correct register path
  },
  {
    title: "Smoking Habits",
    value: dbuserdetails?.about?.smokingHabits || "",
    options: dropdownData.smokingHabits,
    registerValue: "about.smokingHabits",
  },
  {
    title: "Caste",
    value: dbuserdetails?.user?.caste || "",
    options: dropdownData.castes,
    registerValue: "user.caste",
  },
  {
    title: "Drinking Habits",
    value: dbuserdetails?.about?.drinkingHabits || "",
    options: dropdownData.drinkingHabits,
    registerValue: "about.drinkingHabits",
  },
  {
    title: "Eating Habits",
    value: dbuserdetails?.about?.eatingHabits || "",
    options: dropdownData.eatingHabits,
    registerValue: "about.eatingHabits",
  },
  {
    title: "Mother Tongue",
    value: dbuserdetails?.user?.motherTongue || "",
    options: dropdownData.motherTongue,
    registerValue: "user.motherTongue",
  },
  {
    title: "Known Languages",
    value: dbuserdetails?.about?.knownLanguages || [],
    options: dropdownData.motherTongue,        // Assuming it's used for known languages as well
    registerValue: "about.knownLanguages",
  },
  {
    title: "Denomination",
    value: dbuserdetails?.user?.denomination || "",
    options: dropdownData.denominations,
    registerValue: "user.denomination",
  },
  {
    title: "Spirituality",
    value: dbuserdetails?.about?.spirituality || "",
    options: dropdownData.spirituality,
    registerValue: "about.spirituality",
  },
  {
    title: "Church Activity",
    value: dbuserdetails?.about?.churchActivity || "",
    options: dropdownData.churchActivity,
    registerValue: "about.churchActivity",
  },
  {
    title: "Marital Status",
    value: dbuserdetails?.about?.maritalStatus || "",
    options: dropdownData.maritalStatus,
    registerValue: "about.maritalStatus",
  },
  
  
];


const familyDetails = [
  {
    title: "Family Value",
    value: dbuserdetails?.familyDetails?.familyValue || "",
    options: dropdownData.familyValues,
    registerValue: "familyValue",  // Flattened key
  },
  {
    title: "Family Type",
    value: dbuserdetails?.familyDetails?.FamilyType || "",
    options: dropdownData.familyType,
    registerValue: "FamilyType",  // Flattened key
  },
  {
    title: "Family Status",
    value: dbuserdetails?.familyDetails?.familyStatus || "",
    options: dropdownData.familyStatus,
    registerValue: "familyStatus",  // Flattened key
  },
  {
    title: "Residence Type",
    value: dbuserdetails?.user?.ResidenceType || "",
    options: dropdownData.residenceType,
    registerValue: "ResidenceType",  // Flattened key
  },
  {
    title: "Brothers",
    value: dbuserdetails?.familyDetails?.brothers || "",
    options: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", ">9"],
    registerValue: "brothers",  // Flattened key
  },
  {
    title: "Sisters",
    value: dbuserdetails?.familyDetails?.sisters || "",
    options: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", ">9"],
    registerValue: "sisters",  // Flattened key
  },
];

const contactDetails = [
    {
      title: "Father's Name",
      value: dbuserdetails?.familyDetails?.fatherName || "",
      registerValue: "familyDetails.fatherName",
    },
    {
      title: "Mother's Name",
      value: dbuserdetails?.familyDetails?.motherName || "",
      registerValue: "familyDetails.motherName",
    },
    {
      title: "Father's Occupation",
      value: dbuserdetails?.familyDetails?.fatherOccupation || "",
      registerValue: "familyDetails.fatherOccupation",
    },
    {
      title: "Mother's Occupation",
      value: dbuserdetails?.familyDetails?.motherOccupation || "",
      registerValue: "familyDetails.motherOccupation",
    },
    {
      title: "Contact Number",
      value: dbuserdetails?.contact?.phoneNumber || "",
      registerValue: "contact.phoneNumber",
    },
    {
      title: "Email ID",
      value: dbuserdetails?.user?.emailid,
      registerValue: "contact.emailId",
    },
    {
      title: "Church",
      value: dbuserdetails?.contact?.churchName,
      registerValue: "contact.churchName",
    },
    {
      title: "Church Location",
      value: dbuserdetails?.contact?.churchLocation,
      registerValue: "contact.churchLocation",
    },
];


const professionalDetails = [
    {
      title: "Education",
      value: dbuserdetails?.educationAndEmployment?.Education || "",
      options: dropdownData.education,
      registerValue: "educationAndEmployment.Education",
    },
    {
      title: "College",
      value: dbuserdetails?.educationAndEmployment?.collegeName || "",
      registerValue: "educationAndEmployment.collegeName",
    },
    {
      title: "Employment Type",
      value: dbuserdetails?.educationAndEmployment?.employmentType || "",
      options: dropdownData.employmentType,
      registerValue: "educationAndEmployment.employmentType",
    },
    {
      title: "Occupation",
      value: dbuserdetails?.educationAndEmployment?.Occupation || "",
      options: dropdownData.occupations,
      registerValue: "educationAndEmployment.Occupation",
    },
    {
      title: "Company Name",
      value: dbuserdetails?.educationAndEmployment?.companyName || "",
      registerValue: "educationAndEmployment.companyName",
    },
    {
      title: "Annual Income",
      value: dbuserdetails?.educationAndEmployment?.Salary || "",
      options: dropdownData.income,
      registerValue: "educationAndEmployment.Salary",
    },
];

const lifeStyle = [
    {
      title: "Hobbies",
      value: dbuserdetails?.lifeStyle?.hobbies || "",
      options: dropdownData.hobbies,
      registerValue: "lifeStyle.hobbies",
    },
    {
      title: "Interests",
      value: dbuserdetails?.lifeStyle?.interests || "",
      options: dropdownData.interests,
      registerValue: "lifeStyle.interests",
    },
    {
      title: "Favourite Music",
      value: dbuserdetails?.lifeStyle?.favouriteMusic || "",
      options: dropdownData.favouriteMusic,
      registerValue: "lifeStyle.favouriteMusic",
    },
    {
      title: "Favourite Reads",
      value: dbuserdetails?.lifeStyle?.favouriteReads || "",
      options: dropdownData.favouriteReads,
      registerValue: "lifeStyle.favouriteReads",
    },
    {
      title: "Favourite Food",
      value: dbuserdetails?.lifeStyle?.favouriteFood || "",
      options: dropdownData.favouriteFood,
      registerValue: "lifeStyle.favouriteFood",
    },
    {
      title: "Sports",
      value: dbuserdetails?.lifeStyle?.sports || "",
      options: dropdownData.sports,
      registerValue: "lifeStyle.sports",
    },
    {
      title: "Dress Styles",
      value: dbuserdetails?.lifeStyle?.dressStyles || "",
      options: dropdownData.dressStyles,
      registerValue: "lifeStyle.dressStyles",
    },
];

  
  const selectedState: string = Array.isArray(dbuserdetails?.partnerPreferences?.partnerState)
  ? dbuserdetails.partnerPreferences.partnerState[0] || ''  // Use the first state if it's an array
  : dbuserdetails?.partnerPreferences?.partnerState || ''; // Fallback to empty string

const selectedCity: string = Array.isArray(dbuserdetails?.partnerPreferences?.partnerCity)
  ? dbuserdetails.partnerPreferences.partnerCity[0] || ''  // Use the first city if it's an array
  : dbuserdetails?.partnerPreferences?.partnerCity || ''; // Fallback to empty string


  const partnerPreferences = {
    basicPreferences: [
      {
        title: "Min Age",
        value: dbuserdetails?.partnerPreferences?.partnerMinAge || "",
        options: dropdownData.age,
        registerValue: "partnerPreferences.partnerMinAge",
      },
      {
        title: "Max Age",
        value: dbuserdetails?.partnerPreferences?.partnerMaxAge || "",
        options: dropdownData.age,
        registerValue: "partnerPreferences.partnerMaxAge",
      },
      {
        title: "Min Height",
        value: dbuserdetails?.partnerPreferences?.partnerMinHeight || "",
        options: dropdownData.heights,
        registerValue: "partnerPreferences.partnerMinHeight",
      },
      {
        title: "Max Height",
        value: dbuserdetails?.partnerPreferences?.partnerMaxHeight || "",
        options: dropdownData.heights,
        registerValue: "partnerPreferences.partnerMaxHeight",
      },
      {
        title: "Marital Status",
        value: dbuserdetails?.partnerPreferences?.partnerMaritalStatus || "",
        options: dropdownData.maritalStatus,
        registerValue: "partnerPreferences.partnerMaritalStatus",
      },
      {
        title: "Mother Tongue",
        value: dbuserdetails?.partnerPreferences?.partnerMotherTongue || "",
        options: dropdownData.motherTongue,
        registerValue: "partnerPreferences.partnerMotherTongue",
      },
      {
        title: "Caste",
        value: dbuserdetails?.partnerPreferences?.partnerCaste || "",
        options: dropdownData.castes,
        registerValue: "partnerPreferences.partnerCaste",
      },
      {
        title: "Physical Status",
        value: dbuserdetails?.partnerPreferences?.partnerPhysicalStatus || "",
        options: dropdownData.physicalStatus,
        registerValue: "partnerPreferences.partnerPhysicalStatus",
      },
      {
        title: "Eating Habits",
        value: dbuserdetails?.partnerPreferences?.partnerEatingHabits || "",
        options: dropdownData.eatingHabits,
        registerValue: "partnerPreferences.partnerEatingHabits",
      },
      {
        title: "Drinking Habits",
        value: dbuserdetails?.partnerPreferences?.partnerDrinkingHabits || "",
        options: dropdownData.drinkingHabits,
        registerValue: "partnerPreferences.partnerDrinkingHabits",
      },
      {
        title: "Smoking Habits",
        value: dbuserdetails?.partnerPreferences?.partnerSmokingHabits || "",
        options: dropdownData.smokingHabits,
        registerValue: "partnerPreferences.partnerSmokingHabits",
      },
      {
        title: "Denomination",
        value: dbuserdetails?.partnerPreferences?.partnerDenomination || "",
        options: dropdownData.denominations,
        registerValue: "partnerPreferences.partnerDenomination",
      },
      {
        title: "Spirituality",
        value: dbuserdetails?.partnerPreferences?.partnerSpirituality || "",
        options: dropdownData.spirituality,
        registerValue: "partnerPreferences.partnerSpirituality",
      },
    ],
    professionalPreferences: [
      {
        title: "Employment Type",
        value: dbuserdetails.partnerPreferences.partnerEmploymentType || '',
        options: dropdownData.employmentType,
        registerValue: "partnerPreferences.partnerEmploymentType",
      },
      {
        title: "Annual Income",
        value: dbuserdetails.partnerPreferences.partnerAnnualIncome || '',
        options: dropdownData.income,
        registerValue: "partnerPreferences.partnerAnnualIncome",
      },
      {
        title: "Education",
        value: dbuserdetails?.partnerPreferences?.partnerEducation || 'Hello',
        options: dropdownData.education,
        registerValue: "partnerPreferences.partnerEducation",
      },
      {
        title: "Occupation",
        value: dbuserdetails?.partnerPreferences?.partnerOccupation || '',
        options: dropdownData.occupations,
        registerValue: "partnerPreferences.partnerOccupation",
      },
    ],
    locationPreferences: [
      {
        title: "Partner Country",
        value: dbuserdetails?.partnerPreferences?.partnerCountry || '',
        options: countriesWithPhoneCodes.map(({ country}) => ({
          label: country,
          value: country,
        })) || [],
        registerValue: "partnerPreferences.partnerCountry",
      },
      {
        title: "Partner State",
        value: selectedState,
        options: locations.map(({ state }) => ({
          label: state,
          value: state,
        })),
        registerValue: "partnerPreferences.partnerState",
      },
      {
        title: "Partner City",
        value: dbuserdetails?.partnerPreferences?.partnerCity || '',
        options: selectedState
          ? locations
              .find(location => location.state === selectedState)?.cities.map(city => ({
                label: city.name,
                value: city.name,
              })) || []
          : [],
        registerValue: "partnerPreferences.partnerCity",
      },
      {
        title: "Partner Locality",
        value: dbuserdetails?.partnerPreferences?.partnerLocality || '',
        options: selectedCity
          ? locations
              .flatMap(location =>
                location.cities
                  .filter(city => city.name === selectedCity)
                  .flatMap(city =>
                    city.localities.map(locality => ({
                      label: locality,
                      value: locality,
                    }))
                  )
              )
          : [],
        registerValue: "partnerPreferences.partnerLocality",
      },
    ],
  };
  



  const handleEditClick = () => {
    setIsEditing(true);  
  };
  

  // Trigger onSubmit manually
  const handleSaveClick = async () => {
    console.log("Save button clicked");
  
    const formData = getValues(); // Use a method from your form library to get the values
  
    try {
      console.log("Submitting with data:", formData);
  
      // Send form data to the backend API using axios
      const response = await axios.post(`${apiUrl}/api/profile/submit-form/${TMI}`, formData);
  
      // Check if the response status is 200 (success)
      if (response.status === 200) {
        toast.success('Profile updated successfully');
        setIsEditing(false); // Exit edit mode
        fetchUserData();

      } else {
        toast.error('Failed to update the profile. Please try again.');
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      toast.error('Failed to update the profile. Please try again.');
    }
  };

  return (
    <>
      <div className="flex w-full  ">
        <form
          // onSubmit={handleSaveClick(onSubmit)}
          className="w-full border-2 p-2 mobileLg:p-8"
        >
       {screenType === "desktop" ? (
  <div className="flex flex-wrap justify-between items-start bg-gradient-to-r from-white via-gray-50 to-gray-100 p-4 rounded-xl shadow-lg">
    {/* User Details and Badges Wrapper */}
    <div className="flex w-full">

      {/* User Details Section - left aligned */}
      <div className="flex flex-col space-y-4 flex-1 bg-gradient-to-r from-[#F0E7F4] via-[#F4F4F4] to-[#E0F7FA] p-4 rounded-2xl shadow-md">
        {/* Name */}
        <h1 className="text-5xl font-extrabold text-transparent bg-gradient-to-r from-purple-500 via-pink-500 to-red-500 bg-clip-text tracking-tight animate-textGlow shadow-md pb-2">
          {`${dbuserdetails.user.firstname} ${dbuserdetails.user.lastname}`}
        </h1>

        {/* TMI */}
        <p className="text-lg text-gray-600 ml-2 italic transition-transform duration-200 hover:scale-105 hover:text-purple-600">
          TMI ID: {dbuserdetails.user.TMI}
        </p>

        {/* Profile Created For */}
        <p className="text-base text-gray-700 ml-2 font-medium transition-transform duration-200 hover:scale-105 hover:text-purple-600">
          Profile created for/by <span className="font-semibold">{dbuserdetails.user.createdFor}</span>
        </p>

        {/* Plan Name */}
        <div className="flex items-center ml-2 gap-x-2">
          <p className="text-gray-800 font-semibold">Plan Name:</p>
          <p
            className={`text-xl mobileLg:text-2xl laptop:text-3xl font-bold ${
              dbuserdetails.membershipDetails.membership === "Gold" && "text-yellow-400 glowGold"
            } ${
              dbuserdetails.membershipDetails.membership === "Silver" && "text-gray-400 glowSilver"
            } ${
              dbuserdetails.membershipDetails.membership === "Bronze" && "text-orange-600 glowBronze"
            } ${
              dbuserdetails.membershipDetails.membership === "Gold Plus" && "text-yellow-300 glowGoldPlus"
            } ${
              dbuserdetails.membershipDetails.membership === "Diamond" && "text-indigo-400 glowDiamond"
            } ${
              dbuserdetails.membershipDetails.membership === "Diamond Plus" && "text-cyan-500 glowDiamondPlus"
            }`}
          >
            {dbuserdetails.membershipDetails.membership}
          </p>
        </div>
      </div>

      {/* Badges Section - right aligned */}
      <div className="flex flex-col gap-y-2 items-center justify-center w-1/2 bg-gradient-to-r from-[#E3F2FD] to-[#BBDEFB] p-4 rounded-2xl shadow-md">
        <h2 className="text-xl font-medium text-gray-800">Background Verification Badges</h2>
        <div className="flex gap-x-2 items-center text-lg">
          <Badge title="Family Check" isVerified={dbuserdetails.user.isVerified} imageGreen={familyCheckGreen} imageGrey={familyCheckGrey} />
          <Badge title="Education Check" isVerified={dbuserdetails.user.isVerified} imageGreen={educationCheckGreen} imageGrey={educationCheckGrey} />
          <Badge title="Employment Check" isVerified={dbuserdetails.user.isVerified} imageGreen={employmentCheckGreen} imageGrey={employmentCheckGrey} />
          <Badge title="Socials Check" isVerified={dbuserdetails.user.isVerified} imageGreen={socialCheckGreen} imageGrey={socialCheckGrey} />
          <Badge title="Credit Check" isVerified={dbuserdetails.user.isVerified} imageGreen={creditCheckGreen} imageGrey={creditCheckGrey} />
          <Badge title="Criminal Check" isVerified={dbuserdetails.user.isVerified} imageGreen={criminalCheckGreen} imageGrey={criminalCheckGrey} />
        </div>
        <p className="text-xs text-gray-500">
          Last verified on{" "}
          <strong className="text-gray-700">12-09-2024</strong>
        </p>
      </div>
    </div>

    {/* Edit Profile Button */}
    {dbuserdetails.user.TMI === user && (
      <div className="w-full flex items-center justify-center mt-2">
        {!isEditing ? (
          <button
            className="btn relative w-full max-w-xs px-6 py-2 bg-gradient-to-r from-blue-500 to-green-500 text-white font-semibold rounded-md shadow-lg hover:from-green-500 hover:to-blue-500 transition-all duration-200 ease-in-out"
            type="button"
            id="btn-editProfile"
            onClick={handleEditClick}
          >
            Edit Profile
          </button>
        ) : (
          <button
            className="btn relative w-full max-w-xs px-6 py-2 bg-gradient-to-r from-purple-500 to-indigo-500 text-white font-semibold rounded-md shadow-lg hover:from-indigo-500 hover:to-purple-500 transition-all duration-200 ease-in-out"
            type="button"
            id="btn-submitProfile"
            onClick={handleSaveClick}
          >
            Save Profile
          </button>
        )}
      </div>
    )}
  </div>
        ) : (
          <div className="flex flex-col justify-between items-start bg-gradient-to-r from-white via-gray-50 to-gray-100 p-2 rounded-xl shadow-lg">
            {/* User Details and Badges Wrapper */}
            <div className="flex flex-col w-full">

              {/* User Details Section - left aligned */}
              <div className="flex flex-col space-y-4 flex-1 bg-gradient-to-r from-[#F0E7F4] via-[#F4F4F4] to-[#E0F7FA] p-2 rounded-2xl shadow-md">
                {/* Name */}
                <h1 className="text-3xl font-extrabold text-transparent bg-gradient-to-r from-purple-500 via-pink-500 to-red-500 bg-clip-text tracking-tight animate-textGlow shadow-md pb-2">
                  {`${dbuserdetails.user.firstname} ${dbuserdetails.user.lastname}`}
                </h1>

                {/* TMI */}
                <p className="text-lg text-gray-600 ml-2 italic transition-transform duration-200 hover:scale-105 hover:text-purple-600">
                  TMI ID: {dbuserdetails.user.TMI}
                </p>

                {/* Profile Created For */}
                <p className="text-base text-gray-700 ml-2 font-medium transition-transform duration-200 hover:scale-105 hover:text-purple-600">
                  Profile created for/by <span className="font-semibold">{dbuserdetails.user.createdFor}</span>
                </p>

                {/* Plan Name */}
                <div className="flex items-center ml-2 gap-x-2">
                  <p className="text-gray-800 font-semibold">Plan Name:</p>
                  <p
                    className={`text-xl mobileLg:text-2xl laptop:text-3xl font-bold ${
                      dbuserdetails.membershipDetails.membership === "Gold" && "text-yellow-400 glowGold"
                    } ${
                      dbuserdetails.membershipDetails.membership === "Silver" && "text-gray-400 glowSilver"
                    } ${
                      dbuserdetails.membershipDetails.membership === "Bronze" && "text-orange-600 glowBronze"
                    } ${
                      dbuserdetails.membershipDetails.membership === "Gold Plus" && "text-yellow-300 glowGoldPlus"
                    } ${
                      dbuserdetails.membershipDetails.membership === "Diamond" && "text-indigo-400 glowDiamond"
                    } ${
                      dbuserdetails.membershipDetails.membership === "Diamond Plus" && "text-cyan-500 glowDiamondPlus"
                    }`}
                  >
                    {dbuserdetails.membershipDetails.membership}
                  </p>
                </div>
              </div>

              {/* Badges Section - centered */}
              <div className="flex flex-col gap-y-2 items-center justify-center w-full bg-gradient-to-r from-[#E3F2FD] to-[#BBDEFB] p-4 rounded-2xl shadow-md mt-4">
                <h2 className="text-xl font-medium text-gray-800">Background Verification Badges</h2>
                <div className="flex gap-x-2 items-center text-lg">
                  <Badge title="Family Check" isVerified={dbuserdetails.user.isVerified} imageGreen={familyCheckGreen} imageGrey={familyCheckGrey} />
                  <Badge title="Education Check" isVerified={dbuserdetails.user.isVerified} imageGreen={educationCheckGreen} imageGrey={educationCheckGrey} />
                  <Badge title="Employment Check" isVerified={dbuserdetails.user.isVerified} imageGreen={employmentCheckGreen} imageGrey={employmentCheckGrey} />
                  <Badge title="Socials Check" isVerified={dbuserdetails.user.isVerified} imageGreen={socialCheckGreen} imageGrey={socialCheckGrey} />
                  <Badge title="Credit Check" isVerified={dbuserdetails.user.isVerified} imageGreen={creditCheckGreen} imageGrey={creditCheckGrey} />
                  <Badge title="Criminal Check" isVerified={dbuserdetails.user.isVerified} imageGreen={criminalCheckGreen} imageGrey={criminalCheckGrey} />
                </div>
                <p className="text-xs text-gray-500">
                  Last verified on{" "}
                  <strong className="text-gray-700">12-09-2024</strong>
                </p>
              </div>
            </div>

            {/* Image Slider (conditional for mobile) */}
            
            <ImageSlider
          photoArray={photoArray}
          className="rounded-2xl h-85 w-full tablet:w-auto relative mb-4 shadow-md"
        />

            

            {/* Edit Profile Button */}
            {dbuserdetails.user.TMI === user && (
              <div className="w-full flex items-center justify-center mt-2">
                {!isEditing ? (
                  <button
                    className="btn relative w-full max-w-xs px-6 py-2 bg-gradient-to-r from-blue-500 to-green-500 text-white font-semibold rounded-md shadow-lg hover:from-green-500 hover:to-blue-500 transition-all duration-200 ease-in-out"
                    type="button"
                    id="btn-editProfile"
                    onClick={handleEditClick}
                  >
                    Edit Profile
                  </button>
                ) : (
                  <button
                    className="btn relative w-full max-w-xs px-6 py-2 bg-gradient-to-r from-purple-500 to-indigo-500 text-white font-semibold rounded-md shadow-lg hover:from-indigo-500 hover:to-purple-500 transition-all duration-200 ease-in-out"
                    type="button"
                    id="btn-submitProfile"
                    onClick={handleSaveClick}
                  >
                    Save Profile
                  </button>
                )}
              </div>
            )}
          </div>
        )}


          {/* Buttons */}
          {dbuserdetails.user.TMI != user && (
            <div className="desktop:grid desktop:grid-cols-5 flex flex-wrap gap-3 mt-6">
              <button
                className="relative group/btnClicked border-2 px-2 btnProfile rounded-md h-10 font-medium"
                type="button"
                id="btn-expressinterest"
                style={{ backgroundColor: expressInterestColor }} 
                onClick={
                  () => expressInterest(dbuserdetails.user.TMI)
                  
                }
              >
                Express Interest
                <BottomGradient />
              </button>
              <button
                className="relative group/btn border-2 px-2 btnProfile rounded-md h-10 font-medium shadow-[0px_1px_0px_0px_#ffffff40_inset,0px_-1px_0px_0px_#ffffff40_inset]]"
                type="button"
                id="btn-shortlist"
                style={{ backgroundColor: shortlistColor }}
                onClick={
                  () => shortlistProfiles(dbuserdetails.user.TMI)
                }
                
              >
                Shortlist
                <BottomGradient />
              </button>
              <button
                className="relative group/btn border-2 px-2 btnProfile rounded-md h-10 font-medium shadow-[0px_1px_0px_0px_#ffffff40_inset,0px_-1px_0px_0px_#ffffff40_inset]]"
                type="button"
                id="btn-sendemail"
                onClick={() => navigate('/send-email', {
                  state: {
                    RecipientTMI: dbuserdetails.user.TMI,
                    firstname: dbuserdetails.user.firstname,
                    lastname: dbuserdetails.user.lastname,
                    emailid:dbuserdetails.user.emailid
                  }
                })}
              >
                Send Email
                <BottomGradient />
              </button>
              <button
                className="relative group/btn border-2 px-2 btnProfile rounded-md h-10 font-medium shadow-[0px_1px_0px_0px_#ffffff40_inset,0px_-1px_0px_0px_#ffffff40_inset]]"
                type="button"
                id="btn-block"
                onClick={() => {
                  blockUser(dbuserdetails.user.TMI)
                }}
              >
                Block
                <BottomGradient />
              </button>
              <button
                className="relative group/btn border-2 px-2 btnProfile rounded-md h-10 font-medium shadow-[0px_1px_0px_0px_#ffffff40_inset,0px_-1px_0px_0px_#ffffff40_inset]]"
                type="button"
                id="btn-viewContact"
                onClick={() => {
                  viewContact(dbuserdetails.user.TMI)
                  setContactVisible(true);
                  console.log("Get contact clicked");
                }}
              >
                Get Contact
                <BottomGradient />
              </button>
            </div>
          )}
          

          <hr className="border-red-600 my-12" />

          <div className="flex flex-col  gap-y-8">
                {/* About Me Intro */}
                {/* About Me */}
<div className="border-2 p-6 tablet:p-8 shadow-md rounded-lg bg-gradient-to-r from-yellow-100 to-yellow-300 transition-transform duration-300 hover:shadow-lg transform hover:scale-105">
  <h2 className="text-3xl font-medium text-gray-800 mb-6">ABOUT ME</h2>

  {aboutMeIntro.map((field) => (
    <React.Fragment key={field.title}>
      {field.title === "aboutMe" && (
        <div className="my-4">
          {!isEditing ? (
            <p className="text-gray-700">{dbuserdetails.about.aboutMe}</p>
          ) : (
            <textarea
              className="my-4 w-full h-32 px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400 resize-none"
              {...register("about.aboutMe")}
              placeholder="Tell us about yourself..."
              defaultValue={dbuserdetails.about.aboutMe}
            />
          )}
        </div>
      )}
    </React.Fragment>
  ))}

  {/* About Me Details */}
  <div className="grid grid-cols-1 tablet:grid-cols-2 gap-4">
    {aboutMe.map((field) => (
      <div key={field.title} className="border-b border-gray-300 pb-4 mb-4">
        {field.title === "Marital Status" && (
          <div className="flex justify-between items-center">
            {isEditing ? (
              <div className="flex items-center">
                <p className="mr-2 font-medium text-gray-800">{field.title}:</p>
                <select
                  {...register("about.maritalStatus")}
                  className="p-2 border border-accent rounded-lg w-auto"
                >
                  <option value="">Select Marital Status</option>
                  {field.options?.map((val, index) => (
                    <option value={val} key={index}>
                      {val}
                    </option>
                  ))}
                </select>
              </div>
            ) : (
              <div className="flex items-center">
                <p className="mr-2 font-medium text-gray-800">{field.title}:</p>
                <p className="font-semibold text-gray-700 w-auto">
                  {dbuserdetails?.about?.maritalStatus}
                </p>
              </div>
            )}

            {watch("about.maritalStatus") !== "Unmarried" && isEditing && (
              <>
                <div className="flex items-center gap-x-2">
                  <p className="mr-2 text-gray-800">Boy Child:</p>
                  <select
                    {...register("about.numberOfBoyChild")}
                    className="p-2 border border-accent rounded-lg w-auto"
                  >
                    <option value="">0</option>
                    {[1, 2, 3, 4, 5, 6].map((num) => (
                      <option value={num} key={num}>
                        {num}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex items-center gap-x-2">
                  <p className="mr-2 text-gray-800">Girl Child:</p>
                  <select
                    {...register("about.numberOfGirlChild")}
                    className="p-2 border border-accent rounded-lg w-auto"
                  >
                    <option value="">0</option>
                    {[1, 2, 3, 4, 5, 6].map((num) => (
                      <option value={num} key={num}>
                        {num}
                      </option>
                    ))}
                  </select>
                </div>
              </>
            )}

            {!isEditing && watch("about.maritalStatus") !== "Unmarried" && (
              <>
                <div className="flex items-center">
                  <p className="mr-2 text-gray-800">Girl Child:</p>
                  <p className="font-semibold text-gray-700 w-auto">
                    {dbuserdetails?.about?.numberOfGirlChild}
                  </p>
                </div>
                <div className="flex items-center">
                  <p className="mr-2 text-gray-800">Boy Child:</p>
                  <p className="font-semibold text-gray-700 w-auto">
                    {dbuserdetails?.about?.numberOfBoyChild}
                  </p>
                </div>
              </>
            )}
          </div>
        )}

        {field.title !== "Marital Status" && field.title !== "Known Languages" && (
          <div className="flex items-center">
            {/* Icon for each field */}
            <i className="fas fa-info-circle text-yellow-500 mr-2"></i>
            <p className="mr-2 font-medium text-gray-800">{field.title}:</p>
            {isEditing ? (
              <select
                {...register(field.registerValue as keyof typeof dbuserdetails)}
                className="p-2 border border-accent rounded-lg w-auto"
              >
                {field.options?.map((val, index) => (
                  <option value={val} key={index}>
                    {val}
                  </option>
                ))}
              </select>
            ) : (
              <p className="font-semibold text-gray-700 w-auto">
                {field.value || String(getNestedValue(dbuserdetails, field.registerValue))}
              </p>
            )}
          </div>
        )}

        {field.title === "Known Languages" && (
          <div className="flex items-center">
            {/* Icon for languages */}
            <i className="fas fa-language text-yellow-500 mr-2"></i>
            <p className="mr-2 font-medium text-gray-800">{field.title}:</p>
            {isEditing ? (
              <Controller
                control={control}
                name={field.registerValue as "about.knownLanguages"}
                render={({ field: formField }) => {
                  const knownLanguages = formField.value || dbuserdetails?.about?.knownLanguages || [];
                  const options = field.options || [];

                  return (
                    <Select
                      {...formField}
                      isMulti
                      options={options.map((option) => ({
                        label: String(option),
                        value: String(option),
                      }))}
                      value={knownLanguages.map((val: string) => ({
                        label: val,
                        value: val,
                      }))}
                      onChange={(selectedOptions) => {
                        formField.onChange(selectedOptions?.map((option) => option.value) || []);
                      }}
                    />
                  );
                }}
              />
            ) : (
              <p className="font-semibold text-gray-700">
                {Array.isArray(dbuserdetails?.about?.knownLanguages)
                  ? dbuserdetails.about.knownLanguages.join(", ")
                  : ""}
              </p>
            )}
          </div>
        )}
      </div>
    ))}
  </div>

  {isEditing ? (
    <div className="mt-6">
      <div className="flex flex-wrap items-center gap-4">
        {/* Country Selection */}
        <div className="flex items-center gap-x-2 w-auto">
          <p className="font-medium text-gray-800 w-auto">Country:</p>
          <p className="font-semibold text-gray-700 w-auto">{dbuserdetails?.user?.country || "-"}</p>
        </div>

        {/* State Selection */}
        <div className="flex items-center gap-x-2 w-auto">
          <p className="font-medium text-gray-800 w-auto">Residence State:</p>
          <select
            className="p-2 border border-accent rounded-lg w-auto"
            {...register("user.ResidenceState")}
          >
            <option value="">Select State</option>
            {locations.map((state) => (
              <option key={state.state} value={state.state}>
                {state.state}
              </option>
            ))}
          </select>
          {errors.user && (
            <p className="text-red-600 w-auto">{errors.user.ResidenceState?.message}</p>
          )}
        </div>

        {/* City Selection based on State */}
        {watch("user.ResidenceState") && (
          <div className="flex items-center gap-x-2 w-auto">
            <p className="font-medium text-gray-800 w-auto">Residence City:</p>
            <select
              className="p-2 border border-accent rounded-lg w-auto"
              {...register("user.ResidenceCity")}
            >
              <option value="">Select City</option>
              {locations
                .find((state) => state.state === watch("user.ResidenceState"))
                ?.cities.map((city) => (
                  <option key={city.name} value={city.name}>
                    {city.name}
                  </option>
                ))}
            </select>
            {errors.user && (
              <p className="text-red-600 w-auto">{errors.user.ResidenceCity?.message}</p>
            )}
          </div>
        )}
      </div>
    </div>
  ) : (
    <div className="mt-6">
      <div className="flex flex-wrap items-center gap-4">
        {/* Display Country */}
        <div className="flex items-center gap-x-2 w-auto">
          <p className="font-medium text-gray-800 w-auto">Country:</p>
          <p className="font-semibold text-gray-700 w-auto">{dbuserdetails?.user?.country || "-"}</p>
        </div>

        {/* Display State */}
        <div className="flex items-center gap-x-2 w-auto">
          <p className="font-medium text-gray-800 w-auto">Residence State:</p>
          <p className="font-semibold text-gray-700 w-auto">
            {dbuserdetails?.user?.ResidenceState || "-"}
          </p>
        </div>

        {/* Display City */}
        <div className="flex items-center gap-x-2 w-auto">
          <p className="font-medium text-gray-800 w-auto">Residence City:</p>
          <p className="font-semibold text-gray-700 w-auto">
            {dbuserdetails?.user?.ResidenceCity || "-"}
          </p>
        </div>
      </div>
    </div>
  )}
</div>
    


             

               {/* Family Details */}
               <div className="border-2 p-8 shadow-md rounded-lg bg-gradient-to-r from-orange-100 to-orange-400 transition-transform duration-300 hover:shadow-lg transform hover:scale-105">
  <h2 className="text-3xl font-medium text-gray-800 mb-6">FAMILY DETAILS</h2>

  {/* Mobile View */}
  <div className="block tablet:hidden">
    {familyDetails.map((field) => (
      <div key={field.title} className="border-b border-gray-300 pb-4 mb-4">
        <div className="flex items-center mb-4">
          <p className="font-medium text-gray-800 mr-4 whitespace-nowrap">{field.title}:</p>
          {isEditing ? (
            <select
              {...register(`familyDetails.${field.registerValue}` as const)}
              defaultValue={field.value || ""}
              className="p-2 border border-accent rounded-lg flex-grow w-full"
            >
              <option value="">{`Select ${field.title}`}</option>
              {field.options.map((val, index) => (
                <option value={val} key={index}>
                  {val}
                </option>
              ))}
            </select>
          ) : (
            <p className="font-semibold text-gray-700 flex-grow">
              {field.value || "-"}
            </p>
          )}
        </div>
      </div>
    ))}
  </div>

  {/* Tablet and Desktop View */}
  <div className="hidden tablet:block">
    <div className="grid grid-cols-1 tablet:grid-cols-2 gap-4">
      {familyDetails.map((field) => (
        <div key={field.title} className="border-b border-gray-300 pb-4 mb-4">
          <div className="flex items-center mb-4">
            <p className="font-medium text-gray-800 mr-4 whitespace-nowrap">{field.title}:</p>
            {isEditing ? (
              <select
                {...register(`familyDetails.${field.registerValue}` as const)}
                defaultValue={field.value || ""}
                className="p-2 border border-accent rounded-lg flex-grow"
              >
                <option value="">{`Select ${field.title}`}</option>
                {field.options.map((val, index) => (
                  <option value={val} key={index}>
                    {val}
                  </option>
                ))}
              </select>
            ) : (
              <p className="font-semibold text-gray-700 flex-grow">
                {field.value || "-"}
              </p>
            )}
          </div>
        </div>
      ))}
    </div>
  </div>
</div>









              {/* Contact Details */}
              <div className="border-2 p-8 shadow-md rounded-lg bg-gradient-to-r from-blue-100 to-blue-400 transition-transform duration-300 hover:shadow-lg transform hover:scale-105">
  <div className="flex justify-between mb-4">
    <h2 className="text-3xl font-medium text-gray-800">CONTACT DETAILS</h2>
    {dbuserdetails.user.TMI !== user && (
      <button
        className="relative group/btn px-6 border-2 btnProfile rounded-md h-10 font-medium shadow-[0px_1px_0px_0px_#ffffff40_inset,0px_-1px_0px_0px_#ffffff40_inset]"
        type="button"
        id="btn-getcontact"
        onClick={() => {
          viewContact(dbuserdetails.user.TMI)
          setContactVisible(true);
          console.log("Get contact clicked");
        }}
      >
        Get Contact
        <BottomGradient />
      </button>
    )}
  </div>

  {/* Mobile View */}
  <div className="block tablet:hidden">
    {contactDetails.map((field) => (
      <div key={field.title} className="border-b border-gray-300 pb-4 mb-4 flex gap-x-2 items-center">
        <p className="font-medium text-gray-800 whitespace-nowrap">{field.title}:</p>
        {isEditing ? (
          <>
            {field.title !== "Email ID" && (
              <input
                {...register(
                  field.registerValue as
                    | "familyDetails.fatherName"
                    | "familyDetails.motherName"
                    | "familyDetails.fatherOccupation"
                    | "familyDetails.motherOccupation"
                    | "contact.churchName"
                    | "contact.churchLocation"
                    | "contact.phoneNumber"
                )}
                type="text"
                className="p-2 border border-accent rounded-lg flex-grow"
              />
            )}
            {field.title === "Email ID" && (
              <p className="font-semibold text-gray-700">{field.value}</p>
            )}
          </>
        ) : (
          <p
            className={`font-semibold ${
              !isContactVisible && dbuserdetails.user.TMI !== user
                ? "blurred-field"
                : "text-gray-700"
            }`}
          >
            {field.value}
          </p>
        )}
      </div>
    ))}
  </div>

  {/* Tablet and Desktop View */}
  <div className="hidden tablet:block">
    <div className="grid grid-cols-1 tablet:grid-cols-2 gap-4">
      {contactDetails.map((field) => (
        <div key={field.title} className="border-b border-gray-300 pb-4 mb-4 flex gap-x-2">
          <p className="font-medium text-gray-800 whitespace-nowrap">{field.title}:</p>
          {isEditing ? (
            <>
              {field.title !== "Email ID" && (
                <input
                  {...register(
                    field.registerValue as
                      | "familyDetails.fatherName"
                      | "familyDetails.motherName"
                      | "familyDetails.fatherOccupation"
                      | "familyDetails.motherOccupation"
                      | "contact.churchName"
                      | "contact.churchLocation"
                      | "contact.phoneNumber"
                  )}
                  type="text"
                  className="p-2 border border-accent rounded-lg flex-grow"
                />
              )}
              {field.title === "Email ID" && (
                <p className="font-semibold text-gray-700">{field.value}</p>
              )}
            </>
          ) : (
            <p
              className={`font-semibold ${
                !isContactVisible && dbuserdetails.user.TMI !== user
                  ? "blurred-field"
                  : "text-gray-700"
              }`}
            >
              {field.value}
            </p>
          )}
        </div>
      ))}
    </div>
  </div>
</div>



                

                {/* Professional Details */}
                {/* Professional Details */}
<div className="border-2 p-8 shadow-md rounded-lg bg-gradient-to-r from-pink-100 to-pink-400 transition-transform duration-300 hover:shadow-lg transform hover:scale-105">
  <h2 className="text-3xl font-medium text-gray-800 mb-4">PROFESSIONAL DETAILS</h2>
  <div className="grid grid-cols-1 mobileLg:grid-cols-2 gap-4">
    {professionalDetails.map((field) => (
      <div key={field.title} className="border-b border-gray-300 pb-4 mb-4 flex gap-x-2">
        <p className="place-content-center font-medium text-gray-800">{field.title}:</p>

        {/* For education and occupation */}
        {(field.title === "Education" || field.title === "Occupation") &&
          (isEditing ? (
            <select
              {...register(field.registerValue as "educationAndEmployment.Occupation" | "educationAndEmployment.Education", {
                value: dbuserdetails?.educationAndEmployment?.[field.registerValue.split(".")[1] as keyof EducationAndEmployment] || "",
              })}
              className="p-2 border border-accent rounded-lg w-[60%]"
              defaultValue={dbuserdetails?.educationAndEmployment?.[field.registerValue.split(".")[1] as keyof EducationAndEmployment] || ""}
            >
              <option value="">{field.title}</option>
              {field.options && typeof field.options === 'object' && !Array.isArray(field.options) &&
                Object.entries(field.options).map(([key, values]) => (
                  <optgroup label={key} key={key}>
                    {Array.isArray(values) ? values.map((value) => (
                      <option value={value} key={value}>
                        {value}
                      </option>
                    )) : null}
                  </optgroup>
                ))
              }
            </select>
          ) : (
            <p className="font-semibold text-gray-700">
              {dbuserdetails?.educationAndEmployment?.[field.registerValue.split(".")[1] as keyof EducationAndEmployment] || "Nothing specified"}
            </p>
          ))}

        {/* No options/Text field */}
        {!field.options &&
          (isEditing ? (
            <input
              {...register(field.registerValue as "educationAndEmployment.companyName" | "educationAndEmployment.collegeName", {
                value: dbuserdetails?.educationAndEmployment?.[field.registerValue.split(".")[1] as keyof EducationAndEmployment] || "",
              })}
              type="text"
              className="p-2 border border-accent rounded-lg"
              defaultValue={dbuserdetails?.educationAndEmployment?.[field.registerValue.split(".")[1] as keyof EducationAndEmployment] || ""}
            />
          ) : (
            <p className="font-semibold text-gray-700">
              {dbuserdetails?.educationAndEmployment?.[field.registerValue.split(".")[1] as keyof EducationAndEmployment] || "Nothing specified"}
            </p>
          ))}

        {/* General fields */}
        {(field.title === "Employment Type" || field.title === "Annual Income") &&
          (isEditing ? (
            <select
              {...register(field.registerValue as "educationAndEmployment.employmentType" | "educationAndEmployment.Salary", {
                value: dbuserdetails?.educationAndEmployment?.[field.registerValue.split(".")[1] as keyof EducationAndEmployment] || "",
              })}
              className="p-2 border border-accent rounded-lg"
              defaultValue={dbuserdetails?.educationAndEmployment?.[field.registerValue.split(".")[1] as keyof EducationAndEmployment] || ""}
            >
              <option value="">{field.title}</option>
              {Array.isArray(field.options) ? (
                field.options.map((val: string) => (
                  <option value={val} key={val}>
                    {val}
                  </option>
                ))
              ) : (
                Object.entries(field.options || {}).map(([key, values]) => (
                  <optgroup label={key} key={key}>
                    {Array.isArray(values) ? values.map((value) => (
                      <option value={value} key={value}>
                        {value}
                      </option>
                    )) : null}
                  </optgroup>
                ))
              )}
            </select>
          ) : (
            <p className="font-semibold text-gray-700">
              {dbuserdetails?.educationAndEmployment?.[field.registerValue.split(".")[1] as keyof EducationAndEmployment] || "Nothing specified"}
            </p>
          ))}
      </div>
    ))}
  </div>
</div>






              {/* Lifestyle */}
              <div className="border-2 p-8 shadow-md rounded-lg bg-gradient-to-r from-green-100 to-green-400 transition-transform duration-300 hover:shadow-lg transform hover:scale-105">
  <h2 className="text-3xl font-medium text-gray-800 mb-4">LIFESTYLE</h2>
  <div className="grid grid-cols-1 mobileLg:grid-cols-2 gap-4">
    {lifeStyle.map((field) => (
      <div className="border-b border-gray-300 pb-4 mb-4 flex gap-x-2" key={field.title}>
        <p className="font-medium text-gray-800 flex-shrink-0 w-auto">{field.title}:</p>
        {isEditing ? (
          <Controller
            control={control}
            name={field.registerValue as
              | "lifeStyle.hobbies"
              | "lifeStyle.interests"
              | "lifeStyle.favouriteMusic"
              | "lifeStyle.favouriteReads"
              | "lifeStyle.favouriteFood"
              | "lifeStyle.sports"
              | "lifeStyle.dressStyles"
            }
            render={({ field: formField }) => (
              <Select
                {...formField}
                isMulti
                options={field?.options.map((option) => ({
                  label: option,
                  value: option,
                }))}
                value={formField?.value?.map((val: string) => ({
                  label: val,
                  value: val,
                }))}
                onChange={(selectedOptions) => {
                  formField.onChange(selectedOptions.map((option) => option.value));
                }}
                className="p-2 border border-accent rounded-lg w-full"
                styles={{
                  control: (base) => ({
                    ...base,
                    minHeight: "40px", // Adjust the height of the select box
                  }),
                }}
              />
            )}
          />
        ) : (
          <p className="font-semibold text-gray-700 flex-1 overflow-hidden whitespace-normal">
            {/* Display multiple values separated by commas or 'Not specified' */}
            {Array.isArray(field.value) && field.value.length > 0
              ? field.value.join(", ")
              : "Not specified"}
          </p>
        )}
      </div>
    ))}
  </div>
</div>







               {/* Partner Preferences */}
              <div className="border-2 p-8 shadow-md rounded-lg bg-gradient-to-r from-blue-100 to-blue-400 transition-transform duration-300 hover:shadow-lg transform hover:scale-105">
                  <h2 className="text-3xl font-medium text-gray-800 mb-4">PARTNER PREFERENCES</h2>

                      {/* Basic Preferences */}
                      <div>
  <h3 className="text-2xl py-2 mt-4 text-gray-700">BASIC PREFERENCES</h3>
  <div className="grid grid-cols-1 mobileLg:grid-cols-2 gap-4 mt-4">
    {partnerPreferences.basicPreferences.map((field) => (
      <div className="grid grid-cols-2 gap-x-2 mb-4" key={field.title}>
        <p className="place-content-center font-medium text-gray-800">{field.title}:</p>

        {/* For age and height */}
        {(field.title === "Min Age" ||
          field.title === "Max Age" ||
          field.title === "Min Height" ||
          field.title === "Max Height") &&
          (isEditing ? (
            <select
              {...register(
                field.registerValue as
                  | "partnerPreferences.partnerMinAge"
                  | "partnerPreferences.partnerMaxAge"
                  | "partnerPreferences.partnerMinHeight"
                  | "partnerPreferences.partnerMaxHeight"
              )}
              className="p-2 border border-accent rounded-lg w-[60%]"
            >
              <option value="">{field.title}</option>
              {field.options?.map((val) => (
                <option value={val} key={val}>
                  {val}
                </option>
              ))}
            </select>
          ) : (
            <p className="font-semibold">
              {/* Display single value for age/height fields */}
              {field.value || "Not specified"}
            </p>
          ))}

        {/* For other fields */}
        {!(
          field.title === "Min Age" ||
          field.title === "Max Age" ||
          field.title === "Min Height" ||
          field.title === "Max Height"
        ) && (isEditing ? (
          <Controller
            control={control}
            name={field.registerValue as
              | "partnerPreferences.partnerMaritalStatus"
              | "partnerPreferences.partnerMotherTongue"
              | "partnerPreferences.partnerCaste"
              | "partnerPreferences.partnerPhysicalStatus"
              | "partnerPreferences.partnerEatingHabits"
              | "partnerPreferences.partnerDrinkingHabits"
              | "partnerPreferences.partnerSmokingHabits"
              | "partnerPreferences.partnerDenomination"
              | "partnerPreferences.partnerSpirituality"
            }
            render={({ field: formField }) => (
              <Select
                {...formField}
                isMulti
                options={field?.options.map((option) => ({
                  label: String(option),
                  value: String(option),
                })) || []}
                value={(formField?.value || []).map((val: string) => ({
                  label: String(val),
                  value: String(val),
                }))}
                onChange={(selectedOptions) => {
                  formField.onChange(
                    selectedOptions.map((option) => option.value)
                  );
                }}
                className="p-2 border border-accent rounded-lg w-full min-w-[100px] max-w-full"
              />
            )}
          />
        ) : (
          <p className="font-semibold place-content-center whitespace-normal">
            {/* Display multiple values separated by commas */}
            {Array.isArray(field.value) ? field.value.join(", ") : field.value || "Not specified"}
          </p>
        ))}
        <hr className="my-2 border-gray-300" />
      </div>
    ))}
  </div>
</div>


                      
                      {/* Professional Preferences */}
                      <div>
      <h3 className="text-2xl py-2 mt-4 text-gray-700">PROFESSIONAL PREFERENCES</h3>
      <div className="grid grid-cols-1 mobileLg:grid-cols-2 gap-4 mt-4">
        {partnerPreferences.professionalPreferences.map((field) => (
          <div className="grid grid-cols-2 gap-x-2 mb-4" key={field.title}>
            <p className="place-content-center font-medium text-gray-800">{field.title}:</p>

            {isEditing ? (
  <Controller
    control={control}
    name={field.registerValue as
      | "partnerPreferences.partnerEmploymentType"
      | "partnerPreferences.partnerAnnualIncome"
      | "partnerPreferences.partnerOccupation"
      | "partnerPreferences.partnerEducation"
    }
    render={({ field: formField }) => {
      // Prepare options
      const options = Array.isArray(field.options)
        ? field.options.map((option) => ({
            label: option,
            value: option,
          }))
        : Object.entries(field.options).flatMap(([key, values]) =>
            (values as string[]).map((value) => ({
              label: `${key} - ${value}`,
              value: value,
            }))
          );

      // Prepare the value for the Select component
      const value = (formField.value || []).map((val) =>
        typeof val === 'string' ? { label: val, value: val } : val
      );

      // Debugging: Log current value and options
      console.log("Current Value:", value);
      console.log("Options:", options);

      return (
        <Select
          {...formField}
          isMulti
          options={options}
          value={value} // Set the value from formField
          onChange={(selectedOptions) => {
            // Handle the change and update the value in the form state
            formField.onChange(
              (selectedOptions || []).map((option) => option.value)
            );
          }}
          className="p-2 border border-accent rounded-lg"
          placeholder={`Select ${field.title}`}
        />
      );
    }}
  />
) : (
  <p className="font-semibold place-content-center whitespace-normal">
    {/* Display multiple values separated by commas */}
    {Array.isArray(field.value) ? field.value.join(", ") : field.value || "Not specified"}
  </p>
)}


            <hr className="my-2 border-gray-300" />
          </div>
        ))}
      </div>
    </div>


                  {/* Location Preferences */}
                  <div>
    <h3 className="text-2xl py-2 mt-4 text-gray-700">LOCATION PREFERENCES</h3>
    <div className="grid grid-cols-1 mobileLg:grid-cols-2 gap-4 mt-4">
        {/* Partner Country */}
        
<div className="grid grid-cols-2 gap-x-2 mb-4">
    <p className="place-content-center font-medium text-gray-800">Country:</p>
    {isEditing ? (
        <Controller
            control={control}
            name="partnerPreferences.partnerCountry"
            render={({ field: formField }) => (
                <Select
                    {...formField}
                    options={countriesWithPhoneCodes.map(({ country }) => ({
                        label: country,
                        value: country,
                    }))}
                    isMulti
                    value={Array.isArray(formField.value) ? formField.value.map(val => ({ label: val, value: val })) : []}
                    onChange={(selectedOptions) => {
                        const selectedCountries = selectedOptions ? selectedOptions.map(option => option.value) : [];
                        formField.onChange(selectedCountries);
                    }}
                    className="p-2 border border-accent rounded-lg"
                />
            )}
        />
    ) : (
        <p className="font-semibold">
            {Array.isArray(dbuserdetails?.partnerPreferences?.partnerCountry) 
                ? dbuserdetails.partnerPreferences.partnerCountry.join(', ') 
                : "Not specified"}
        </p>
    )}
    <hr className="my-2 border-gray-300" />
</div>


        {/* Partner State(s) */}
        <div className="grid grid-cols-2 gap-x-2 mb-4">
            <p className="place-content-center font-medium text-gray-800">State(s):</p>
            {isEditing ? (
                <Controller
                    control={control}
                    name="partnerPreferences.partnerState"
                    render={({ field: formField }) => (
                        
                            
                                <Select
                                    {...formField}
                                    isMulti
                                    options={locations.map(location => ({
                                        label: location.state,
                                        value: location.state,
                                    }))}
                                    value={Array.isArray(formField.value) ? formField.value.map(val => ({ label: val, value: val })) : []}
                                    onChange={(selectedOptions) => {
                                        const selectedStates = selectedOptions ? selectedOptions.map(option => option.value) : [];
                                        setSelectedStates(selectedStates);
                                        formField.onChange(selectedStates);
                                    }}
                                    className="p-2 border border-accent rounded-lg"
                                />
                             
                        
                    )}
                />
            ) : (
                <p className="font-semibold">{dbuserdetails?.partnerPreferences?.partnerState?.join(', ') || "Not specified"}</p>
            )}
            <hr className="my-2 border-gray-300" />
        </div>

        {/* Partner City(s) */}
        <div className="grid grid-cols-2 gap-x-2 mb-4">
            <p className="place-content-center font-medium text-gray-800">City(s):</p>
            {isEditing ? (
                <Controller
                    control={control}
                    name="partnerPreferences.partnerCity"
                    render={({ field: formField }) => (
                        <>
                           
                                <Select
                                    {...formField}
                                    isMulti
                                    options={locations
                                        .filter(location => selectedStates.includes(location.state))
                                        .flatMap(location => location.cities.map(city => ({
                                            label: city.name,
                                            value: city.name,
                                        })))
                                    }
                                    value={Array.isArray(formField.value) ? formField.value.map(val => ({ label: val, value: val })) : []}
                                    onChange={(selectedOptions) => {
                                        const selectedCities = selectedOptions ? selectedOptions.map(option => option.value) : [];
                                        setSelectedCities(selectedCities);
                                        formField.onChange(selectedCities);
                                    }}
                                    className="p-2 border border-accent rounded-lg"
                                />
                           
                        </>
                    )}
                />
            ) : (
                <p className="font-semibold">{dbuserdetails?.partnerPreferences?.partnerCity?.join(', ') || "Not specified"}</p>
            )}
            <hr className="my-2 border-gray-300" />
        </div>

        {/* Partner Locality/ies */}
        <div className="grid grid-cols-2 gap-x-2 mb-4">
            <p className="place-content-center font-medium text-gray-800">Locality/ies:</p>
            {isEditing ? (
                <Controller
                    control={control}
                    name="partnerPreferences.partnerLocality"
                    render={({ field: formField }) => (
                        <>
                            
                                <Select
                                    {...formField}
                                    isMulti
                                    options={locations
                                        .flatMap(location =>
                                            location.cities
                                                .filter(city => selectedCities.includes(city.name))
                                                .flatMap(city => city.localities.map(locality => ({
                                                    label: locality,
                                                    value: locality,
                                                })))
                                        )
                                    }
                                    value={Array.isArray(formField.value) ? formField.value.map(val => ({ label: val, value: val })) : []}
                                    onChange={(selectedOptions) => {
                                        formField.onChange(selectedOptions.map(option => option.value));
                                    }}
                                    className="p-2 border border-accent rounded-lg"
                                />
                            
                        </>
                    )}
                />
            ) : (
                <p className="font-semibold">{dbuserdetails?.partnerPreferences?.partnerLocality?.join(', ') || "Not specified"}</p>
            )}
            <hr className="my-2 border-gray-300" />
        </div>
    </div>
</div>






                  

              </div>






                
                
                
          </div>
        </form>
        {screenType === "desktop" && (
          <div className="laptop:w-[24rem] desktop:w-[28rem] relative flex flex-col gap-y-8 h-full">
          {/* Gallery Images Tag */}
          <div className="text-center text-xl font-bold text-gray-800 mb-2">
            Gallery
          </div>
        
          <ImageSlider
            photoArray={photoArray}
            className="rounded-2xl relative h-[29rem] mb-4 shadow-lg"
          />
        
          <div className="flex flex-col gap-y-4 px-4">
            <div className="bg-red-500 flex-grow text-center h-[10rem] rounded-lg shadow-md py-2 flex items-center justify-center">
              AdSpace 4.1
            </div>
            <div className="bg-green-500 flex-grow text-center h-[10rem] rounded-lg shadow-md py-2 flex items-center justify-center">
              AdSpace 4.2
            </div>
            <div className="bg-blue-600 flex-grow text-center h-[10rem] rounded-lg shadow-md py-2 flex items-center justify-center">
              AdSpace 4.3
            </div>
          </div>
        </div>
        
        
        )}
      </div>
    </>
  );
};

const BottomGradient = () => {
  return (
    <>
      <span className="group-hover/btn:opacity-100 block transition duration-500 opacity-0 absolute h-px w-full -bottom-px inset-x-0 bg-gradient-to-r from-transparent via-cyan-500 to-transparent" />
      <span className="group-hover/btn:opacity-100 blur-sm block transition duration-500 opacity-0 absolute h-px w-1/2 mx-auto -bottom-px inset-x-10 bg-gradient-to-r from-transparent via-indigo-500 to-transparent" />
    </>
  );
};

const Badge: React.FC<{
  title: string;
  imageGrey: string;
  imageGreen: string;
  isVerified: boolean;
}> = ({ title, imageGrey, imageGreen, isVerified }) => {
  return (
    <div className="relative group">
      <p className="absolute bottom-16 text-sm w-max bg-slate-300 font-medium py-1 px-2 rounded z-10 hidden group-hover:block">
        {title}
      </p>
      {isVerified ? (
        <img
          src={imageGreen}
          alt="Crime Check Badge"
          className="h-8 mobileLg:h-12"
        />
      ) : (
        <img
          src={imageGrey}
          alt="Crime Check Badge"
          className="h-8 mobileLg:h-12"
        />
      )}
    </div>
  );
};

export default Profile;
