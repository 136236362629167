import { Link, Outlet } from "react-router-dom";

const links = {
  newEvent: ["New Event", "/admin/events/newevent"],
  newNews: ["New News", "/admin/events/newnews"],
  newBlog: ["New Blog", "/admin/events/newblog"],
  newGallery: ["New Gallery Photo", "/admin/events/newgallery"],
  oldEvents: ["Old Events", "/admin/events/oldevents"],
  oldGallery: ["Old Gallery Photos", "/admin/events/oldgallery"],
};

const EventsGallery: React.FC = () => {
  return (
    <>
      <div className="flex">
        <div className="w-[20%] flex flex-col px-6 py-8 gap-y-4 min-h-[32rem] border-r">
          {Object.values(links).map((value) => (
            <Link
              to={value[1]}
              className="bg-accent/50 focus:bg-accent active:bg-gray-600 border border-accent py-2 px-4 text-lg text-black font-medium rounded-xl"
            >
              {value[0]}
            </Link>
          ))}
        </div>
        <div className="w-[80%]">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default EventsGallery;
