// CustomerSupportDashboard.tsx
import React, { useEffect, useState } from 'react';
import axios from 'axios';

interface FollowDetail {
  TMI: string;
  followedAt: string;
  userName: string;
  phoneNumber: string | null;
  email: string | null;
  membership:string | null;
}

const CustomerSupportDashboard: React.FC = () => {
  const [follows, setFollows] = useState<FollowDetail[]>([]);
  const apiUrl = import.meta.env.VITE_API_URL; // Replace with your API URL

  useEffect(() => {
    const fetchFollows = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/customer-follow`);
        setFollows(response.data);
      } catch (error) {
        console.error('Error fetching follow-up data:', error);
      }
    };
    fetchFollows();
  }, []);

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <h2 className="text-2xl font-bold mb-4">Customer Follow-up Dashboard</h2>
      <table className="min-w-full bg-white rounded-lg shadow-sm">
        <thead>
          <tr className="bg-gray-200 text-gray-600 text-left text-sm uppercase tracking-wider">
            <th className="px-6 py-3">TMI</th>
            <th className="px-6 py-3">User Name</th>
            <th className="px-6 py-3">Phone Number</th>
            <th className="px-6 py-3">Email</th>
            <th className="px-6 py-3">Membership Type</th>
            <th className="px-6 py-3">Visited Membership Page At</th>
          </tr>
        </thead>
        <tbody>
          {follows.map((follow) => (
            <tr key={follow.TMI} className="border-t text-gray-700">
              <td className="px-6 py-4 font-semibold">{follow.TMI}</td>
              <td className="px-6 py-4">{follow.userName}</td>
              <td className="px-6 py-4">{follow.phoneNumber || 'N/A'}</td>
              <td className="px-6 py-4">{follow.email || 'N/A'}</td>
              <th className="px-6 py-3">{follow.membership}</th>
              <td className="px-6 py-4">{new Date(follow.followedAt).toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CustomerSupportDashboard;
