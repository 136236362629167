import Dropdown from "../components/Dropdown";
import { dropdownData } from "../utils/data";
import videoBgV from "/images/videoBgV.png";
import { Link } from "react-router-dom";

const VideoBgV: React.FC = () => {
  return (
    <>
      <div className="m-10 rounded-2xl p-10 bg-gradient-to-br from-accent to-accentSec">
        <div className="flex gap-x-4 items-center">
          <img
            src={videoBgV}
            alt="Video Background Verification"
            className="h-[60px]"
          />
          <p className="text-5xl">Video Background Verification</p>
        </div>
        <div className="bg-white grid gap-y-12 py-14 mt-6 rounded-2xl px-12">
          <div className="grid grid-cols-3 gap-x-2">
            {/* Main info */}
            <div className="flex flex-col gap-y-6">
              <div className="flex items-center gap-x-4">
                <p className="text-xl">Language Preferred:</p>
                <Dropdown
                  options={dropdownData.localLanguages}
                  className="p-2 rounded-2xl border"
                />
              </div>
              <div className="flex items-center gap-x-4">
                <p className="text-xl">Available Date:</p>
                <input
                  type="date"
                  name="available-date"
                  id="available-date"
                  className="p-2 rounded-2xl border h-fit"
                />
              </div>
              <div className="flex items-center gap-x-4">
                <p className="text-xl">Time slot:</p>
                <Dropdown
                  options={["1", "2"]}
                  className="p-2 rounded-2xl border"
                />
              </div>
            </div>
            {/* Personal Fields */}
            <div className="flex flex-col gap-y-5">
              <div className="flex items-center gap-x-4">
                <p className="text-xl">Name:</p>
                <input
                  type="text"
                  placeholder="Your Name"
                  className="py-2 px-4 rounded-2xl border"
                />
              </div>
              <div className="flex items-center gap-x-4">
                <p className="text-lg">Contact Number:</p>
                <input
                  type="text"
                  placeholder="Your Number"
                  className="py-2 px-4 rounded-2xl border"
                />
              </div>
              <div className="flex items-center gap-x-4">
                <p className="text-xl">Email ID:</p>
                <input
                  type="text"
                  placeholder="Your Email"
                  className="py-2 px-4 rounded-2xl border"
                />
              </div>
            </div>
            {/* Comment */}
            <div className="flex flex-col gap-y-5">
              <div>
                <p className="text-xl">Your Comments:</p>
                <textarea
                  name="comment-marital-counselling"
                  id="comment-marital-counselling"
                  className="border rounded-2xl min-w-full p-2 mt-2"
                  rows={4}
                  cols={50}
                  placeholder="Enter your text here..."
                  required
                />
              </div>
              <div className="flex place-content-center">
                <button
                  className="relative w-full px-8 group/btn border-2 text-black rounded-md h-10 font-medium shadow-[0px_1px_0px_0px_#ffffff40_inset,0px_-1px_0px_0px_#ffffff40_inset]]"
                  type="button"
                >
                  Book Appointment
                  <BottomGradient />
                </button>
              </div>
            </div>
          </div>
          <hr className="border-accentSec" />
          <div>
            <p className="text-center text-3xl font-semibold">How it works</p>
            <ul className="text-xl pt-5 flex flex-col gap-y-2">
              <li>
                1. Fill the Video Background Verification form & book an
                appointment
              </li>
              <li>
                2. We will share the video meeting details to your email &
                whatsapp.
              </li>
              <li>
                3. Join the meeting and Our Relationship manager will guide you
                on different verification steps.
              </li>
              <li>4. Show your original documents on camera.</li>
              <li>
                5. Once we have verified your documents, you will be provided
                with the appropriate verification badges on your profile.
              </li>
              <li>
                6. Post the session, please share your{" "}
                <Link to="/feedback" className="font-medium text-blue-600">
                  feedback
                </Link>{" "}
                with us.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default VideoBgV;

const BottomGradient = () => {
  return (
    <>
      <span className="group-hover/btn:opacity-100 block transition duration-500 opacity-0 absolute h-px w-full -bottom-px inset-x-0 bg-gradient-to-r from-transparent via-cyan-500 to-transparent" />
      <span className="group-hover/btn:opacity-100 blur-sm block transition duration-500 opacity-0 absolute h-px w-1/2 mx-auto -bottom-px inset-x-10 bg-gradient-to-r from-transparent via-indigo-500 to-transparent" />
    </>
  );
};
