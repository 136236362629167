interface DropdownProps {
  options: (string | number)[];
  className: string;
  id?: string;
  onChange?: any;
  rest?: any;
  children?: any;
}

const Dropdown: React.FC<DropdownProps> = ({
  options,
  className,
  rest,
  children,
}) => {
  return (
    <select className={className} {...rest}>
      {children}
      {options.map((option) => (
        <option value={option} key={option} className="p-2">
          {option}
        </option>
      ))}
    </select>
  );
};

export default Dropdown;
