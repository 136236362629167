import FeaturedProfiles from "../components/FeaturedProfiles";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useAuthUser } from '../pages/AuthUserContext';
import { useUser } from './UserContext';
import { useNavigate } from "react-router-dom";
import MarriageCounselling from "/images/marriageCounselling.png";
import MultiLang from "/images/MultiLang.png";
import OnlineBgV from "/images/onlineBgV.png";
import MarriageRegistration from "/images/marriageRegistration.png";
import RelationshipManager from "/images/CustomerSupport.png";
import WhatsappSupport from "/images/WhatsAppSupport.png";
import Journey from "/images/Journey.png";
import useScreenType from "../hooks/useScreenType";

const UserHome: React.FC = () => {
  
  const screenType = useScreenType();
  const navigate = useNavigate();
  const user = useAuthUser(); // Assuming this is always set
  const TMI=user.user;
 console.log(user)
 
  const { setUserType } = useUser();
  
  useEffect(() => {
    setUserType('user');
});



  return (
    <>
   
 
   {screenType == "desktop"  && (
      <section className="flex flex-col h-screen  w-full overflow-hidden relative gap-5 laptop:px-20 laptop:py-8">
        <div className=" absolute inset-0 h-full w-full  ">
          {/* Image Slider */}
          <div className="slider flex h-full animate-slider">
            <div className="slide h-full w-full flex justify-center items-center">
              <img
                src="/images/triune-christian-matrimony-signupCover.jpg" // Replace with your image path
                alt="Background"
                className="h-full w-full object-cover"
              />
            </div>
            <div className="slide h-full w-full flex justify-center items-center">
              <img
                src="/images/matrimonybg2.jpg" // Replace with your image path
                alt="Background"
                className="h-full w-full object-cover"
              />
            </div>
            <div className="slide h-full w-full flex justify-center items-center">
              <img
                src="/images/matrimonybg3.jpg" // Replace with your image path
                alt="Background"
                className="h-full w-full object-cover"
              />
            </div>
          </div>
        </div>

        <h1 className="relative  signup-card-heading w-[60%] self-start mt-20 sm:text-2xl tablet:text-4xl tablet:mt-40 tablet:w-[40%] laptop:text-5xl laptop:mt-24 laptop:w-[50%] laptop:text-left desktop:text-6xl desktop:mt-20 desktop:w-[60%] text-center text-black">
          I Have Found The One <br /><br/> My Heart Loves
          <br />
          <span className="text-3xl text-right  ml-20">Song of Solomon 3:4</span>
        </h1>

        {/* Login form for mobile and tablet */}
        
      </section>)}

      <style >{`
        

        .slider {
          display: flex;
          height: 100%;
          animation: slide 9s infinite; /* Duration of the entire slide */
        }

        .slide {
          width: 100%; /* Each slide takes full width */
          height: 100%; /* Full height for each slide */
          flex-shrink: 0; /* Prevent shrinking */
        }

        img {
          height: 100%; /* Fill height of the slide */
          width: 100%; /* Fill width of the slide */
          object-fit: cover; /* Ensures full coverage while maintaining aspect ratio */
        }

        @keyframes slide {
          0%, 20% {
            transform: translateX(0%); /* Show first image */
          }
          30%, 50% {
            transform: translateX(-100%); /* Show second image */
          }
          60%, 80% {
            transform: translateX(-200%); /* Show third image */
          }
          100% {
            transform: translateX(0%); /* Loop back to first image */
          }
        }
      `}</style>
      

      {screenType !== "desktop" && screenType !== "laptop" && (
  <div className="relative flex justify-center items-center w-full h-[40vh] sm:h-[50vh] tablet:h-[60vh] overflow-hidden">
    {/* Heading appears over the image slider */}
    <h1 className="signup-card-heading absolute z-10 w-[90%] text-2xl sm:text-3xl tablet:text-4xl laptop:text-5xl text-center text-black">
      I Have Found The One <br />
      <br /> My Heart Loves
      <br />
      <span className="text-2xl sm:text-3xl ml-4 sm:ml-10">Song of Solomon 3:4</span>
    </h1>

    {/* Image slider */}
    <div className="slider-container w-full h-full">
      <div className="slider flex h-full animate-slider">
        <div className="slide flex justify-center items-center w-full h-full">
          <img
            src="/images/triune-christian-matrimony-signupCover.jpg"
            alt="Background"
            className="h-full w-full object-cover"
          />
        </div>
        <div className="slide flex justify-center items-center w-full h-full">
          <img
            src="/images/matrimonybg2.jpg"
            alt="Background"
            className="h-full w-full object-cover"
          />
        </div>
        <div className="slide flex justify-center items-center w-full h-full">
          <img
            src="/images/matrimonybg3.jpg"
            alt="Background"
            className="h-full w-full object-cover"
          />
        </div>
      </div>
    </div>
  </div>
)}

<style >{`
  .slider {
    display: flex;
    height: 100%;
    animation: slide 9s infinite;
  }

  .slide {
    width: 100%;
    height: 100%;
    flex-shrink: 0;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  @keyframes slide {
    0%, 20% {
      transform: translateX(0%);
    }
    30%, 50% {
      transform: translateX(-100%);
    }
    60%, 80% {
      transform: translateX(-200%);
    }
    100% {
      transform: translateX(0%);
    }
  }
`}</style>



      {/* Our Ad */}
      <Link to="/membership">
        <div className="scrolling-container relative h-20 flex place-content-center bg-gradient-to-r from-accent to-accent/50 py-2 border-t-[1px] border-b-[1px] border-black">
          <span className="scrolling-content flex place-content-center font-medium text-3xl text-black">
            Find your God-ordained partner with Triune Matrimony - Enjoy{" "}
            <strong className="text-accentSec font-bold">
              50% OFF on premium membership
            </strong>{" "}
            - To discover your perfect match in faith,{" "}
            <strong className="text-accentSec font-bold">CLICK HERE!</strong>
          </span>
        </div>
      </Link>
      {/* Vision*/}
      <section className="flex flex-col w-full py-8 px-4 relative border-t-2">
        <div className="flex flex-col items-center">
          <h2 className="home-heading">Triune Matrimony's Vision</h2>
          <h4 className="mt-2 home-subheading">Subheading?</h4>
        </div>
        <div className="mt-12 px-4 md:px-20">
          <p className="text-xl">
            Triune Matrimony is to create a Christian community where
            individuals can trustingly find their perfect life partner, built on
            a foundation of shared values and faith, resulting in lifelong
            companionship and Background verification feature helps in
            significant reduction in divorce rates.
          </p>
        </div>
      </section>
      {/* Why us */}
      <section className="flex flex-col w-full py-8 relative min-h-[32rem] border-t-2">
        <div className="flex flex-col items-center">
          <h2 className="home-heading">Why Us?</h2>
          <h4 className="mt-2 home-subheading">
            Discover the unique benefits we offer
          </h4>
        </div>
        <div className="grid mobileLg:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 mt-12 px-20 gap-8">
          <div className="flex flex-col items-center">
            <div className="flex">
              <img src={MultiLang} alt="Multi Language" />
            </div>
            <p className="text-center text-xl mt-10">
              View the website in your own language
            </p>
          </div>

          <div className="flex flex-col items-center">
            <div className="flex">
              <img src={OnlineBgV} alt="Background Verification" />
            </div>
            <p className="text-center text-xl mt-10">
              Profile Background Verification
            </p>
          </div>

          <div className="flex flex-col items-center">
            <div className="flex">
              <img src={MarriageCounselling} alt="Marriage Counselling" />
            </div>
            <p className="text-center text-xl mt-10">
              Pre & Post Marriage Counselling Support
            </p>
          </div>

          <div className="flex flex-col items-center">
            <div className="flex">
              <img src={MarriageRegistration} alt="Marriage Registration" />
            </div>
            <p className="text-center text-xl mt-10">
              Marriage Registration Support
            </p>
          </div>

          <div className="flex flex-col items-center">
            <div className="flex">
              <img src={RelationshipManager} alt="Marriage Registration" />
            </div>
            <p className="text-center text-xl mt-10">
              Dedicated Relationship Manager Support for your assistance
            </p>
          </div>

          <div className="flex flex-col items-center">
            <div className="flex">
              <img src={WhatsappSupport} alt="Whatsapp Support" />
            </div>
            <p className="text-center text-xl mt-10">
              24 x 7 Whatsapp customer support
            </p>
          </div>
        </div>
      </section>
      {/* Verification & Services */}
      <section className="flex flex-col w-full pt-8 relative min-h-[32rem] border-t-2">
        <div className="grid gap-y-4 laptop:gap-y-0 laptop:grid-cols-2">
          <div className="laptop:ml-16 px-4 order-1 place-content-center items-center">
            <h2 className="text-4xl sm:text-5xl font-bold text-[#d46754] ">
              Worried about genuineness?
            </h2>
            <h3 className="font-medium text-xl sm:text-2xl mt-1">
              We provide you the solution to your worries.{" "}
            </h3>
            <h4 className="text-lg sm:text-xl mt-4 ">
              Background verification in marriage is essential for ensuring
              safety, trust, and compatibility. It helps prevent future
              conflicts by providing a clear understanding of each partner's
              history and character. Our thorough on-demand verification process
              provides you peace of mind and confidence in your relationship.
            </h4>
          </div>
          <div className="flex order-2 place-content-center">
            <img
              src="/images/verification2.png"
              alt="Background Verification Infographic"
              className="w-[80%] sm:w-[70%] md:w-[60%] laptop:w-[80%]"
            />
          </div>
        </div>
        <div className="grid gap-y-4 laptop:gap-y-0 laptop:grid-cols-2">
          <div className="flex order-2 laptop:order-1 place-content-center">
            <img
              src="/images/services2.png"
              alt="Services Infographic"
              className="w-[80%] sm:w-[70%] md:w-[60%] laptop:w-[100%]"
            />
          </div>
          <div className="laptop:mr-12 order-1 laptop:order-2 laptop:ml-8 px-4 place-content-center items-center">
            <h2 className="text-4xl sm:text-5xl font-bold text-[#d46754] ">
              Need some extra help?
            </h2>
            <h3 className="font-medium text-xl sm:text-2xl mt-1">
              We have got you covered!
            </h3>
            <h4 className="text-lg sm:text-xl mt-4 ">
              Our add-on services support your journey towards a fulfilling
              marriage. We offer marriage registration for legal ease, and
              counseling with pastors and doctors for spiritual and emotional
              well-being. Our relationship managers provide personalized
              guidance, and thorough background verification ensures trust and
              safety. Together, these services ensure a successful partnership.
            </h4>
          </div>
        </div>
      </section>
      {/* Your Journey*/}
      <section className="flex flex-col w-full relative bg-secondaryBg">
        <img
          src={Journey}
          alt="Your journey"
          className="object-fit w-full h-full"
        />
      </section>
      {/* Featured Profiles */}
      <section
  className="flex flex-col w-full py-12 relative border-t-2 bg-secondaryBg"
  style={{
    backgroundImage: `url('/images/bgimage.png')`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
  }}
>
  {/* Background overlay */}
  <div
    className="absolute inset-0  z-0"
    style={{ pointerEvents: "none" }}
  ></div>

  {/* Content wrapper */}
  <div className="relative z-10 flex flex-col items-center text-center">
    <h2 className="text-4xl font-bold text-white mb-2 tracking-wide">
      Featured Profiles
    </h2>
    <h4 className="text-lg text-gray-200 mt-2 font-medium">
      Stand out in the crowd by becoming a premium member
    </h4>
  </div>

  {/* Featured Profiles */}
  <div className="relative z-10 mt-12 px-4">
    <FeaturedProfiles TMI={user.user} />
  </div>

  {/* View All button */}
  <div className="relative z-10 flex justify-center mt-10">
    <button className="btn bg-primary text-white font-semibold px-12 py-3 rounded-full shadow-lg hover:bg-primaryDark transition duration-300 ease-in-out"
    onClick={() => navigate(`/profilelisting/featured/?tmi=${TMI}`)}
    >
      View All
    </button>
  </div>
</section>

      
      {/* Ad space */}
      <section className="px-4 tablet:px-12 laptop:px-20 py-8 min-h-[36rem] border-t-2">
        <div className="flex flex-col items-center">
          <h2 className="home-heading">All Your Marriage Needs</h2>
          <h4 className="mt-2 home-subheading">
            Advertise with us to make marriages more memorable
          </h4>
        </div>
        <div className="grid laptop:grid-cols-2 justify-between mt-10 gap-2">
          <div className="border border-red-500">
            <img
              src="/images/ad1.jpg"
              className="w-full h-full object-fit"
              alt="ad1"
            />
          </div>
          <div className="grid gap-2">
            <div className=" border border-red-500">
              <img
                src="/images/ad2.jpg"
                className="w-full h-full object-fit"
                alt="ad2"
              />
            </div>
            <div className=" border border-red-500">
              <img
                src="/images/ad3.jpg"
                className="w-full h-full object-fit"
                alt="ad3"
              />
            </div>
          </div>
        </div>

        
      </section>

      
   
      
    </>
  );
};

export default UserHome;



