import { Link } from "react-router-dom";


const Footer: React.FC = () => {
  return (
    <>
      
            
        
      <footer className="footer-text flex flex-col z-40 px-8 sm:px-20 py-8 min-h-[20rem] bg-black">
         
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-12 sm:px-4">
          <div className="flex flex-col">
            <h3 className="text-3xl">Services</h3>
            <nav className="grid mt-4 pl-1 w-full">
              <Link to="/bgverification">Background Verification </Link>
              <Link to="/services">Marriage Counselling</Link>
              <Link to="/services?section=1">Marriage Registration</Link>
              <p>Relationship Manager</p>
            </nav>
          </div>
          <div className="flex flex-col">
            <h3 className="text-3xl">Useful URLs</h3>
            <nav className="grid w-full pl-1 mt-4">
              <Link to="/support">Customer Support</Link>
              <Link to="/feedback">Feedback</Link>
              <Link to="/membershipplans">Membership Plans</Link>
              <Link to="/stories">Success Stories</Link>
            </nav>
          </div>
          <div className="flex flex-col">
            <h3 className="text-3xl">The Company</h3>
            <nav className="grid mt-4 pl-1 w-full">
              <Link to="/about">About Us</Link>
              <Link to="/news">News</Link>
              <Link to="/events">Events & Meetups</Link>
              <Link to="/gallery">Gallery</Link>
              <Link to="/advertising">Advertising</Link>
              <Link to="/careers">Careers</Link>
              <Link to="/blogs">Blogs & Articles</Link>
            </nav>
          </div>
          <div className="flex flex-col">
            <h3 className="text-3xl">Privacy</h3>
            <nav className="grid mt-4 pl-1 w-full">
            <Link to="/privacy">Privacy Policy</Link>
            <Link to="/terms">Terms and Conditions</Link>
            <Link to="/refund">Refund and Cancellation</Link>
            <Link to="/misuse">Report Misuse</Link>
            </nav>
          </div>
          <div className="flex flex-col">
            <h3 className="text-3xl">Socials</h3>
            <nav className="grid grid-cols-3 mt-4 pl-1 w-full">
              <div>
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="30" height="30" fill="black" />
                  <path
                    d="M22.5 2.5H18.75C17.0924 2.5 15.5027 3.15848 14.3306 4.33058C13.1585 5.50269 12.5 7.0924 12.5 8.75V12.5H8.75V17.5H12.5V27.5H17.5V17.5H21.25L22.5 12.5H17.5V8.75C17.5 8.41848 17.6317 8.10054 17.8661 7.86612C18.1005 7.6317 18.4185 7.5 18.75 7.5H22.5V2.5Z"
                    stroke="white"
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div>
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_56_229)">
                    <path
                      d="M28.75 3.74987C27.553 4.59422 26.2276 5.24001 24.825 5.66237C24.0722 4.79676 23.0717 4.18324 21.9588 3.90478C20.8459 3.62632 19.6744 3.69637 18.6026 4.10544C17.5308 4.51451 16.6106 5.24288 15.9662 6.19202C15.3219 7.14116 14.9846 8.26529 15 9.41237V10.6624C12.8033 10.7193 10.6266 10.2321 8.66376 9.24418C6.70093 8.25622 5.0129 6.79817 3.75 4.99987C3.75 4.99987 -1.25 16.2499 10 21.2499C7.42566 22.9973 4.35895 23.8735 1.25 23.7499C12.5 29.9999 26.25 23.7499 26.25 9.37487C26.2488 9.02669 26.2154 8.67937 26.15 8.33737C27.4258 7.07924 28.326 5.49077 28.75 3.74987Z"
                      stroke="white"
                      strokeWidth="4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_56_229">
                      <rect width="30" height="30" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div>
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_56_230)">
                    <path
                      d="M21.875 8.125H21.8875M8.75 2.5H21.25C24.7018 2.5 27.5 5.29822 27.5 8.75V21.25C27.5 24.7018 24.7018 27.5 21.25 27.5H8.75C5.29822 27.5 2.5 24.7018 2.5 21.25V8.75C2.5 5.29822 5.29822 2.5 8.75 2.5ZM20 14.2125C20.1543 15.2528 19.9766 16.3153 19.4922 17.2488C19.0078 18.1823 18.2414 18.9393 17.302 19.4121C16.3626 19.8849 15.2981 20.0495 14.2597 19.8824C13.2214 19.7153 12.2622 19.2251 11.5185 18.4815C10.7749 17.7378 10.2847 16.7786 10.1176 15.7403C9.9505 14.7019 10.1151 13.6374 10.5879 12.698C11.0607 11.7586 11.8177 10.9922 12.7512 10.5078C13.6847 10.0234 14.7472 9.84574 15.7875 10C16.8487 10.1574 17.8311 10.6518 18.5896 11.4104C19.3482 12.1689 19.8426 13.1513 20 14.2125Z"
                      stroke="white"
                      strokeWidth="4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_56_230">
                      <rect width="30" height="30" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </nav>
          </div>
        </div>
        <hr className="my-8" />
        <div className="flex px-4">
          <p>Copyright © 2024 Triune Tech Mentors LLP</p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
