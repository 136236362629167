import { useState } from "react";
import axios from "axios"; // Import axios for HTTP requests
const apiUrl = import.meta.env.VITE_API_URL;

const NewEvent: React.FC = () => {
  const [date, setDate] = useState("");
  const [image, setImage] = useState<string | null>(null);
  const [heading, setHeading] = useState("");
  const [venue, setVenue] = useState("");
  const [description, setDescription] = useState("");
  const [previewImage, setPreviewImage] = useState<string | null>(null); // Preview image for UI feedback
  const [status, setStatus] = useState<string>("");

  const handleDateChange = (e: any) => setDate(e.target.value);

  const handleImageChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      // Check for valid image format (JPEG or PNG)
      const validFormats = ["image/jpeg", "image/png", "image/jpg"];
      if (!validFormats.includes(file.type)) {
        setStatus("Invalid image format. Please upload JPEG or PNG only.");
        return;
      }
      
      // Set the file itself instead of a Base64 string
      setImage(file);
  
      // For preview image, you can use object URL as before
      setPreviewImage(URL.createObjectURL(file));
    }
  };
  

  const handleHeadingChange = (e: any) => setHeading(e.target.value);
  const handleVenueChange = (e: any) => setVenue(e.target.value);
  const handleDescriptionChange = (e: any) => setDescription(e.target.value);

  const handleSubmit = async () => {
    if (!image || !date || !heading || !venue || !description) {
      setStatus("All fields are required.");
      return;
    }
  
    try {
      const formData = new FormData();
      formData.append('date', date);
      formData.append('image', image); // Append the image file here
      formData.append('heading', heading);
      formData.append('venue', venue);
      formData.append('description', description);
      
      const response = await axios.post(`${apiUrl}/api/events`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      });
  
      setStatus("Event created successfully!");
      console.log("Response:", response.data);
    } catch (error) {
      setStatus("Error creating event. Please try again.");
      console.error("Error:", error);
    }
  };
  

  return (
    <>
      <div className="p-8 grid gap-y-8">
        <h1 className="text-4xl text-center font-semibold">New Event Data</h1>
        <hr className="border-black" />
        {status && <p className="text-center text-xl">{status}</p>}

        <div className="grid grid-cols-2">
          <div className="flex gap-x-8 items-center place-items-center">
            <p className="text-2xl">Event Date:</p>
            <input
              type="date"
              className="p-1 border-2 border-black"
              value={date}
              onChange={handleDateChange}
            />
          </div>
          <div className="flex gap-x-8 items-center place-items-center">
            <p className="text-2xl">Upload Image:</p>
            <input
              type="file"
              accept="image/*"
              className="p-2"
              onChange={handleImageChange}
            />
          </div>
        </div>

        <div>
          <p className="text-2xl">Event Title:</p>
          <input
            type="text"
            className="p-2 border-2 w-full border-black"
            placeholder="Title of the event"
            value={heading}
            onChange={handleHeadingChange}
          />
        </div>

        <div>
          <p className="text-2xl">Event Venue:</p>
          <input
            type="text"
            className="p-2 border-2 w-full border-black"
            placeholder="Venue of the event"
            value={venue}
            onChange={handleVenueChange}
          />
        </div>

        <div>
          <p className="text-2xl">Event Details:</p>
          <textarea
            cols={30}
            rows={5}
            className="p-2 border-2 border-black w-full"
            placeholder="Enter the event details"
            value={description}
            onChange={handleDescriptionChange}
          />
        </div>

        <div>
          <button
            onClick={handleSubmit}
            className="p-2 border-2 border-black w-full"
          >
            Post Event
          </button>
        </div>
        <hr className="border-black" />

        <div className="p-8">
          <h1 className="text-center text-5xl font-semibold">{heading}</h1>
          <p className="mt-8 text-2xl">{description}</p>
          <div className="flex place-items-center mt-8 gap-x-6">
            <p className="text-3xl font-semibold">Venue:</p>
            <p className="text-2xl">{venue}</p>
          </div>
          <div className="flex place-items-center mt-4 gap-x-6">
            <p className="text-3xl font-semibold">Event Date:</p>
            <p className="text-2xl">{date}</p>
          </div>
          <div className="relative flex place-content-center mt-12">
            {previewImage && (
              <img
                src={previewImage}
                alt="Event Image"
                className="w-[40%]"
              />
            )}
            <div className="absolute flex gap-x-2 right-0 bottom-0 text-3xl">
              <p className="text-xl font-semibold">Posted On:</p>
              <p className="text-xl">{date}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewEvent;
