import { useForm, SubmitHandler } from "react-hook-form";

type SupportFormInputs = {
  name: string;
  email: string;
  issueType: string;
  description: string;
};

const CustomerSupport: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SupportFormInputs>();

  const onSubmit: SubmitHandler<SupportFormInputs> = (data) => {
    console.log("Support Request Submitted:", data);
  };

  return (
    <div className="min-h-screen bg-gray-100 py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-2xl mx-auto bg-white shadow-md rounded-lg overflow-hidden">
        <div className="bg-accent text-white text-center py-6">
          <h1 className="text-3xl font-bold">Customer Support</h1>
        </div>
        <div className="p-8">
          <p className="mb-6 text-gray-700 leading-relaxed">
            If you're experiencing issues or have any questions, please fill out
            the form below, and our support team will get back to you as soon as
            possible.
          </p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-4">
              <label
                className="block text-gray-700 font-semibold mb-2"
                htmlFor="name"
              >
                Your Name
              </label>
              <input
                id="name"
                type="text"
                {...register("name", { required: "Name is required" })}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              {errors.name && (
                <p className="text-red-600 mt-1">{errors.name.message}</p>
              )}
            </div>

            <div className="mb-4">
              <label
                className="block text-gray-700 font-semibold mb-2"
                htmlFor="email"
              >
                Your Email
              </label>
              <input
                id="email"
                type="email"
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /^\S+@\S+$/i,
                    message: "Invalid email address",
                  },
                })}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              {errors.email && (
                <p className="text-red-600 mt-1">{errors.email.message}</p>
              )}
            </div>

            <div className="mb-4">
              <label
                className="block text-gray-700 font-semibold mb-2"
                htmlFor="issueType"
              >
                Issue Type
              </label>
              <select
                id="issueType"
                {...register("issueType", {
                  required: "Please select an issue type",
                })}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="">Select an issue type</option>
                <option value="technical">Technical Issue</option>
                <option value="billing">Billing Issue</option>
                <option value="general">General Inquiry</option>
              </select>
              {errors.issueType && (
                <p className="text-red-600 mt-1">{errors.issueType.message}</p>
              )}
            </div>

            <div className="mb-6">
              <label
                className="block text-gray-700 font-semibold mb-2"
                htmlFor="description"
              >
                Description
              </label>
              <textarea
                id="description"
                {...register("description", {
                  required: "Please describe your issue",
                })}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                rows={6}
              ></textarea>
              {errors.description && (
                <p className="text-red-600 mt-1">
                  {errors.description.message}
                </p>
              )}
            </div>

            <div className="text-center">
              <button
                type="submit"
                className="w-full bg-accent text-white py-2 rounded-lg hover:bg-accentSec focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                Submit Request
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CustomerSupport;
