const Careers: React.FC = () => {
  return (
    <div className="min-h-screen bg-gray-100 py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto bg-white shadow-md rounded-lg overflow-hidden">
        <div className="bg-accent text-white text-center py-6">
          <h1 className="text-4xl font-bold">Careers</h1>
        </div>
        <div className="p-8">
          <section className="mb-8">
            <h2 className="text-3xl font-semibold mb-4 text-accent">
              Join Our Team
            </h2>
            <p className="text-gray-700 leading-relaxed">
              At <strong>Triune Tech Mentors</strong>, we are passionate about
              creating meaningful tech solutions that serve communities and
              align with their values. As the creators of{" "}
              <strong>Triune Matrimony</strong>, we are committed to building a
              platform that supports Christians in their journey to find a life
              partner. We are looking for talented individuals who share our
              vision and are eager to make an impact.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-3xl font-semibold mb-4 text-accent">
              Why Work With Us?
            </h2>
            <p className="text-gray-700 leading-relaxed">
              Working at <strong>Triune Tech Mentors</strong> means being part
              of a team that values innovation, integrity, and community. We
              offer a collaborative environment where your ideas can thrive, and
              your contributions can make a real difference. Whether you're
              passionate about technology, design, or customer support, there's
              a place for you here.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-3xl font-semibold mb-4 text-accent">
              Current Openings
            </h2>
            <p className="text-gray-700 leading-relaxed">
              We are currently looking for motivated professionals to join our
              team in the following roles:
            </p>
            <ul className="list-disc list-inside text-gray-700 mt-4">
              <li className="mb-2">
                <strong>Frontend Developer</strong> - Focused on building
                user-friendly interfaces using React and Tailwind CSS.
              </li>
              <li className="mb-2">
                <strong>Backend Developer</strong> - Specializing in creating
                robust and scalable server-side applications.
              </li>
              <li className="mb-2">
                <strong>UI/UX Designer</strong> - Passionate about creating
                intuitive and visually appealing designs.
              </li>
              <li className="mb-2">
                <strong>Customer Support Specialist</strong> - Dedicated to
                providing excellent service and support to our users.
              </li>
            </ul>
          </section>

          <section>
            <h2 className="text-3xl font-semibold mb-4 text-accent">
              How to Apply
            </h2>
            <p className="text-gray-700 leading-relaxed">
              If you're interested in joining our team, please send your resume
              and a cover letter to{" "}
              <a
                href="mailto:careers@triunetechmentors.com"
                className="text-accent font-semibold hover:underline"
              >
                careers@triunetechmentors.com
              </a>
              . Be sure to mention the position you're applying for in the
              subject line.
            </p>
            <p className="mt-4 text-gray-700 leading-relaxed">
              We look forward to hearing from you and exploring how you can
              contribute to the success of <strong>Triune Matrimony</strong> and{" "}
              <strong>Triune Tech Mentors</strong>.
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Careers;
