import {  useEffect, useState } from "react";
// import Dropdown from "../Dropdown";
import { useAuthUser } from "../../pages/AuthUserContext";
import axios from 'axios';
import {  useForm } from "react-hook-form";
const apiUrl = import.meta.env.VITE_API_URL;
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { newUser as user } from "../../utils/data";
//Password Change
const passwordChangeSchema = z
  .object({
    password: z.string().min(8, "password too short"),
    confirmPassword: z.string(),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords don't match",
    path: ["confirmPassword"],
  });

type PasswordChangeFields = z.infer<typeof passwordChangeSchema>;

//Delete Profile
const deleteProfileTMSchema = z.object({
  brideName: z.string().min(1, { message: "Required" }),
  groomName: z.string().min(1, { message: "Required" }),
  brideTMID: z.string().min(1, { message: "Required" }),
  groomTMID: z.string().min(1, { message: "Required" }),
  engagementDate: z.string().min(1, { message: "Required" }),
  photo: z.instanceof(FileList),
  successStory: z.string().min(1, { message: "Required" }),
  feedback: z.string().min(1, { message: "Required" }),
});
type DeleteProfileTMFields = z.infer<typeof deleteProfileTMSchema>;

const deleteProfileOSSchema = z.object({
  siteName: z.string().min(1, { message: "Required" }),
  feedback: z.string().min(1, { message: "Required" }),
});
type DeleteProfileOSFields = z.infer<typeof deleteProfileOSSchema>;

const deleteProfileOTSchema = z.object({
  feedback: z.string().min(1, { message: "Required" }),
});
type DeleteProfileOTFields = z.infer<typeof deleteProfileOTSchema>;

export const AccountSettings: React.FC = () => {
  const [selectedFileName, setSelectedFileName] = useState<string | null>(null);
  const [message, setMessage] = useState<string | null>(null);
  const [settingPart, setSettingsPart] = useState(0);
  const { user: TMI } = useAuthUser();  // Retrieve user ID or identifier

  // Mobile Number Change
  const [mobileNumber, setMobileNumber] = useState("");
  const [mobileOTP, setMobileOTP] = useState("");
  const [mobileErrors, setMobileErrors] = useState({
    mobileNumber: "",
    mobileOTP: "",
  });

  const handleMobileSubmit = () => {
    setMobileErrors({ ...mobileErrors, mobileNumber: "" });

    if (mobileNumber.length < 10) {
      setMobileErrors({
        ...mobileErrors,
        mobileNumber: "Enter a valid number",
      });
      return;
    }

    console.log(mobileNumber);
  };
  const handleMobileOTPSubmit = () => {
    setMobileErrors({ ...mobileErrors, mobileOTP: "" });

    if (mobileOTP.length < 4) {
      setMobileErrors({
        ...mobileErrors,
        mobileOTP: "Invalid OTP",
      });
      return;
    }

    console.log(mobileOTP);
  };

  //Change Emial 
  const [email, setEmail] = useState("");
  const [emailOTP, setEmailOTP] = useState("");
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [freezeProfile, setFreezeProfile] = useState(false);
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [emailErrors, setEmailErrors] = useState({
    email: "",
    emailOTP: "",
  });

  // API Call: Send OTP to the new email
  const handleEmailSubmit = async () => {
    setEmailErrors({ ...emailErrors, email: "" });
    setSuccessMessage("");
  
    if (!email.includes("@")) {
      setEmailErrors({
        ...emailErrors,
        email: "Enter a valid email",
      });
      return;
    }
  
    try {
      setLoading(true);
      await axios.post(`${apiUrl}/api/change-email`, { TMI, newEmail: email });
      setLoading(false);
      setSuccessMessage("OTP sent to your email.");
    } catch (error) {
      setLoading(false);
  
      // Type guard to check if error is AxiosError and has response property
      if (axios.isAxiosError(error) && error.response) {
        setEmailErrors({
          ...emailErrors,
          email: error.response.data?.message || "Error sending OTP. Try again.",
        });
      } else if (error instanceof Error) {
        // Handle general JS errors
        setEmailErrors({
          ...emailErrors,
          email: error.message || "Something went wrong. Try again.",
        });
      } else {
        // For unknown error types
        setEmailErrors({
          ...emailErrors,
          email: "An unexpected error occurred. Try again.",
        });
      }
    }
  };
  

  // API Call: Verify OTP and Change Email
  const handleEmailOTPSubmit = async () => {
    setEmailErrors({ ...emailErrors, emailOTP: "" });
    setSuccessMessage("");
  
    if (emailOTP.length < 4) {
      setEmailErrors({
        ...emailErrors,
        emailOTP: "Invalid OTP",
      });
      return;
    }
  
    try {
      setLoading(true);
       await axios.post(`${apiUrl}/api/change-email/verify`, { TMI, otpToken: emailOTP });
      setLoading(false);
      setSuccessMessage("Email changed successfully.");
      setEmail("")
      setEmailOTP("")
    } catch (error) {
      setLoading(false);
  
      // Type guard to check if error is AxiosError and has response property
      if (axios.isAxiosError(error) && error.response) {
        setEmailErrors({
          ...emailErrors,
          emailOTP: error.response.data?.message || "Invalid OTP. Try again.",
        });
      } else if (error instanceof Error) {
        // Handle general JS errors
        setEmailErrors({
          ...emailErrors,
          emailOTP: error.message || "Something went wrong. Try again.",
        });
      } else {
        // For unknown error types
        setEmailErrors({
          ...emailErrors,
          emailOTP: "An unexpected error occurred. Try again.",
        });
      }
    }
  };
  


  //Password Change
  const passwordChangeForm = useForm<PasswordChangeFields>({
    resolver: zodResolver(passwordChangeSchema),
  });

  const onPasswordSubmit = async (data: PasswordChangeFields) => {
    console.log("Form Data:", data);

    try {
      const response = await axios.post(`${apiUrl}/api/changepassword/${TMI}`, {
        password: data.password,
        confirmPassword: data.confirmPassword,
      });
  
      if (response.status === 200) {
        // Handle success (e.g., notify user, redirect, etc.)
        alert('Password changed successfully.');
      }
    } catch (error) {
      // Handle error (e.g., display error message)
      alert('Error changing password. Please try again.');
    }
  };

  //Freeze Profile
  useEffect(() => {
    const checkFreezeStatus = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/check-freeze-profile/${TMI}`);
        setFreezeProfile(response.data.freezeProfile);
        setCheckboxChecked(response.data.freezeProfile);
      } catch (error) {
        console.error('Error fetching freeze status:', error);
        setErrorMessage('Failed to retrieve freeze status.');
      }
    };
    checkFreezeStatus();
  }, [TMI, apiUrl]);



  const handleFreezeProfile = async () => {
    if (!checkboxChecked) {
      setErrorMessage("Please select the checkbox to freeze your profile.");
      return;
    }

    try {
      setLoading(true);
      const response = await axios.post(`${apiUrl}/api/freeze-profile/${TMI}`);
      setSuccessMessage(response.data.message);
      setErrorMessage("");
      setFreezeProfile(true);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        setErrorMessage(error.response.data.message || "Error freezing profile.");
      } else {
        setErrorMessage("Error freezing profile.");
      }
      setSuccessMessage("");
    } finally {
      setLoading(false);
    }
  };

  const handleUnfreezeProfile = async () => {
    try {
      setLoading(true);
      const response = await axios.post(`${apiUrl}/api/unfreeze-profile/${TMI}`);
      setSuccessMessage(response.data.message);
      setErrorMessage("");
      setFreezeProfile(false);
      setCheckboxChecked(false);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        setErrorMessage(error.response.data.message || "Error unfreezing profile.");
      } else {
        setErrorMessage("Error unfreezing profile.");
      }
      setSuccessMessage("");
    } finally {
      setLoading(false);
    }
  };

  const handleCheckboxChange = () => {
    setCheckboxChecked(!checkboxChecked);
    if (errorMessage) setErrorMessage(""); // Clear error message when checkbox is selected
  };

  

  //Delete Profile
  const [reason, setReason] = useState<string | null>(null);

  const handleReason = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const deleteReason = e.target.value;
    setReason(deleteReason);
  };

  const deleteProfileTMForm = useForm<DeleteProfileTMFields>({
    resolver: zodResolver(deleteProfileTMSchema),
  });
  const onDeleteProfileTMSubmit = async (data: DeleteProfileTMFields) => {
    const formData = new FormData();
  
    // Iterate over the data object
    Object.entries(data).forEach(([key, value]) => {
      console.log(`${key}:`, value); // Log each key-value pair
      if (key === 'profilePhoto' && value instanceof FileList) {
        // Ensure we are extracting the file correctly
        const file = value[0]; // Get the first file from the FileList
        if (file) {
          formData.append(key, file); // Append the file to FormData
        }
      } else {
        // Append other fields directly (making sure they are strings)
        formData.append(key, value as string); // Cast value to string for safety
      }
    });
  
    try {
      const response = await fetch(`${apiUrl}/api/delete-profile/triune-matrimony/${TMI}`, {
        method: 'POST',
        body: formData,
      });
  
      const statusCode = response.status; // Get the status code
      console.log(`Status Code: ${statusCode}`); // Log the status code
  
      if (!response.ok) {
        const errorResult = await response.json(); // Parse the error response
        throw new Error(`Failed to delete profile: ${errorResult.error || 'Unknown error'}`);
      }
  
      const result = await response.json();
      console.log('Success:', result);
      setMessage('Your Profile is sent for approval !'); // Set success message

      // Handle success (e.g., show a success message or redirect)
    } catch (error) {
      // Handle unknown error types safely
      if (error instanceof Error) {
        console.error('Error:', error.message); // Log the error message
        setMessage(error.message); // Set error message to state
      } else {
        console.error('Error:', 'An unknown error occurred'); // Fallback for unknown types
        setMessage('An unknown error occurred.'); // Set generic error message
      }
  
      // Handle error (e.g., show an error message to the user)
    }
  };
  
  
  
  
  

  const deleteProfileOSForm = useForm<DeleteProfileOSFields>({
    resolver: zodResolver(deleteProfileOSSchema),
  });
  const onDeleteProfileOSSubmit = async (data: DeleteProfileOSFields) => {
    try {
      const response = await fetch(`${apiUrl}/api/delete-profile/other-site/${TMI}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
  
      const statusCode = response.status; // Get the status code
      console.log(`Status Code: ${statusCode}`); // Log the status code
  
      if (!response.ok) {
        const errorResult = await response.json(); // Parse the error response
        throw new Error(`Failed to delete profile: ${errorResult.error || 'Unknown error'}`);
      }
  
      const result = await response.json();
      console.log('Success:', result);
      setMessage('Your Profile is sent for approval !'); // Set success message

      // Handle success (e.g., show a success message or redirect)
    } catch (error) {
      // Handle unknown error types safely
      if (error instanceof Error) {
        console.error('Error:', error.message); // Log the error message
        setMessage(error.message); // Set error message to state
      } else {
        console.error('Error:', 'An unknown error occurred'); // Fallback for unknown types
        setMessage('An unknown error occurred.'); // Set generic error message
      }
  
      // Handle error (e.g., show an error message to the user)
    }
  };
  

  const deleteProfileOTForm = useForm<DeleteProfileOTFields>({
    resolver: zodResolver(deleteProfileOTSchema),
  });
  const onDeleteProfileOTSubmit = async (data: DeleteProfileOTFields) => {
    try {
      const response = await fetch(`${apiUrl}/api/delete-profile/other/${TMI}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
  
      const statusCode = response.status; // Get the status code
      console.log(`Status Code: ${statusCode}`); // Log the status code
  
      if (!response.ok) {
        const errorResult = await response.json(); // Parse the error response
        throw new Error(`Failed to delete profile: ${errorResult.error || 'Unknown error'}`);
      }
  
      const result = await response.json();
      console.log('Success:', result);
      setMessage('Your Profile is sent for approval !'); // Set success message

      // Handle success (e.g., show a success message or redirect)
    } catch (error) {
      // Handle unknown error types safely
      if (error instanceof Error) {
        console.error('Error:', error.message); // Log the error message
        setMessage(error.message); // Set error message to state
      } else {
        console.error('Error:', 'An unknown error occurred'); // Fallback for unknown types
        setMessage('An unknown error occurred.'); // Set generic error message
      }
  
      // Handle error (e.g., show an error message to the user)
    }
  };

  //console.log(deleteProfileTMForm.watch());

  return (
    <>
      <div className="flex flex-col laptop:flex-row gap-x-6 laptop:p-8">
        {/* Section Selection Buttons*/}
        <div className="w-full laptop:w-[30%] flex flex-wrap laptop:flex-nowrap place-content-center laptop:place-content-start laptop:flex-col my-6 laptop:my-0">
          {[
            "Update Mobile Number",
            "Change Email",
            "Change Password",
            "Freeze Profile",
            "Delete Profile",
          ].map((label, index) => (
            <button
              className={`relative group/btn border-2 px-3 ${
                settingPart === index ? "bg-accent/30" : "bg-white"
              } laptop:px-0 h-10 font-medium shadow-[0px_1px_0px_0px_#ffffff40_inset,0px_-1px_0px_0px_#ffffff40_inset]] ${
                index > 0
                  ? "laptop:border-t-0 border-l-0 laptop:border-l-2"
                  : ""
              }`}
              type="button"
              key={index}
              onClick={() => setSettingsPart(index)}
            >
              {label}
              <BottomGradient />
            </button>
          ))}
        </div>

        {/* Mobile Number Change*/}
        <div
          className={`${
            settingPart === 0 ? "flex" : "hidden"
          } w-full  laptop:w-[70%] flex-col border-2 px-4`}
        >
          <div>
            <p className="text-xl font-semibold text-center py-2">
              Change Mobile Number
            </p>
            <hr />
          </div>
          <div className="flex flex-col items-center py-6 gap-y-6">
            <div className="flex flex-wrap laptop:flex-nowrap items-center w-full gap-x-2 px-16">
              <div className="w-full">
                <p className="text-lg">Mobile Number:</p>
                {mobileErrors.mobileNumber && (
                  <p className="text-red-600">{mobileErrors.mobileNumber}</p>
                )}
              </div>
              <input
                value={mobileNumber}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setMobileNumber(e.target.value)
                }
                type="text"
                placeholder={user.contactInformation.mobileNumber}
                className="border p-2"
              />
              <button
                className="relative w-full group/btn border-2 btn rounded-md h-10 font-medium shadow-[0px_1px_0px_0px_#ffffff40_inset,0px_-1px_0px_0px_#ffffff40_inset]]"
                type="button"
                onClick={() => handleMobileSubmit()}
              >
                Send OTP
                <BottomGradient />
              </button>
            </div>
            <div className="flex flex-wrap laptop:flex-nowrap items-center w-full gap-x-2 px-16">
              <div className="w-full">
                <p className="text-lg w-full">Enter OTP:</p>
                {mobileErrors.mobileOTP && (
                  <p className="text-red-600"> {mobileErrors.mobileOTP}</p>
                )}
              </div>
              <input
                value={mobileOTP}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setMobileOTP(e.target.value)
                }
                type="text"
                placeholder="OTP"
                className="border p-2"
              />
              <button
                onClick={() => handleMobileOTPSubmit()}
                className="relative w-full group/btn border-2 btn rounded-md h-10 font-medium shadow-[0px_1px_0px_0px_#ffffff40_inset,0px_-1px_0px_0px_#ffffff40_inset]]"
                type="button"
              >
                Validate
                <BottomGradient />
              </button>
            </div>
          </div>
        </div>

        {/* Email Change */}
        <div className={`${settingPart === 1 ? "flex" : "hidden"} w-full laptop:w-[70%] flex-col border-2 px-4`}>
      <div>
        <p className="text-xl font-semibold text-center py-2">Change Email ID</p>
        <hr />
      </div>

      <div className="flex flex-col items-center py-10 gap-y-6">
        {/* Email Input */}
        <div className="flex flex-wrap laptop:flex-nowrap items-center w-full gap-x-2 px-16">
          <div className="w-full">
            <p className="text-lg w-full">Email ID:</p>
            {emailErrors.email && <p className="text-red-600">{emailErrors.email}</p>}
          </div>
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            placeholder="Enter your new email ID"
            className="border p-2"
          />
          <button
            onClick={handleEmailSubmit}
            className="relative w-full group/btn border-2 btn rounded-md h-10 font-medium shadow-[0px_1px_0px_0px_#ffffff40_inset,0px_-1px_0px_0px_#ffffff40_inset]]"
            type="button"
            disabled={loading}
          >
            {loading ? "Sending..." : "Send OTP"}
            <BottomGradient />
          </button>
        </div>

        {/* OTP Input */}
        <div className="flex flex-wrap laptop:flex-nowrap items-center w-full gap-x-2 px-16">
          <div className="w-full">
            <p className="text-lg w-full">Enter OTP:</p>
            {emailErrors.emailOTP && <p className="text-red-600">{emailErrors.emailOTP}</p>}
          </div>
          <input
            value={emailOTP}
            onChange={(e) => setEmailOTP(e.target.value)}
            type="text"
            placeholder="OTP"
            className="border p-2"
          />
          <button
            onClick={handleEmailOTPSubmit}
            className="relative w-full group/btn border-2 btn rounded-md h-10 font-medium shadow-[0px_1px_0px_0px_#ffffff40_inset,0px_-1px_0px_0px_#ffffff40_inset]]"
            type="button"
            disabled={loading}
          >
            {loading ? "Validating..." : "Validate"}
            <BottomGradient />
          </button>
        </div>

        {/* Success Message */}
        {successMessage && <p className="text-green-600">{successMessage}</p>}
      </div>
        </div>

        {/* Password Change */}
        <form
    onSubmit={passwordChangeForm.handleSubmit(onPasswordSubmit)}
    className={`${
      settingPart === 2 ? "flex" : "hidden"
    } w-full  laptop:w-[70%] flex-col border-2 px-4`}
  >
    <div>
      <p className="text-xl font-semibold text-center py-2">Change Password</p>
      <hr />
    </div>
    <div className="flex flex-col flex-wrap laptop:flex-nowrap w-full items-center py-6 gap-y-6">
      <div className="flex flex-wrap items-center gap-x-6">
        <div className="w-48">
          <p className="text-lg">New Password:</p>
          <p className="text-red-600">
            {passwordChangeForm.formState.errors.password?.message}
          </p>
        </div>
        <input
          {...passwordChangeForm.register("password")}
          type="password"
          placeholder="New Password"
          className="border p-2"
        />
      </div>
      <div className="flex flex-wrap items-center gap-x-6">
        <div className="w-48">
          <p className="text-lg">Re-enter Password:</p>
          <p className="text-red-600">
            {passwordChangeForm.formState.errors.confirmPassword?.message}
          </p>
        </div>
        <input
          {...passwordChangeForm.register("confirmPassword")}
          type="password"
          placeholder="New Password"
          className="border p-2"
        />
      </div>
    </div>
    <div className="flex flex-col flex-wrap place-content-center items-center pb-6 gap-y-4">
      <p className="text-center">
        Password should contain 6 - 10 characters, at least 1 number & 1
        letter
      </p>
      <button
        className="relative px-8 group/btn border-2 btn rounded-md h-10 font-medium shadow-[0px_1px_0px_0px_#ffffff40_inset,0px_-1px_0px_0px_#ffffff40_inset]]"
        type="submit"
      >
        Change
        <BottomGradient />
      </button>
    </div>
        </form>

        {/* Freeze Profile */}
        <div className={`${settingPart === 3 ? "flex" : "hidden"} w-full laptop:w-[70%] flex-col border-2 px-4`}>
      <div>
        <p className="text-xl font-semibold text-center py-2">Freeze Profile</p>
        <hr />
      </div>
      <div className="flex flex-col items-center py-6 gap-y-6">
        <p>
          We hope your journey ahead is filled with joy, love, and fulfillment. Remember, this is not a farewell forever but rather a pause in your journey with us. Should you ever choose to return, we will welcome you back with open arms. Thank you for being a part of our community. Your presence has been truly valued.
        </p>
      </div>
      <div className="flex w-full flex-col items-center pb-6 gap-y-4">
        {!freezeProfile && (
          <div className="flex gap-x-4 items-center">
            <input
              checked={checkboxChecked}
              onChange={handleCheckboxChange}
              type="checkbox"
              name="freeze-profile"
              id="freeze-profile"
              className="w-[1rem] h-[1rem]"
              disabled={loading}
            />
            <label htmlFor="freeze-profile" className="text-lg">
              Yes, I want to freeze my profile
            </label>
          </div>
        )}
        {freezeProfile ? (
          <button
            onClick={handleUnfreezeProfile}
            className="relative px-8 group/btn border-2 btn rounded-md h-10 font-medium shadow-[0px_1px_0px_0px_#ffffff40_inset,0px_-1px_0px_0px_#ffffff40_inset]]"
            type="button"
            disabled={loading}
          >
            {loading ? "Unfreezing..." : "Unfreeze"}
          </button>
        ) : (
          <button
            onClick={handleFreezeProfile}
            className="relative px-8 group/btn border-2 btn rounded-md h-10 font-medium shadow-[0px_1px_0px_0px_#ffffff40_inset,0px_-1px_0px_0px_#ffffff40_inset]]"
            type="button"
            disabled={loading}
          >
            {loading ? "Freezing..." : "Freeze"}
          </button>
        )}
      </div>

      {/* Display success or error messages */}
      {successMessage && <p className="text-green-600">{successMessage}</p>}
      {errorMessage && <p className="text-red-600">{errorMessage}</p>}
    </div>

        {/* Delete Profile */}
        <div
          className={`${
            settingPart === 4 ? "flex" : "hidden"
          }  w-full  laptop:w-[70%] flex-col border-2 px-4`}
        >

          <div>
            <p className="text-xl font-semibold text-center py-2">
              Delete Profile
            </p>
            <hr />
          </div>
          <div className="flex flex-col items-center py-6 gap-y-6">
            <p>
              We understand that your journey with us has come to an end, and we
              respect your decision to delete your profile. Thank you for
              allowing us to be a part of your journey in seeking companionship
              and love. Your presence has been meaningful to our community, and
              you will be missed. Wishing you all the happiness and success in
              your future endeavors.
            </p>
          </div>
          <div className="flex w-full flex-col items-center pb-6 gap-y-6">
            {/* Reason select */}
            <select id="reason" className="w-full p-2" onChange={handleReason}>
              <option value="">Select your reason for leaving</option>
              <option value="marriage-triune">
                Marriage Fixed - TriuneMatrimony
              </option>
              <option value="marriage-other-site">
                Marriage Fixed - Other Site
              </option>
              <option value="other">Other</option>
            </select>

            {reason === "marriage-triune" && (
              <form
                onSubmit={deleteProfileTMForm.handleSubmit(
                  onDeleteProfileTMSubmit
                )}
              >
                <div className="w-full flex flex-col tablet:grid tablet:grid-cols-2 gap-4 px-2">
                  <div className="grid grid-cols-3">
                    <div>
                      <p className="flex items-center">Bride Name:</p>
                      <p className="text-red-500">
                        {
                          deleteProfileTMForm.formState.errors.brideName
                            ?.message
                        }
                      </p>
                    </div>
                    <input
                      {...deleteProfileTMForm.register("brideName")}
                      type="text"
                      placeholder="Bride's Name"
                      className="col-span-2 p-2 border"
                    />
                  </div>
                  <div className="grid grid-cols-3">
                    <div>
                      <p className="flex items-center">Groom Name:</p>
                      <p className="text-red-500">
                        {
                          deleteProfileTMForm.formState.errors.groomName
                            ?.message
                        }
                      </p>
                    </div>
                    <input
                      {...deleteProfileTMForm.register("groomName")}
                      type="text"
                      placeholder="Groom's Name"
                      className="col-span-2 p-2 border"
                    />
                  </div>
                  <div className="grid grid-cols-3">
                    <div>
                      <p className="flex items-center">Bride ID:</p>
                      <p className="text-red-500">
                        {deleteProfileTMForm.formState.errors.brideTMID?.message}
                      </p>
                    </div>
                    <input
                      {...deleteProfileTMForm.register("brideTMID")}
                      type="text"
                      placeholder="Bride's TM ID"
                      className="col-span-2 p-2 border"
                    />
                  </div>
                  <div className="grid grid-cols-3">
                    <div>
                      <p className="flex items-center">Groom ID:</p>
                      <p className="text-red-500">
                        {deleteProfileTMForm.formState.errors.groomTMID?.message}
                      </p>
                    </div>
                    <input
                      {...deleteProfileTMForm.register("groomTMID")}
                      type="text"
                      placeholder="Groom's TM ID"
                      className="col-span-2 p-2 border"
                    />
                  </div>
                  <div className="grid grid-cols-2">
                    <div>
                      <p className="flex items-center">Engagement Date:</p>
                      <p className="text-red-500">
                        {
                          deleteProfileTMForm.formState.errors.engagementDate
                            ?.message
                        }
                      </p>
                    </div>
                    <input
                      {...deleteProfileTMForm.register("engagementDate")}
                      type="date"
                      className="p-2 border"
                    />
                  </div>
                  <div className="grid grid-cols-2">
  <p className="flex items-center">Upload Photo</p>
  <label className="flex btn place-items-center cursor-pointer">
    <span className={`text-center w-full`}>Browse</span>
    <input
      {...deleteProfileTMForm.register("photo")}
      type="file"
      accept="image/*"
      className="px-4 hidden"
      onChange={(e) => {
        const file = e.target.files?.[0];
        if (file) {
          setSelectedFileName(file.name);
        } else {
          setSelectedFileName(null);
        }
      }}
    />
  </label>
  {selectedFileName && (
    <p className="text-gray-500 mt-2">{`Selected file: ${selectedFileName}`}</p>
  )}
</div>

                </div>
                <div className="mt-2 w-full px-2">
                  <div>
                    <p className="flex items-center">Success Story:</p>
                    <p className="text-red-500">
                      {
                        deleteProfileTMForm.formState.errors.successStory
                          ?.message
                      }
                    </p>
                  </div>
                  <textarea
                    {...deleteProfileTMForm.register("successStory")}
                    value={deleteProfileTMForm.watch("successStory")}
                    onChange={(e) =>
                      deleteProfileTMForm.setValue(
                        "successStory",
                        e.target.value
                      )
                    }
                    rows={3}
                    className="w-full border p-4 mt-2"
                    placeholder="Tell us how TriuneMatrimony helped you find the one"
                  />
                </div>
                <div className="mt-2 flex flex-col w-full">
                  <div>
                    <p className="flex items-center">Feedback:</p>
                    <p className="text-red-500">
                      {deleteProfileTMForm.formState.errors.feedback?.message}
                    </p>
                  </div>
                  <textarea
                    {...deleteProfileTMForm.register("feedback")}
                    value={deleteProfileTMForm.watch("feedback")}
                    onChange={(e) =>
                      deleteProfileTMForm.setValue("feedback", e.target.value)
                    }
                    rows={3}
                    className="w-full border p-4 mt-2"
                    placeholder="Tell us about your experience with us and how we can improve it"
                  />
                </div>
                <div>
      {message && <div className="text-red-500">{message}</div>} {/* Display message to user */}
      {/* Your form goes here */}
    </div>
                <button
                  className={`group/btn relative mt-4 px-8 border-2 btn rounded-md h-10 font-medium shadow-[0px_1px_0px_0px_#ffffff40_inset,0px_-1px_0px_0px_#ffffff40_inset]]`}
                  type="submit"
                >
                  Submit & Delete Profile
                  <BottomGradient />
                </button>
              </form>
            )}
            {reason === "marriage-other-site" && (
              <form
                onSubmit={deleteProfileOSForm.handleSubmit(
                  onDeleteProfileOSSubmit
                )}
              >
                <div className="grid grid-cols-2">
                  <div>
                    <p className="flex">Site Name:</p>
                    <p className="text-red-500">
                      {deleteProfileOSForm.formState.errors.siteName?.message}
                    </p>
                  </div>
                  <input
                    {...deleteProfileOSForm.register("siteName")}
                    type="text"
                    placeholder="Name of the site"
                    className="p-2 border"
                  />
                </div>
                <div className="flex flex-col w-full my-2">
                  <div>
                    <p className="flex">Feedback:</p>
                    <p className="text-red-500">
                      {deleteProfileOSForm.formState.errors.siteName?.message}
                    </p>
                  </div>
                  <textarea
                    {...deleteProfileOSForm.register("feedback")}
                    value={deleteProfileOSForm.watch("feedback")}
                    onChange={(e) =>
                      deleteProfileOSForm.setValue("feedback", e.target.value)
                    }
                    rows={3}
                    className="w-full border p-4 mt-2"
                    placeholder="Tell us about your experience with us and how we can improve it"
                  />
                </div>

                <div>
      {message && <div className="text-red-500">{message}</div>} {/* Display message to user */}
      {/* Your form goes here */}
    </div>
                <button
                  className={`group/btn relative px-8 border-2 btn rounded-md h-10 font-medium shadow-[0px_1px_0px_0px_#ffffff40_inset,0px_-1px_0px_0px_#ffffff40_inset]]`}
                  type="submit"
                >
                  Delete Profile
                  <BottomGradient />
                </button>
              </form>
            )}
            {reason === "other" && (
              <form
                className="w-full"
                onSubmit={deleteProfileOTForm.handleSubmit(
                  onDeleteProfileOTSubmit
                )}
              >
                <div className="flex flex-col">
                  <div>
                    <p className="flex">Feedback:</p>
                    <p className="text-red-500">
                      {deleteProfileOTForm.formState.errors.feedback?.message}
                    </p>
                  </div>
                  <textarea
                    {...deleteProfileOTForm.register("feedback")}
                    value={deleteProfileOTForm.watch("feedback")}
                    onChange={(e) =>
                      deleteProfileOTForm.setValue("feedback", e.target.value)
                    }
                    rows={5}
                    cols={10}
                    className="w-full border p-4 mt-2"
                    placeholder="Tell us about your experience with us and how we can improve it"
                  />
                </div>
                <div>
      {message && <div className="text-red-500">{message}</div>} {/* Display message to user */}
      {/* Your form goes here */}
    </div>
                <button
                  className={`relative disabled:text-gray-600 px-8 mt-4 border-2 btn rounded-md h-10 font-medium shadow-[0px_1px_0px_0px_#ffffff40_inset,0px_-1px_0px_0px_#ffffff40_inset]]`}
                  type="submit"
                >
                  Delete Profile
                  <BottomGradient />
                </button>
              </form>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const BottomGradient = () => {
  return (
    <>
      <span className="group-hover/btn:opacity-100 block transition duration-500 opacity-0 absolute h-px w-full -bottom-px inset-x-0 bg-gradient-to-r from-transparent via-cyan-500 to-transparent" />
      <span className="group-hover/btn:opacity-100 blur-sm block transition duration-500 opacity-0 absolute h-px w-1/2 mx-auto -bottom-px inset-x-10 bg-gradient-to-r from-transparent via-indigo-500 to-transparent" />
    </>
  );
};
