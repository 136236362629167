import { useState } from "react";

const NewBlog: React.FC = () => {
  const [date, setDate] = useState("");
  const [image, setImage] = useState(null);
  const [title, setTitle] = useState("");
  const [author, setAuthor] = useState("");
  const [content, setContent] = useState("");

  const handleDateChange = (e: any) => setDate(e.target.value);
  const handleImageChange = (e: any) => setImage(e.target.files[0]);
  const handleTitleChange = (e: any) => setTitle(e.target.value);
  const handleAuthorChange = (e: any) => setAuthor(e.target.value);
  const handleContentChange = (e: any) => setContent(e.target.value);

  const handleSubmit = () => {
    const data = {
      date,
      image,
      title,
      author,
      content,
    };
    console.log(data);
  };

  return (
    <>
      <div className="p-8 grid gap-y-8">
        <h1 className="text-4xl text-center font-semibold">New Blog Data</h1>
        <hr className="border-black" />
        {/* Date and upload button */}
        <div className="grid grid-cols-2">
          <div className="flex gap-x-8 items-center place-items-center">
            <p className="text-2xl">Blog Date:</p>
            <input
              type="date"
              className="p-1 border-2 border-black"
              value={date}
              onChange={handleDateChange}
            />
          </div>
          <div className="flex gap-x-8 items-center place-items-center">
            <p className="text-2xl">Upload:</p>
            <input
              type="file"
              accept="image/*"
              className=" p-2"
              onChange={handleImageChange}
            />
          </div>
        </div>
        {/* Blog Title */}
        <div>
          <p className="text-2xl">Blog Title:</p>
          <input
            type="text"
            className="p-2 border-2 w-full border-black"
            placeholder="Title of the Blog"
            value={title}
            onChange={handleTitleChange}
          />
        </div>
        {/* News Author*/}
        <div>
          <p className="text-2xl">News Summary:</p>
          <input
            type="text"
            className="p-2 border-2 w-full border-black"
            placeholder="Author of the Blog"
            value={author}
            onChange={handleAuthorChange}
          />
        </div>
        {/* Blog description */}
        <div>
          <p className="text-2xl">Blog Content:</p>
          <textarea
            cols={30}
            rows={5}
            className="p-2 border-2 border-black w-full"
            placeholder="Enter the Blog Content"
            value={content}
            onChange={handleContentChange}
          />
        </div>
        {/* Submit Button */}
        <div>
          <button
            onClick={() => handleSubmit()}
            className="p-2 border-2 border-black w-full"
          >
            Post Blog
          </button>
        </div>
        <hr className="border-black" />
        {/* Preview */}
        <div className="container mx-auto max-w-4xl">
          <h1 className="text-5xl font-bold text-center mb-12">Blog</h1>
          <div className="mb-16">
            {image && (
              <img
                src={URL.createObjectURL(image)}
                alt="Preview Blog Image"
                className="w-full h-full rounded-lg mb-8 border-2 shadow-sm"
              />
            )}
            <h2 className="text-4xl font-semibold mb-4">{title}</h2>
            <p className="text-gray-500 mb-6">
              By <strong>{author}</strong> on {date}
            </p>
            <p className="text-lg leading-relaxed mb-8">{content}</p>
            <hr className="border-t-2 border-gray-300" />
          </div>
        </div>
      </div>
    </>
  );
};

export default NewBlog;
