import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import axios from "axios";

const apiUrl = import.meta.env.VITE_API_URL;

// Define plan durations
const durations = [1, 3, 6, 9, 12];

// Define all available plans
const allPlans = [
  "Bronze",
  "Silver",
  "Gold",
  "Gold Plus",
  "Diamond",
  "Diamond Plus",
];

// Define the features
const features = [
  "contactViews25",
  "contactViews50",
  "contactViews75",
  "expressInterest25",
  "expressInterest50",
  "expressInterest75",
  "profileHighlighter15",
  "profileHighlighter30",
  "profileHighlighter45",
  "featuredProfile15",
  "featuredProfile30",
  "featuredProfile45",
  "membershipFrame",
  "emailAlert",
  "whatsAppAlert",
  "relationshipManager",
  "marriageRegistration",
  "marriageCounsellingPastor",
  "marriageCounsellingDoctor",
  "marriageExpoEntry",
  "basicBackgroundVerification",
  "premiumBackgroundVerification",
];

const PlanFeatures: React.FC = () => {
  const { control, handleSubmit } = useForm();
  const [openMonths, setOpenMonths] = useState<number[]>([]);
  const [formData, setFormData] = useState<any>(null); // State to hold form data

  // Submit the form data
  console.log(formData)
  const onSubmit = async (data: any, duration: number) => {
    console.log('Form data before sending:', data); // Log the entire data object
  
    // Store form data to state for display
    setFormData(data);
  
    // Create an array to hold structured plan data
    const structuredData = allPlans.map(plan => ({
      months: duration,
      name: plan,
      price: data[`${duration}-${plan}-price`] || 0,
      gst: data[`${duration}-${plan}-gst`] || 0, // Include GST
      discount: data[`${duration}-${plan}-discount`] || 0, // Include Discount
      features: {
        contactViews25: data[`${duration}-${plan}-contactViews25`] || false,
        contactViews50: data[`${duration}-${plan}-contactViews50`] || false,
        contactViews75: data[`${duration}-${plan}-contactViews75`] || false,
        expressInterest25: data[`${duration}-${plan}-expressInterest25`] || false,
        expressInterest50: data[`${duration}-${plan}-expressInterest50`] || false,
        expressInterest75: data[`${duration}-${plan}-expressInterest75`] || false,
        profileHighlighter15: data[`${duration}-${plan}-profileHighlighter15`] || false,
        profileHighlighter30: data[`${duration}-${plan}-profileHighlighter30`] || false,
        profileHighlighter45: data[`${duration}-${plan}-profileHighlighter45`] || false,
        featuredProfile15: data[`${duration}-${plan}-featuredProfile15`] || false,
        featuredProfile30: data[`${duration}-${plan}-featuredProfile30`] || false,
        featuredProfile45: data[`${duration}-${plan}-featuredProfile45`] || false,
        membershipFrame: data[`${duration}-${plan}-membershipFrame`] || false,
        emailAlert: data[`${duration}-${plan}-emailAlert`] || false,
        whatsAppAlert: data[`${duration}-${plan}-whatsAppAlert`] || false,
        relationshipManager: data[`${duration}-${plan}-relationshipManager`] || false,
        marriageRegistration: data[`${duration}-${plan}-marriageRegistration`] || false,
        marriageCounsellingPastor: data[`${duration}-${plan}-marriageCounsellingPastor`] || false,
        marriageCounsellingDoctor: data[`${duration}-${plan}-marriageCounsellingDoctor`] || false,
        marriageExpoEntry: data[`${duration}-${plan}-marriageExpoEntry`] || false,
        basicBackgroundVerification: data[`${duration}-${plan}-basicBackgroundVerification`] || false,
        premiumBackgroundVerification: data[`${duration}-${plan}-premiumBackgroundVerification`] || false,
      },
    }));
  
    console.log('Structured Data to be sent:', structuredData); // Log structured data
  
    try {
      const response = await axios.post(`${apiUrl}/api/admin/memberships`, structuredData);
      console.log('Response from server:', response.data); // Log the server's response
      alert(`Membership plans for ${duration} month(s) saved successfully!`);
    } catch (error: any) {
      console.error("Error saving membership plans:", error); // Log the server's error message
      alert("Error saving membership plans: " + (error.response?.data?.message || "An unknown error occurred"));
    }
  };
  

  // Toggle month visibility
  const toggleMonth = (month: number) => {
    setOpenMonths(prev =>
      prev.includes(month) ? prev.filter(m => m !== month) : [...prev, month]
    );
  };

  return (
    <div className="p-8 bg-gray-100 rounded-lg shadow-md">
      <h1 className="text-center text-4xl font-bold mb-6">Plan Features</h1>
      <form>
        {durations.map((duration) => (
          <div key={duration} className="mb-6">
            <button
              type="button"
              onClick={() => toggleMonth(duration)}
              className="flex justify-between items-center w-full p-4 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition duration-300"
            >
              <h2 className="text-3xl font-semibold">{duration} Month(s)</h2>
              <span className="text-xl">{openMonths.includes(duration) ? '-' : '+'}</span>
            </button>
            {openMonths.includes(duration) && (
              <div className="bg-cyan p-4 rounded-lg mt-2 shadow">
                <ul className="flex flex-col gap-y-6">
                  {(duration === 1 ? ["Bronze", "Silver"] : allPlans).map((plan) => (
                    <li key={plan} className="flex flex-col gap-y-3 border-b pb-3">
                      <h3 className="text-2xl font-semibold">{plan}</h3>
                      <label className="text-lg font-medium">Price:</label>
                      <Controller
                        name={`${duration}-${plan}-price`}
                        control={control}
                        defaultValue={0} // Set default value to 0
                        render={({ field }) => (
                          <input
                            type="number"
                            placeholder="Enter price"
                            {...field}
                            className="border p-2 w-full rounded-md focus:outline-none focus:ring-2 focus:ring-blue-300"
                          />
                        )}
                      />
                      <label className="text-lg font-medium">GST:</label>
                      <Controller
                        name={`${duration}-${plan}-gst`}
                        control={control}
                        defaultValue={0} // Set default value to 0
                        render={({ field }) => (
                          <input
                            type="number"
                            placeholder="Enter GST"
                            {...field}
                            className="border p-2 w-full rounded-md focus:outline-none focus:ring-2 focus:ring-blue-300"
                          />
                        )}
                      />
                      <label className="text-lg font-medium">Discount:</label>
                      <Controller
                        name={`${duration}-${plan}-discount`}
                        control={control}
                        defaultValue={0} // Set default value to 0
                        render={({ field }) => (
                          <input
                            type="number"
                            placeholder="Enter Discount"
                            {...field}
                            className="border p-2 w-full rounded-md focus:outline-none focus:ring-2 focus:ring-blue-300"
                          />
                        )}
                      />
                      <div className="flex flex-wrap gap-4">
                        {features.map((feature) => (
                          <div key={feature} className="flex items-center">
                            <Controller
                              name={`${duration}-${plan}-${feature}`}
                              control={control}
                              defaultValue={false} // Default to false
                              render={({ field: { onChange, value, ref, ...fieldProps } }) => (
                                <>
                                  <input
                                    type="checkbox"
                                    checked={value} // Control checked state
                                    onChange={(e) => onChange(e.target.checked)} // Update value based on checkbox state
                                    ref={ref} // Pass ref
                                    {...fieldProps} // Spread other field properties
                                    className="mr-2"
                                  />
                                  <label>{feature.replace(/([A-Z])/g, ' $1').trim()}</label>
                                </>
                              )}
                            />
                          </div>
                        ))}
                      </div>
                    </li>
                  ))}
                </ul>
                <button
                  type="button"
                  onClick={handleSubmit((data) => onSubmit(data, duration))} // Call onSubmit with the specific duration
                  className="mt-4 bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 transition duration-300"
                >
                  Save Membership Plans for {duration} Month(s)
                </button>
              </div>
            )}
          </div>
        ))}
      </form>
    </div>
  );
};

export default PlanFeatures;

