import React from 'react';
import { Navigate } from 'react-router-dom';
import { useUser } from './pages/UserContext';
import PrivateAdminRoute from './PrivateAdminRoute';
import PrivateExecutiveRoute from './PrivateExecutiveRoute';
import PrivateCustomerRoute from './PrivateCustomerRoute';

interface PrivateRoleRouteProps {
  requiredRoles: Array<'admin' | 'executive'|'user'|'guest'|'customer'>; // Specify roles that are allowed
  children: React.ReactNode; // Add children to the props
}

const PrivateRoleRoute: React.FC<PrivateRoleRouteProps> = ({ requiredRoles, children }) => {
  const { userType } = useUser();

  // Check if the user type matches any of the required roles
  if (!requiredRoles.includes(userType)) {
    return <Navigate to="/" replace />;
  }

  // Apply the appropriate route guard based on user type
  if (userType === 'admin') {
    return <PrivateAdminRoute>{children}</PrivateAdminRoute>;
  }

  if (userType === 'executive') {
    return <PrivateExecutiveRoute>{children}</PrivateExecutiveRoute>;
  }

  if (userType === 'customer') {
    return <PrivateCustomerRoute>{children}</PrivateCustomerRoute>;
  }

  // Default case if no role matches
  return <Navigate to="/" replace />;
};

export default PrivateRoleRoute;







