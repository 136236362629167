import { GalleryInfiniteMovingCards } from "../components/GalleryInfinitemovingCards";

const imagesP = [
  "https://plus.unsplash.com/premium_photo-1722009043656-a43537f0f7d7?q=80&w=1907&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  "https://images.unsplash.com/photo-1722230290258-38a1a0e3ec56?q=80&w=1886&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  "https://images.unsplash.com/photo-1722404557005-00b57aeb5126?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
];

const imagesL = [
  "https://images.unsplash.com/photo-1470252649378-9c29740c9fa8?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=3540&q=80",
  "https://images.unsplash.com/photo-1719937050679-c3a2c9c67b0f?q=80&w=1772&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  "https://images.unsplash.com/photo-1722332998970-f2335db8ab6d?q=80&w=1793&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
];

const Gallery: React.FC = () => {
  return (
    <>
      <div className="flex">
        <div className="w-[20%]">A</div>
        <div className="w-[80%] flex flex-col gap-y-8 py-6">
          <div className="flex flex-col antialiased bg-white items-center justify-center relative overflow-hidden">
            <GalleryInfiniteMovingCards items={imagesP}
              direction="right"
              speed="normal"
            />
          </div>
          <div className="flex flex-col antialiased bg-white items-center justify-center relative overflow-hidden">
            <GalleryInfiniteMovingCards
              items={imagesL}
              direction="left"
              speed="normal"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Gallery;
