import { useState } from "react";

const NewNews: React.FC = () => {
  const [date, setDate] = useState("");
  const [image, setImage] = useState(null);
  const [title, setTitle] = useState("");
  const [summary, setSummary] = useState("");
  const [content, setContent] = useState("");

  const handleDateChange = (e: any) => setDate(e.target.value);
  const handleImageChange = (e: any) => setImage(e.target.files[0]);
  const handleTitleChange = (e: any) => setTitle(e.target.value);
  const handleSummaryChange = (e: any) => setSummary(e.target.value);
  const handleContentChange = (e: any) => setContent(e.target.value);

  const handleSubmit = () => {
    const data = {
      date,
      image,
      title,
      summary,
      content,
    };
    console.log(data);
  };

  return (
    <>
      <div className="p-8 grid gap-y-8">
        <h1 className="text-4xl text-center font-semibold">New News Data</h1>
        <hr className="border-black" />
        {/* Date and upload button */}
        <div className="grid grid-cols-2">
          <div className="flex gap-x-8 items-center place-items-center">
            <p className="text-2xl">News Date:</p>
            <input
              type="date"
              className="p-1 border-2 border-black"
              value={date}
              onChange={handleDateChange}
            />
          </div>
          <div className="flex gap-x-8 items-center place-items-center">
            <p className="text-2xl">Upload:</p>
            <input
              type="file"
              accept="image/*"
              className=" p-2"
              onChange={handleImageChange}
            />
          </div>
        </div>
        {/* News Title */}
        <div>
          <p className="text-2xl">News Title:</p>
          <input
            type="text"
            className="p-2 border-2 w-full border-black"
            placeholder="Title of the News"
            value={title}
            onChange={handleTitleChange}
          />
        </div>
        {/* News Summary*/}
        <div>
          <p className="text-2xl">News Summary:</p>
          <input
            type="text"
            className="p-2 border-2 w-full border-black"
            placeholder="Summary of the News"
            value={summary}
            onChange={handleSummaryChange}
          />
        </div>
        {/* News description */}
        <div>
          <p className="text-2xl">News Content:</p>
          <textarea
            cols={30}
            rows={5}
            className="p-2 border-2 border-black w-full"
            placeholder="Enter the News Content"
            value={content}
            onChange={handleContentChange}
          />
        </div>
        {/* Submit Button */}
        <div>
          <button
            onClick={() => handleSubmit()}
            className="p-2 border-2 border-black w-full"
          >
            Post News
          </button>
        </div>
        <hr className="border-black" />
        {/* Preview */}
        <div className="min-h-screen bg-gray-100 py-8 px-4 sm:px-6 lg:px-8">
          <div className="max-w-4xl mx-auto bg-white shadow-md rounded-lg overflow-hidden">
            <div className="bg-accent text-white text-center py-6">
              <h1 className="text-4xl font-bold">Latest News</h1>
            </div>
            <div className="p-8">
              <div className="mb-8">
                {image && (
                  <div className="mb-4">
                    <img
                      src={URL.createObjectURL(image)}
                      alt="preview new image"
                    />
                  </div>
                )}
                <h2 className="text-2xl font-semibold mb-2 text-accent">
                  {title}
                </h2>
                <p className="text-gray-600 mb-2">{date}</p>
                <p className="text-gray-700 mb-4">{summary}</p>
                <details className="text-accent cursor-pointer">
                  <summary>Read More</summary>
                  <p className="text-gray-700 mt-2">{content}</p>
                </details>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewNews;
