import { useState, useEffect } from "react";

const apiUrl = import.meta.env.VITE_API_URL;

interface Plan {
  _id: string;
  name: string;
}

interface ApplicablePlan {
  months: number;
  plans: Plan[];
  _id: string;
}

interface Coupon {
  _id: string;
  code: string;
  validFrom: string;
  validUntil: string;
  userTMI: string;
  userEmail: string;
  userName: string;
  discount: number;
  applicablePlans: ApplicablePlan[];
}

const CouponSent: React.FC = () => {
  const [coupons, setCoupons] = useState<Coupon[]>([]);

  useEffect(() => {
    fetchCoupons();
  }, []);

  const fetchCoupons = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/coupons-sent`);
      if (response.ok) {
        const data = await response.json();
        setCoupons(data);
      } else {
        console.error('Failed to fetch coupons');
      }
    } catch (error) {
      console.error('Error fetching coupons:', error);
    }
  };

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <h2 className="text-2xl font-bold mb-4">Sent Coupons</h2>
      <div className="overflow-auto">
        <table className="min-w-full bg-white border rounded-lg shadow-sm">
          <thead>
            <tr className="bg-gray-200 text-gray-600 text-left text-sm uppercase tracking-wider">
              <th className="px-6 py-3">Code</th>
              <th className="px-6 py-3">Discount (INR)</th>
              <th className="px-6 py-3">Valid From</th>
              <th className="px-6 py-3">Valid Until</th>
              <th className="px-6 py-3">Applicable Plans</th>
              <th className="px-6 py-3">User TMI</th>
              <th className="px-6 py-3">User Email</th>
              <th className="px-6 py-3">User Name</th>
            </tr>
          </thead>
          <tbody>
            {coupons.map((coupon) => (
              <tr key={coupon._id} className="border-t text-gray-700">
                <td className="px-6 py-4 font-semibold text-green-600">{coupon.code}</td>
                <td className="px-6 py-4">{coupon.discount}</td>
                <td className="px-6 py-4">{new Date(coupon.validFrom).toLocaleDateString()}</td>
                <td className="px-6 py-4">{new Date(coupon.validUntil).toLocaleDateString()}</td>
                <td className="px-6 py-4">
                  {coupon.applicablePlans.map((appPlan) => (
                    <div key={appPlan._id}>
                      <strong>{appPlan.months} Month(s):</strong>{" "}
                      {appPlan.plans.map((plan) => (
                        <span key={plan._id} className="text-blue-500 ml-1">
                          {plan.name}
                        </span>
                      ))}
                    </div>
                  ))}
                </td>
                <td className="px-6 py-4">{coupon.userTMI}</td>
                <td className="px-6 py-4">{coupon.userEmail}</td>
                <td className="px-6 py-4">{coupon.userName}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CouponSent;
