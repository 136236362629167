"use client";

import { useState } from "react";
import { motion } from "framer-motion";
import { cn } from "../utils/utils";

type Tab = {
  title: string;
  value: string;
  content?: string | React.ReactNode | any;
};

export const Tabs = ({
  tabs: propTabs,
  containerClassName,
  activeTabClassName,
  tabClassName,
  contentClassName,
}: {
  tabs: Tab[];
  containerClassName?: string;
  activeTabClassName?: string;
  tabClassName?: string;
  contentClassName?: string;
}) => {
  const [active, setActive] = useState<Tab>(propTabs[0]);

  const handleTabClick = (tab: Tab) => {
    setActive(tab);
  };

  return (
    <>
      <div
        className={cn(
          "flex flex-row items-center justify-start text-sm sm:text-lg font-semibold [perspective:1000px] relative overflow-x-auto sm:overflow-visible no-visible-scrollbar max-w-full w-full space-x-2 sm:space-x-2",
          containerClassName
        )}
      >
        {propTabs.map((tab) => (
          <button
            key={tab.title}
            onClick={() => handleTabClick(tab)}
            className={cn(
              "relative px-3 py-2 sm:px-4 sm:py-2 rounded-lg sm:rounded-full whitespace-nowrap",
              tabClassName
            )}
            style={{
              transformStyle: "preserve-3d",
            }}
          >
            {active.value === tab.value && (
              <motion.div
                layoutId="clickedbutton"
                transition={{ type: "spring", bounce: 0.3, duration: 0.6 }}
                className={cn(
                  "absolute inset-0 bg-gray-200 rounded-lg sm:rounded-full",
                  activeTabClassName
                )}
              />
            )}

            <span className="relative block text-black">{tab.title}</span>
          </button>
        ))}
      </div>
      <FadeInDiv
        active={active}
        className={cn("mt-3 sm:mt-12", contentClassName)}
      />
    </>
  );
};

export const FadeInDiv = ({
  className,
  active,
}: {
  className?: string;
  active: Tab;
}) => {
  return (
    <div className="relative w-full h-full">
      <motion.div
        key={active.value}
        className={cn("w-full absolute inset", className)}
      >
        {active.content}
      </motion.div>
    </div>
  );
};
