import { useState, useEffect, ChangeEvent, FormEvent } from 'react';
import axios from 'axios';
import { HiEye, HiEyeOff } from 'react-icons/hi'; // Import icons for visibility toggle

const apiUrl = import.meta.env.VITE_API_URL;

interface User {
  _id: string;
  name: string;
  email: string;
  userType: string;
  employeeId?: string; // Optional for non-executive users
}

const UserManagement: React.FC = () => {
  const [userType, setUserType] = useState<string>('admin');
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [employeeId, setEmployeeId] = useState<string>('');
  const [passwords, setPasswords] = useState<{ [key: string]: string }>({});
  const [passwordVisibility, setPasswordVisibility] = useState<{ [key: string]: boolean }>({});
  const [users, setUsers] = useState<User[]>([]);
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/user-management/users`);
        if (Array.isArray(response.data)) {
          setUsers(response.data);
        } else {
          console.error('Unexpected data format:', response.data);
          setErrorMessage('Error fetching users');
        }
      } catch (error: any) {
        console.error('Error fetching users:', error);
        setErrorMessage('Error fetching users');
      }
    };
    fetchUsers();
  }, []);

  const handleUserTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setUserType(e.target.value);
  };

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleEmployeeIdChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmployeeId(e.target.value);
  };

  const handlePasswordChange = (userId: string, e: ChangeEvent<HTMLInputElement>) => {
    setPasswords(prev => ({ ...prev, [userId]: e.target.value }));
  };

  const handlePasswordVisibilityToggle = (userId: string) => {
    setPasswordVisibility(prev => ({ ...prev, [userId]: !prev[userId] }));
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const passwordToSend = passwords['newUserPassword'] || ''; // Use a specific key for new user password

    try {
      const response = await axios.post(`${apiUrl}/api/user-management/users`, {
        userType,
        name,
        email,
        employeeId,
        password: passwordToSend
      });

      setSuccessMessage(`User created successfully: ${response.data.email}`);
      setName('');
      setEmail('');
      setPasswords({});
      setEmployeeId('');

      const updatedUsers = await axios.get(`${apiUrl}/api/user-management/users`);
      if (Array.isArray(updatedUsers.data)) {
        setUsers(updatedUsers.data);
      } else {
        console.error('Unexpected data format:', updatedUsers.data);
        setErrorMessage('Error fetching users');
      }
    } catch (error: any) {
      setErrorMessage(error.response?.data?.message || 'Error creating user');
    }
  };

  const handleDelete = async (userId: string) => {
    try {
      await axios.delete(`${apiUrl}/api/user-management/users/${userId}`);
      setUsers(users.filter(user => user._id !== userId));
      setSuccessMessage('User deleted successfully');
    } catch (error: any) {
      setErrorMessage(error.response?.data?.message || 'Error deleting user');
    }
  };

  const handlePasswordUpdate = async (userId: string) => {
    const newPassword = passwords[userId];
    if (newPassword) {
      try {
        await axios.put(`${apiUrl}/api/user-management/users/${userId}/password`, { newPassword });
        setSuccessMessage('Password updated successfully');
        setPasswords(prev => ({ ...prev, [userId]: '' }));

        const updatedUsers = await axios.get(`${apiUrl}/api/user-management/users`);
        if (Array.isArray(updatedUsers.data)) {
          setUsers(updatedUsers.data);
        } else {
          console.error('Unexpected data format:', updatedUsers.data);
          setErrorMessage('Error fetching users');
        }
      } catch (error: any) {
        setErrorMessage(error.response?.data?.message || 'Error updating password');
      }
    }
  };

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">User Management</h1>
      <form onSubmit={handleSubmit} className="space-y-4 mb-6">
        <div>
          <label htmlFor="userType" className="block text-lg font-medium">User Type</label>
          <select
            id="userType"
            value={userType}
            onChange={handleUserTypeChange}
            className="block w-full mt-1 p-2 border border-gray-300 rounded"
          >
            <option value="admin">Admin</option>
            <option value="customer">Customer Support</option>
            <option value="executive">Executive</option>
          </select>
        </div>
        <div>
          <label htmlFor="name" className="block text-lg font-medium">Name</label>
          <input
            id="name"
            type="text"
            value={name}
            onChange={handleNameChange}
            className="block w-full mt-1 p-2 border border-gray-300 rounded"
            required
          />
        </div>
        <div>
          <label htmlFor="email" className="block text-lg font-medium">Email</label>
          <input
            id="email"
            type="email"
            value={email}
            onChange={handleEmailChange}
            className="block w-full mt-1 p-2 border border-gray-300 rounded"
            required
          />
        </div>
        <div>
          <label htmlFor="employeeId" className="block text-lg font-medium">Employee ID (e.g., TTMLLP001)</label>
          <input
            id="employeeId"
            type="text"
            value={employeeId}
            onChange={handleEmployeeIdChange}
            placeholder="TTMLLP001"
            className="block w-full mt-1 p-2 border border-gray-300 rounded"
            required
          />
        </div>
        <div>
          <label htmlFor="password" className="block text-lg font-medium">Password</label>
          <div className="relative">
            <input
              id="password"
              type={passwordVisibility['newUserPassword'] ? 'text' : 'password'}
              value={passwords['newUserPassword'] || ''}
              onChange={(e) => handlePasswordChange('newUserPassword', e)} 
              className="block w-full mt-1 p-2 border border-gray-300 rounded pr-10"
              required
            />
            <button
              type="button"
              onClick={() => setPasswordVisibility(prev => ({ ...prev, ['newUserPassword']: !prev['newUserPassword'] }))}
              className="absolute inset-y-0 right-0 px-3 flex items-center text-gray-600"
            >
              {passwordVisibility['newUserPassword'] ? <HiEyeOff size={20} /> : <HiEye size={20} />}
            </button>
          </div>
        </div>
        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          Create User
        </button>
        {successMessage && <p className="text-green-500 mt-2">{successMessage}</p>}
        {errorMessage && <p className="text-red-500 mt-2">{errorMessage}</p>}
      </form>
      <div>
        <h2 className="text-xl font-semibold mb-4">Existing Users</h2>
        <table className="w-full border-collapse">
          <thead>
            <tr>
              <th className="border border-gray-300 px-4 py-2">Name</th>
              <th className="border border-gray-300 px-4 py-2">Email</th>
              <th className="border border-gray-300 px-4 py-2">Employee ID</th>
              <th className="border border-gray-300 px-4 py-2">User Type</th>
              <th className="border border-gray-300 px-4 py-2">Actions</th>
              <th className="border border-gray-300 px-4 py-2">Change Password</th>
            </tr>
          </thead>
          <tbody>
            {users.map(user => (
              <tr key={user._id}>
                <td className="border border-gray-300 px-4 py-2">{user.name}</td>
                <td className="border border-gray-300 px-4 py-2">{user.email}</td>
                <td className="border border-gray-300 px-4 py-2">{user.employeeId || 'N/A'}</td>
                <td className="border border-gray-300 px-4 py-2">{user.userType}</td>
                <td className="border border-gray-300 px-4 py-2">
                  <button
                    onClick={() => handleDelete(user._id)}
                    className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"
                  >
                    Delete
                  </button>
                </td>
                <td className="border border-gray-300 px-4 py-2">
                 
                  <div className="relative mt-2">
                    <input
                      type={passwordVisibility[user._id] ? 'text' : 'password'}
                      value={passwords[user._id] || ''}
                      onChange={(e) => handlePasswordChange(user._id, e)}
                      className="block w-full mt-1 p-2 border border-gray-300 rounded pr-10"
                      placeholder="New Password"
                    />
                    <button
                      type="button"
                      onClick={() => handlePasswordVisibilityToggle(user._id)}
                      className="absolute inset-y-0 right-0 px-3 flex items-center text-gray-600"
                    >
                      {passwordVisibility[user._id] ? <HiEyeOff size={20} /> : <HiEye size={20} />}
                    </button>
                    <button
                    onClick={() => handlePasswordUpdate(user._id)}
                    className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
                  >
                    Change Password
                  </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UserManagement;

