const About: React.FC = () => {
  return (
    <div className="min-h-screen bg-gray-100 py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto bg-white shadow-md rounded-lg overflow-hidden">
        <div className="bg-accent text-white text-center py-6">
          <h1 className="text-4xl font-bold">About Us</h1>
        </div>
        <div className="p-8">
          <section className="mb-8">
            <h2 className="text-3xl font-semibold mb-4 text-accent">
              Who We Are
            </h2>
            <p className="text-gray-700 leading-relaxed">
              Welcome to <strong>Triune Matrimony</strong>, a dedicated platform
              designed exclusively for Christians seeking meaningful and
              faith-based relationships. We are a product of{" "}
              <strong>Triune Tech Mentors</strong>, a company driven by a
              mission to bring innovative and reliable tech solutions to life,
              with a deep commitment to values and community.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-3xl font-semibold mb-4 text-accent">
              Our Mission
            </h2>
            <p className="text-gray-700 leading-relaxed">
              At Triune Tech Mentors, our mission is to leverage technology to
              serve communities by providing platforms that align with their
              values and beliefs. <strong>Triune Matrimony</strong> is a
              testament to this vision, as we strive to connect Christian
              individuals who are looking to find their life partner within a
              faith-centered environment.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-3xl font-semibold mb-4 text-accent">
              Why Triune Matrimony?
            </h2>
            <p className="text-gray-700 leading-relaxed">
              We understand that finding a partner who shares your faith and
              values is crucial. That's why we created{" "}
              <strong>Triune Matrimony</strong>, a platform where Christians can
              meet, connect, and build relationships rooted in their shared
              faith. Our platform is designed with features that respect and
              uphold Christian principles, ensuring a safe and supportive
              environment for all our users.
            </p>
          </section>

          <section>
            <h2 className="text-3xl font-semibold mb-4 text-accent">
              Our Commitment
            </h2>
            <p className="text-gray-700 leading-relaxed">
              We are committed to providing a trustworthy and efficient
              platform. <strong>Triune Tech Mentors</strong> takes pride in our
              rigorous security measures, user-friendly interface, and dedicated
              customer support, all aimed at making your journey to finding a
              life partner as smooth and fulfilling as possible.
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default About;
