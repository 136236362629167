import { useState, useEffect } from "react";
import useScreenType from "../hooks/useScreenType";
const apiUrl = import.meta.env.VITE_API_URL;

interface UserProfile {
  TMI: string;
  firstname?: string;
  age?: number;
  Occupation: string;
  photo:string;
  
}

interface FeaturedProfilesProps {
  TMI: string;
}

const FeaturedProfiles: React.FC<FeaturedProfilesProps> = ({ TMI }) => {
  console.log('Received TMI:', TMI);
  const [userProfiles, setUserProfiles] = useState<UserProfile[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [index, setIndex] = useState(0);
  
  const screenType = useScreenType();
  let numberOfProfiles = 4;

  if (screenType === "mobile") {
    numberOfProfiles = 1;
  } else if (screenType === "tablet") {
    numberOfProfiles = 2;
  } else if (screenType === "laptop") {
    numberOfProfiles = 3;
  } else if (screenType === "desktop") {
    numberOfProfiles = 4;
  }

  useEffect(() => {
    console.log(TMI); // Log TMI when it changes
    const fetchFeaturedProfiles = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/users/featured-profiles/${TMI}`);
        if (!response.ok) {
          throw new Error('Failed to fetch featured profiles');
        }
        const data: UserProfile[] = await response.json();
        console.log('Fetched profiles:', data); // Log fetched data
        setUserProfiles(data);
      } catch (err: any) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchFeaturedProfiles();
  }, [TMI]); // Only re-fetch when TMI changes

  useEffect(() => {
    if (userProfiles.length > 0) {
      const interval = setInterval(() => {
        setIndex((prev) => (prev + numberOfProfiles) % userProfiles.length);
      }, 3000);
      return () => clearInterval(interval);
    }
  }, [userProfiles.length, numberOfProfiles]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const getDisplayedProfiles = () => {
    if (userProfiles.length < numberOfProfiles) {
      return userProfiles;
    }
    let displayedProfiles = userProfiles.slice(index, index + numberOfProfiles);
    if (displayedProfiles.length < numberOfProfiles) {
      displayedProfiles = displayedProfiles.concat(
        userProfiles.slice(0, numberOfProfiles - displayedProfiles.length)
      );
    }
    return displayedProfiles;
  };

  const displayedProfiles = getDisplayedProfiles();

  return (
    <div className={`grid grid-cols-${numberOfProfiles} desktop:grid-cols-4 sm:h-full gap-4 sm:overflow-hidden`}>
  {displayedProfiles.map((profile) => (
    <div key={profile.TMI} className="h-full w-full transition-transform duration-300 transform hover:scale-105">
      <div className="border border-gray-300 shadow-lg rounded-lg overflow-hidden bg-transparent"> {/* Transparent background */}
        <div className="flex justify-center items-center"> {/* Centering the image */}
          <img
            src={`${profile.photo}`}
            alt={profile.firstname || 'User Photo'}
            className="object-cover transition-transform duration-300 transform hover:scale-110 rounded-full" // Circular shape
            style={{ width: '150px', height: '150px', borderRadius: '50%' }} // Set equal width and height for circle
          />
        </div>
        <div className="p-4 flex flex-col">
          <div className="bg-white bg-opacity-80 rounded-lg p-2"> {/* Semi-transparent background for text */}
            <h3 className="text-xl font-semibold text-gray-800">{profile.firstname || 'Unknown Name'}</h3>
            <p className="text-md font-medium text-gray-600">{profile.age ? `${profile.age} years old` : 'N/A years old'}</p>
            <p className="text-sm text-gray-500">{profile.Occupation || 'No description available.'}</p>
          </div>
        </div>
      </div>
    </div>
  ))}
</div>








  );
};

export default FeaturedProfiles;
