const apiUrl = import.meta.env.VITE_API_URL;

import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useUser } from './UserContext';
import { useAuthUser } from './AuthUserContext';
import useScreenType from "../hooks/useScreenType";
import { SubmitHandler, useForm } from "react-hook-form";

interface LoginFormValues {
  email: string;
  password: string;
}

const Home: React.FC = () => {
  const { register, handleSubmit, formState: { errors } } = useForm<LoginFormValues>();
  console.log("API URL:", import.meta.env.VITE_API_URL);
  const { setUserType, setUserDetails } = useUser();
  const { setUser } = useAuthUser();
  const screenType = useScreenType();
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<LoginFormValues> = async (data) => {
    try {
      const formData = new URLSearchParams();
      formData.append('email', data.email); // Ensure key matches backend expectation
      formData.append('password', data.password);

      const response = await axios.post(`${apiUrl}/api/companyauth/login`, formData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        withCredentials: true, // Send cookies with the request
      });

      if (response.data.success) {
        alert("Login Successful");
        const { email, name, employeeId, userType } = response.data.user;
        setUser({ email, userType }); // Set user data
        setUserType(userType); // Set user type
        setUserDetails?.({ name, employeeId }); // Set additional user details
        
        // Navigate based on userType
        if (userType === "customer") {
            navigate('/customer/accountsummary', { replace: true });
        } else {
            navigate('/admin/approvals/profileapproval', { replace: true });
        }
    }
    else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error("Login error:", error);
      alert("Failed to login. Please check your credentials and try again.");
    }
  };

  return (
    <>
      <section className="flex flex-col laptop:min-h-[40rem] w-full overflow-hidden relative gap-5 laptop:px-20 laptop:py-8">
        {screenType !== "mobile" && screenType !== "tablet" && (
          <>
            <img
              src="/images/triune-christian-matrimony-signupCover.jpg" // Replace with your image path
              alt="Background"
              className="absolute inset-0 -z-10 h-full w-full object-cover" // Positioning and sizing
            />

            <h1 className="relative signup-card-heading w-[60%] self-start mt-20 sm:text-2xl tablet:text-4xl tablet:mt-40 tablet:w-[40%] laptop:text-5xl laptop:mt-24 laptop:w-[50%] laptop:text-left desktop:text-6xl desktop:mt-20 desktop:w-[60%] text-center text-black">
              I Have Found The One <br /> My Heart Loves
              <br />
              <p className="text-3xl text-right pr-16">Song of Solomon 3:4</p>
            </h1>
          </>
        )}

        {/* Login form */}
        {screenType !== "desktop" && screenType !== "laptop" && (
          <div className="flex flex-col w-full mt-6 px-3 bg-white text-accentSec signup-card-heading place-items-center place-content-center text-5xl gap-3">
            <p className="text-center tablet:text-left">
              I Have Found The One My Heart Loves
            </p>
            <p className="text-2xl w-full text-right ">Song of Solomon 3:4</p>
          </div>
        )}
        <div className="w-full border-8 tablet:border-[1rem] laptop:border-0 rounded-3xl border-white tablet:flex tablet:flex-wrap tablet:gap-4 laptop:block laptop:absolute laptop:w-[30rem] laptop:right-8 desktop:right-12 top-12 laptop:border-gray-800 px-5 py-8 laptop:py-8 laptop:px-10 laptop:rounded-3xl bg-black bg-opacity-70 content-center backdrop-blur-sm">
          <div className="w-full tablet:w-[60%] tablet:mx-auto laptop:w-full laptop:mx-0">
            <h2 className="text-5xl text-accent text-center font-medium">LogIn</h2>
            <div className="mt-8 antialiased">
              {/* Email Login */}
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="w-full">
                  <div>
                    <p className="text-white">Email ID</p>
                    <p className="text-red-500">{errors.email?.message}</p>
                  </div>
                  <input
                    type="text"
                    {...register("email", { required: "Email is required" })}
                    className="p-2 w-full border border-accent rounded-lg"
                    placeholder="youremail@xyz.com"
                  />
                </div>
                <div className="w-full my-3">
                  <p className="text-white">Password</p>
                  <input
                    type="password"
                    {...register("password", { required: "Password is required" })}
                    className="p-2 w-full border border-accent rounded-lg"
                    placeholder="password"
                  />
                </div>

                <button
                  className="relative w-full group/btn btn rounded-md h-10 font-medium"
                  type="submit"
                >
                  Log In &rarr;
                  <BottomGradient />
                </button>
              </form>

              <div className="bg-gradient-to-r from-transparent via-neutral-300 to-transparent my-4 h-[1px] w-full" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;

const BottomGradient = () => {
  return (
    <>
      <span className="group-hover/btn:opacity-100 block transition duration-500 opacity-0 absolute h-px w-full -bottom-px inset-x-0 bg-gradient-to-r from-transparent via-cyan-500 to-transparent" />
      <span className="group-hover/btn:opacity-100 blur-sm block transition duration-500 opacity-0 absolute h-px w-1/2 mx-auto -bottom-px inset-x-10 bg-gradient-to-r from-transparent via-indigo-500 to-transparent" />
    </>
  );
};
