import { useState, useEffect } from "react";

interface ImageSliderProps {
  className: string;
  photoArray: string[];
  onImageIndexChange?: (index: number) => void;
  autoSlideInterval?: number; // Optional auto-slide interval in milliseconds
}

const ImageSlider: React.FC<ImageSliderProps> = ({
  className,
  photoArray,
  onImageIndexChange,
  autoSlideInterval = 3000, // Default to 3000ms
}) => {
  const [imageIndex, setImageIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false); // State to track hover status

  useEffect(() => {
    if (onImageIndexChange) {
      onImageIndexChange(imageIndex);
    }
  }, [imageIndex, onImageIndexChange]);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (!isHovered && photoArray.length > 1) {
      interval = setInterval(showNextImage, autoSlideInterval);
    }
    return () => clearInterval(interval); // Clean up interval on unmount
  }, [isHovered, imageIndex, autoSlideInterval, photoArray.length]);

  const showNextImage = () => {
    const size = photoArray.length;
    setImageIndex((prev) => (prev + 1) % size);
  };

  const showPrevImage = () => {
    const size = photoArray.length;
    setImageIndex((prev) => (prev - 1 + size) % size);
  };

  const setImageByIndex = (index: number) => {
    setImageIndex(index);
  };

  return (
    <div className={`${className} flex flex-col items-center`} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <div className="relative w-full h-96 overflow-hidden rounded-3xl">
        {photoArray.length > 1 && (
          <button
            onClick={showPrevImage}
            aria-label="Previous Image"
            className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-gray-700 text-white p-2 rounded-full"
          >
            {/* Left Arrow Icon */}
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#FFFFFF" viewBox="0 0 256 256">
              <path d="M208,72H128V32a8,8,0,0,0-13.66-5.66l-96,96a8,8,0,0,0,0,11.32l96,96A8,8,0,0,0,128,224V184h80a16,16,0,0,0,16-16V88A16,16,0,0,0,208,72Zm0,96H120a8,8,0,0,0-8,8v28.69L35.31,128,112,51.31V80a8,8,0,0,0,8,8h88Z"></path>
            </svg>
          </button>
        )}

        <img
          className="object-cover w-full h-full transition-transform duration-500 ease-in-out"
          src={photoArray[imageIndex]}
          alt={`Image ${imageIndex + 1}`}
          onError={() => console.error('Error loading image:', photoArray[imageIndex])}
        />

        {photoArray.length > 1 && (
          <button
            onClick={showNextImage}
            aria-label="Next Image"
            className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-gray-700 text-white p-2 rounded-full"
          >
            {/* Right Arrow Icon */}
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256">
              <path d="M237.66,122.34l-96-96A8,8,0,0,0,128,32V72H48A16,16,0,0,0,32,88v80a16,16,0,0,0,16,16h80v40a8,8,0,0,0,13.66,5.66l96-96A8,8,0,0,0,237.66,122.34ZM144,204.69V176a8,8,0,0,0-8-8H48V88h88a8,8,0,0,0,8-8V51.31L220.69,128Z"></path>
            </svg>
          </button>
        )}
      </div>

      {/* Indicator Dots */}
      <div className="flex gap-1 mt-2">
        {photoArray.map((_, index) => (
          <button
            key={index}
            onClick={() => setImageByIndex(index)}
            className={`w-3 h-3 rounded-full ${index === imageIndex ? "bg-accent" : "bg-gray-300"}`}
            aria-label={`Go to image ${index + 1}`}
          />
        ))}
      </div>

      {/* Thumbnails */}
      <div className="flex gap-2 mt-4 overflow-x-auto">
        {photoArray.map((photo, index) => (
          <img
            key={index}
            src={photo}
            onClick={() => setImageByIndex(index)}
            className={`cursor-pointer w-16 h-16 object-cover rounded-md transition-opacity duration-300 ${
              index === imageIndex ? "border-2 border-accent" : "opacity-50"
            }`}
            alt={`Thumbnail ${index + 1}`}
          />
        ))}
      </div>
    </div>
  );
};

export default ImageSlider;

