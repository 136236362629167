import { useState } from "react";
//import { locations } from "../utils/data";

import locations from "../utils/locations.json";

const Test: React.FC<{
  className?: string;
  classNameState?: string;
  classNameCity?: string;
  classNameLocality?: string;
}> = ({ className, classNameState, classNameCity, classNameLocality }) => {
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedLocality, setSelectedLocality] = useState("");

  const handleStateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedState(e.target.value);
    setSelectedCity("");
    setSelectedLocality("");
  };

  const handleCityChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCity(e.target.value);
    setSelectedLocality("");
  };

  const handleLocalityChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedLocality(e.target.value);
  };

  return (
    <>
      <div className={className}>
        {/* State selection */}
        <div className="">
          <p>Select State</p>
          <select
            className={classNameState}
            value={selectedState}
            onChange={handleStateChange}
          >
            <option value="">Select State</option>
            {locations.map((state) => (
              <option key={state.state} value={state.state}>
                {state.state}
              </option>
            ))}
          </select>
        </div>

        {/* If a state is selected */}
        {selectedState && (
          <div className="">
            <p>Select City</p>
            <select
              className={classNameCity}
              value={selectedCity}
              onChange={handleCityChange}
            >
              <option value="">Select City</option>
              {locations
                .find((state) => state.state === selectedState)
                ?.cities.map((city) => (
                  <option key={city.name} value={city.name}>
                    {city.name}
                  </option>
                ))}
            </select>
          </div>
        )}

        {/* If a city is selected */}
        {selectedCity && (
          <div className="">
            <p>Select Locality</p>
            <select
              className={classNameLocality}
              value={selectedLocality}
              onChange={handleLocalityChange}
            >
              <option value="">Select Locality</option>
              {locations
                .find((state) => state.state === selectedState)
                ?.cities.find((city) => city.name === selectedCity)
                ?.localities.map((locality) => (
                  <option key={locality} value={locality}>
                    {locality}
                  </option>
                ))}
            </select>
          </div>
        )}
      </div>
    </>
  );
};

export default Test;
