import { testimonials } from "../utils/data";
import Cat from "/images/cat.jpg";

const SuccessStories: React.FC = () => {
  return (
    <>
      <div className="mb-8">
        <div className="flex flex-col place-items-center my-8 px-3">
          <h1 className="text-3xl laptop:text-5xl">Success Stories</h1>
          <h3 className="text-xl laptop:text-2xl">
            Read what our customers have to say about us
          </h3>
        </div>
        <ul className="grid laptop:grid-cols-2 mx-6 gap-4">
          {testimonials &&
            testimonials.map((story) => (
              <li className="h-fit tablet:min-h-[24rem] tablet:grid tablet:grid-cols-8 bg-secondaryBg border border-black rounded-2xl">
                <div className="h-[20rem] tablet:w-full tablet:h-full laptop:h-full tablet:col-span-3 rounded-2xl">
                  <img
                    src={Cat}
                    alt="Story Photo"
                    className="w-full h-full rounded-l-2xl object-fit"
                  />
                </div>
                <div className="flex flex-col gap-y-4 tablet:col-span-5 px-4 py-6">
                  <h4 className="text-center text-xl font-medium">
                    {story.title}
                  </h4>
                  <p className="text-lg">{story.quote}</p>
                </div>
              </li>
            ))}
        </ul>
      </div>
    </>
  );
};

export default SuccessStories;
