import { Link } from "react-router-dom";

import adSpace1 from "/images/adSpace1.jpg";
import adSpace2 from "/images/adSpace2.jpg";
import adSpace3 from "/images/adSpace3.jpg";
import adSpace4 from "/images/adSpace4.jpg";



import advertise from "/images/advertise.png";
import { ReactElement, useState } from "react";

import { SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

interface AdSpace {
  id: number;
  title: string;
  preview: string;
  description: ReactElement;
}

const adSpaces: AdSpace[] = [
  {
    id: 1,
    title: "Ad Space 1",
    preview: adSpace1,
    description: <p>This is ad1 description</p>,
  },
  {
    id: 2,
    title: "Ad Space 2",
    preview: adSpace2,
    description: <p>This is ad2 description</p>,
  },
  {
    id: 3,
    title: "Ad Space 3",
    preview: adSpace3,
    description: <p>This is ad3 description</p>,
  },
  {
    id: 4,
    title: "Ad Space 4",
    preview: adSpace4,
    description: <p>This is ad4 description</p>,
  },
];

const appointmentBookingSchema = z.object({
  contactPersonName: z.string().min(1, { message: "Required" }),
  contactNumber: z.string().min(10, { message: "Invalid Number" }),
  emailId: z.string().min(1, { message: "Required" }),
  typeOfBusiness: z.string().min(1, { message: "Required" }),
  companyAddress: z.string().min(1, { message: "Required" }),
  adStartDate: z.string().min(1, { message: "Required" }),
  howYouKnow: z.string().min(1, { message: "Required" }),
  adDetails: z.string().min(1, { message: "Required" }),
});
type AppointmentBookingFields = z.infer<typeof appointmentBookingSchema>;

const Advertising: React.FC = () => {
  const [selectedAd, setSelectedAd] = useState<AdSpace | null>(null);

  const appointmentBookingForm = useForm<AppointmentBookingFields>({
    resolver: zodResolver(appointmentBookingSchema),
  });
  const onAppointmentBookingFormSubmit: SubmitHandler<
    AppointmentBookingFields
  > = async (data) => {
    try {
      console.log(data);
    } catch (error) {
      console.log("ERROR: ", error);
    }
  };

  const handleDropdown = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedId = Number(e.target.value);
    const ad = adSpaces.find((ad) => ad.id === selectedId) || null;
    setSelectedAd(ad);
  };

  return (
    <>
      <div className="m-4 laptop:m-10 rounded-2xl p-4 laptop:p-10 bg-gradient-to-br from-accent to-accentSec">
        <div className="flex gap-x-4 items-center">
          <img
            src={advertise}
            alt="Advertise with us"
            className="h-[40px] laptop:h-[60px]"
          />
          <p className="text-xl mobileLg:text-4xl laptop:text-5xl">
            Advertise With Us
          </p>
        </div>
        <div className="bg-white grid gap-y-4 laptop:gap-y-8 py-8 mt-6 rounded-2xl px-3 laptop:px-12">
          <div className="flex flex-col items-center place-content-center">
            <p className="text-3xl text-center font-semibold">
              Boost your online presence with us
            </p>
            <p className="text-lg text-center">
              Take your business to the next level by advertising on our website
            </p>
          </div>
          <div className="flex  tablet:grid tablet:grid-cols-2 flex-col gap-4 items-center place-content-center">
            <div className="h-full w-full">
              <p className="text-3xl font-semibold">Why Choose Us?</p>
              <ul className="text-lg">
                <li>Huge User Base</li>
                <li>Variety of Ad Formats</li>
                <li>Expert Support</li>
              </ul>
            </div>
            <div className="h-full w-full">
              <p className="text-3xl font-semibold">Benefits Of Advertising</p>
              <ul className="text-lg">
                <li>Increased website traffic</li>
                <li>Improved brand recognition</li>
                <li>Enhanced lead generation</li>
                <li>Measurable ROI</li>
              </ul>
            </div>
          </div>
          <hr className="border-accentSec" />
          <div className="grid">
            <select
              id="ad-select"
              className="p-2 border-2 rounded-2xl w-full laptop:w-[40%] mx-auto"
              onChange={handleDropdown}
            >
              <option value="">Select an ad space to get a preview</option>
              {adSpaces.map((ad) => (
                <option key={ad.id} value={ad.id}>
                  {ad.title}
                </option>
              ))}
            </select>
            {selectedAd && (
              <div className="mt-3">
                <h2 className="text-center">{selectedAd.title}</h2>
                <img
                  src={selectedAd.preview}
                  alt={selectedAd.title}
                  className="my-3"
                />
                {selectedAd.description}
              </div>
            )}
          </div>
          <hr className="border-accentSec" />
          <div className="">
            <p className="text-xl mobileLg:text-2xl font-semibold text-center">
              Appointment Booking
            </p>
          </div>
          {/* Appointment Form */}
          <form
            onSubmit={appointmentBookingForm.handleSubmit(
              onAppointmentBookingFormSubmit
            )}
            className="grid gap-y-6"
          >
            {/* Personal Fields */}
            <div className="grid gap-y-8">
              {/* Contact Details */}
              <div className="desktop:grid desktop:grid-cols-3 flex flex-wrap gap-4">
                <div className="desktop:grid desktop:grid-cols-2 flex flex-wrap gap-x-3 items-center">
                  <div>
                    <p className="tablet:text-xl col-span-1 flex min-w-max">
                      Contact Person Name:
                    </p>
                    <p className="text-red-500">
                      {
                        appointmentBookingForm.formState.errors
                          .contactPersonName?.message
                      }
                    </p>
                  </div>
                  <input
                    type="text"
                    {...appointmentBookingForm.register("contactPersonName")}
                    className="py-2 px-4 form-input col-span-1"
                    placeholder="Contact Person Name"
                  />
                </div>
                <div className="desktop:grid desktop:grid-cols-2 flex flex-wrap gap-x-3 items-center">
                  <div>
                    <p className="tablet:text-xl col-span-1 flex min-w-max">
                      Contact Number:
                    </p>
                    <p className="text-red-500">
                      {
                        appointmentBookingForm.formState.errors.contactNumber
                          ?.message
                      }
                    </p>
                  </div>
                  <input
                    type="text"
                    {...appointmentBookingForm.register("contactNumber")}
                    className="py-2 px-4 form-input"
                    placeholder="Contact Number"
                  />
                </div>
                <div className="desktop:grid desktop:grid-cols-3 flex flex-wrap gap-x-3 items-center">
                  <div>
                    <p className="tablet:text-xl col-span-1 flex min-w-max">
                      Email ID:
                    </p>
                    <p className="text-red-500">
                      {appointmentBookingForm.formState.errors.emailId?.message}
                    </p>
                  </div>
                  <input
                    type="email"
                    {...appointmentBookingForm.register("emailId")}
                    className="py-2 px-4 form-input col-span-2"
                    placeholder="Email ID"
                  />
                </div>
              </div>
              {/* Company Details */}
              <div className="desktop:grid desktop:grid-cols-2 flex flex-wrap gap-y-2 laptop:gap-x-6">
                <div className="grid grid-cols-3 gap-x-6 items-center">
                  <div>
                    <p className="tablet:text-xl col-span-1 flex min-w-max">
                      Type of Business:
                    </p>
                    <p className="text-red-500">
                      {
                        appointmentBookingForm.formState.errors.typeOfBusiness
                          ?.message
                      }
                    </p>
                  </div>
                  <input
                    type="text"
                    {...appointmentBookingForm.register("typeOfBusiness")}
                    className="py-2 px-4 form-input col-span-2"
                    placeholder="Type of Business"
                  />
                </div>
                <div className="grid grid-cols-3 gap-x-6 items-center">
                  <div>
                    <p className="tablet:text-xl col-span-1 flex min-w-max">
                      Company Address:
                    </p>
                    <p className="text-red-500">
                      {
                        appointmentBookingForm.formState.errors.companyAddress
                          ?.message
                      }
                    </p>
                  </div>
                  <input
                    type="text"
                    {...appointmentBookingForm.register("companyAddress")}
                    className="py-2 px-4 form-input col-span-2"
                    placeholder="Company Address"
                  />
                </div>
              </div>
              {/* Ad details */}
              <div className="desktop:grid desktop:grid-cols-2 flex flex-wrap gap-2 laptop:gap-x-6">
                <div className="flex flex-wrap gap-x-6 items-center">
                  <div>
                    <p className="tablet:text-xl col-span-1 flex min-w-max">
                      Start Date of the Ad:
                    </p>
                    <p className="text-red-500">
                      {
                        appointmentBookingForm.formState.errors.adStartDate
                          ?.message
                      }
                    </p>
                  </div>
                  <input
                    type="date"
                    {...appointmentBookingForm.register("adStartDate")}
                    className="py-2 px-4 col-span-2 form-input"
                    placeholder="Start Date of Ad"
                  />
                </div>
                <div className="flex flex-wrap gap-x-6 items-center">
                  <div>
                    <p className="tablet:text-xl col-span-1 flex min-w-max">
                      How did you come to know about us?:
                    </p>
                    <p className="text-red-500">
                      {
                        appointmentBookingForm.formState.errors.howYouKnow
                          ?.message
                      }
                    </p>
                  </div>
                  <select
                    {...appointmentBookingForm.register("howYouKnow")}
                    className="p-2 form-input"
                  >
                    <option value="">Select Source</option>
                    {[
                      "Our Website",
                      "Marketing Campaign",
                      "Referral",
                      "Advertisement",
                      "Friend",
                    ].map((val) => (
                      <option value={val} key={val}>
                        {val}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            {/* Comment & Submit*/}
            <div className="grid gap-y-5">
              <div>
                <p className="tablet:text-xl">Advertisement Details:</p>
                <textarea
                  {...appointmentBookingForm.register("adDetails")}
                  value={appointmentBookingForm.watch("adDetails")}
                  onChange={(e) =>
                    appointmentBookingForm.setValue("adDetails", e.target.value)
                  }
                  className="form-input min-w-full p-6 mt-2"
                  rows={4}
                  placeholder="Tell us more about the kind of advertisement you want to place"
                  required
                />
              </div>
              <div className="flex place-content-center">
                <button
                  className="relative w-full px-8 group/btn border-2 btn rounded-md h-10 font-medium shadow-[0px_1px_0px_0px_#ffffff40_inset,0px_-1px_0px_0px_#ffffff40_inset]]"
                  type="submit"
                >
                  Book Appointment
                  <BottomGradient />
                </button>
              </div>
            </div>
          </form>
          <hr className="border-accentSec" />
          {/* How it works */}
          <div>
            <p className="text-2xl font-semibold text-center mb-4">
              How it works
            </p>
            <ul className="text-lg flex flex-col gap-y-2">
              <li>
                1. As per Government Marriage registration, please fill out the
                form.
              </li>
              <li>
                2. Book an appointment for registering your marriage with a
                Government Authorized Christian marriage register.
              </li>
              <li>
                3. We will share the in person meeting details to your email and
                WhatsApp.
              </li>
              <li>
                4. Carry your Marriage Banns Certificate issued from your
                church,
              </li>
              <li>5. Carry Marriage Invitation Card for proof.</li>
              <li>
                6. Carry original identification document with a photocopy along
                with a set of 4 recent colored passport size photos of both the
                Bride and Groom individually.
              </li>
              <li>
                7. One witness for bride and one witness for groom, physically
                need to sign in the marriage register and also on the
                certificate.
              </li>
              <li>
                8. After the signing, marriage register will issue a marriage
                certificate.
              </li>
              <li>
                9. Post the registration, please share your{" "}
                <Link to="/feedback" className="font-medium text-blue-600">
                  feedback
                </Link>{" "}
                with us.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Advertising;

const BottomGradient = () => {
  return (
    <>
      <span className="group-hover/btn:opacity-100 block transition duration-500 opacity-0 absolute h-px w-full -bottom-px inset-x-0 bg-gradient-to-r from-transparent via-cyan-500 to-transparent" />
      <span className="group-hover/btn:opacity-100 blur-sm block transition duration-500 opacity-0 absolute h-px w-1/2 mx-auto -bottom-px inset-x-10 bg-gradient-to-r from-transparent via-indigo-500 to-transparent" />
    </>
  );
};
