// import { Link, Outlet } from "react-router-dom";
import OurAds from "../components/AdminDashboard/Ads/OurAds";

// const links = {
//   ourAds: ["Our Ads", "/admin/ads/ourads"],
//   customerAds: ["Customer Ads", "/admin/ads/customerads"],
// };

const AdminAds: React.FC = () => {
  return (
    <>
      {/* <div className="flex">
        <div className="w-[20%] flex flex-col px-6 py-8 gap-y-4 min-h-[32rem] border-r">
          {Object.values(links).map((value) => (
            <Link
              key={value[1]}
              to={value[1]}
              className="bg-accent/50 border border-accent py-2 px-4 text-lg text-black font-medium rounded-xl"
            >
              {value[0]}
            </Link>
          ))}
        </div>
        <div className="w-[80%]">
          <Outlet />
        </div>
      </div> */}
      <OurAds />
    </>
  );
};

export default AdminAds;
