const apiUrl = import.meta.env.VITE_API_URL;

import { Link,useNavigate } from "react-router-dom";
import axios from "axios";
import { useUser } from './UserContext';
import { useAuthUser } from './AuthUserContext';

// import { HoverBorderGradient } from "../components/HoverBorderGradient";
import MarriageCounselling from "/images/marriageCounselling.png";
import MultiLang from "/images/MultiLang.png";
import OnlineBgV from "/images/onlineBgV.png";
import MarriageRegistration from "/images/marriageRegistration.png";
import RelationshipManager from "/images/CustomerSupport.png";
import Journey from "/images/Journey.png";

// import { Label } from "../components/Label";
// import { Input } from "../components/Input";
// import { cn } from "../utils/utils";

// import { useState } from "react";
// import Modal from "../components/SignupModal";
import useScreenType from "../hooks/useScreenType";

import { SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";





const emailLoginFormSchema = z.object({
  email: z.string().email(),
  password: z.string(),
});
type EmailLoginFormFields = z.infer<typeof emailLoginFormSchema>;

const mobileLoginFormSchema = z.object({
  mobile: z.string().min(10, { message: "Invalid mobile number" }),
});
type MobileLoginFormFields = z.infer<typeof mobileLoginFormSchema>;

const Home: React.FC = () => {

  console.log("API URL:", import.meta.env.VITE_API_URL);

  const { setUser } = useAuthUser()
  // const [showModal, setShowModal] = useState(false);
  //const isMobile = useIsMobile();
  const screenType = useScreenType();

  // const handleModal = () => {
  //   setShowModal(true);
  // };
  const { setUserType, setUserDetails } = useUser();
  const navigate = useNavigate();
  const emailLoginForm = useForm<EmailLoginFormFields>({
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: zodResolver(emailLoginFormSchema),
  });
  const onEmailLoginFormSubmit: SubmitHandler<EmailLoginFormFields> = async (
    data
  ) => {
    try {
      const formData = new URLSearchParams();
      formData.append('emailid', data.email); // Ensure key matches backend expectation
      formData.append('password', data.password);

      
  
      const response = await axios.post(`${apiUrl}/api/authlogin/login`, formData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        withCredentials: true, // Send cookies with the request
      });
    
  
      if (response.data.success) {
        alert("Login Successful");
        const { firstname, photo ,TMI } = response.data.user;
        setUserType('user'); // Setting userType to 'user'
        setUserDetails?.({ firstname, photo }); // Set user details
        setUser(TMI);
        console.log(TMI);
        
        navigate('/profilelisting', { replace: true }); // Navigate to profilelisting
      } 
      else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error("Login error:", error);
      alert("Failed to login. Please check your credentials and try again.");
    }
  };
  

  const mobileLoginForm = useForm<MobileLoginFormFields>({
    defaultValues: {
      mobile: "",
    },
    resolver: zodResolver(mobileLoginFormSchema),
  });
  const onMobileLoginFormSubmit: SubmitHandler<MobileLoginFormFields> = async (
    data
  ) => {
    try {
      
      console.log(data);
    } catch (error) {
      mobileLoginForm.setError("mobile", { message: "Mobile No. not found" });
    }
  };



  return (
    <>
      {/* SignUp Banner */}
      <section className="flex flex-col laptop:min-h-[40rem] w-full overflow-hidden relative gap-5 laptop:px-20 laptop:py-8">
        {screenType != "mobile" && screenType != "tablet" && (
          <>
            <img
              src="/images/triune-christian-matrimony-signupCover.jpg" // Replace with your image path
              alt="Background"
              className="absolute inset-0 -z-10 h-full w-full object-cover" // Positioning and sizing
            />

            <h1 className="relative signup-card-heading w-[60%] self-start mt-20 sm:text-2xl tablet:text-4xl tablet:mt-40 tablet:w-[40%] laptop:text-5xl laptop:mt-24 laptop:w-[50%] laptop:text-left desktop:text-6xl desktop:mt-20 desktop:w-[60%] text-center text-black">
              I Have Found The One <br /> My Heart Loves
              <br />
              <p className="text-3xl text-right pr-16">Song of Solomon 3:4</p>
            </h1>
          </>
        )}

        {/* Login form */}
        {screenType != "desktop" && screenType != "laptop" && (
          <div className="flex flex-col w-full mt-6 px-3 bg-white text-accentSec signup-card-heading place-items-center place-content-center text-5xl gap-3">
            <p className="text-center tablet:text-left">
              I Have Found The One My Heart Loves
            </p>
            <p className="text-2xl w-full text-right ">Song of Solomon 3:4</p>
          </div>
        )}
        <div className="w-full border-8 tablet:border-[1rem] laptop:border-0 rounded-3xl border-white tablet:flex tablet:flex-wrap tablet:gap-4 laptop:block laptop:absolute laptop:w-[30rem] laptop:right-8 desktop:right-12 top-12 laptop:border-gray-800 px-5 py-8 laptop:py-8 laptop:px-10 laptop:rounded-3xl bg-black bg-opacity-70 content-center backdrop-blur-sm">
          <div className="w-full tablet:w-[60%] tablet:mx-auto laptop:w-full laptop:mx-0">
            <h2 className="text-5xl text-accent text-center font-medium">
              LogIn
            </h2>
            <div className="mt-8 antialiased">
              {/* Email Login */}
              <form
                onSubmit={emailLoginForm.handleSubmit(onEmailLoginFormSubmit)}
              >
                <div className="w-full">
                  <div>
                    <p className="text-white">Email ID</p>
                    <p className="text-red-500">
                      {emailLoginForm.formState.errors.email?.message}
                    </p>
                  </div>
                  <input
                    type="text"
                    {...emailLoginForm.register("email")}
                    className="p-2 w-full border border-accent rounded-lg"
                    placeholder="youremail@xyz.com"
                  />
                </div>
                <div className="w-full my-3">
                  <p className="text-white">Password</p>
                  <input
                    type="password"
                    {...emailLoginForm.register("password")}
                    className="p-2 w-full border border-accent rounded-lg"
                    placeholder="password"
                  />
                </div>
                <button
                  className="relative w-full group/btn btn  rounded-md h-10 font-medium"
                  type="submit"
                >
                  Log In &rarr;
                  <BottomGradient />
                </button>
              </form>

              <div className="bg-gradient-to-r from-transparent via-neutral-300  to-transparent my-4 h-[1px] w-full" />
              <p className="text-white text-2xl text-center">OR</p>
              <div className="bg-gradient-to-r from-transparent via-neutral-300  to-transparent my-4 h-[1px] w-full" />

              {/* Mobile Login */}
              <div>
                <form
                  onSubmit={mobileLoginForm.handleSubmit(
                    onMobileLoginFormSubmit
                  )}
                  className="flex flex-col place-items-center md:flex-row space-y-2 md:space-y-0 md:space-x-2"
                >
                  <div className="w-[70%] flex-col">
                    <input
                      {...mobileLoginForm.register("mobile")}
                      type="text"
                      placeholder="Your Mobile number"
                      className="form-input w-full"
                    />
                  </div>
                  <p className="laptop:hidden text-red-500">
                    {mobileLoginForm.formState.errors.mobile?.message}
                  </p>
                  <button
                    className="relative w-[30%] group/btn btn rounded-md h-10 font-medium"
                    type="submit"
                  >
                    Get OTP
                    <BottomGradient />
                  </button>
                </form>

                <p className="hidden laptop:block text-red-500">
                  {mobileLoginForm.formState.errors.mobile?.message}
                </p>
              </div>
            </div>
            <p className="text-white text-center mt-6">
              Are you a new user?{" "}
              <Link
                to="/signup"
                className="underline underline-offset-2 text-blue-500"
              >
                Register Free
              </Link>
            </p>
          </div>
        </div>
      </section>
      {/* Our Ad */}
      <Link to="/membership">
        <div className="scrolling-container relative h-20 flex place-content-center bg-gradient-to-r from-accent to-accent/50 py-2 border-t-[1px] border-b-[1px] border-black">
          <span className="scrolling-content flex place-content-center font-medium text-3xl text-black">
            Find your God-ordained partner with Triune Matrimony - Enjoy{" "}
            <strong className="text-accentSec font-bold">
              50% OFF on premium membership
            </strong>{" "}
            - To discover your perfect match in faith,{" "}
            <strong className="text-accentSec font-bold">CLICK HERE!</strong>
          </span>
        </div>
      </Link>
      {/* Vision*/}
      <section className="flex flex-col w-full py-8 px-4 relative border-t-2">
        <div className="flex flex-col items-center">
          <h2 className="home-heading">Triune Matrimony's Vision</h2>
          <h4 className="mt-2 home-subheading">Subheading?</h4>
        </div>
        <div className="mt-12 px-4 md:px-20">
          <p className="text-xl">
            Triune Matrimony is to create a Christian community where
            individuals can trustingly find their perfect life partner, built on
            a foundation of shared values and faith, resulting in lifelong
            companionship and Background verification feature helps in
            significant reduction in divorce rates.
          </p>
        </div>
      </section>
      {/* Why us */}
      <section className="flex flex-col w-full py-8 relative min-h-[32rem] border-t-2">
        <div className="flex flex-col items-center">
          <h2 className="home-heading">Why Us?</h2>
          <h4 className="mt-2 home-subheading">
            Discover the unique benefits we offer
          </h4>
        </div>
        <div className="grid mobileLg:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 mt-5 px-16 gap-8">
          <div className="flex flex-col items-center">
            <div className="flex w-[9rem] h-[9rem]">
              <img src={MultiLang} alt="Multi Language" />
            </div>
            <p className="text-center text-xl mt-10">
              View the website in your own language
            </p>
          </div>

          <div className="flex flex-col items-center">
            <div className="flex w-[9rem] h-[9rem]">
              <img src={OnlineBgV} alt="Background Verification" />
            </div>
            <p className="text-center text-xl mt-10">
              Profile Background Verification
            </p>
          </div>

          <div className="flex flex-col items-center">
            <div className="flex w-[9rem] h-[9rem]">
              <img src={MarriageCounselling} alt="Marriage Counselling" />
            </div>
            <p className="text-center text-xl mt-10">
              Pre & Post Marriage Counselling Support
            </p>
          </div>

          <div className="flex flex-col items-center">
            <div className="flex w-[9rem] h-[9rem]">
              <img src={MarriageRegistration} alt="Marriage Registration" />
            </div>
            <p className="text-center text-xl mt-10">
              Marriage Registration Support
            </p>
          </div>

          <div className="flex flex-col items-center">
            <div className="flex w-[9rem] h-[9rem]">
              <img src={RelationshipManager} alt="Marriage Registration" />
            </div>
            <p className="text-center text-xl mt-10">
              Dedicated Relationship Manager Support 
            </p>
          </div>

          {/* <div className="flex flex-col items-center">
            <div className="flex">
              <img src={WhatsappSupport} alt="Whatsapp Support" />
            </div>
            <p className="text-center text-xl mt-10">
              24 x 7 Whatsapp customer support
            </p>
          </div> */}
        </div>
      </section>
      {/* Verification & Services */}
      <section className="flex flex-col w-full pt-8 relative min-h-[32rem] border-t-2">
        <div className="grid gap-y-4 laptop:gap-y-0 laptop:grid-cols-2">
          <div className="laptop:ml-16 px-4 order-1 place-content-center items-center">
            <h2 className="text-4xl sm:text-5xl font-bold text-[#d46754] ">
              Worried about genuineness?
            </h2>
            <h3 className="font-medium text-xl sm:text-2xl mt-1">
              We provide you the solution to your worries.{" "}
            </h3>
            <h4 className="text-lg sm:text-xl mt-4 ">
              Background verification in marriage is essential for ensuring
              safety, trust, and compatibility. It helps prevent future
              conflicts by providing a clear understanding of each partner's
              history and character. Our thorough on-demand verification process
              provides you peace of mind and confidence in your relationship.
            </h4>
          </div>
          <div className="flex order-2 place-content-center">
            <img
              src="/images/verification2.png"
              alt="Background Verification Infographic"
              className="w-[80%] sm:w-[70%] md:w-[60%] laptop:w-[80%]"
            />
          </div>
        </div>
        <div className="grid gap-y-4 laptop:gap-y-0 laptop:grid-cols-2">
          <div className="flex order-2 laptop:order-1 place-content-center">
            <img
              src="/images/services2.png"
              alt="Services Infographic"
              className="w-[80%] sm:w-[70%] md:w-[60%] laptop:w-[100%]"
            />
          </div>
          <div className="laptop:mr-12 order-1 laptop:order-2 laptop:ml-8 px-4 place-content-center items-center">
            <h2 className="text-4xl sm:text-5xl font-bold text-[#d46754] ">
              Need some extra help?
            </h2>
            <h3 className="font-medium text-xl sm:text-2xl mt-1">
              We have got you covered!
            </h3>
            <h4 className="text-lg sm:text-xl mt-4 ">
              Our add-on services support your journey towards a fulfilling
              marriage. We offer marriage registration for legal ease, and
              counseling with pastors and doctors for spiritual and emotional
              well-being. Our relationship managers provide personalized
              guidance, and thorough background verification ensures trust and
              safety. Together, these services ensure a successful partnership.
            </h4>
          </div>
        </div>
      </section>
      {/* Your Journey*/}
      <section className="flex flex-col w-full relative bg-secondaryBg">
        <img
          src={Journey}
          alt="Your journey"
          className="object-fit w-full h-full"
        />
      </section>
      {/* Featured Profiles */}
      
      {/* Testimonials */}
      {/* <section className="flex flex-col w-full py-8 relative laptop:min-h-[32rem] border-t-2">
        <div className="rounded-md flex flex-col antialiased bg-white items-center justify-center relative overflow-hidden">
          <div className="flex flex-col items-center">
            <h2 className="home-heading">Testimonials</h2>
            <h4 className="mt-2 home-subheading">
              Read what our clients have to say about us
            </h4>
          </div>
          <TestimonialsInfiniteMovingCards
            items={testimonials}
            direction="right"
            speed="slow"
          />
        </div>
        <div className="flex place-content-end">
          <Link to="/stories">
            <button className="btn flex flex-shrink-0 flex-grow-0 justify-center mx-6 items-center px-12 py-2">
              View All
            </button>
          </Link>
        </div>
      </section> */}
      {/* Ad space */}
      <section className="px-4 tablet:px-12 laptop:px-20 py-8 min-h-[36rem] border-t-2">
        <div className="flex flex-col items-center">
          <h2 className="home-heading">All Your Marriage Needs</h2>
          <h4 className="mt-2 home-subheading">
            Advertise with us to make marriages more memorable
          </h4>
        </div>
        <div className="grid laptop:grid-cols-2 justify-between mt-10 gap-2">
          <div className="border border-red-500">
            <img
              src="/images/ad1.jpg"
              className="w-full h-full object-fit"
              alt="ad1"
            />
          </div>
          <div className="grid gap-2">
            <div className=" border border-red-500">
              <img
                src="/images/ad2.jpg"
                className="w-full h-full object-fit"
                alt="ad2"
              />
            </div>
            <div className=" border border-red-500">
              <img
                src="/images/ad3.jpg"
                className="w-full h-full object-fit"
                alt="ad3"
              />
            </div>
          </div>
        </div>
      </section>
      {/* Begin your search */}
      <section className="flex justify-start px-4 mobileLg:px-12 laptop:px-20 min-h-[12rem] bg-[#60C5FF] border-t-2 gap-5">
        <h2 className="text-3xl mobileLg:text-4xl flex items-center my-auto">
          Begin your search
        </h2>
        <Link
          to="/signup"
          className="flex place-items-center text-nowrap my-16 text-lg px-4 mobileLg:px-12 text-white rounded-3xl bg-[#65558F]"
        >
          Sign Up
        </Link>
      </section>
      <div className="flex flex-col items-center text-white bg-black"> This website is strictly for matrimonial purpose only and not a dating website and
should not be used for posting any obscene material. </div>
      {/* Modal */}
      {/* <Modal show={showModal} onClose={() => setShowModal(false)} /> */}
    </>
  );
};

export default Home;

const BottomGradient = () => {
  return (
    <>
      <span className="group-hover/btn:opacity-100 block transition duration-500 opacity-0 absolute h-px w-full -bottom-px inset-x-0 bg-gradient-to-r from-transparent via-cyan-500 to-transparent" />
      <span className="group-hover/btn:opacity-100 blur-sm block transition duration-500 opacity-0 absolute h-px w-1/2 mx-auto -bottom-px inset-x-10 bg-gradient-to-r from-transparent via-indigo-500 to-transparent" />
    </>
  );
};

// const LabelInputContainer = ({
//   children,
//   className,
// }: {
//   children: React.ReactNode;
//   className?: string;
// }) => {
//   return (
//     <div className={cn("flex flex-col space-y-2 w-full", className)}>
//       {children}
//     </div>
//   );
// };
