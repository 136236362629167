import { IconHearts, IconUsersGroup } from "@tabler/icons-react";
import { dropdownData } from "../utils/data";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import locations from "../utils/locations.json";
import useScreenType from "../hooks/useScreenType";
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { useAuthUser } from '../pages/AuthUserContext';
const apiUrl = import.meta.env.VITE_API_URL;


interface RegistrationStatusResponse {
  isRegistered: boolean;
  message: string;
}

const ValueAddServices: React.FC = () => {
  const location = useLocation();

  const [service, setService] = useState(location.state?.service || 0);

  useEffect(() => {
    setService(location.state?.service || 0);
  }, [location]);

  return (
    <>
      <div className="grid">
        <div className="flex place-content-center p-2 gap-2 laptop:gap-x-8 laptop:p-4">
          <button
            className={`${
              service === 0 ? "bg-gray-200" : ""
            } rounded-full p-2 laptop:px-4 laptop:py-2`}
            onClick={() => setService(0)}
          >
            <p className="laptop:text-lg font-semibold">Marriage Counselling</p>
          </button>
          <button
            className={`${
              service === 1 ? "bg-gray-200" : ""
            } rounded-full p-2 laptop:px-4 laptop:py-2`}
            onClick={() => setService(1)}
          >
            <p className="laptop:text-lg font-semibold">
              Marriage Registration
            </p>
          </button>
        </div>
        <div>
          {service === 0 ? <MarriageCounselling /> : <MarriageRegistration />}
        </div>
      </div>
    </>
  );
};

export default ValueAddServices;

const schemaRegistration = z.object({
  minister: z.string().min(1, { message: "Required" }),
  dateOfMarriage: z.string().min(1, { message: "Required" }),
  marriagePlace: z.string().min(1, { message: "Required" }),

  //Bride fields
  brideName: z.string().min(1, { message: "Required" }),
  brideAge: z.string().min(1, { message: "Required" }),
  brideCondition: z.string().min(1, { message: "Required" }),
  brideProfession: z.string().min(1, { message: "Required" }),
  brideResidence: z.string().min(1, { message: "Required" }),
  brideFather: z.string().min(1, { message: "Required" }),
  brideMother: z.string().min(1, { message: "Required" }),
  brideLicense: z.string().min(1, { message: "Required" }),
  brideWitness: z.string().min(1, { message: "Required" }),

  //Groom fields
  groomName: z.string().min(1, { message: "Required" }),
  groomAge: z.string().min(1, { message: "Required" }),
  groomCondition: z.string().min(1, { message: "Required" }),
  groomProfession: z.string().min(1, { message: "Required" }),
  groomResidence: z.string().min(1, { message: "Required" }),
  groomFather: z.string().min(1, { message: "Required" }),
  groomMother: z.string().min(1, { message: "Required" }),
  groomLicense: z.string().min(1, { message: "Required" }),
  groomWitness: z.string().min(1, { message: "Required" }),

  //appointment booking
  preferredLanguage: z.string().min(1, { message: "Required" }),
  dateAvailable: z.string().min(1, { message: "Required" }),
  timeSlot: z.string().min(1, { message: "Required" }),
  comment: z.string().min(1, { message: "Required" }),
});

type MarriageRegistrationFields = z.infer<typeof schemaRegistration>;

const MarriageRegistration: React.FC = () => {
  const screenType = useScreenType();
  const user = useAuthUser(); // Assuming this is always set
  const tmi=user.user;

  const [status, setStatus] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  console.log(loading,error)
  const fetchRegistrationStatus = async () => {
    try {
      setLoading(true);

      const response = await fetch(`${apiUrl}/api/marriage-registration-status/${tmi}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      

      const data: RegistrationStatusResponse = await response.json();

      // If registration is found, update the status
      setStatus(data.isRegistered ? 'Registered' : 'Not Registered');
      toast.success(data.message); // Show success message via Toastify
    } catch (error) {
      setError('Error fetching registration status.');
      toast.error('Failed to load status. Please try again later.');
      console.error('Error fetching registration status:', error);
    } finally {
      setLoading(false);
    }
  };

  const registrationForm = useForm<MarriageRegistrationFields>({
    resolver: zodResolver(schemaRegistration),
  });

  const onSubmit: SubmitHandler<MarriageRegistrationFields> = async (data) => {
    try {
      // Simulate delay (for UX improvements, if needed)
      await new Promise((resolve) => setTimeout(resolve, 1000));
      console.log(data)
      // Call the API to submit the marriage registration form
      const response = await fetch(`${apiUrl}/api/marriage-registration/${tmi}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data), // Sending form data in JSON format
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      // Parse the API response
      const result = await response.json();
  
      // Display success toast message
      toast.success('Marriage registration submitted successfully!');
      console.log('Form Data:', data);
      console.log('API Response:', result);
    } catch (error) {
      // Handle errors and show error toast message
      toast.error('Failed to submit registration. Please check the form and try again.');
      console.error('Submission Error:', error);
  
      // Optionally, handle validation errors here
      // registrationForm.setError("root", {
      //   message: "All fields are mandatory",
      // });
    }
  };

  console.log(registrationForm.watch("minister"));

  return (
    <>
      <div className="m-4 laptop:m-10 rounded-2xl p-3 laptop:p-10 bg-gradient-to-br from-accent to-accentSec">
        <div className="flex gap-x-4 items-center">
          <IconHearts
            size={
              screenType === "desktop" ? 60 : screenType === "tablet" ? 55 : 40
            }
            color="#ff0055"
          />
          <p className="text-xl tablet:text-4xl laptop:text-5xl">
            Marriage Registration
          </p>
        </div>
        <div className="bg-white grid mt-6 rounded-2xl p-3 laptop:px-12 laptop:py-8">
          <div className="flex flex-col items-center place-content-center">
            <p className="text-2xl laptop:text-3xl text-center font-semibold">
              Marriage Registration Details
            </p>
            <p className="text-lg text-center">
              Under the Indian Christian Marriage Act, 1872. See Sections 28 to
              and 79.
            </p>
          </div>
          <hr className="border-accentSec my-6" />
          {/* Form */}
          <form onSubmit={registrationForm.handleSubmit(onSubmit)}>
            {/* General Details */}
            <div className="grid laptop:grid-cols-3 gap-4 laptop:gap-x-14">
              <div className="flex flex-wrap laptop:grid gap-x-6 items-center">
                <p className="text-center mobileLg:text-xl">
                  Minister who Solemnized the marriage:
                </p>
                <input
                  {...registrationForm.register("minister")}
                  type="text"
                  placeholder="Minister Name"
                  className="py-2 px-4 border border-accent rounded-lg"
                />
                <p className="text-red-600 text-center">
                  {registrationForm.formState.errors.minister?.message}
                </p>
              </div>
              <div className="flex flex-wrap laptop:grid gap-x-6 items-center">
                <p className="text-center mobileLg:text-xl">
                  Date of Marriage:
                </p>

                <input
                  {...registrationForm.register("dateOfMarriage")}
                  type="date"
                  className="py-2 px-4 border border-accent rounded-lg"
                />
                <p className="text-red-600 text-center">
                  {registrationForm.formState.errors.dateOfMarriage?.message}
                </p>
              </div>
              <div className="flex flex-wrap laptop:grid gap-x-6 items-center">
                <p className="text-center mobileLg:text-xl">
                  Place of Marriage:
                </p>

                <input
                  {...registrationForm.register("marriagePlace")}
                  type="text"
                  placeholder="Marriage Location"
                  className="py-2 px-4 border border-accent rounded-lg"
                />
                <p className="text-red-600 text-center">
                  {registrationForm.formState.errors.marriagePlace?.message}
                </p>
              </div>
            </div>
            <hr className="border-accentSec my-6" />

            <div className="">
              <p className="text-xl tablet:text-2xl font-semibold text-center">
                Appointment Booking
              </p>
            </div>

            <div className="grid tablet:grid-cols-2 laptop:grid-cols-3 mt-6 gap-6">
              {/* Main info */}
              <div className="grid grid-rows-3">
                <div className="grid grid-cols-2 gap-x-6 items-center">
                  <div>
                    <p className="mobileLg:text-xl">Preferred Language:</p>
                    <p className="text-red-600">
                      {
                        registrationForm.formState.errors.preferredLanguage
                          ?.message
                      }
                    </p>
                  </div>
                  <select
                    {...registrationForm.register("preferredLanguage")}
                    className="p-2 border border-accent rounded-lg"
                  >
                    <option value="">Preferred Language</option>
                    <option value="Kannada">Kannada</option>
                    <option value="Tamil">Tamil</option>
                    <option value="English">English</option>
                  </select>
                </div>
                <div className="grid grid-cols-2 gap-x-6 items-center">
                  <div>
                    <p className="mobileLg:text-xl">Available Date:</p>
                    <p className="text-red-600">
                      {registrationForm.formState.errors.dateAvailable?.message}
                    </p>
                  </div>
                  <input
                    type="date"
                    {...registrationForm.register("dateAvailable")}
                    className="py-2 px-4 border border-accent rounded-lg"
                  />
                </div>
                <div className="grid grid-cols-2 gap-x-6 items-center">
                  <div>
                    <p className="mobileLg:text-xl">Time Slot:</p>
                    <p className="text-red-600">
                      {registrationForm.formState.errors.timeSlot?.message}
                    </p>
                  </div>
                  <select
                    {...registrationForm.register("timeSlot")}
                    className="p-2 border border-accent rounded-lg"
                  >
                    <option value="">Time Slot</option>
                    {dropdownData.timeSlots.map((val) => (
                      <option value={val} key={val}>
                        {val}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              {/* Comment */}
              <div className="grid gap-y-5">
                <div>
                  <div>
                    <p className="text-xl">Your Comments:</p>
                    <p className="text-red-600">
                      {registrationForm.formState.errors.comment?.message}
                    </p>
                  </div>
                  <textarea
                    {...registrationForm.register("comment")}
                    value={registrationForm.watch("comment")}
                    onChange={(e) =>
                      registrationForm.setValue("comment", e.target.value)
                    }
                    className="border rounded-2xl min-w-full p-2 mt-2"
                    rows={4}
                    placeholder="Enter your text here..."
                  />
                </div>
               
              </div>
            </div>
            <hr className="border-accentSec my-6" />
            {/* Bride & Groom details */}

            <div className="grid laptop:grid-cols-2 mt-6">
              {/* Bride Part */}
              <div className="grid">
                <p className="text-3xl font-semibold text-center">The Bride</p>
                <div className="grid mt-6 gap-y-4">
                  <div className="grid grid-cols-2 gap-x-2 laptop:gap-x-6 p-1 laptop:px-12 items-center">
                    <div>
                      <p className="text-center mobileLg:text-xl">Full Name:</p>
                      <p className="text-center text-red-600">
                        {registrationForm.formState.errors.brideName?.message}
                      </p>
                    </div>
                    <input
                      {...registrationForm.register("brideName")}
                      type="text"
                      placeholder="Bride's Full Name"
                      className="py-2 px-4 border border-accent rounded-lg"
                    />
                  </div>
                  <div className="grid grid-cols-2 gap-x-2 laptop:gap-x-6 p-1 laptop:px-12 items-center">
                    <div>
                      <p className="text-center mobileLg:text-xl">Age:</p>
                      <p className="text-center text-red-600">
                        {registrationForm.formState.errors.brideAge?.message}
                      </p>
                    </div>
                    <input
                      {...registrationForm.register("brideAge")}
                      type="text"
                      placeholder="Bride's Age"
                      className="py-2 px-4 border border-accent rounded-lg"
                    />
                  </div>
                  <div className="grid grid-cols-2 gap-x-2 laptop:gap-x-6 p-1 laptop:px-12 items-center">
                    <div>
                      <p className="text-center mobileLg:text-xl">Condition:</p>
                      <p className="text-center text-red-600">
                        {
                          registrationForm.formState.errors.brideCondition
                            ?.message
                        }
                      </p>
                    </div>
                    <select
                      {...registrationForm.register("brideCondition")}
                      className="py-2 px-4 border border-accent rounded-lg"
                    >
                      <option value="">Condition</option>
                      {["Spinster", "Widow", "Divorcee"].map((val) => (
                        <option value={val} key={val}>
                          {val}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="grid grid-cols-2 gap-x-2 laptop:gap-x-6 p-1 laptop:px-12 items-center">
                    <div>
                      <p className="text-center mobileLg:text-xl">
                        Profession:
                      </p>
                      <p className="text-center text-red-600">
                        {
                          registrationForm.formState.errors.brideProfession
                            ?.message
                        }
                      </p>
                    </div>
                    <input
                      {...registrationForm.register("brideProfession")}
                      type="text"
                      placeholder="Bride's Profession"
                      className="py-2 px-4 border border-accent rounded-lg"
                    />
                  </div>
                  <div className="grid grid-cols-2 gap-x-2 laptop:gap-x-6 p-1 laptop:px-12 items-center">
                    <div>
                      <p className="text-center mobileLg:text-xl">Residence:</p>
                      <p className="text-center text-red-600">
                        {
                          registrationForm.formState.errors.brideResidence
                            ?.message
                        }
                      </p>
                    </div>
                    <input
                      {...registrationForm.register("brideResidence")}
                      type="text"
                      placeholder="Bride's Residence"
                      className="py-2 px-4 border border-accent rounded-lg"
                    />
                  </div>
                  <div className="grid grid-cols-2 gap-x-2 laptop:gap-x-6 p-1 laptop:px-12 items-center">
                    <div>
                      <p className="text-center mobileLg:text-xl">Father:</p>
                      <p className="text-center text-red-600">
                        {registrationForm.formState.errors.brideFather?.message}
                      </p>
                    </div>
                    <input
                      {...registrationForm.register("brideFather")}
                      type="text"
                      placeholder="Bride's Father"
                      className="py-2 px-4 border border-accent rounded-lg"
                    />
                  </div>
                  <div className="grid grid-cols-2 gap-x-2 laptop:gap-x-6 p-1 laptop:px-12 items-center">
                    <div>
                      <p className="text-center mobileLg:text-xl">Mother:</p>
                      <p className="text-center text-red-600">
                        {registrationForm.formState.errors.brideMother?.message}
                      </p>
                    </div>
                    <input
                      {...registrationForm.register("brideMother")}
                      type="text"
                      placeholder="Bride's Mother"
                      className="py-2 px-4 border border-accent rounded-lg"
                    />
                  </div>
                  <div className="grid grid-cols-2 gap-x-2 laptop:gap-x-6 p-1 laptop:px-12 items-center">
                    <div>
                      <p className="text-center mobileLg:text-xl">
                        By Banns or License:
                      </p>
                      <p className="text-center text-red-600">
                        {
                          registrationForm.formState.errors.brideLicense
                            ?.message
                        }
                      </p>
                    </div>
                    <input
                      {...registrationForm.register("brideLicense")}
                      type="text"
                      placeholder="Bride's Banns/License"
                      className="py-2 px-4 border border-accent rounded-lg"
                    />
                  </div>
                  <div className="grid grid-cols-2 gap-x-2 laptop:gap-x-6 p-1 laptop:px-12 items-center">
                    <div>
                      <p className="text-center mobileLg:text-xl">
                        Bride's Witness:
                      </p>
                      <p className="text-center text-red-600">
                        {
                          registrationForm.formState.errors.brideWitness
                            ?.message
                        }
                      </p>
                    </div>
                    <input
                      {...registrationForm.register("brideWitness")}
                      type="text"
                      placeholder="Bride's Witness"
                      className="py-2 px-4 border border-accent rounded-lg"
                    />
                  </div>
                </div>
              </div>
              {/* Groom Part */}
              <div className="grid mt-6 laptop:mt-0">
                <p className="text-3xl font-semibold text-center">The Groom</p>
                <div className="grid mt-6 gap-y-4">
                  <div className="grid grid-cols-2 gap-x-2 laptop:gap-x-6 p-1 laptop:px-12 items-center">
                    <div>
                      <p className="text-center mobileLg:text-xl">Full Name:</p>
                      <p className="text-center text-red-600">
                        {registrationForm.formState.errors.groomName?.message}
                      </p>
                    </div>
                    <input
                      {...registrationForm.register("groomName")}
                      type="text"
                      placeholder="Groom's Full Name"
                      className="py-2 px-4 border border-accent rounded-lg"
                    />
                  </div>
                  <div className="grid grid-cols-2 gap-x-2 laptop:gap-x-6 p-1 laptop:px-12 items-center">
                    <div>
                      <p className="text-center mobileLg:text-xl">Age:</p>
                      <p className="text-center text-red-600">
                        {registrationForm.formState.errors.groomAge?.message}
                      </p>
                    </div>
                    <input
                      {...registrationForm.register("groomAge")}
                      type="text"
                      placeholder="Groom's Age"
                      className="py-2 px-4 border border-accent rounded-lg"
                    />
                  </div>
                  <div className="grid grid-cols-2 gap-x-2 laptop:gap-x-6 p-1 laptop:px-12 items-center">
                    <div>
                      <p className="text-center mobileLg:text-xl">Condition:</p>
                      <p className="text-center text-red-600">
                        {
                          registrationForm.formState.errors.groomCondition
                            ?.message
                        }
                      </p>
                    </div>
                    <select
                      {...registrationForm.register("groomCondition")}
                      className="py-2 px-4 border border-accent rounded-lg"
                    >
                      <option value="">Condition</option>
                      {["Spinster", "Widow", "Divorcee"].map((val) => (
                        <option value={val} key={val}>
                          {val}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="grid grid-cols-2 gap-x-2 laptop:gap-x-6 p-1 laptop:px-12 items-center">
                    <div>
                      <p className="text-center mobileLg:text-xl">
                        Profession:
                      </p>
                      <p className="text-center text-red-600">
                        {
                          registrationForm.formState.errors.groomProfession
                            ?.message
                        }
                      </p>
                    </div>
                    <input
                      {...registrationForm.register("groomProfession")}
                      type="text"
                      placeholder="Groom's Profession"
                      className="py-2 px-4 border border-accent rounded-lg"
                    />
                  </div>
                  <div className="grid grid-cols-2 gap-x-2 laptop:gap-x-6 p-1 laptop:px-12 items-center">
                    <div>
                      <p className="text-center mobileLg:text-xl">Residence:</p>
                      <p className="text-center text-red-600">
                        {
                          registrationForm.formState.errors.groomResidence
                            ?.message
                        }
                      </p>
                    </div>
                    <input
                      {...registrationForm.register("groomResidence")}
                      type="text"
                      placeholder="Groom's Residence"
                      className="py-2 px-4 border border-accent rounded-lg"
                    />
                  </div>
                  <div className="grid grid-cols-2 gap-x-2 laptop:gap-x-6 p-1 laptop:px-12 items-center">
                    <div>
                      <p className="text-center mobileLg:text-xl">Father:</p>
                      <p className="text-center text-red-600">
                        {registrationForm.formState.errors.groomFather?.message}
                      </p>
                    </div>
                    <input
                      {...registrationForm.register("groomFather")}
                      type="text"
                      placeholder="Groom's Father"
                      className="py-2 px-4 border border-accent rounded-lg"
                    />
                  </div>
                  <div className="grid grid-cols-2 gap-x-2 laptop:gap-x-6 p-1 laptop:px-12 items-center">
                    <div>
                      <p className="text-center mobileLg:text-xl">Mother:</p>
                      <p className="text-center text-red-600">
                        {registrationForm.formState.errors.groomMother?.message}
                      </p>
                    </div>
                    <input
                      {...registrationForm.register("groomMother")}
                      type="text"
                      placeholder="Groom's Mother"
                      className="py-2 px-4 border border-accent rounded-lg"
                    />
                  </div>
                  <div className="grid grid-cols-2 gap-x-2 laptop:gap-x-6 p-1 laptop:px-12 items-center">
                    <div>
                      <p className="text-center mobileLg:text-xl">
                        By Banns or License:
                      </p>
                      <p className="text-center text-red-600">
                        {
                          registrationForm.formState.errors.groomLicense
                            ?.message
                        }
                      </p>
                    </div>
                    <input
                      {...registrationForm.register("groomLicense")}
                      type="text"
                      placeholder="Groom's Banns/License"
                      className="py-2 px-4 border border-accent rounded-lg"
                    />
                  </div>
                  <div className="grid grid-cols-2 gap-x-2 laptop:gap-x-6 p-1 laptop:px-12 items-center">
                    <div>
                      <p className="text-center mobileLg:text-xl">
                        Bride's Witness:
                      </p>
                      <p className="text-center text-red-600">
                        {
                          registrationForm.formState.errors.groomWitness
                            ?.message
                        }
                      </p>
                    </div>
                    <input
                      {...registrationForm.register("groomWitness")}
                      type="text"
                      placeholder="Groom's Witness"
                      className="py-2 px-4 border border-accent rounded-lg"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="flex place-content-center">
                  <button
                    className="relative  px-8 group/btn border-2 btn rounded-md h-10 font-medium shadow-[0px_1px_0px_0px_#ffffff40_inset,0px_-1px_0px_0px_#ffffff40_inset]]"
                    type="submit"
                  >
                    Book Appointment
                    <BottomGradient />
                  </button>
            </div>
            
          </form>
          <hr className="border-accentSec my-6" />
          <div className="flex flex-col items-center justify-center ">
                  <h1 className="text-2xl font-bold mb-6">Check Your Registration Status</h1>
                  <div className="mb-4">
                    <label htmlFor="tmi" className="block text-sm font-medium text-gray-700">
                      Your TMI
                    </label>
                    <input
                      type="text"
                      id="tmi"
                      value={tmi}
                      disabled
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                  <button
                    onClick={fetchRegistrationStatus}
                    className="bg-blue-500 text-white px-4 py-2 rounded-md shadow-md hover:bg-blue-700"
                  >
                    Check Status
                  </button>

                  {status && (
                    <div className="mt-4">
                      <p className="text-xl font-semibold">
                        Registration Status: <span className="text-blue-600">{status}</span>
                      </p>
                    </div>
                  )}
          </div>
          <div>
            <p className="text-2xl font-semibold text-center mb-4">
              How it works
            </p>
            <ul className="text-lg flex flex-col gap-y-2">
              <li>
                1. As per Government Marriage registration, please fill out the
                form.
              </li>
              <li>
                2. Book an appointment for registering your marriage with a
                Government Authorized Christian marriage register.
              </li>
              <li>
                3. We will share the in person meeting details to your email and
                WhatsApp.
              </li>
              <li>
                4. Carry your Marriage Banns Certificate issued from your
                church,
              </li>
              <li>5. Carry Marriage Invitation Card for proof.</li>
              <li>
                6. Carry original identification document with a photocopy along
                with a set of 4 recent colored passport size photos of both the
                Bride and Groom individually.
              </li>
              <li>
                7. One witness for bride and one witness for groom, physically
                need to sign in the marriage register and also on the
                certificate.
              </li>
              <li>
                8. After the signing, marriage register will issue a marriage
                certificate.
              </li>
              <li>
                9. Post the registration, please share your{" "}
                <Link to="/feedback" className="font-medium text-blue-600">
                  feedback
                </Link>{" "}
                with us.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

//Marriage Counselling

const schemaCounselling = z.object({
  counsellingType: z.string().min(1, { message: "Select One" }),
  counsellingMode: z.string().min(1, { message: "Select One" }),
  counsellingBy: z.string().min(1, { message: "Select One" }),
  preferredLanguage: z.string().min(1, { message: "Choose an option" }),
  state: z.string().min(1, { message: "Choose an option" }),
  city: z.string().min(1, { message: "Choose an option" }),
  date: z.string().min(1, { message: "Required" }),
  timeSlot: z.string().min(1, { message: "Choose an option" }),
  comment: z.string(),
});

type MarriageCounsellingFields = z.infer<typeof schemaCounselling>;

const MarriageCounselling: React.FC = () => {

  const [isOfflineEnabled, setIsOfflineEnabled] = useState(true);
  const [isOnlineEnabled, setIsOnlineEnabled] = useState(true);
  const [status, setStatus] = useState('');  // Store booking status

  const user = useAuthUser(); // Assuming this is always set
  const tmi=user.user;

  const counsellingForm = useForm<MarriageCounsellingFields>({
    defaultValues: {
      counsellingType: "",
      counsellingMode: "",
      counsellingBy: "",
      preferredLanguage: "",
      state: "",
      city: "",
      date: "",
      timeSlot: "",
      comment: "",
      
    },
    resolver: zodResolver(schemaCounselling),
  });

  const screenType = useScreenType();

  useEffect(() => {
    
    console.log("Form errors: ", counsellingForm.formState.errors);
  }, [counsellingForm.formState.errors]);

  const onSubmit: SubmitHandler<MarriageCounsellingFields> = async (data) => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 1000));

      const response = await fetch(`${apiUrl}/api/marriage-counselling/${tmi}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      toast.success('Appointment booked successfully!');
      console.log(data);
    } catch (error) {
      toast.error('Submission failed. Please try again.');
      console.error("Submission Error: ", error);
    }
  };

  const checkBookingStatus = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/marriage-counselling/status/${tmi}`);
      const data = await response.json();

      if (response.ok) {
        setStatus(data.isBooked ? 'Booked' : 'Not Booked');
      } else {
        toast.error('Error fetching status. Please try again.');
      }
    } catch (error) {
      toast.error('Network error occurred. Please try again.');
    }
  };
  

  return (
    <>
      <div className="m-4 laptop:m-10 rounded-2xl p-3 laptop:p-10 bg-gradient-to-br from-accent to-accentSec">
        <div className="flex gap-x-4 items-center">
          <IconUsersGroup
            size={
              screenType === "desktop" ? 60 : screenType === "tablet" ? 55 : 40
            }
            color="#000000"
          />
          <p className="text-xl tablet:text-4xl laptop:text-5xl">
            Marriage Counselling
          </p>
        </div>
        <div className="flex flex-col place-content-center mt-6 bg-white rounded-2xl p-3 laptop:px-12 laptop:py-8">
          <div className="flex flex-col gap-y-8">
            <p className="text-2xl tablet:text-3xl laptop:text-4xl font-semibold text-center">
              Appointment Booking
            </p>
            <form
              onSubmit={counsellingForm.handleSubmit(onSubmit)}
              className="grid laptop:grid-cols-3 gap-x-6"
            >

              {/* Personal Fields */}
              <div className="grid grid-rows-6 gap-y-3 mobile:gap-y-1">
                <div className="grid grid-cols-2 gap-x-6 items-center mobile:gap-x-2">
                  <div>
                    <p className="mobileLg:text-xl">Preferred Language:</p>
                    <p className="text-red-600">
                      {
                        counsellingForm.formState.errors.preferredLanguage
                          ?.message
                      }
                    </p>
                  </div>
                  <select
                      {...counsellingForm.register("preferredLanguage")}
                      className="p-2 border border-accent rounded-lg"
                    >
                      <option value="">Preferred Language</option>
                      <option value="Kannada">Kannada</option>
                      <option value="Tamil">Tamil</option>
                      <option value="English">English</option>
                  </select>

                </div>
                 {/* State Selection */}
                  <div className="grid grid-cols-2 gap-x-6 items-center mobile:gap-x-2">
                    <div>
                      <p className="mobileLg:text-xl">State:</p>
                      <p className="text-red-600">
                        {counsellingForm.formState.errors.state?.message}
                      </p>
                    </div>
                    <select
                      className="p-2 border border-accent rounded-lg w-full"
                      {...counsellingForm.register("state")}
                      onChange={(e) => {
                        // Clear city selection when state changes
                        counsellingForm.setValue("state", e.target.value);
                        counsellingForm.setValue("city", ""); // Reset city selection
                      }}
                    >
                      <option value="">Select State</option>
                      {locations.map((state) => (
                        <option key={state.state} value={state.state}>
                          {state.state}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* City Selection */}
                  {counsellingForm.watch("state") && (
        <div className="grid grid-cols-2 gap-x-6 items-center mobile:gap-x-2">
          <div>
            <p className="mobileLg:text-xl">City:</p>
            <p className="text-red-600">
              {counsellingForm.formState.errors.city?.message}
            </p>
          </div>
          <select
            className="p-2 border border-accent rounded-lg w-full"
            {...counsellingForm.register("city")}
            onChange={(e) => {
              const selectedCity = e.target.value;

              // Logic for counselling mode based on selected city
              if (selectedCity === "Bangalore" || selectedCity === "Bangalore Rural") {
                setIsOfflineEnabled(true); // Enable offline option
                setIsOnlineEnabled(true); // Enable online option
                counsellingForm.setValue("counsellingMode", ""); // Clear counselling mode for selection
              } else {
                counsellingForm.setValue("counsellingMode", "Online"); // Set to Online if another city is selected
                setIsOfflineEnabled(false); // Disable offline option
                setIsOnlineEnabled(true); // Ensure online option remains enabled
              }
            }}
          >
            <option value="">Select City</option>
            {locations
              .find((state) => state.state === counsellingForm.watch("state"))
              ?.cities.map((city) => (
                <option key={city.name} value={city.name}>
                  {city.name}
                </option>
              ))}
          </select>
        </div>
      )}



              </div>
              {/* Radio Buttons */}
              <div className="grid grid-rows-4 laptop:gap-y-4 mobile:gap-y-2 -mt-[150px]">
                {/* Counselling Type */}
                <div className="grid">
                  <p className="mobileLg:text-xl font-medium">
                    Counselling Type:
                  </p>
                  <p className="text-red-600">
                    {counsellingForm.formState.errors.counsellingType?.message}
                  </p>
                  <div className="flex flex-wrap gap-x-4 ml-6">
                    <div className="flex gap-x-2 items-center">
                      <input
                        type="radio"
                        {...counsellingForm.register("counsellingType")}
                        value="Pre-Marital"
                        id="Pre-Marital"
                        className="w-[1.2rem] h-[1.2rem]"
                      />
                      <label htmlFor="Pre-Marital" className="mobileLg:text-xl">
                        Pre-Marital
                      </label>
                    </div>
                    <div className="flex gap-x-2 items-center">
                      <input
                        type="radio"
                        {...counsellingForm.register("counsellingType")}
                        value="Post-Marital"
                        id="Post-Marital"
                        className="w-[1.2rem] h-[1.2rem]"
                      />
                      <label
                        htmlFor="Post-Marital"
                        className="mobileLg:text-xl"
                      >
                        Post-Marital
                      </label>
                    </div>
                  </div>
                </div>

                {/* Counselling Mode */}
                <div className="grid">
        <p className="mobileLg:text-xl font-medium">Counselling Mode:</p>
        <p className="text-red-600">
          {counsellingForm.formState.errors.counsellingMode?.message}
        </p>
        <div className="flex flex-wrap gap-x-4 ml-6">
          <div className="flex gap-x-2 items-center">
            <input
              type="radio"
              {...counsellingForm.register("counsellingMode")}
              value="Offline"
              id="Counselling-Offline"
              className="w-[1.2rem] h-[1.2rem]"
              disabled={!isOfflineEnabled} // Disable if conditions aren't met
            />
            <label htmlFor="Counselling-Offline" className="mobileLg:text-xl">
              Offline
            </label>
          </div>
          <div className="flex gap-x-2 items-center">
            <input
              type="radio"
              {...counsellingForm.register("counsellingMode")}
              value="Online"
              id="Counselling-Online"
              className="w-[1.2rem] h-[1.2rem]"
              disabled={!isOnlineEnabled} // Disable online if conditions aren't met
              
            />
            <label htmlFor="Counselling-Online" className="mobileLg:text-xl">
              Online
            </label>
          </div>
        </div>
                </div>

                {/* Counselling By */}
                <div className="grid grid-row-2">
                  <p className="mobileLg:text-xl font-medium">
                    Counselling by:
                  </p>
                  <p className="text-red-600">
                    {counsellingForm.formState.errors.counsellingBy?.message}
                  </p>
                  <div className="flex flex-wrap gap-x-4 ml-6">
                    <div className="flex gap-x-2 items-center">
                      <input
                        type="radio"
                        {...counsellingForm.register("counsellingBy")}
                        value="Pastor"
                        id="Counselling-Pastor"
                        className="w-[1.2rem] h-[1.2rem]"
                      />
                      <label
                        htmlFor="Counselling-Pastor"
                        className="mobileLg:text-xl"
                      >
                        Pastor
                      </label>
                    </div>
                    <div className="flex gap-x-2 items-center">
                      <input
                        type="radio"
                        {...counsellingForm.register("counsellingBy")}
                        value="Doctor"
                        id="Counselling-Doctor"
                        className="w-[1.2rem] h-[1.2rem]"
                      />
                      <label
                        htmlFor="Counselling-Doctor"
                        className="mobileLg:text-xl"
                      >
                        Doctor
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              
              {/* Comment & book */}
              <div className="grid gap-y-3">
                {/*Available date &  Time slot */}
                <div className="grid grid-rows-2 gap-y-3">
                  <div className="grid grid-cols-2 gap-x-6 items-center">
                    <div>
                      <p className="mobileLg:text-xl">Available Date:</p>
                      <p className="text-red-600">
                        {counsellingForm.formState.errors.date?.message}
                      </p>
                    </div>
                    <input
                      type="date"
                      {...counsellingForm.register("date")}
                      className="py-2 px-4 border-2 rounded-2xl"
                    />
                  </div>
                  <div className="grid grid-cols-2 gap-x-6 items-center">
                    <div>
                      <p className="mobileLg:text-xl">Time Slot:</p>
                      <p className="text-red-600">
                        {counsellingForm.formState.errors.timeSlot?.message}
                      </p>
                    </div>
                    <select
                      {...counsellingForm.register("timeSlot")}
                      className="p-2 border border-accent rounded-lg"
                    >
                      <option value="">Time Slot</option>
                      {dropdownData.timeSlots.map((val) => (
                        <option value={val} key={val}>
                          {val}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                {/* Comment */}
                <div className="grid items-center">
                  <p className="text-xl">Your Comments:</p>
                  <p className="text-red-600">
                    {counsellingForm.formState.errors.comment?.message}
                  </p>
                  <textarea
                    {...counsellingForm.register("comment")}
                    value={counsellingForm.watch("comment")}
                    onChange={(e) =>
                      counsellingForm.setValue("comment", e.target.value)
                    }
                    name="comment-marital-counselling"
                    id="comment-marital-counselling"
                    className="border rounded-2xl min-w-full p-2"
                    rows={4}
                    placeholder="Enter your text here..."
                    required
                  />
                </div>
                <div className="flex place-content-center">
                <button
                    className="relative w-full px-8 group/btn border-2 btn rounded-md h-10 font-medium shadow-[0px_1px_0px_0px_#ffffff40_inset,0px_-1px_0px_0px_#ffffff40_inset]]"
                    type="submit"  // Change type to "button" if you want to manually handle submission
                    onClick={counsellingForm.handleSubmit(onSubmit)} // Ensure onSubmit is called on click
                  >
                    Book Appointment
                    <BottomGradient />
                  </button>

                </div>
              </div>
            </form>

            <div className="flex flex-col items-center justify-center ">
                  <h1 className="text-2xl font-bold mb-6">Check Your Booking Status</h1>
                  <div className="mb-4">
                    <label htmlFor="tmi" className="block text-sm font-medium text-gray-700">
                      Enter Your TMI
                    </label>
                    <input
                      type="text"
                      id="tmi"
                      value={tmi}
                      disabled
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                  <button
                    onClick={checkBookingStatus}
                    className="bg-blue-500 text-white px-4 py-2 rounded-md shadow-md hover:bg-blue-700"
                  >
                    Check Status
                  </button>

                  {status && (
                    <div className="mt-4">
                      <p className="text-xl font-semibold">
                        Booking Status: <span className="text-blue-600">{status}</span>
                      </p>
                    </div>
                  )}
          </div>
            <hr className="border-accentSec" />
          </div>
            {/*  book status*/}
          

          {/* How it works */}
          <div className="mt-8">
            <p className="text-2xl font-semibold text-center mb-4">
              How it works
            </p>
            <ul className="text-lg flex flex-col gap-y-2">
              <li>
                1. Select what type of marriage counselling session would you
                like to have.
              </li>
              <li>
                2. Select whom do you want to have a counselling session with.
              </li>
              <li>3. Select the mode of counselling session.</li>
              <li>4. Fill the counselling session form.</li>
              <li>
                5. We will share the in-person/online meeting details to your
                email and WhatsApp.
              </li>
              <li>
                6. Post the counselling session, please share your{" "}
                <Link to="/feedback" className="font-medium text-blue-600">
                  feedback
                </Link>{" "}
                with us.
              </li>
            </ul>
          </div>

        </div>
      </div>
    </>
  );
};

const BottomGradient = () => {
  return (
    <>
      <span className="group-hover/btn:opacity-100 block transition duration-500 opacity-0 absolute h-px w-full -bottom-px inset-x-0 bg-gradient-to-r from-transparent via-cyan-500 to-transparent" />
      <span className="group-hover/btn:opacity-100 blur-sm block transition duration-500 opacity-0 absolute h-px w-1/2 mx-auto -bottom-px inset-x-10 bg-gradient-to-r from-transparent via-indigo-500 to-transparent" />
    </>
  );
};
