import { Link } from "react-router-dom";
import { dropdownData } from "../utils/data";
import SignUpImage from "/images/signup.avif";
import locations from "../utils/locations.json";
import { countriesWithPhoneCodes } from '../utils/data'; 
import '../pages/loader.css'; 
const apiUrl = import.meta.env.VITE_API_URL;
import { SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react"; 
import { parsePhoneNumber, CountryCode } from 'libphonenumber-js';
// import { useNavigate } from 'react-router-dom';
import EmailConfirmation from "./EmailConfirmation";


const schema = z
  .object({
    firstname: z.string().min(1, { message: "Required" }),
    lastname: z.string().min(1, { message: "Required" }),
    country: z.string().min(1, { message: "Required" }),
    phoneNumber: z
      .string()
      .max(10, "Enter a valid phone number")
      .min(10, "Enter a valid phone number"),
    gender: z.string().min(1, { message: "Choose an option" }),
    emailid: z.string().email(),
    password: z.string().min(8, { message: "Password too short" }),
    confirmPassword: z.string(),
    createdFor: z.string().min(1, { message: "Choose an option" }),
    MotherTongue: z.string().min(1, { message: "Choose an option" }),
    Denomination: z.string().min(1, { message: "Choose an option" }),
    Education: z.string().min(1, { message: "Choose an option" }),
    Occupation: z.string().min(1, { message: "Choose an option" }),
    Salary: z.string().min(1, { message: "Choose an option" }),
    maritalStatus: z.string().min(1, { message: "Choose an option" }),
    DateOfBirth: z.string().min(1, { message: "Required" }),
    Height: z.string().min(1, { message: "Choose an option" }),
    Complexion: z.string().min(1, { message: "Choose an option" }),
    FamilyType: z.string().min(1, { message: "Choose an option" }),
    FamilyStatus: z.string().min(1, { message: "Choose an option" }),
    ResidenceType: z.string().min(1, { message: "Choose an option" }),
    ResidenceState: z.string().min(1, { message: "Choose an option" }),
    ResidenceCity: z.string().min(1, { message: "Choose an option" }),
    ResidenceLocality: z.string().min(1, { message: "Choose an option" }),
    profilePhoto: z
      .instanceof(FileList)
      .refine((files) => files.length > 0, "Profile photo is required"),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords don't match",
    path: ["confirmPassword"],
  });

type SignUpFormFields = z.infer<typeof schema>;

const SignUp: React.FC = () => {
  // const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);
  const [isAgreed, setIsAgreed] = useState(false);
  const [submittedEmail, setSubmittedEmail] = useState<string | null>(null);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleAgree = () => {
    setIsAgreed(true);
  };

  const handleNotAgree = () => {
    setIsAgreed(false);
    setIsChecked(false); // Uncheck the box if not agree
  };

  const { register, handleSubmit, setError, formState, watch, setValue,reset,trigger } =
  useForm<SignUpFormFields>({
    resolver: zodResolver(schema),
  });
 
  
  const [selectedCountryCode, setSelectedCountryCode] = useState<string>('');
  const [selectedCountryName, setSelectedCountryName] = useState<string>('');
  const [selectedCountryisoCode, setSelectedCountryisoCode] = useState<string>('');

  const handleCountryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCountryCode = event.target.value;
    const countryData = countriesWithPhoneCodes.find(({ code }) => code === selectedCountryCode);
  
    if (countryData) {
      setSelectedCountryCode(countryData.code);
      setSelectedCountryName(countryData.country);
      setSelectedCountryisoCode(countryData.isoCode);
      setValue("country", countryData.country);  // Update the form state with the selected country
    }
  };
 
  const selectedState = watch('ResidenceState');
  const selectedCity = watch('ResidenceCity');
  const [isLoading, setIsLoading] = useState(false);
  const { errors } = formState;

  //To make sure that only dates from 18 years ago or before can be selected
  const today = new Date();
  const maxDate = new Date(
    today.getFullYear() - 18,
    today.getMonth(),
    today.getDate()
  )
    .toISOString()
    .split("T")[0];

    const onSubmit: SubmitHandler<SignUpFormFields> = async (data) => {
      if (!isAgreed) {
        alert("You need to agree to the terms and conditions to sign up.");
        return;
      }
      console.log("Form Data on Submit:", data);
      setIsLoading(true);
      try {
        const isValid = await trigger("phoneNumber");
        console.log("Phone number valid:", isValid);
        
        if (!isValid) {
          alert("Please fix the errors in the form.");
          return;
        }
    
        if (selectedCountryCode) {
          // Use the selected country's phone code, not ISO code
          const countryCode = selectedCountryCode; // e.g., "+91"
          
          const rawPhoneNumber = data.phoneNumber;
          console.log("Raw Phone Number:", rawPhoneNumber);
          
          // Format the phone number using the selected country code
          const formattedPhoneNumber = rawPhoneNumber.startsWith('+')
            ? rawPhoneNumber
            : `${countryCode}${rawPhoneNumber}`;
    
          console.log("Formatted Phone Number:", formattedPhoneNumber);
          
          // Parse the formatted phone number
          const phoneNumberInstance = parsePhoneNumber(formattedPhoneNumber);
          
          console.log("Parsed Phone Number Instance:", phoneNumberInstance);
    
          if (!phoneNumberInstance || !phoneNumberInstance.isValid()) {
            console.log("Invalid phone number:", formattedPhoneNumber);
            alert("NOT_A_NUMBER");
            setError("phoneNumber", { message: "Phone number is not valid" });
            return;
          }
          
          console.log("Phone number is valid:", phoneNumberInstance.formatInternational());
          
          // Continue with form submission as before
          const formData = new FormData();
          formData.append("firstname", data.firstname);
          formData.append("lastname", data.lastname);
          formData.append("country", selectedCountryName);
          formData.append("phoneNumber", data.phoneNumber);
          formData.append("gender", data.gender);
          formData.append("emailid", data.emailid);
          formData.append("password", data.password);
          formData.append("confirmPassword", data.confirmPassword);
          formData.append("createdFor", data.createdFor);
          formData.append("motherTongue", data.MotherTongue);
          formData.append("denomination", data.Denomination);
          formData.append("Education", data.Education);
          formData.append("Occupation", data.Occupation);
          formData.append("Salary", data.Salary);
          formData.append("DateOfBirth", data.DateOfBirth);
          formData.append("Height", data.Height);
          formData.append("complexion", data.Complexion);
          formData.append("FamilyType", data.FamilyType);
          formData.append("familyStatus", data.FamilyStatus);
          formData.append("ResidenceType", data.ResidenceType);
          formData.append("ResidenceState", data.ResidenceState);
          formData.append("ResidenceCity", data.ResidenceCity);
          formData.append("ResidenceLocality", data.ResidenceLocality);
          formData.append("maritalStatus", data.maritalStatus);
          
          const profilePhotoFile = data.profilePhoto?.[0];
          if (profilePhotoFile) {
            formData.append("profilePhoto", profilePhotoFile);
          }
    
          console.log("Form Data:");
          formData.forEach((value, key) => {
            console.log(`${key}: ${value}`);
          });
    
          // Make HTTP request
          const response = await fetch(`${apiUrl}/api/auth/signup`, {
            method: "POST",
            body: formData,
          });
          console.log("Response:", response);
          const result = await response.json();
          console.log("Response Result:", result);
          
          if (!response.ok) {
            throw new Error(result.message || "An error occurred. Please try again.");
          }
          setIsLoading(false);
          setSubmittedEmail(data.emailid);
          reset();
          
        }
      } catch (error: any) {
        console.log("catch block", error);
        alert(error.message);
      } finally {
        setIsLoading(false); // Hide loader after completion (success or error)
      }
    };
    
    
    
    
    
    
    

  return (
    <>
    
      ({isLoading ? ( // Show loading indicator when isLoading is true
        <div className="flex justify-center items-center h-screen">
          <div className="loader"></div> {/* You can replace with your loading spinner */}
          <p className="text-lg">Registering your account, please wait...</p>
        </div>):
        submittedEmail ? (
          <EmailConfirmation email={submittedEmail} /> // Show confirmation message
        ) :
      (<form
        onSubmit={handleSubmit(onSubmit)}
        className="py-4 px-4 rounded-lg bg-accent/10 flex flex-col gap-y-6"
      >
        <h1 className="text-6xl text-center font-bold my-4">Register Today</h1>
        <div className="min-h-[28rem] rounded-2xl bg-white laptop:grid laptop:grid-cols-2">
          {/* Form section */}
          <div className=" py-12 border-r">
            {/* Main Info */}
            <div className="flex flex-col relative px-4 tablet:px-8 gap-y-4">
              <div className="flex flex-wrap gap-y-4 gap-x-12">
                <div className="flex flex-wrap gap-4">
                  {/* First Name, Last Name */}
                  <div className="flex flex-wrap gap-4">
                    <div>
                      <p className="text-lg">First Name</p>
                      <input
                        {...register("firstname")}
                        type="text"
                        placeholder="First Name"
                        className="p-2 border border-accent rounded-lg"
                      />
                      <p className="text-red-600">
                        {errors.firstname?.message}
                      </p>
                    </div>
                    <div>
                      <p className="text-lg">Last Name</p>
                      <input
                        {...register("lastname")}
                        type="text"
                        placeholder="Last Name"
                        className="p-2 border border-accent rounded-lg"
                      />
                      <p className="text-red-600">{errors.lastname?.message}</p>
                    </div>
                  </div>



                     {/* Country Field */}
                     <div className="flex flex-col laptop:w-[50%]">
                        <p className="text-lg">Country</p>
                        <select
                          {...register("country")}
                          className="p-2 border border-accent rounded-lg"
                          value={selectedCountryCode}  // Bind select value to form state
                          onChange={handleCountryChange}  // Handle country change
                        >
                          <option value="">Country</option>
                          {countriesWithPhoneCodes.map(({ code, country }) => (
                            <option value={code} key={code}>
                              {country}
                            </option>
                          ))}
                        </select>
                        <p className="text-red-600">{errors.country?.message}</p>
                      </div>
                  
                  
                  
                  
                  {/* Phone Number */}
                  <div className="flex items-center mobileLg:w-[60%] tablet:w-[40%] laptop:w-[60%] desktop:w-[40%]">
    <span className="p-2 border border-accent rounded-l-lg">
      {selectedCountryCode ? `${selectedCountryCode}` : "+XX"}
    </span>
    
    {/* Phone number input */}
    <input
  {...register("phoneNumber", {
    required: "Phone number is required",
    validate: (value) => {
      // Log the input value
      console.log("Phone number value:", value);
      
      if (!value) {
        console.log("No phone number entered.");
        return "Phone number is required";
      }

      // Check if a country is selected
      if (selectedCountryisoCode) {
        try {
          // Log the selected country code and ISO code
          console.log("Selected Country ISO Code:", selectedCountryisoCode);
          console.log("Selected Country Code:", selectedCountryCode);

          // Ensure the phone number starts with the country code
          const formattedPhoneNumber = value.startsWith('+') 
            ? value 
            : `+${selectedCountryCode}${value}`;

          // Log the formatted phone number
          console.log("Formatted Phone Number:", formattedPhoneNumber);

          // Parse the phone number using libphonenumber
          const phoneNumberInstance = parsePhoneNumber(formattedPhoneNumber, selectedCountryisoCode as CountryCode);

          console.log("Phone Number Instance:", phoneNumberInstance);

          // Check if the phone number is valid
          if (phoneNumberInstance && phoneNumberInstance.isValid()) {
            console.log("Phone number is valid");
            return true; // Valid phone number
          } else {
            console.log("Invalid phone number:", formattedPhoneNumber);
            return "Phone number is not valid";
          }
        } catch (error) {
          console.error("Error while parsing phone number:", error);
          return "Phone number is not valid";
        }
      }

      // If no country is selected
      console.log("No country selected for validation");
      return true; // Skip validation if no country is selected
    },
  })}
  type="text"
  placeholder="Phone Number"
  className="p-2 border w-full border-accent rounded-r-lg"
/>



  </div>


                  {/* Gender */}
                  <div className="w-full">
                    <div className="flex flex-col mobileLg:w-[60%] tablet:w-[40%] laptop:w-[60%] desktop:w-[40%]">
                      <p className="text-lg">Geder</p>
                      <select
                          {...register("gender")}
                          className="p-2 border border-accent rounded-lg"
                        >
                          <option value="">Gender</option>
                          {dropdownData.gender.map((val) => (
                            <option value={val} key={val}>
                              {val}
                            </option>
                          ))}
                    </select>
                      <p className="text-red-600">
                        {errors.gender?.message}
                      </p>
                    </div>
                  </div>
                </div>

                {/* Photo Upload */}
                <div>
                  {(!watch("profilePhoto") ||
                    watch("profilePhoto").length === 0) && (
                    <>
                      <label className="top-0 right-[2rem] border border-accent cursor-pointer h-48 w-40 flex place-content-center place-items-center">
                        <span className={` px-4 w-full text-center `}>
                          {/* {image ? "Uploaded" : "Upload"} */}
                          CLICK HERE TO UPLOAD A <strong>PHOTO</strong>
                        </span>
                        <input
                          {...register("profilePhoto")}
                          type="file"
                          accept="image/*"
                          name="profilePhoto"
                          className="px-4 hidden"
                        />
                      </label>
                      <p className="top-52 right-[1.7rem] text-red-600">
                        {errors.profilePhoto?.message}
                      </p>
                    </>
                  )}

                  {watch("profilePhoto") &&
                    watch("profilePhoto").length > 0 && (
                      <div className="flex flex-col top-0 right-[2rem]">
                        <div className="border border-accent place-content-center place-items-center h-48 w-40">
                          <img
                            src={URL.createObjectURL(watch("profilePhoto")[0])}
                            alt="profile photo"
                            className="object-fill h-full w-full"
                          />
                        </div>
                        <button
                          className="p-1 border border-accent mt-2"
                          type="button"
                          onClick={() => {
                            console.log("CLear clicked");
                            setValue("profilePhoto", null as any);
                          }}
                        >
                          Clear
                        </button>
                      </div>
                    )}
                </div>
              </div>

              {/* Email */}
              <div>
                <div className="flex flex-col mobileLg:w-[60%] tablet:w-[40%] laptop:w-[60%] desktop:w-[40%]">
                  <p className="text-lg">Email ID</p>
                  <input
                    {...register("emailid")}
                    type="email"
                    placeholder="Email ID"
                    className="p-2 border border-accent rounded-lg"
                  />
                  <p className="text-red-600">{errors.emailid?.message}</p>
                </div>
              </div>
              {/* Password, Confirm Password */}
              <div className="flex flex-wrap gap-4 w-full">
                <div className="flex flex-col w-full mobileLg:w-[60%] tablet:w-[40%] laptop:w-[60%] desktop:w-[40%]">
                  <p className="text-lg">Password</p>
                  <input
                    {...register("password")}
                    type="password"
                    placeholder="Password"
                    className="p-2 border border-accent rounded-lg"
                  />
                  <p className="text-red-600">{errors.password?.message}</p>
                </div>
                <div className="flex flex-col w-full mobileLg:w-[60%] tablet:w-[40%] laptop:w-[60%] desktop:w-[40%]">
                  <p className="text-lg">Confirm Password</p>
                  <input
                    {...register("confirmPassword")}
                    type="password"
                    placeholder="Confirm Password"
                    className="p-2 border border-accent rounded-lg"
                  />
                  <p className="text-red-600">
                    {errors.confirmPassword?.message}
                  </p>
                </div>
              </div>
            </div>

            <div className="bg-gradient-to-r from-transparent via-accentSec  to-transparent my-6 h-[1px] w-full" />

            {/* Additional Info */}
            <div className="flex flex-col px-4 tablet:px-8 gap-y-4">
              {/* Created For, Mother Tongue, Denomination */}
              <div className="flex flex-wrap gap-4">
                <div className="flex flex-col laptop:w-[30%]">
                  <p className="text-lg">Created For</p>
                  <select
                    {...register("createdFor")}
                    className="p-2 border border-accent rounded-lg"
                  >
                    <option value="">Created For</option>
                    {dropdownData.createdFor.map((val) => (
                      <option value={val} key={val}>
                        {val}
                      </option>
                    ))}
                  </select>
                  <p className="text-red-600">{errors.createdFor?.message}</p>
                </div>


                <div className="flex flex-col laptop:w-[30%]">
      <p className="text-lg">Mother Tongue</p>
      {selectedCountryCode === '+91' ? ( // Check if the selected country code is '+91' (India's phone code)
        <select
          {...register("MotherTongue")}
          className="p-2 border border-accent rounded-lg"
        >
          <option value="">Mother Tongue</option>
          {dropdownData.motherTongue.map((val) => (
            <option value={val} key={val}>
              {val}
            </option>
          ))}
        </select>
      ) : (
        <input
          {...register("MotherTongue")}
          type="text"
          className="p-2 border border-accent rounded-lg"
          placeholder="Enter your mother tongue"
        />
      )}
      <p className="text-red-600">{errors.MotherTongue?.message}</p>
    </div>
    
                
                        
                        
                
                  

                <div className="flex flex-col w-[80%] tablet:w-[50%] laptop:w-[30%]">
                  <p className="text-lg">Denomination</p>
                  <select
                    {...register("Denomination")}
                    className="p-2 border border-accent rounded-lg"
                  >
                    <option value="">Denomination</option>
                    {dropdownData.denominations.map((val) => (
                      <option value={val} key={val}>
                        {val}
                      </option>
                    ))}
                  </select>
                  <p className="text-red-600">{errors.Denomination?.message}</p>
                </div>
              </div>
              {/* Education */}
              <div className="">
                <div className="flex flex-col mobileLg:w-[60%]">
                  <p className="text-lg">Education</p>
                  <select
                    {...register("Education")}
                    className="p-2 border border-accent rounded-lg"
                  >
                    <option value="">Education</option>
                    {Object.entries(dropdownData.education).map(
                      ([key, values]) => (
                        <optgroup label={key} key={key}>
                          {values.map((value) => (
                            <option value={value} key={value}>
                              {value}
                            </option>
                          ))}
                        </optgroup>
                      )
                    )}
                  </select>
                  <p className="text-red-600">{errors.Education?.message}</p>
                </div>
              </div>
              {/* Marital Status*/}
              <div className="">
                <div className="flex flex-col mobileLg:w-[60%]">
                  <p className="text-lg">Marital Status</p>
                  <select
                    {...register("maritalStatus")}
                    className="p-2 border border-accent rounded-lg"
                  >
                    <option value="">Marital Status</option>
                    {dropdownData.maritalStatus.map((val) => (
                      <option value={val} key={val}>
                        {val}
                      </option>
                    ))}
                  </select>
                  <p className="text-red-600">{errors.maritalStatus?.message}</p>
                </div>
              </div>
              {/* Occupation, Salary */}
              <div className="flex flex-wrap gap-4">
                <div className="flex flex-col">
                  <p className="text-lg">Occupation</p>
                  <select
                    {...register("Occupation")}
                    className="p-2 border w-[60%] tablet:w-auto border-accent rounded-lg"
                  >
                    <option value="">Occupation</option>
                    {Object.entries(dropdownData.occupations).map(
                      ([key, values]) => (
                        <optgroup label={key} key={key}>
                          {values.map((value) => (
                            <option value={value} key={value}>
                              {value}
                            </option>
                          ))}
                        </optgroup>
                      )
                    )}
                  </select>
                  <p className="text-red-600">{errors.Occupation?.message}</p>
                </div>
                <div className="flex flex-col">
                  <p className="text-lg">Salary</p>
                  <select
                    {...register("Salary")}
                    className="p-2 border border-accent rounded-lg"
                  >
                    <option value="">Salary Range</option>
                    {dropdownData.income.map((val) => (
                      <option value={val} key={val}>
                        {val}
                      </option>
                    ))}
                  </select>
                  <p className="text-red-600">{errors.Salary?.message}</p>
                </div>
              </div>
              {/* DOB, Height, Complexion */}
              <div className="flex flex-wrap gap-4">
                <div className="flex flex-col">
                  <p className="text-lg">Date of Birth</p>
                  <input
                    {...register("DateOfBirth")}
                    max={maxDate}
                    type="date"
                    className="p-2 border border-accent rounded-lg"
                  />
                  <p className="text-red-600">{errors.DateOfBirth?.message}</p>
                </div>
                <div className="flex flex-col">
                  <p className="text-lg">Height</p>
                  <select
                    {...register("Height")}
                    className="p-2 border border-accent rounded-lg"
                  >
                    <option value="">Height</option>
                    {dropdownData.heights.map((val) => (
                      <option value={val} key={val}>
                        {val}
                      </option>
                    ))}
                  </select>
                  <p className="text-red-600">{errors.Height?.message}</p>
                </div>
                <div className="flex flex-col">
                  <p className="text-lg">Complexion</p>
                  <select
                    {...register("Complexion")}
                    className="p-2 border border-accent rounded-lg"
                  >
                    <option value="">Complexion</option>
                    {dropdownData.complexion.map((val) => (
                      <option value={val} key={val}>
                        {val}
                      </option>
                    ))}
                  </select>
                  <p className="text-red-600">{errors.Complexion?.message}</p>
                </div>
              </div>
              {/* Family Type, Family Status */}
              <div className="flex flex-wrap gap-4">
                <div className="flex flex-col">
                  <p className="text-lg">Family Type</p>
                  <select
                    {...register("FamilyType")}
                    className="p-2 border border-accent rounded-lg"
                  >
                    <option value="">Family Type</option>
                    {dropdownData.familyType.map((val) => (
                      <option value={val} key={val}>
                        {val}
                      </option>
                    ))}
                  </select>
                  <p className="text-red-600">{errors.FamilyType?.message}</p>
                </div>
                <div className="flex flex-col">
                  <p className="text-lg">Family Status</p>
                  <select
                    {...register("FamilyStatus")}
                    className="p-2 border border-accent rounded-lg"
                  >
                    <option value="">Family Status</option>
                    {dropdownData.familyStatus.map((val) => (
                      <option value={val} key={val}>
                        {val}
                      </option>
                    ))}
                  </select>
                  <p className="text-red-600">{errors.FamilyStatus?.message}</p>
                </div>
                <div className="flex flex-col">
                  <p className="text-lg">Residence Type</p>
                  <select
                    {...register("ResidenceType")}
                    className="p-2 border border-accent rounded-lg"
                  >
                    <option value="">Residence Type</option>
                    {dropdownData.residenceType.map((val) => (
                      <option value={val} key={val}>
                        {val}
                      </option>
                    ))}
                  </select>
                  <p className="text-red-600">
                    {errors.ResidenceType?.message}
                  </p>
                </div>
              </div>
              {/* Location */}
              <div className="flex flex-wrap gap-4">
                    {/* Residential State */}
                    
                          {selectedCountryCode !== '+91' ? (
                            <div className="w-full">
                              <p className="text-lg">Residential State</p>
                              <input
                                {...register("ResidenceState")}
                                type="text"
                                placeholder="Residential State"
                                className="p-2 border border-accent rounded-lg w-full"
                              />
                              <p className="text-red-600">{errors.ResidenceState?.message}</p>
                            </div>
                          ) : (
                            <div className="w-full">
                              <p className="text-lg">Residential State</p>
                              <select
                                className="p-2 border border-accent rounded-lg w-full"
                                {...register("ResidenceState")}
                              >
                                <option value="">Select State</option>
                                {locations.map((state) => (
                                  <option key={state.state} value={state.state}>
                                    {state.state}
                                  </option>
                                ))}
                              </select>
                              <p className="text-red-600">{errors.ResidenceState?.message}</p>
                            </div>
                          )}

                    {/* Residential City */}
                    {selectedCountryCode !== '+91' ? (
                          <div className="w-full">
                                <p className="text-lg">Residential City</p>
                                <input
                                  {...register("ResidenceCity")}
                                  type="text"
                                  placeholder="Residential City"
                                  className="p-2 border border-accent rounded-lg w-full"
                                />
                                <p className="text-red-600">{errors.ResidenceCity?.message}</p>
                              </div>
                            ) : selectedState ? (
                              <div className="w-full">
                                <p className="text-lg">Residential City</p>
                                <select
                                  className="p-2 border border-accent rounded-lg w-full"
                                  {...register("ResidenceCity")}
                                >
                                  <option value="">Select City</option>
                                  {locations
                                    .find((state) => state.state === selectedState)
                                    ?.cities.map((city) => (
                                      <option key={city.name} value={city.name}>
                                        {city.name}
                                      </option>
                                    ))}
                                </select>
                                <p className="text-red-600">{errors.ResidenceCity?.message}</p>
                              </div>
                            ) : null}
                    {/* Residential Locality */}
                    {selectedCountryCode !== '+91' ?(
                             <div className="w-full">
                            <p className="text-lg">Residential Locality</p>
                            <input
                            {...register("ResidenceLocality")}
                            type="text"
                            placeholder="Residential Locality"
                            className="p-2 border border-accent rounded-lg w-full"
                            />
                            <p className="text-red-600">{errors.ResidenceLocality?.message}</p>
                            </div>
                            ) : selectedCity?(
                              <div className="w-full">
                                <p className="text-lg">Residence Locality</p>
                                <select
                                  className="p-2 border border-accent rounded-lg w-full"
                                  {...register("ResidenceLocality")}
                                >
                                  <option value="">Select Locality</option>
                                  {locations
                                    .find((state) => state.state === selectedState)
                                    ?.cities.find((city) => city.name === selectedCity)
                                    ?.localities.map((locality) => (
                                      <option key={locality} value={locality}>
                                        {locality}
                                      </option>
                                    ))}
                                </select>
                                <p className="text-red-600">{errors.ResidenceLocality?.message}</p>
                              </div>
                            ):null}

                  </div>
                  <div className="my-4">
                                        <label className="flex items-center space-x-2">
                                          <input 
                                            type="checkbox" 
                                            checked={isChecked} 
                                            onChange={handleCheckboxChange} 
                                            className="form-checkbox"
                                          />
                                          <span className="text-gray-700" >I agree to the <a href="/terms" className="text-blue-500">terms and conditions</a> and 
                                          <a href="/privacy" className="text-blue-500"> privacy policy</a>
                                          </span>
                                        </label>
                  </div>

                  {/* Show terms and conditions text when checkbox is checked */}
                  {isChecked && (
                    <div className="my-4 p-4 bg-gray-100 border rounded-lg">
                      <p className="text-gray-700">
                        By agreeing to our terms and conditions, you accept our privacy policy, refund policy ,rules and regulations for using our service. Please make sure to review them carefully.
                      </p>
                      <div className="flex justify-end space-x-2 mt-4">
                        <button 
                          type="button" 
                          onClick={handleAgree} 
                          className="bg-green-500 text-white py-1 px-3 rounded hover:bg-green-600"
                        >
                          Agree
                        </button>
                        <button 
                          type="button" 
                          onClick={handleNotAgree} 
                          className="bg-red-500 text-white py-1 px-3 rounded hover:bg-red-600"
                        >
                          Not Agree
                        </button>
                      </div>
                    </div>
                  )}
            </div>
            

      
            <div className="bg-gradient-to-r from-transparent via-accentSec  to-transparent my-6 h-[1px] w-full" />

            {/* Submit Button */}
            <div className="flex place-content-center place-items-center">
            <button
  className={`relative w-[12rem] group/btn border-2 btn rounded-md h-10 font-medium shadow-[0px_1px_0px_0px_#ffffff40_inset,0px_-1px_0px_0px_#ffffff40_inset] transition-opacity duration-300 ${
    isLoading || !isAgreed || !isChecked ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-600'
  }`}
  type="submit"
  disabled={isLoading || !isAgreed || !isChecked}
>
  {isLoading ? "Submitting..." : "Submit"}
  <BottomGradient />
</button>

            </div>

            <div className="text-center mt-1">
              <p className="text-lg">OR</p>
              <p className="mt-1">
                Already a member?{" "}
                <Link
                  to="/"
                  className="text-blue-700 underline underline-offset-2"
                >
                  LogIn
                </Link>
              </p>
            </div>
          </div>
          {/* Photo section */}
          <div className="hidden laptop:block">
            <img
              src={SignUpImage}
              alt="Sign Up Today"
              className="rounded-r-2xl object-fit h-full w-full"
            />
          </div>
        </div>

       
      </form>
      )})
    </>
  );
};

export default SignUp;

const BottomGradient = () => {
  return (
    <>
      <span className="group-hover/btn:opacity-100 block transition duration-500 opacity-0 absolute h-px w-full -bottom-px inset-x-0 bg-gradient-to-r from-transparent via-cyan-500 to-transparent" />
      <span className="group-hover/btn:opacity-100 blur-sm block transition duration-500 opacity-0 absolute h-px w-1/2 mx-auto -bottom-px inset-x-10 bg-gradient-to-r from-transparent via-indigo-500 to-transparent" />
    </>
  );
};
