/* App.css or styles.css */

import { useNavigate } from "react-router-dom";
import { useAuthUser } from '../../pages/AuthUserContext';
import React, { useState, useEffect } from "react";
const apiUrl = import.meta.env.VITE_API_URL;
import { AiOutlineEye, AiOutlineHeart, AiOutlineCheckCircle, AiOutlinePhone } from 'react-icons/ai'; // Import icons

export const UserActivity: React.FC = () => {
  const [viewerCount, setViewerCount] = useState<number | null>(null);
  const [interestsCount, setInterestsCount] = useState<number | null>(null); 
  const [interestsShownCount, setInterestsShownCount] = useState<number | null>(null);
  const [shortlistedCount, setShortlistedCount] = useState<number | null>(null);
  const [mobileNumberIViewedCount, setMobileNumberIViewedCount] = useState<number | null>(null);
  const [myMobileNumberViewersCount, setMyMobileNumberViewersCount] = useState<number | null>(null);
  const navigate = useNavigate();
  const { user } = useAuthUser();

  const handleViewProfile = () => {
    const TMI = user;
    navigate(`/profilelisting/viewed?tmi=${TMI}`);
  };

  const handleInterestsReceived = () => {
    const TMI = user;
    navigate(`/profilelisting/interests?tmi=${TMI}`);
  };

  const handleInterestsShown = () => {
    const TMI = user;
    navigate(`/profilelisting/interestsshown?tmi=${TMI}`);
  };

  const handleShortlist = () => {
    const TMI = user;
    navigate(`/profilelisting/shortlisted?tmi=${TMI}`);
  };

  const handleMobileNumberViewdByMe = () => {
    const TMI = user;
    navigate(`/profilelisting/mobilenumberviewedbyme?tmi=${TMI}`);
  };

  const handleMyMobileNumberViewers = () => {
    const TMI = user;
    navigate(`/profilelisting/mymobilenumberviewers?tmi=${TMI}`);
  };

  useEffect(() => {
    const fetchCounts = async () => {
      const TMI = user;
      try {
        const viewerResponse = await fetch(`${apiUrl}/api/viewed-profiles/profile/${TMI}/count`);
        const interestsResponse = await fetch(`${apiUrl}/api/interests-received/${TMI}/count`);
        const interestsShownResponse = await fetch(`${apiUrl}/api/interests-shown/${TMI}/count`);
        const shortlistedResponse = await fetch(`${apiUrl}/api/shortlisted-profiles/${TMI}/count`);
        const mobileNumberViewedByMe=await fetch(`${apiUrl}/api/viewed-mobile/${TMI}/count`);
        const myMobileNumberViewers=await fetch(`${apiUrl}/api/viewed-my-mobile/${TMI}/count`);


        if (viewerResponse.ok) {
          const data = await viewerResponse.json();
          setViewerCount(data.viewerCount);
        }
        if (interestsResponse.ok) {
          const data = await interestsResponse.json();
          setInterestsCount(data.interestsCount);
        }
        if (interestsShownResponse.ok) {
          const data = await interestsShownResponse.json();
          setInterestsShownCount(data.interestsShownCount);
        }
        if (shortlistedResponse.ok) {
          const data = await shortlistedResponse.json();
          setShortlistedCount(data.shortlistedProfilesCount);
        }
        if (mobileNumberViewedByMe.ok) {
          const data = await mobileNumberViewedByMe.json();
          setMobileNumberIViewedCount(data.mobileIViewedcount);
        }

        if (myMobileNumberViewers.ok) {
          const data = await myMobileNumberViewers.json();
          setMyMobileNumberViewersCount(data.myMobileViewers);
        }


      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchCounts();
  }, [user]);

  return (
    <div className="bg-gray-50  rounded-xl shadow-md p-10 m-5">
      <h2 className="font-spectral-200 py-1 mt-2 text-3xl text-gray-900 text-center tracking-wide ">Activity Summary</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 mt-6 px-5 md:px-10"> {/* Responsive grid setup */}

        {/* Who Viewed My Profile */}
        <a
          onClick={handleViewProfile}
          className="flex p-4 flex-col cursor-pointer rounded-xl bg-gradient-to-r from-blue-400 to-pink-500 hover:scale-105 hover:shadow-lg transform transition-all duration-500 ease-out items-center gap-y-2 border-2 w-[90%] mx-auto"
        >
          <AiOutlineEye className="text-white text-4xl animate-pulse" />
          <span className="text-white font-semibold text-lg">Who Viewed My Profile</span>
          <p className="text-3xl font-bold text-white">{viewerCount !== null ? viewerCount : 'Loading...'}</p>
        </a>

        {/* Who Viewed My Mobile Number */}
        <a
          onClick={handleMyMobileNumberViewers}
          className="flex p-4 flex-col cursor-pointer rounded-xl bg-gradient-to-r from-red-500 to-blue-500 hover:scale-105 hover:shadow-lg transform transition-all duration-500 ease-out items-center gap-y-2 border-2 w-[90%] mx-auto"
        >
          <AiOutlinePhone className="text-white text-4xl animate-pulse" />
          <span className="text-lg text-white font-semibold">Who viewed my mobile no.</span>
          <p className="text-3xl font-bold text-white">{myMobileNumberViewersCount !== null ? myMobileNumberViewersCount : 'Loading...'}</p>
        </a>

        {/* Interests I Received */}
        <a
          onClick={handleInterestsReceived}
          className="flex p-4 flex-col cursor-pointer rounded-xl bg-gradient-to-r from-green-400 to-teal-500 hover:scale-105 hover:shadow-lg transform transition-all duration-500 ease-out items-center gap-y-2 border-2 w-[90%] mx-auto"
        >
          <AiOutlineHeart className="text-white text-4xl animate-pulse" />
          <span className="text-white font-semibold text-lg">Interests I received</span>
          <p className="text-3xl font-bold text-white">{interestsCount !== null ? interestsCount : 'Loading...'}</p>
        </a>

        {/* Interests Shown By Me */}
        <a
          onClick={handleInterestsShown}
          className="flex p-4 flex-col cursor-pointer rounded-xl bg-gradient-to-r from-green-400 to-blue-500 hover:scale-105 hover:shadow-lg transform transition-all duration-500 ease-out items-center gap-y-2 border-2 w-[90%] mx-auto"
        >
          <AiOutlineCheckCircle className="text-white text-4xl animate-pulse" />
          <span className="text-white font-semibold text-lg">Interests shown by me</span>
          <p className="text-3xl font-bold text-white">{interestsShownCount !== null ? interestsShownCount : 'Loading...'}</p>
        </a>

        {/* Profiles Shortlisted By Me */}
        <a
          onClick={handleShortlist}
          className="flex p-4 flex-col cursor-pointer rounded-xl bg-gradient-to-r from-yellow-400 to-orange-500 hover:scale-105 hover:shadow-lg transform transition-all duration-500 ease-out items-center gap-y-2 border-2 w-[90%] mx-auto"
        >
          <AiOutlineCheckCircle className="text-white text-4xl animate-pulse" />
          <span className="text-white font-semibold text-lg">Profiles shortlisted by me</span>
          <p className="text-3xl font-bold text-white">{shortlistedCount !== null ? shortlistedCount : 'Loading...'}</p>
        </a>

        {/* Mobile Number Viewed By Me */}
        <a
          onClick={handleMobileNumberViewdByMe}
          className="flex p-4 flex-col cursor-pointer rounded-xl bg-gradient-to-r from-blue-600 to-green-500 hover:scale-105 hover:shadow-lg transform transition-all duration-500 ease-out items-center gap-y-2 border-2 w-[90%] mx-auto"
        >
          <AiOutlinePhone className="text-white text-4xl animate-pulse" />
          <span className="text-lg text-white font-semibold">Mobile no. viewed by me</span>
          <p className="text-3xl font-bold text-white">{mobileNumberIViewedCount !== null ? mobileNumberIViewedCount : 'Loading...'}</p>
        </a>
      </div>
    </div>
  );
};


