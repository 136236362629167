const Privacy = () => {
  return (
    <div className="overflow-y-auto h-[100vh] p-4 md:p-6 lg:p-8 bg-white rounded shadow-lg">
      <h2 className="text-2xl font-bold mb-4 text-center">Privacy Policy</h2>
      <p className="mb-4 text-justify">
        Triune Matrimony ("Triune" / "we" / "us") recognizes the importance of privacy and is 
        committed to protecting the privacy of its users. This Privacy Policy ("Policy") explains how we collect, use, 
        disclose, and transfer personal information of users through its website (collectively referred to as the 
        "Website"). This Policy applies to those who visit the Website or application (App) or whose information is 
        collected during the use of our services, Website/App, and features owned or operated by Triune ("Users" / "you" 
        / "your"). By accessing or using the Website/App, and providing your personal information, you agree to the 
        practices and policies outlined in this Privacy Policy. If you do not agree with our policies and practices, please 
        refrain from using our Website and App.
      </p>

      <h3 className="font-bold text-lg mt-4">1. Information We Collect:</h3>
      <p className="mb-2">To provide you with the best matrimonial services, we collect the following types of information when you register, interact with other users, or engage with our services:</p>
      <ul className="list-disc pl-6 mb-4">
        <li> Personal Information: Name, date of birth, educational qualifications, contact details, gender, photographs, and other details voluntarily provided by you.</li>
        <li> Verification Data: Identity proof documents (e.g., Aadhaar, PAN), address proof, and other verification data, collected with your explicit consent for identity verification purposes.</li>
        <li> Financial Information: Billing address, payment details (such as credit/debit card information), and transaction history when you make payments on our Website/App.</li>
        <li> Technical Information: IP address, device ID, browser type, device specifications, and geographic location data collected when you access our Website/App.</li>
        <li> Social Media Information: Data from social networking platforms (e.g., Facebook, Google) if you register or log in using these accounts, including public profile information.</li>
      </ul>

      <h3 className="font-bold text-lg">2. Purpose of Data Collection:</h3>
      <p className="mb-2">We collect and use your personal information for the following purposes:</p>
      <ul className="list-disc pl-6 mb-4">
        <li> Profile Verification and Authentication: To verify your identity and ensure the authenticity of your profile.</li>
        <li> Personalized Services: To provide customized matrimonial services and improve your overall experience on the platform.</li>
        <li> Communication: To keep you informed about our services, updates, and offers.</li>
        <li> Data Analysis and Improvement: To analyze user activity, usage trends, and improve our services, features, and user experience.</li>
        <li> Payment Processing: To process payments for subscription plans and maintain secure transaction records.</li>
        <li> Fraud Prevention: To detect, prevent, and address fraudulent activities, unauthorized access, or other illegal activities.</li>
        <li> Legal Compliance: To ensure adherence to legal and regulatory requirements, including data protection laws.</li>
      </ul>
      <p className="mb-2">Additionally, your data helps us:</p>
      <ul className="list-disc pl-6 mb-4">
        <li> Facilitate connections between members for matrimonial purposes.</li>
        <li> Enhance user experience through personalized recommendations based on profile data.</li>
        <li> Verify member identities to maintain the trustworthiness and safety of the platform.</li>
        <li> Comply with relevant laws and ensure the security of our platform.</li>
      </ul>
      <p className="mb-4">
        You have the right to understand how your data is used and shared. Triune Matrimonial only shares your personal data with authorized third parties, strictly in accordance with your consent and applicable legal obligations.
      </p>

      <h3 className="font-bold text-lg">3. Consent and Data Processing:</h3>
      <p className="mb-2">By registering on Triune Matrimonial and using our services, you explicitly consent to the collection, use, disclosure, and storage of your personal data, including sensitive personal information such as identity proofs, photographs, and educational qualifications, as described in this Privacy Policy. We collect this information to provide you with personalized matrimonial services, verify identities, and ensure a secure platform. Triune Matrimonial ensures that your personal information is processed fairly, lawfully, and transparently.</p>
      <h4 className="font-bold">How We Obtain Consent:</h4>
      <ul className="list-disc pl-6 mb-4">
        <li> Explicit Consent: We seek your explicit consent at the time of registration, and you will be required to provide consent before submitting any sensitive personal data (such as profile details, contact information, or identity verification).</li>
        <li> Withdrawal of Consent: You may withdraw your consent at any time by contacting us at the details provided below. However, please note that withdrawing your consent may limit your access to certain services or result in the termination of your profile.</li>
      </ul>

      <h3 className="font-bold text-lg">4. Data Minimization and Purpose Limitation:</h3>
      <p className="mb-4">We collect only the minimum necessary personal data required for the intended purpose. Your data will not be used for purposes unrelated to the services we provide, without your explicit consent.</p>

      <h3 className="font-bold text-lg">5. Data Retention:</h3>
      <p className="mb-4">We retain your personal information as long as it is necessary to provide you with our services or for other legitimate purposes, such as enforcing agreements, conducting audits, resolving disputes, or complying with legal obligations. Upon your request, we will delete or anonymize your personal information, unless we are required to retain it for legal reasons.</p>

      <h3 className="font-bold text-lg">6. Your Data Rights:</h3>
      <p className="mb-2">You have the following rights regarding your personal information:</p>
      <ul className="list-disc pl-6 mb-4">
        <li> Access: You can request access to your personal information that we hold.</li>
        <li> Correction: You can request corrections or updates to your personal information.</li>
        <li> Deletion: You can request the deletion of your personal information, subject to legal obligations.</li>
        <li> Portability: You can request the transfer of your data to another platform.</li>
        <li> Consent Withdrawal: You can withdraw your consent for the collection and use of your data at any time.</li>
      </ul>
      <p className="mb-4">To exercise your rights, please contact our Data Protection Officer as per clause 11 below.</p>

      <h3 className="font-bold text-lg">7. Data Security:</h3>
      <p className="mb-4">We implement industry-standard technical and organizational security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no system can guarantee 100% security. We advise you to take precautions, such as using strong passwords and avoiding sharing sensitive information over unsecured networks.</p>

      <h3 className="font-bold text-lg">8. Data Sharing and Disclosure:</h3>
      <p className="mb-2">We may share your personal data with third parties under the following circumstances:</p>
      <ul className="list-disc pl-6 mb-4">
        <li> Service Providers: We may share your data with third-party service providers who assist us in delivering our services, such as payment processors, anti-fraud solutions, and IT support.</li>
        <li> Legal Obligations: We may disclose personal data if required by law, in response to legal processes, or to protect the safety of users or the public.</li>
        <li> Business Transfers: In the event of a merger, acquisition, or sale of assets, your personal data may be transferred as part of the business transaction.</li>
        <li> Cross-border Data Transfers: If your personal data is transferred outside of India, we ensure that adequate safeguards are in place, including compliance with Digital Protection Data Protection Act, 2023 and other applicable data protection laws.</li>
      </ul>

      <h3 className="font-bold text-lg">9. Third-party Links and Cookies:</h3>
      <p className="mb-4">Our Website/App may contain links to third-party websites. We are not responsible for the privacy practices or content of these third-party sites. We also use cookies to enhance your experience. You can control cookie preferences through your browser settings.</p>

      <h3 className="font-bold text-lg">10. International Data Transfers:</h3>
      <p className="mb-4">In some cases, we may transfer your data to third-party service providers or partners located outside India, in accordance with applicable laws. These transfers are made with adequate safeguards to ensure the security and protection of your personal information.</p>

      <h3 className="font-bold text-lg">11. Grievance Redressal Mechanism:</h3>
      <p className="mb-2">If you have any grievances regarding the processing of your personal information, you may contact our Data Protection Officer (DPO) at:</p>
      <p>Email: <a href="mailto:grievance@triunematrimony.com" className="text-blue-600">grievance@triunematrimony.com</a></p>
      <p className="mb-4">We will acknowledge your grievance within 24 hours and resolve it within 30 days, as required by Indian law.</p>

      <h3 className="font-bold text-lg">12. Changes to the Privacy Policy:</h3>
      <p className="mb-4">We may update this Privacy Policy from time to time. Any changes will be posted on this page. It is your responsibility to review this policy periodically. By continuing to use our Website/App after any updates to this Privacy Policy, you acknowledge that you accept the changes.</p>
    </div>
  );
};

export default Privacy;
